import React from 'react';
import '../tooltips/tooltips.css'
import stats from '../custom_icons/stats.svg'
import copy from '../custom_icons/copy.svg'
import trash_red from '../custom_icons/trash_red.svg'
import ruble_red from '../custom_icons/ruble_red.svg'
import users from '../custom_icons/users.svg'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { openDuplicateOrderThisWindow } from '../utils_android/Utils';
import { BotOrderStates, getIsOrderCompleted } from '../../bot_orders/utils/BotUtils';

const strings = getLocalizedStrings();

const OrderMoreTooltip = ({ order, fromBots, fromSite, showIdfa, toogleMore, deleteOrder, onRemoveMoney }) => {
    const openStats = () => {
        window.location.href = "/statistics?bundle=" + order.bundle;
    };

    const duplicateOrder = () => {
        openDuplicateOrderThisWindow(order, fromBots, fromSite);
    };

    const handleShowIdfa = () => {
        showIdfa();
        toogleMore();
    };

    const handleDeleteOrder = () => {
        toogleMore();
        deleteOrder();
    };
    const onToggleRemoveMoney = () => {
        onRemoveMoney()
        toogleMore()
    }
    return (
        <div className="display_block">
            <div onClick={toogleMore} className="tooltip_bg_up_balance" />
            <div className="tooltip_more_plashka">
                <div className="tooltip_more_arrow" />
                <div className="bg_white_line" />
                <div className="display_block">
                    <div className="padding_24 tooltip_right">
                        {(!fromBots && !fromSite) && (
                            <div onClick={openStats} style={{ paddingBottom: '16px' }} className="up_balance_info hover">
                                <img alt="" src={stats} />
                                <p className="up_balance_info_text">{strings.statsProp}</p>
                            </div>
                        )}

                        <div onClick={duplicateOrder} className="up_balance_info hover">
                            <img alt="" src={copy} />
                            <p className="up_balance_info_text">{strings.duplicateOrder}</p>
                        </div>

                        {(!fromBots && !fromSite) && (
                            <div onClick={handleShowIdfa} className="up_balance_info top_16 hover">
                                <img alt="" src={users} />
                                <p className="up_balance_info_text">{strings.idfaTitle}</p>
                            </div>
                        )}

                        {/* {(!fromBots || (fromBots && !getIsOrderCompleted(order) && order.state != BotOrderStates.canceled && order.state != BotOrderStates.waiting)) && ( */}
                        {(!fromBots) && (
                            <div onClick={onToggleRemoveMoney} className="up_balance_info top_16 hover">
                                <img alt="" src={ruble_red} />
                                <p className="up_balance_info_text red_text">
                                    {strings.returnRemainingFunds}
                                </p>
                            </div>
                        )}
                        {(!fromBots) && (
                            <div onClick={handleDeleteOrder} className="up_balance_info top_16 hover">
                                <img alt="" src={trash_red} />
                                <p className="up_balance_info_text red_text">
                                    {fromBots ? strings.cancelOrderCap : strings.deleteOrderInfo}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderMoreTooltip;
