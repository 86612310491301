import {  getHttpParams } from "../pages_android/custom_views/analyze_review/utils/Utils";
import { getRequestParams, getRequestUrl } from "../pages_android/custom_views/utils_android/CookieHelper";
import { log, onResponse } from "./ApiUtils";
export class UserApi {
    
    static setMailingEnable(mailing_enable, onSuccess, onError) {
        var params = getRequestParams()
        params.mailing_enable = mailing_enable ? 1 : 0
        fetch(getRequestUrl() + "setMailingEnable", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("setMailingEnable response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   
   
}