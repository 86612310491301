/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import '../../css_android/bot_orders.css'
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import NewOrderSearchLine from '../custom_views/NewOrderSearchLine';
import { getUrlParameter } from '../custom_views/utils_android/UrlHelperAndroid';
import SearchRequest from '../custom_views/SearchRequest';
import CountryPicker from '../custom_views/analyze_review/customViews/CountryPicker';
import InstallsCount from '../custom_views/InstallsCount';
import Limits from '../custom_views/Limits';
import { BotApi } from '../../api/BotApi';
import { getRequestParams } from '../custom_views/utils_android/CookieHelper';
import CreateOrderButton from '../custom_views/analyze_review/customViews/CreateOrderButton';
import Toast from '../custom_views/utils_android/Toast';
import ProgressOrder from '../custom_views/ProgressOrder';
import { AppsType, Pages } from '../custom_views/analyze_review/utils/Utils';

var strings = getLocalizedStrings()

function CreateBotOrder(props) {
    const [name, setName] = useState("")
    const [icon, setIcon] = useState("")
    const [type_os, setType_os] = useState("")
    const [bundle, setBundle] = useState("")
    const [price, setPrice] = useState(0)
    const [version_os, setVersion_os] = useState("")
    const [category, setCategory] = useState("")
    const [id, setId] = useState("")

    const [isSearchChecked, setIsSearchChecked] = useState(false)
    const [isLimitDayChecked, setLimitDayChecked] = useState(false)
    const [isProgressOrder, setProgressOrder] = useState(getUrlParameter("progress_order"))
    const [isLoading, setLoading] = useState(false)
    const [searchPostion, setSearchPostion] = useState("")
    const [searchText, setSearchText] = useState("")

    const [countries, setCountries] = useState([])
    const [installCount, setInstallCount] = useState(100)
    const [limitDay, setLimitDay] = useState(100)

    const [orderPrices, setOrderPrices] = useState({})
    const [progress_array, setProgress_array] = useState([])
    const [toastText, setToastText] = useState("")

    var preOrderBundle = getUrlParameter("bundle")
    var preOrderTypeOs = getUrlParameter("os")
    var preOrderKeywords = getUrlParameter("word")
    var preOrderPositions = getUrlParameter("positions")

    useEffect(() => {
       BotApi.getPrices((responseData) => {
            setOrderPrices(responseData)
       })
      
    }, [])
    const appPicked = (name, icon, type_os, bundle, price, version_os, id, category) => {
        setName(name)
        setIcon(icon)
        setType_os(type_os)
        setBundle(bundle)
        setPrice(price)
        setVersion_os(version_os)
        setCategory(category)
        setId(id)

        //this.calcPrice(this.state.is3DayChecked, this.state.isCheckedReview, this.state.isGoodReviewsTop, type_os, price, this.state.installCount)
        
    }
    const searchAppPosition = (isSearchChecked, searchPostion, searchText) =>{
        setIsSearchChecked(isSearchChecked)
        setSearchPostion(searchPostion)
        setSearchText(searchText)
    }
    const installCountChanged = (count) => {
        setInstallCount(count)
    }
    const limitsChanged = (isLimitDayChecked, limitDay) => {
        setLimitDayChecked(isLimitDayChecked)
        setLimitDay(limitDay)
        if (isLimitDayChecked){
            setProgressOrder(false)
        }
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 3000)
    }
    const progressChanged = () => {
        setProgressOrder(true)
        setLimitDayChecked(false)
    }
    const disbleLimits = () => {
        setProgressOrder(false)
        setLimitDayChecked(false)
    }
    var selectedCountries = countries.filter(c => c.is_selected)
    const isIos = type_os == AppsType.ios
    const isAndroid = type_os == AppsType.android
    const isRustore = type_os == AppsType.rustore
    const createOrder = () => {
        if (isRustore){
            showToast(strings.rustoreDisabled)
            return
        }
        if (isLoading){
            return
        }
        setLoading(true)

        var params = getRequestParams()
        params.count_installs = installCount
        if (isLimitDayChecked){
            params.day_limit = limitDay
        } else if (isProgressOrder){
            params.enable_progress_distribution = 1
        }
        params.countries = JSON.stringify(selectedCountries)
        if (isSearchChecked){
            params.keywords = searchText
            params.search_position = searchPostion
        }
        params.app_icon = icon
        params.app_name = name
        params.bundle = bundle
        params.version_os = version_os
        params.price = price
        params.appstore_id = id
        params.type_os = type_os
        console.log("createOrder params = ", params)

        BotApi.addOrder(params, (responseData) => {
            showToast(strings.siteOrderCreated)
            setLoading(false)
            window.location.href = Pages.installsOrders
            
        }, (responseData) => {
            showToast(responseData.status)
            setLoading(false)
        })
    }
    const countKeywords = (isSearchChecked && searchText.includes(",")) ? searchText.split(",").length : 0
    const minInstallsCount = isIos ? (isProgressOrder ? 500 : 10) : (isProgressOrder ? 500 : 50)
    const getProgressionDistribution = () => {
        if (isProgressOrder && installCount >= minInstallsCount){
            BotApi.getProgressionDistribution(installCount, countKeywords, (responseData) => {
                setProgress_array(responseData.result.count_list)
            }, () => {

            })
        }
        
    }
    useEffect(() => {
        getProgressionDistribution()
    }, [installCount, isProgressOrder, countKeywords])

    const priceOnInstall = ((isAndroid || isRustore) || type_os == "") ? (isSearchChecked ? orderPrices.PRICE_ANDROID_WITH_KEYWORD : orderPrices.PRICE_ANDROID) : (isSearchChecked ? orderPrices.PRICE_IOS_WITH_KEYWORD : orderPrices.PRICE_IOS)
    const sumOrder = priceOnInstall * installCount
    const isEnabledButton = selectedCountries.length > 0 && bundle != "" && installCount && installCount >= (isSearchChecked ? (minInstallsCount * (countKeywords <= 0 ? 1 : countKeywords)) : minInstallsCount) && (isLimitDayChecked ? limitDay >= minInstallsCount : true)
    const isNotEnoughtMoney = isEnabledButton && props.count_money_r != undefined && sumOrder > props.count_money_r
    return (
        <div>
            <div className="main_container" style={{minHeight : 'auto'}}>
                {/* <div className="main_title">
                    <div className='create_order_prices'>
                        <div className='create_order_j_emoji'>🤑</div>
                        <div className='create_order_j_flex'>
                            <div className='flex'><div className='android_ml' style={{marginLeft : '10px'}}>{strings.androindInstallsPrice}</div> <div className='order_price_bold order_price_android'>{strings.fromM} 4₽</div></div>
                            <div className='flex '><div className='ios_ml' style={{marginLeft : '10px'}}>{strings.iosInstallsPrice}</div> <div className='order_price_bold order_price_ios'>{strings.fromM} 12₽</div></div>
                        </div>
                    </div>
                </div> */}
                <div className='create_order_just'>
                        <div className="main_title">
                            {strings.newOrder}
                            <span style={{opacity : 0.3, marginLeft : '8px'}}>{strings.menuWorld}</span>
                        </div>
                        {orderPrices.PRICE_ANDROID != undefined && 
                            <div className='create_order_prices'>
                                <div className='create_order_j_emoji'>🤑</div>
                                <div className='create_order_j_flex'>
                                    <div className='flex'><div className='android_ml' style={{marginLeft : '10px'}}>{strings.androindInstallsPrice}</div> <div className='order_price_bold order_price_android'>{strings.fromM} {orderPrices.PRICE_ANDROID}₽</div></div>
                                    <div className='flex '><div className='ios_ml' style={{marginLeft : '10px'}}>{strings.iosInstallsPrice}</div> <div className='order_price_bold order_price_ios order_price_ios_world'>{strings.fromM} {orderPrices.PRICE_IOS}</div></div>
                                </div>
                            </div>}
                </div>
                <div className='up_to_max_installs'>{strings.installsLimitAndroid}</div>
                <NewOrderSearchLine isDisabled={false} preOrderBundle={preOrderBundle} preOrderTypeOs={preOrderTypeOs} appPicked={appPicked} description={strings.nameDescription} />
                <div className="line-row"/>
                <CountryPicker countries={countries} setCountries={setCountries}/>
                <div className="line-row"/>
                <InstallsCount fromBots isProgressOrder={isProgressOrder} countKeywords={countKeywords <= 0 ? 1 : countKeywords} minInstallsCount={minInstallsCount} type_os={type_os} installCountChanged={installCountChanged}/>
                <div className="line-row"/>
                <SearchRequest bots_countries={countries} fromBots preOrderPositions={preOrderPositions} preOrderKeywords={preOrderKeywords} preOrderTypeOs={preOrderTypeOs} searchAppPosition={searchAppPosition} type_os={preOrderTypeOs ? preOrderTypeOs : type_os} bundle={preOrderBundle ? preOrderBundle : bundle} />

            </div>
            <div className="main_container" style={{minHeight : 'auto', marginTop : '16px'}}>
                <p className="installs_limits_title">{strings.installsLimits}</p>
                <div className="line-row left_40"/>
                <div className="flex">
                    <div onClick={disbleLimits} style={(!isLimitDayChecked && !isProgressOrder) ? {border : "1px solid #F5DB31"} : {}} className="radio_limits hover">
                        {(!isLimitDayChecked && !isProgressOrder) && <div className="radio_inside"/>}
                    </div>
                    <p className="text-pre-subscr">{strings.noLimitsTitle}</p>
                </div>
                <div className="line-row left_40"/>
                <ProgressOrder fromBots countKeywords={countKeywords <= 0 ? 1 : countKeywords} progress_array={progress_array} isProgressOrder={isProgressOrder} progressChanged={progressChanged} installCount={installCount}/>

                <div className="line-row left_40"/>
                <Limits fromBots minInstallsCount={minInstallsCount} isLimitDayChecked={isLimitDayChecked} limitsChanged={limitsChanged}/>
                <CreateOrderButton isLoading={isLoading} isEnabledButton={isEnabledButton} exchangeRate={props.exchangeRate} isSdkError={false} sumOrder={sumOrder} priceOnInstall={priceOnInstall} createOrder={createOrder} installCount={installCount} isShowHelp={false} isSdkControll={false}/>
                {isNotEnoughtMoney && <p className="installs_error">{strings.notEnoughtMoneyBots} {Math.ceil(sumOrder - props.count_money_r)}₽</p>}

            </div>
            {toastText != "" && <Toast text={toastText}/>}
        </div> 
    )
}

export default CreateBotOrder
