import React from 'react';
import { useHistory, Link } from "react-router-dom";
import close_order from '../custom_icons/close_order.svg'

function OrderBack(props) {
    const history = useHistory()
    const back = () => {
        history.goBack()
    }
    return (
            <img onClick={back} src={close_order} className="close_order_icon hover" />
    )
    
}
export default OrderBack;