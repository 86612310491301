/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { getRequestParams, getRequestUrl } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getUrlParameter } from '../pages_android/custom_views/utils_android/UrlHelperAndroid';
import { getDates, getStatsParams, getFormattedDate } from './custom_views/utils_android/Utils';
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import arrow_filter from './custom_views/custom_icons/drop_arrow.svg'
import AppsDropdown from './custom_views/AppsDropdown';
import android_icon from './custom_views/custom_icons/android.svg'
import ios_icon from './custom_views/custom_icons/apple.svg'
import FilterDropdown from './custom_views/FilterDropdown';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../css_android/dateRangeStyle.css'; // theme css file
import { DateRange } from 'react-date-range';
import ruLocale from 'date-fns/locale/ru'
import moment from 'moment';
import StatsChart from './custom_views/StatsChart';
import cross_icon from './custom_views/custom_icons/cross.svg'
import EmptyStats from './custom_views/empty_views/EmptyStats';
import Loader from './custom_views/utils_android/LoaderAndroid';
import { getHttpParams } from './custom_views/analyze_review/utils/Utils';
import { formateDate } from './custom_views/analyze_review/utils/DateHelper';
var strings = getLocalizedStrings()
var filters = [strings.filterCampainRequest, strings.filterCampainReviews]
var dropdownStyle = {
    margin: "35px 0px 0px -6px",
    top: "auto"
}

var arrowRotate = {
    transform: 'rotate(180deg)',
    marginTop: '6px'
}
var notDisplay = { display: 'none' }
var display = { display: 'block' }
var androidIconStyle = { marginTop: '2px', display: 'block' }
var colors = ['#2F80ED', '#1ED9C7', '#F2994A', '#EB5757', '#9B51E0', '#6FCF97', '#56CCF2', '#219653', '#4F4F4F', '#828282']

function Statistics(props) {
    const bundle = getUrlParameter("bundle");
    const initialSelectionRange = {
        startDate: new Date(moment().subtract(6, "days")),
        endDate: new Date(),
        key: 'selection',
    };

    // State hooks
    const [pickedApp, setPickedApp] = useState({ name: strings.pickApp });
    const [isShowApps, setIsShowApps] = useState(false);
    const [isShowFilters, setIsShowFilters] = useState(false);
    const [filterPicked, setFilterPicked] = useState(strings.filterCampainRequest);
    const [isShowDates, setIsShowDates] = useState(false);
    const [selectedRange, setSelectedRange] = useState(initialSelectionRange);
    const [installsData, setInstallsData] = useState([]);
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [settedBundle] = useState(bundle || "");
    const [isLoading, setIsLoading] = useState(false);
    const [allInstallsCount, setAllInstallsCount] = useState(0);
    const [allPrice, setAllPrice] = useState(0);
    const [appsList, setAppsList] = useState([]);

    // Методы
    const toogleApps = () => {
        // setIsShowApps(!isShowApps)
        if (appsList && appsList.length > 0) {
            setIsShowApps(!isShowApps);
        }
    };

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const handleSelect = (ranges) => {
        if (ranges.selection.startDate != ranges.selection.endDate) {
            setIsShowDates(false);
            getStats(ranges.selection, pickedApp, filterPicked);
        }
        setSelectedRange(ranges.selection);
    };
    const getStats = (ranges, app, filter) => {
        // Ранняя проверка валидности приложения
        if (app.name == strings.pickApp || app.name == undefined) {
            return;
        }

        setInstallsData([]);
        setIsLoading(true);

        const params = getStatsParams(ranges, app);
        const method = filter !== strings.filterCampainRequest ? "getOrderReviewStatistic" : "getOrderStatistic";
        const dateLabels = getDates(new Date(moment(ranges.startDate).subtract(1, "days")), ranges.endDate);

        fetch(getRequestUrl() + method, getHttpParams(params))
            .then((response) => response.json())
            .then((responseData) => {
                const installsData = [];
                let statsAllInstallsCount = 0;
                let statsAllPrice = 0;
                console.log("method = ", responseData)
                Object.entries(responseData).forEach(([_, orderData], colorIndex) => {
                    const employers_dates = filter == strings.filterCampainRequest 
                        ? orderData.employers_dates 
                        : orderData.employers_dates_review;

                    if (employers_dates.length > 0) {
                        const installs = dateLabels.map(date => {
                            const foundDate = employers_dates.find(currentDate => 
                                date == getFormattedDate(currentDate.date)
                            );

                            if (foundDate) {
                                const count = filter == strings.filterCampainRequest 
                                    ? foundDate.count_installs 
                                    : foundDate.count_reviews;
                                
                                statsAllInstallsCount += count;
                                statsAllPrice += count * orderData.price_one_install;
                                
                                return count;
                            }
                            return 0;
                        });

                        const searchReq = orderData.search_request || strings.withoutSearchReq;
                        const color = colorIndex < colors.length ? colors[colorIndex] : getRandomColor();

                        installsData.push({
                            data: installs,
                            label: searchReq,
                            borderColor: color,
                            backgroundColor: color,
                            pointBorderColor: color,
                            pointRadius: 3,
                            borderWidth: 1,
                            pointBackgroundColor: "white",
                            fill: false,
                        });
                    }
                });

                if (responseData.length > 0) {
                    setAllPrice(statsAllPrice);
                }
                
                setInstallsData(installsData);
                setLabels(dateLabels);
                setIsLoading(false);
                setAllInstallsCount(statsAllInstallsCount);
            });
    };

    const onAppPicked = (app) => {
        getStats(selectedRange, app, filterPicked);
        setPickedApp(app);
    };

    const onFilterPicked = (filter, index) => {
        if (filter !== filterPicked) {
            getStats(selectedRange, pickedApp, filter);
        }
        setFilterPicked(filter);
    };

    // useEffect для начальной загрузки
    // useEffect(() => {
        
    //     getStats(selectedRange, pickedApp, filterPicked)
    // }, [selectedRange])
    useEffect(() => {
        getMyApps();
    }, []); // Пустой массив зависимостей для выполнения только при монтировании
    const getMyApps = () => {
        const params = getRequestParams();
        fetch(getRequestUrl() + "getMyApps", getHttpParams(params))
            .then((response) => response.json())
            .then((responseData) => {
                console.log("getMyApps responseData = ", responseData)
                if (responseData.response > 0 && responseData.apps.length > 0) {
                    const apps = responseData.apps.reverse();
                    let settedApp = apps[0];

                    if (settedBundle !== "") {
                        settedApp = apps.find(element => element.bundle === settedBundle) || settedApp;
                    }
                    setAppsList(apps);
                    setPickedApp(settedApp);
                    onAppPicked(settedApp);
                }
            });
    };
    // Render
    return (
        <div className="main_container">
            <p className="main_title">{strings.stats}</p>

            <div className="filter_stats stats_types">
                <div onClick={toogleApps} className="button_choose_app">
                    <img style={pickedApp.icon ? display : notDisplay} className="img_choose_app left_16" src={pickedApp.icon} alt="" />
                    <img style={pickedApp.icon ? pickedApp.type_os == "android" ? androidIconStyle : display : notDisplay} className="img_choose_app_icon" src={pickedApp.type_os == "android" ? android_icon : ios_icon} alt="" />
                    <div className="just_content w_100">
                        <p className="button_choose_text">{pickedApp.name}</p>
                        <img style={isShowApps ? arrowRotate : {}} className="drop_arrow" src={arrow_filter} />
                    </div>
                    {isShowApps && <AppsDropdown dropdownStyle={dropdownStyle} onAppPicked={onAppPicked} apps={appsList} />}
                </div>

                <div onClick={() => setIsShowFilters(!isShowFilters)} className="type_campaign campaig_top">
                    <p className="black_text">{strings.campainType}</p>
                    <div>
                        <p className="blue_text_filter blue_hover">{filterPicked}</p>
                        {isShowFilters && <FilterDropdown style={{ margin: '0px 0px 0px -2px', width: 'fit-content' }} filters={filters} onFilterPicked={onFilterPicked} />}

                    </div>

                </div>
                <div className="filter_dates">
                    <p className="black_text">{strings.period}</p>
                    <p onClick={() => setIsShowDates(!isShowDates)} className="blue_text_filter blue_hover">{formateDate(selectedRange)}</p>
                    {isShowDates && <div onClick={() => setIsShowDates(false)} className="tooltip_bg_dates"></div>}
                    {isShowDates && <DateRange locale={ruLocale} ranges={[selectedRange]} onChange={handleSelect} />}
                </div>

            </div>
            {allInstallsCount > 0 && <p style={{ marginBottom: '12px' }}>Всего установок : {allInstallsCount}</p>}
            {allInstallsCount > 0 && <p style={{ marginBottom: '12px', marginBottom: '24px' }}>Затраты : {allPrice} руб.</p>}

            {isLoading ? <Loader /> :
                (installsData && installsData.length > 0) ?
                    <div>
                        <p className="stats_title_text">{strings.upInstallCount}</p>
                        <div style={{ marginLeft: '-16px' }}>
                            <StatsChart installsData={installsData} labels={labels} series={series} />
                        </div>

                    </div> :
                    <EmptyStats text1={strings.statTextTV1} text2={strings.statTextTV2} icon={cross_icon} />}

        </div>

    );
}

export default Statistics;