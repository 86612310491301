/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { AdminEmailTypes } from './utils/AdminUtils';
import { AdminApi } from '../../api/AdminApi';

const AdminBuyers = ({ }) => {
    const [orderEmails, setOrderEmails] = useState([])
   
    // useEffect(() => {
    //     getEmails(AdminEmailTypes.orders)
    // }, [])
    const getEmails = (type) => {
        AdminApi.getEmails(type, (responseData) => {
            const result = responseData.result
            if (result && result.includes("\n")){
                downloadTxt(type, result)
            }
        }, () => {})
    }
    const downloadTxt = (filename, text) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
    }
    return (
        <div style={{marginTop : '24px'}}>   
            <div onClick={() => getEmails(AdminEmailTypes.orders)} className='sdk_title_controll admin_download_txt hover'>Заказчики приложений</div>
            <div onClick={() => getEmails(AdminEmailTypes.orders_youtube)} className='sdk_title_controll admin_download_txt hover'>Заказчики универсальных заказов</div>
            <div onClick={() => getEmails(AdminEmailTypes.likedrom_orders)} className='sdk_title_controll admin_download_txt hover'>Заказчики likedrom</div>
        </div>
    )        
    
}
export default AdminBuyers

