/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import '../../css_android/bot_orders.css'
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import Loader from '../custom_views/utils_android/LoaderAndroid';
import EmptyOrders from '../custom_views/empty_views/EmptyOrders';
import EmptyStats from '../custom_views/empty_views/EmptyStats';
import FilterDropdown from '../custom_views/FilterDropdown';
import empty_orders from '../custom_views/custom_icons/empty_orders.svg'
import cross from '../custom_views/custom_icons/cross.svg'
import arrow_filter from '../custom_views/custom_icons/drop_arrow.svg'
import { SiteSeoApi } from '../../api/SiteSeoApi';
import SiteOrderItem from './list_items/SiteOrderItem';
import { getRequestParams } from '../custom_views/utils_android/CookieHelper';
import { Link, Route } from "react-router-dom";
import SiteOrderInfo from './SiteOrderInfo';
import { Pages } from '../custom_views/analyze_review/utils/Utils';
import { getIsOrderCompleted } from '../bot_orders/utils/BotUtils';



var strings = getLocalizedStrings()
var filters = [strings.allFilters, strings.workingFilters, strings.pauseFilters, strings.finishedFilters, strings.blockedFilters]

var arrowRotate = {
    transform: 'rotate(180deg)',
    marginTop: '6px'
}

function SiteOrders(props) {
    const [orders, setOrders] = useState([])
    const [filteredOrders, setFilteredOrders] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [isShowFilters, setShowFilters] = useState(false)
    const [emptyFilteredText, setEmptyFilteredText] = useState(strings.emptyFinishedOrders)
    const [filterPicked, setFilterPicked] = useState(strings.allFilters)
    const [isShowOrderInfo, setShowOrderInfo] = useState(false)
    const [pickedOrderInfo, setPickedOrderInfo] = useState({})
    const [pickedOrderIndex, setPickedOrderIndex] = useState(0)


    const getMyOrders = () => {
        SiteSeoApi.getMyOrders((responseData) => {
            setLoading(false)
            setOrders(responseData.orders)
            setFilteredOrders(responseData.orders)
        }, () => { setLoading(false) })
    }
    useEffect(() => {
        getMyOrders()
    }, [])

    const orderChanged = (order, index) => {
        var changedArray = [...filteredOrders]
        changedArray[index] = order
        setFilteredOrders(changedArray)

    }
    const deleteOrder = (index) => {
        var changedArray = [...filteredOrders]
        changedArray.splice(index, 1);
        setFilteredOrders(changedArray)
    }
    const updateOrders = (order) => {
        const foundIndex = orders.findIndex((obj => obj.external_id == order.external_id))
        var mOrders = [...orders]

        if (foundIndex != -1) {
            mOrders[foundIndex] = order
            setOrders(mOrders)
            setFilteredOrders(mOrders)
        }

    }
    const showOrderInfo = (order, index) => {
        setShowOrderInfo(true)
        setPickedOrderInfo(order)
        setPickedOrderIndex(index)
    }
 
    const addCoinsToOrder = (id, count_money_r, count_installs, index) => {
        var params = getRequestParams()
        params.count_installs = count_installs
        params.count_money_r = count_money_r
        params._id = id
        SiteSeoApi.addCoinsToOrder(params, (responseData) => {
            if (responseData.response == 1) {
                var changedArray = [...filteredOrders] 
                var order = changedArray[index]
                order.count_money_r = parseInt(order.count_money_r) + parseInt(count_money_r)
                order.max_count_searches = parseInt(order.max_count_searches) + parseInt(count_installs)
                changedArray[index] = order
                setFilteredOrders(changedArray)

                props.updateMoney(-count_money_r)
            }
        }, () => { })


    }
    const onFilterPicked = (filter, index, app) => {
        setFilterPicked(filter)
        var fullOrders = [...orders]
        fullOrders = fullOrders.filter(function (order) {
            const isOrderCompleted = getIsOrderCompleted(order)
            var filtApps = true
            if (filter == strings.allFilters) {
                return filtApps;
            } else if (filter == strings.workingFilters) {
                return (!isOrderCompleted && order.state == 1 && order.moderated != -1);
            } else if (filter == strings.pauseFilters) {
                return (order.count_money_r >= order.one_perform_price_customer && order.state == 0 && filtApps);
            } else if (filter == strings.finishedFilters) {
                return (isOrderCompleted && filtApps);
            } else if (filter == strings.blockedFilters) {
                return order.moderated == -1 && !isOrderCompleted && filtApps
            }
        });
        setFilteredOrders(fullOrders)
        // setEmptyFilteredText(getFilteredEmpty(filter))
        // var fullOrders = [...orders]
        // var mOrders = fullOrders.filter(function(order) {
        //     const isOrderCompleted = getIsOrderCompleted(order)
        //     var filtApps = ((mPickedApp.name != strings.allApps) ? (mPickedApp.bundle == order.bundle) : true);
        //     if (filter == strings.allFilters) {
        //         return filtApps
        //     } else if (filter == strings.workingFilters){
        //         return (!isOrderCompleted && order.state == BotOrderStates.started && filtApps)
        //     } else if (filter == strings.finishedFilters) {
        //         return (isOrderCompleted && filtApps)
        //     } else if (filter == strings.canceledFilter){
        //         return order.state == BotOrderStates.canceled || order.state == BotOrderStates.waiting
        //     }
        // })
        // setFilteredOrders(mOrders)
        // setEmptyFilteredText(getFilteredEmpty(filter))
    }
    const orderInfoClosed = () => {
        setShowOrderInfo(false)
    }
    return (
        <div className="main_container order_main">
            <Route path={Pages.siteSeoOrdersAll}>
                <div className="display_block">

                    <div className='create_order_just'>
                        <div className="main_title">
                            {strings.goodTrafficSite}
                        </div>

                    </div>
                    {isLoading ?
                        <Loader />
                        :
                        (orders && orders.length > 0) ?
                            <div>
                                <div style={{ marginTop: '24px' }} className="universal_order_filters">
                                    <div className="filters">
                                        <div onClick={() => setShowFilters(!isShowFilters)} className="button_choose_app">
                                            <div className="just_content w_100">
                                                <p className="button_choose_text btn_filter_choose_text">{filterPicked}</p>
                                                <img alt='' style={isShowFilters ? arrowRotate : {}} className="drop_arrow" src={arrow_filter} />
                                            </div>
                                            {isShowFilters && <FilterDropdown filters={filters} onFilterPicked={onFilterPicked} />}
                                        </div>
                                    </div>
                                    <Link to={Pages.createOrderSite}><div className="yellow_btn empty_orders_create new_univ_order">{strings.createNewOrder}</div></Link>

                                </div>

                                <div className="orders_container">
                                    {filteredOrders.length > 0 && filteredOrders.map((item, index) => (
                                        <SiteOrderItem orderChanged={orderChanged} showOrderInfo={showOrderInfo} addCoinsToOrder={addCoinsToOrder} updateOrders={updateOrders} count_money_r={props.count_money_r} index={index} order={item} key={index} getMyInfo={props.getMyInfo} />
                                    ))}
                                </div>
                            </div>
                            :
                            <EmptyOrders fromSite icon={empty_orders} text1={strings.emptyOrders} text2="" text3="" />}
                    {filteredOrders.length == 0 && !isLoading && orders.length > 0 ? <EmptyStats icon={cross} text1={emptyFilteredText} text2="" text3="" /> : <div />}
                </div>
            </Route>
            <Route path={Pages.siteSeoOrdersOrder}>
                <SiteOrderInfo updateMoney={props.updateMoney} exchangeRate={props.exchangeRate} showIdfa={() => { }} getMyInfo={props.getMyInfo} deleteOrder={deleteOrder} count_money_r={props.count_money_r} addCoinsToOrder={addCoinsToOrder} orderChanged={orderChanged} index={pickedOrderIndex} orderInfoClosed={orderInfoClosed} order={pickedOrderInfo} />
            </Route>
            {orders.length > 0 && <div className="mobile_create_order_container">
                <a href={Pages.createOrderSite}><div className="yellow_btn empty_orders_create mobile_univ_btn">{strings.createNewOrder}</div></a>
            </div>}
        </div>
    )
}

export default SiteOrders
