import React from 'react';
import { getLocalizedStrings } from '../../../utils_android/LocalizedStrings';
import TagListItem from '../../list_items/TagListItem';
var strings = getLocalizedStrings()
class TagListRedactor extends React.Component {
    constructor(props){
        super(props)
      
    }
   
    render() {
        return (
            <div className="add_tag_bg">
               <div className="tag_list_container">
                    <p className="tag_list_title">{strings.readtorTagsTitle}</p>
                    <div className="tag_list_line tag_list_line_top"/>
                    <div className="tags_list_cont">
                        {this.props.categories.length > 0 && this.props.categories.map((item, index) => (
                            <TagListItem deleteCategory={this.props.deleteCategory} renameCategory={this.props.renameCategory} category={item} index={index} key={index}/>
                        ))}
                    </div>
                    <div className="tag_list_line"/>
                    <div onClick={this.props.toogleRefactorTags} className="tag_list_btn hover">{strings.closeTagList}</div>
               </div>
            </div>
        );
    }
}
export default TagListRedactor;