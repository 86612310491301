import React from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { getUniversalTypes, getOrderType, proccessRandomVariants, getControlTimeText } from '../utils_android/Utils';

var strings = getLocalizedStrings()
class PreviewTaskDialog extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            test : 0
        }
        this.urlsIndex = -1
    }
    componentDidMount(){
    }
    checkCsv = () => {
        var final = this.props.orderState.description.toString()
        this.props.orderState.csvKeys.forEach(element => {
            if (final.includes(element)){
                final = final.replaceAll(element, this.getCsvValues(element))
            }
        });
        if (!this.props.orderState.isCsvShuffle && !final.includes("[shuffle]")){
            final = "[shuffle]" + final
        }
        return final
    }
    getCsvValues = (key) => {
        var values = "["
        this.props.orderState.csv.forEach(element => {
            if (element[key] != "" && element[key] != undefined){
                if (values == "["){
                    values += element[key].replaceAll("\"", "")
                } else {
                    values += (";" + element[key].replaceAll("\"", ""))
                }
            }
        });
        values += "]"
        return values
    }
    getOrderDesc = (descr) =>{
        var htmlObject = document.createElement('div');
        htmlObject.innerHTML = descr
        var containers = htmlObject.getElementsByClassName("csv_item_container")
        for (let index = 0; index < containers.length; index++) {
            const element = containers[index];
            element.style.background="white";
            element.style.padding = '0px'
        }
       return htmlObject.innerHTML
    }
    doTask = () => {
        window.open(this.getRealUrl(), '_blank');
    }
    getRealUrl = () => {
        const url = this.props.orderState.url
        if (url.includes(";") && this.urlsIndex != -1){
            return url.split(";")[this.urlsIndex]
        }
        return url
    }
    showMoreVariants = () => {
        this.setState({test : this.state.test += 1})
    }
    render() {
        const descr = this.props.orderState.description = this.props.orderState.csv != undefined ? this.checkCsv() : this.props.orderState.description
        var typeIcon = ""
        getUniversalTypes().forEach(element => {
            if (element.text == this.props.orderState.type){
                typeIcon = element.icon
            }
        });
        var result = ""
        if (descr.includes("[") && descr.includes("]")){
            const variants = proccessRandomVariants(descr)
            result = variants[0]
            if (result != "-1" && result != "-2"){
                result = this.getOrderDesc(result)
                this.urlsIndex = variants[2]
            } else {
                result = descr
                this.urlsIndex = -1
            }
        } else {
            this.urlsIndex = -1
            result = descr
        }
        return (
            <div className="add_tag_bg">
               <div className="preview_dialog_container">
                    <p className="tag_list_title">{strings.previewDialogTitle}<br/>{strings.previewDialogTitle2}</p>
                    <p className="preview_dialog_title2">{strings.previewDialogTitle3}</p>
                    <div className="preview_dialog_line"/>
                    <div className="overflow_preview_dialog">
                        <div className="flex">
                            <div className="custom_icon_container">
                                <img className="custom_icon" src={typeIcon} alt=""/>
                            </div>
                           
                            <div>
                                <p className="custom_task_name">{this.props.orderState.name}</p>
                                <p className="money_text custom_money_text top_price_0">+{this.props.orderState.priceOneInstall}₽</p>
                            </div>
                            
                        </div>
                        <p className="custom_task_description wrap_text_dialog" dangerouslySetInnerHTML={{__html: result}}/>

                        {this.props.orderState.controlType != "" && <div className="custom_task_review">
                            <p className="custom_task_review_title">{this.props.orderState.controlType == strings.controlTypeReview ? strings.previewDialogProof : strings.previewDialogProofTimer + this.props.orderState.secCount + strings.previewDialogProofTimerSec}</p>
                            {this.props.orderState.controlType == strings.controlTypeReview && <p dangerouslySetInnerHTML={{__html: this.props.orderState.reviewText ? this.props.orderState.reviewText : strings.previewDialogProofDesc}}/>}
                            {(this.props.orderState.isNeedScreen && this.props.orderState.controlType == strings.controlTypeReview) && <div className="flex">
                                <p className="is_need_screen_text">{strings.previewDialogProofScreen}</p>
                            </div>}
                        </div>}
                        <div style={{margin : '16px 0px 24px 0px'}} onClick={this.doTask} className="open_support_btn start_custom_task_btn hover">{strings.previewDialogProofStart}</div>
                        <div className="divider_line_proff"/>
                        <p className="timer_text">{strings.previewDialogProofStartTimer} <strong>60 {strings.previewDialogProofMin}</strong></p>
                        {this.props.orderState.controlType == strings.controlTypeReview && <p className="custom_task_earning">{strings.previewDialogProofDesc}<br/><br/>{strings.previewDialogProofDesc2} <b>{this.props.orderState.autoReview}</b> {strings.previewDialogProofDesc3}</p>}

                    </div>
                    
                    <div className="preview_dialog_line"/>
                    <div className="idfas_btns center_hor_container">
                        {this.props.orderState.csv != undefined && <div onClick={this.showMoreVariants} className="show_more_variants hover">{strings.previewDialogProofShowmore}</div>}
                        <div style={{width : '110px'}} onClick={this.props.togglePreviewDialog} className="idfas_btn hover">{strings.closeTagList}</div>
                    </div>
                    
               </div>
            </div>
        );
    }
}
export default PreviewTaskDialog;