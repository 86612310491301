import React from 'react';
import KeywordsInput from './KeywordsInput';
import { getLocalizedStrings } from '../../utils_android/LocalizedStrings';
var hide = {opacity : 0, pointerEvents : 'none'}
var show = {opacity : 1, pointerEvents : 'all'}
var showIgnore = {opacity : 1, pointerEvents : 'all'}
var strings = getLocalizedStrings()
class Keywords extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowKeywords : true,
            keywordsHeight : 0
        }
        this.isMobile = (window.innerWidth <= 980)
        this.openKeywords = this.openKeywords.bind(this);
        this.openIgnore = this.openIgnore.bind(this);
    }
    openKeywords(){
        this.setState({isShowKeywords : true})
    }
    openIgnore(){
        this.setState({isShowKeywords : false})
    }
    componentDidMount(){
        this.setState({keywordsHeight : window.document.getElementById("keywords_input_width_left").getBoundingClientRect().height})
    }
    render() {
        
        return (
            <div style={(this.props.isShow && this.props.currentItem != {}) ? {opacity : 1, pointerEvents : 'all'} : {}} className="new_tag_container">
                <div className="mobile_keywords_title_container">
                    <div className="flex">
                        <p onClick={this.openKeywords} className="mobile_keywords_title">{strings.keywordsTitle}</p>
                        <p onClick={this.openIgnore} className="mobile_keywords_title mobile_keywords_title_active">{strings.ignoreTitle}</p>
                    </div>
                    <div className={this.state.isShowKeywords ? "mobile_keywords_line" : "mobile_keywords_line mobile_keywords_line_ignore"}/>
                </div>
                <div style={(this.isMobile && !this.state.isShowKeywords) ? hide : show} id="keywords_input_width_left" className="keywords_input_width keywords_input_width_left">
                    <p className="keywords_title">{strings.keywordsTitle}</p>
                    {this.props.currentItem.words != undefined && <KeywordsInput wordsChanged={this.props.wordsChanged} words={this.props.currentItem.words.includes("|") ? this.props.currentItem.words.split("|") : this.props.currentItem.words != "" ? [this.props.currentItem.words] : []} placeholder="Введите и нажмите Enter"/>}
                    <p className="tag_desription">{strings.keywordsDescription}</p>
                </div>
                <div style={(this.isMobile && this.state.isShowKeywords) ? {opacity : 0, pointerEvents : 'none', marginTop : (this.isMobile ? -this.state.keywordsHeight : 0)} : {opacity : 1, pointerEvents : 'all', marginTop : (this.isMobile ? -this.state.keywordsHeight : 0)}} className="keywords_input_width keywords_input_width_right">
                    <p className="keywords_title">{strings.ignoreTitle}</p>
                    {this.props.currentItem.words_not != undefined && <KeywordsInput wordsChanged={this.props.wordsNotChanged} words={this.props.currentItem.words_not.includes("|") ? this.props.currentItem.words_not.split("|") : this.props.currentItem.words_not != "" ? [this.props.currentItem.words_not] : []} placeholder="Введите и нажмите Enter"/>}
                    <p className="tag_desription">{strings.ignoreDescription}</p>
                </div>
                
            </div>
        );
    }
}
export default Keywords;