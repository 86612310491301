import React, { useState } from 'react';

import '../pages_css/tooltip.css';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { getRequestParams } from '../utils_android/CookieHelper';
import { getRequestUrl, getHttpParams } from '../analyze_review/utils/Utils';
var strings = getLocalizedStrings()

const ChangeUniversalLimits = (props) => {
    const [limit, setLimit] = useState(props.order.limit_count_in_day);

    const onUniversalPriceChange = (event) => {
        if (event) {
            const count = event.target.value;
            setLimit(count);
        }
    };

    const closeTooltip = () => {
        props.closeUpBalance();
    };

    const changeUniversalPrice = () => {
        const params = getRequestParams();
        params._id = props.order_id;
        params.limit_count_in_day = limit;
        
        fetch(getRequestUrl() + "setLimitYoutubeOrder", getHttpParams(params))
            .then((response) => response.json())
            .then((responseData) => {
                const order = props.order;
                
                order.limit_count_in_day = limit
                props.orderChanged(order, props.index);
            });
        closeTooltip();
    };

    return (
        <div className="display_block">
            <div onClick={closeTooltip} className="tooltip_bg_up_balance"></div>
            <div style={props.style ? props.style : {}} className="tooltip_plashka">
                <div style={props.isLeft ? {} : {right: 'auto', left: '-8px'}} className="tooltip_arrow"></div>
                <div className="display_block">
                    <div className="padding_24 tooltip_right">
                        <p className="up_balance_title">{strings.changeDayLimit}</p>
                        <input value={limit} onChange={onUniversalPriceChange} className="input_up_balance"/>
                        {/* <p className="minimal_price_univ">{strings.oneUniversalPriceMin} {props.price_one_install}₽</p> */}
                        <p 
                            style={(limit === "") ? {pointerEvents: 'none', opacity: '0.2'} : {}} 
                            onClick={changeUniversalPrice} 
                            className="add_btn hover"
                        >
                            {strings.saveRenamTag}
                        </p>
                        <p onClick={closeTooltip} className="cancel_btn hover">{strings.upCancel}</p>
                        <div className="line_white_bg"/>
                        <div className="line_white_bg_left"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangeUniversalLimits;
