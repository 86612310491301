import React from 'react';
import site_repeat from '../../custom_views/custom_icons/site_repeat.svg';
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';

const strings = getLocalizedStrings();

const SiteOrderInfoProperties = ({ order }) => {
    console.log("SiteOrderInfoProperties order = ", order)
    return (
        <div className="info_props_container">
            <p className="grey_text bot_8">{strings.wrapOptions}</p>


            {order.allow_double_search &&
                <div style={{ display: 'flex' }} className="prop_line">
                    <img style={{ position: 'absolute', top : '3px' }} className="prop_line_img" src={site_repeat} alt="" />
                    <p style={{marginBottom : '0', marginLeft : '32px'}} className="order_prop_text">{strings.siteAllowDouble}</p>
                </div>
            }

            <div style={{ display: 'flex' }} className="prop_line">
                <div style={{ position: 'absolute', top : '3px', margin : 0 }} className='site_seo_time'>{order.seconds_on_site}’</div>
                <p style={{marginBottom : '0', marginLeft : '32px'}} className="order_prop_text">{strings.siteTimeOnInfo}</p>
            </div>
            {(order.city != undefined && order.city != "") &&<div>
                <p className="grey_text top_32">{strings.cityTitle}</p>
                <p>{order.city}</p>

            </div>}
          
            <div className={(order.limit_day && order.limit_day !== "" && order.limit_day !== 99999 && order.limit_day !== "99999") ? "max_installs_container" : "not_display"}>
                <p className="grey_text bot_8">{strings.siteLimits}</p>
                <div style={order.limit_day && order.limit_day !== "" ? { display: 'block' } : { display: 'none' }} className="prop_line">
                    <p className="order_prop_max">{order.limit_day} {strings.onDay}</p>
                </div>
                {/* <div style={(order.limit_hour && order.limit_hour !== "" && order.limit_hour !== 99999 && order.limit_hour !== "99999") ? {display: 'block'} : {display: 'none'}} className="prop_line">
                    <p className="order_prop_max">{order.limit_hour} {strings.onOur}</p>
                </div>
                <div style={(order.limit_minute && order.limit_minute !== "" && order.limit_minute !== 99999 && order.limit_minute !== "99999") ? {display: 'block'} : {display: 'none'}} className="prop_line">
                    <p className="order_prop_max">{order.limit_minute} {strings.onMinute}</p>
                </div> */}
            </div>
        </div>
    );
};

export default SiteOrderInfoProperties;

