/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import qs from 'querystring'
import './dialogs.css'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { getRequestUrl, setAuthCookie } from '../utils_android/CookieHelper';
import Loader from '../analyze_review/customViews/Loader';
import { getAuthUrlRedirect } from '../utils_android/UrlHelperAndroid';
var strings = getLocalizedStrings()
const EmailCodeDialog = ({ email, closeCodeDialog }) => {

    const [code, setCode] = useState("")
    const [isShowError, setShowError] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const sendCode = () => {
        setShowError(false)
        setLoading(true)
        var params = {
            code: code,
            email : email
        }
        console.log("sendEmailCode params = ", params)
        fetch(getRequestUrl() + "sendEmailCode", {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: qs.stringify(params)
        }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == -1) {
                    setShowError(true)
                } else if (responseData.response == 1) {
                    setAuthCookie(responseData.email, responseData.token, responseData._id)
                    window.location.href = getAuthUrlRedirect()
                }
                setLoading(false)
            })
    }

    const cancel = () => {
        closeCodeDialog()
    }
    return (
        <div className="error_bg">
            <div className="error_banner_container">
                <p className="title-forgot-pass">{strings.enterEmailCode}</p>
                <div>
                    <input style={{ width: '-webkit-fill-available' }} onChange={(event) => setCode(event.target.value)} type="text" className="forgot_pass_input" placeholder={strings.enterCode} value={code} />
                </div>
                <p className={isShowError ? "title-forgot-pass-error" : "not_display"}>{strings.wrongCode}</p>
                <div className="flex">
                    <button onClick={cancel} id="cancel-forgot-btn" className="form-control btn btn-login " value="Отмена">{strings.cancelEmail}</button>
                    <button style={code.length == 6 ? {position : 'relative'} : {pointerEvents : 'none', opacity : '0.2', position : 'relative'}} onClick={sendCode} id="login-submit-reg-btn-2" className="form-control btn btn-login forgot_pass_send">{isLoading ? <Loader style={{marginTop : '-1px'}} isWhite/> : strings.sendEmail}</button>
                </div>
                {/* {isLoading && <InputLoader />} */}
            </div>
        </div>
    );
}
export default EmailCodeDialog;