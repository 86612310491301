/* eslint-disable eqeqeq */
import React from 'react';
import trash_red from '../../custom_views/custom_icons/trash_red.svg'

const AdminMoreTooltip = ({item, toogleMore, deleteOrder, blockOrder, setDescriptionDialog }) => {
    const onDelete = () => {
        deleteOrder(item)
        toogleMore()
    }
    const onBlock = () => {
        blockOrder(item)
        toogleMore()
    }
    const showDescription = () => {
        if (setDescriptionDialog){
            setDescriptionDialog()
            toogleMore()
        }
    }
    return (
        <div className="display_block">
            <div onClick={toogleMore} className="tooltip_bg_up_balance"/>
            <div className="tooltip_more_plashka">
                <div className="tooltip_more_arrow"/>
                <div className="bg_white_line"/>
                <div className="display_block">
                    <div className="padding_24 tooltip_right">
                        {setDescriptionDialog && <div onClick={showDescription}  className="up_balance_info hover">
                            <p className="up_balance_info_text">Открыть описание</p>
                        </div>}
                        <div style={setDescriptionDialog ? {} : {marginTop : '0'}} onClick={onBlock} className="up_balance_info top_16 hover">
                            <img alt='' src={trash_red}></img>
                            <p className="up_balance_info_text red_text">Заблокировать</p>
                        </div>
                        <div onClick={onDelete} className="up_balance_info top_16 hover">
                            <img alt='' src={trash_red}></img>
                            <p className="up_balance_info_text red_text">Удалить без возврата</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )        
    
}
export default AdminMoreTooltip

