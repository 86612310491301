import {  getHttpParams } from "../pages_android/custom_views/analyze_review/utils/Utils";
import { getRequestParams, getRequestUrl } from "../pages_android/custom_views/utils_android/CookieHelper";
import { log, onResponse } from "./ApiUtils";
export class SiteSeoApi {
    
    static checkSiteInSearch(params, onSuccess, onError) {
        fetch(getRequestUrl() + "seo/checkSiteInSearch", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("checkSiteInSearch response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static setStateOrder(params, onSuccess, onError) {
        fetch(getRequestUrl() + "seo/setStateOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("setStateOrder response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static removeOrder(params, onSuccess, onError) {
        fetch(getRequestUrl() + "seo/removeOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("removeOrder response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static addCoinsToOrder(params, onSuccess, onError) {
        fetch(getRequestUrl() + "seo/addCoinsToOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("addCoinsToOrder response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getOrderStatistic(params, onSuccess, onError) {
        fetch(getRequestUrl() + "seo/getOrderStatistic", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getOrderStatistic response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static addOrder(params, onSuccess, onError) {
        fetch(getRequestUrl() + "seo/addOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("addOrder response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getPrices(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "seo/getPrices", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getPrices response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getMyOrders(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "seo/getMyOrders", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getMyOrders response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getSiteIcon(url, onSuccess, onError) {
        var params = getRequestParams()
        params.url = url
        fetch(getRequestUrl() + "seo/getSiteIcon", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getSiteIcon response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   

}