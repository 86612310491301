import { getLocalizedStrings } from "../../custom_views/utils_android/LocalizedStrings"
import bot_canceled from '../../custom_views/custom_icons/bot_canceled.svg'
import bot_waiting from '../../custom_views/custom_icons/bot_waiting.svg'
import bot_doing from '../../custom_views/custom_icons/bot_doing.svg'
import bot_moderating from '../../custom_views/custom_icons/bot_moderating.svg'
import bot_finished from '../../custom_views/custom_icons/finished.svg'

/* eslint-disable eqeqeq */
var strings = getLocalizedStrings()

export const getBotAppStatusText = (order) => {
    if (getIsOrderCompleted(order) || order.state == BotOrderStates.completed){
        return strings.completed1
    }
    if (order.state == BotOrderStates.moderating){
        return strings.moderating
    }
    if (order.state == BotOrderStates.started){
        return strings.working
    }
    if (order.state == BotOrderStates.canceled){
        return strings.canceledOrder
    }
    if (order.state == BotOrderStates.waiting){
        return strings.waitingCancel
    }
    return strings.canceledOrder
}
export const getBotAppIcon = (order) => {
    if (getIsOrderCompleted(order) || order.state == BotOrderStates.completed){
        return bot_finished
    }
    if (order.state == BotOrderStates.moderating){
        return bot_moderating
    }
    if (order.state == BotOrderStates.started){
        return bot_doing
    }
    if (order.state == BotOrderStates.canceled){
        return bot_canceled
    }
    if (order.state == BotOrderStates.waiting){
        return bot_waiting
    }
    return bot_doing
}
export const getBotOrderStartDate = (order) => {
    var arrayDates = order.createDate.split('T')[0].split('-')
    if (arrayDates != undefined && arrayDates.length > 0) {
        return arrayDates[2] + "." + arrayDates[1] + "." + arrayDates[0]
    } else {
        return ""
    }
}
export const getIsOrderCompleted = (order) => {
    if (order.one_perform_price_customer){
        return (order.count_money_r < order.one_perform_price_customer)
    }
    const isOrderCompleted = order.state == BotOrderStates.completed || (order.state == BotOrderStates.started && order.count_complete_installs >= order.count_installs)
    return isOrderCompleted
}


export const BotOrderStates = {
    moderating: 0,
    started: 1,
    canceled: -1,
    waiting: 2,
    completed: 3,
}
export const getBotOrderInfoStatusColor = (order) => {
    const isOrderCompleted = getIsOrderCompleted(order)
    if (order.state == BotOrderStates.waiting || order.state == BotOrderStates.canceled){
        return {background : 'rgb(255, 98, 98)', color : 'white'}
    }
    if (!isOrderCompleted){
        return {background : 'rgb(255, 194, 140)'}
    } else {
        return {background : '#5FD7A4', color : 'white'}
    } 
}