import React from 'react';
import {DebounceInput} from 'react-debounce-input';

import '../custom_views/pages_css/tooltip.css';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import InputLoader from './utils_android/InputLoader';
import { getHttpParams, getRequestUrl } from './analyze_review/utils/Utils';
import { getRequestParams } from './utils_android/CookieHelper';
import { ta } from 'date-fns/esm/locale';
var strings = getLocalizedStrings()
class AddRequestTooltip extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            searchText : "",
            isLoading : false,
            tags: [],
            search_pos : -1
        }
        this.searchTextEvent = undefined
        this.wasPasted = false
    }
    onTextChanged = (event) => {
        if (event){
            var text = event.target.value
            this.searchTextEvent = event
            this.setState({searchText : text})
            if (this.wasPasted){
                this.wasPasted = false
                text = ""
                this.delayedClear()
            }
            if (text != undefined && text != ""){
                if (!this.state.isLoading){
                    this.searchPosition(text)
                    this.setState({isLoading : true})
                }
            } else {
                this.setState({search_pos : -1})
            }
        }
    }
    clearText = () => {
        this.setState({searchText : "", search_pos : -1})
    }
    checkBundle = (info) => {
        for (var i = 0; i < info.length; i++) {
            var item = info[i];

            if (item.bundleId == this.props.bundle) {
                return i + 1;
            }
        }
    }
    onPaste = (e) => {
        var text = e.clipboardData.getData('Text')
        if (text.includes("\n")){
            this.wasPasted = true
            var tags = text.split("\n")
            tags.forEach(tag => {
                this.addTag(tag)
            });
            setTimeout(this.delayedClear, 300);
            setTimeout(this.delayedPaste, 1100);
        }
    }
    delayedPaste = () => {
        this.wasPasted = false
    }
    delayedClear = () => {
        if (this.searchTextEvent != undefined){
            console.log("delayedClear delayedClear")
            this.searchTextEvent.target.value = ""
            this.setState({searchText : "", search_pos : -1})
        }
    }
    addTag = (tag) => {
        var tags = this.state.tags
        tags.push({tag : tag, position : -1})
        this.setState({tags : tags, searchText : ""})
        this.searchPosition(tag, (tags.length - 1))
    }
    onEnter = event => {
        if (event && event.key === "Enter" && event.target.value !== "") {
            var tag = event.target.value
            if (tag != undefined && tag != ""){
                if (tag == this.state.searchText && this.state.search_pos != -1){
                    var tags = this.state.tags
                    tags.push({tag : tag, position : this.state.search_pos})
                    this.setState({tags : tags})
                    this.delayedClear()
                } else {
                    this.addTag(tag)
                    this.delayedClear()
                }
            }
            
        }
    };
    searchPosition = (text, index) => {
        if (this.props.order.type_os == 'android'){
            this.searchApp(text, index)
        } else if (this.props.order.type_os == 'ios'){
            this.searchIosApp(text, index)
        }
    }
    searchApp = (text, index) => {
        if (this.props.order.bundle != "" && this.props.order.type_os != ""){
            var params = getRequestParams()
            params.bundle = this.props.order.bundle
            params.query = text
            params.country = "RU"
            params.type_os = this.props.order.type_os
            fetch(getRequestUrl() + "searchApp", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                this.setState({isLoading : false, search_pos : (this.state.searchText != "" && index == undefined) ? responseData.info : -1})
                this.updateTagPosition(responseData.info, index)
            })
        }
    }
    searchIosApp = (text, index) => {
        fetch("https://itunes.apple.com/search?limit=500&country=ru&entity=software&term=" + text, {
            method: 'GET'
        }).then((response) => response.json())
        .then((responseData) => {
            var info = responseData.results;
            var searchPosition = this.checkBundle(info);
        
            if (searchPosition == undefined) {
              searchPosition = 0;
            }
            this.setState({isLoading : false, search_pos : (this.state.searchText != "" && index == undefined) ? searchPosition : -1})
            this.updateTagPosition(searchPosition, index)
        })
    }

    updateTagPosition = (position, index) => {
        var tags = this.state.tags
        if (index < tags.length){
            var tag = tags[index]
            tag.position = position
            tags[index] = tag
            this.setState({tags : tags})
        }
    }
  
    addRequest = () => {
        var searchWords = []
        if (this.state.tags.length > 0){
            var tags = this.state.tags
            if (this.state.searchText != "" && this.state.searchText != undefined && this.state.search_pos != -1){
                tags.push({tag : this.state.searchText, position : this.state.search_pos})
            }
            tags.forEach(tag => {
                if (tag.position != -1 && tag.position != 0){
                    searchWords.push({text : tag.tag, positionBefore : tag.position})
                }
            });
        } else if (this.state.search_pos != 0 && this.state.search_pos != -1){
            searchWords.push({text : this.state.searchText, positionBefore : this.state.search_pos})
        }
        this.props.addNewSearchWords(searchWords)
        this.props.toggleTooltip()
    }
    render() {
        return (
            <div className="add_tag_bg">
               <div onClick={this.props.toggleTooltip} className="tooltip_bg_analize"></div>

               <div style={{height : 'auto', width : '320px', maxWidth : '320px'}} className="preview_dialog_container">
                    <p className="add_reaquest_dialog_title">{strings.addSearchRequestInOrder}</p>
                    <DebounceInput onKeyUp={event => this.onEnter(event)} onPaste={this.onPaste} autoComplete="off" debounceTimeout={1000} value={this.state.searchText} onChange={this.onTextChanged} className="input_up_balance" placeholder={strings.addSearchRequestInOrderPlaceholder}/>
                    <div className="line_justify">
                    <p style={(this.state.search_pos != -1 || this.state.isLoading) ? {opacity : 1} : {opacity : 0}} className="search_pos_dialog">{strings.addSearchRequestInOrderPos}</p>
                        {this.state.isLoading ? <InputLoader /> : this.state.search_pos == -1 ? <div/> : this.state.search_pos == 0 ? <p style={{color : '#FF6262'}} className="bold">{strings.addSearchRequestNotFound}</p> : <p className="bold">{this.state.search_pos >= 300 ? ">50" : this.state.search_pos}</p>}
                    </div>
                    {(this.state.search_pos == 0 && !this.state.isLoading) &&  <p style={{color : '#FF6262'}} className="search_pos_dialog">{strings.addSearchRequestInOrderErr}<br/><span onClick={this.clearText} className="bold hover"> {strings.addSearchRequestInOrderErr2}</span></p>}
                    <p className="search_pos_dialog mt16">{strings.addSearchRequestInOrderDesc}</p>
                    {this.state.tags.length > 0 && 
                        <div className="requests_dialog_cont">
                            <div className="line_justify opa_05">
                                <p>{strings.asoRequest}</p>
                                <p>{strings.asoPosition}</p>
                            </div>
                            {this.state.tags.map((item, index) => (
                            <div key={index} className="line_justify">
                                <div>
                                    <p>{item.tag}</p>
                                    {item.position == 0 && <p className="request_desc">{strings.addSearchRequestNotFoundDesc}</p>}
                                </div>
                                {item.position == -1 ? <InputLoader style={{marginRight : '-20px', position : 'relative'}}/> :<p style={item.position == 0 ? {color : '#FF6262'} : {}} className="bold">{item.position >= 300 ? ">50" : item.position == 0 ? strings.addSearchRequestNotFound : item.position}</p>}
                            </div>
                        ))}
                        </div>
                    }
                    <p onClick={this.addRequest} style={this.state.tags.length > 0 ? {} : (this.state.isLoading || this.state.search_pos == -1 || this.state.search_pos == 0) ? {opacity : 0.3, pointerEvents : 'none'} : {}} className="add_btn hover">{this.state.tags.length > 0 ? strings.addSearchRequestAddAll : strings.upAdd}</p>
                    <p onClick={this.props.toggleTooltip} className="cancel_btn hover">{strings.upCancel}</p>
               </div>
            </div>
            
        );
    }
}
export default AddRequestTooltip;
