import React from 'react';
import '../pages_css/tooltip.css';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import close_delete from '../custom_icons/close_delete.svg'

var strings = getLocalizedStrings()
class UpBalanceUniversal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
           isShowError : false
        }
       
        
    }
    closeTooltip = () => {
        this.props.closeUpBalance()
    }
  
    openPay = () => {
        this.props.closeUpBalance()
        window.open('/pay' + (this.props.isSocialTask ? "?sum=" + Math.ceil(this.props.sumOrder - this.props.count_money_r) : ""), '_blank');
    }
    render() {
        return (
            <div className="display_block">
                  

                <div onClick={this.closeTooltip} className="tooltip_bg_up_balance"></div>
                <div style={this.props.style ? this.props.style : {}} className="tooltip_plashka univ_up_balance">
                    <img style={{right : '14px', top : '14px'}} className="dialog-close" onClick={this.props.closeUpBalance} src={close_delete} alt=""/> 
                    <div style={this.props.isLeft ? {} : {bottom : '-8px', left : '24px'}} className="tooltip_arrow"></div>
                    <div className="display_block">
                        <div className="padding_24 tooltip_right">
                            {!this.props.isSocialTask ? <p>{strings.upBalanceUniversalDialog} {this.props.count_money_r < this.props.sumOrder ? (this.props.sumOrder - this.props.count_money_r) : (this.props.sumOrder < 100 ? 100 : this.props.sumOrder)} ₽.</p>
                            :
                            <p>Для создания заказа пополните баланс на<br/><b>{Math.ceil(this.props.sumOrder - this.props.count_money_r)}₽</b></p>    
                        }
                            <p onClick={this.openPay} className="up_balance_btn_univ hover">{strings.addMoney}</p>
                        </div>
                    </div>
                    <div className="line_white_bg_bot"/>
                </div>
            </div>
           
        );
    }
}
export default UpBalanceUniversal;
