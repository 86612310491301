/* eslint-disable eqeqeq */
import { Pages } from "../analyze_review/utils/Utils";
import { getCookie, setCookie } from "./CookieHelper";

export function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? undefined : decodeURIComponent(sParameterName[1]);
        }
    }
};
export function getAuthUrlRedirect() {
    const auth_type = "auth_type"
    const saveAuthType = getCookie(auth_type)
    setCookie(auth_type, "")
    if (saveAuthType == "1"){
        return Pages.ordersFull
    } else if (saveAuthType == "2"){
        return Pages.universalOrders
    } else if (saveAuthType == "3"){
        return Pages.siteSeoOrders + '/all'
    } else if (saveAuthType == "4"){
        return Pages.social
    } else {
        return Pages.ordersFull
    }
   
}