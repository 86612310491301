import React from 'react';
import close_delete from '../../../custom_icons/close_delete.svg'
import '../../../dialogs/dialogs.css'
import { getLocalizedStrings } from '../../../utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class DeleteTagDialog extends React.Component {
    constructor(props){
        super(props)

    }
   
    render(){
      
            return (
                <div className="error_bg">
                    <div style={{width : '340px', padding : '24px'}} className="error_banner_container">
                        <img style={{right : '22px'}} className="dialog-close" onClick={this.props.onCanceledDelete} src={close_delete} alt=""/>   
                        <p className="delete_order_title">{strings.tagListRemoveTitle}</p>  
                        <p className="delete_order_text">{strings.tagRemoveText}{this.props.tag}{strings.tagRemoveText2}</p>  
                        <div className="dialog_btns">
                            <p onClick={this.props.closeDeletion} className="error_btn color_cancel">{strings.cancel}</p>
                            <p onClick={this.props.onDeleteConfirmed} className="btn_red">{strings.deleteOrderBtn}</p>
                        </div>
                    </div>  
                </div>
            ); 
        
        
    }
}
export default DeleteTagDialog;