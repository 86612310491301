/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import eye from '../../custom_views/custom_icons/eye.svg'
import { SiteSeoApi } from '../../../api/SiteSeoApi';
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';
import { getRequestParams } from '../../custom_views/utils_android/CookieHelper';
import InputLoader from '../../custom_views/utils_android/InputLoader';

const strings = getLocalizedStrings();

const SiteOrderPositionItem = (props) => {
    const [isPositionLoading, setIsPositionLoading] = useState(false);
    const [isPositionLoaded, setIsPositionLoaded] = useState(false);

    const order = props.order
    const searchSite = (text) => {
        var params = getRequestParams()
        params.search_platform = order.search_platform
        params.domain = order.site_name
        if (order.city != undefined && order.city != ""){
            params.city = order.city
        }
        params.query = text
       
        try {
            console.log("searchSite params" + JSON.stringify(params))
            SiteSeoApi.checkSiteInSearch(params, (responseData) => {
                const info = responseData.result;
                // let searchPosition = checkBundle(info);
            
                // if (searchPosition === undefined) {
                //     searchPosition = 0;
                // }
                setIsPositionLoading(false);
                setIsPositionLoaded(true);
                props.searchAppPosition(info, props.index);
                if (props.updateMoney){
                    props.updateMoney(-0.3)
                }
                
                // this.setState({ isLoading: false, search_pos: responseData.result })
                // this.updateTagPosition(responseData.result, index, responseData.founded_url, responseData.founded_title)
            }, (responseData) => {
                if (responseData.status){
                    props.showToast(responseData.status)
                }
            })

        } catch (error) {
            
        }
    }


    const loadPosition = () => {
        setIsPositionLoading(true);
        searchSite(props.searchObject.text)
    };

    useEffect(() => {
        if (props.index === 0 || props.searchObject.positionBefore === undefined) {
            //loadPosition();
        }
    }, []); // Пустой массив зависимостей для имитации componentDidMount

    return (
        <div className="order_pos_container">
            <div className="flex">
                <p className="order_pos_text">{props.searchObject.text}</p>
                {/* {props.deleteSearchKey && 
                    <img 
                        alt="" 
                        onClick={() => props.deleteSearchKey(props.index)} 
                        src={close_order} 
                        className="remove_order_request hover" 
                    />
                } */}
            </div>
            <div className="flex">
                <p className="order_pos_text">
                    {props.searchObject.positionBefore === 0 
                        ? strings.notFound1 
                        : parseInt(props.searchObject.positionBefore) === 300 
                            ? ">50" 
                            : props.searchObject.positionBefore}
                </p>
                <div className="position_item">
                    {isPositionLoading 
                        ? <InputLoader style={{position: 'initial'}}/> 
                        : ((props.searchObject.currentPosition || props.searchObject.currentPosition === 0) 
                            ? <p className="current_position">
                                {props.searchObject.currentPosition >= 300 
                                    ? ">50" 
                                    : props.searchObject.currentPosition !== 0 
                                        ? props.searchObject.currentPosition 
                                        : strings.notFound2}
                              </p> 
                            : <img alt='' onClick={loadPosition} className="eye_icon hover" src={eye}/>)}
                </div>
            </div>
        </div>
    );
};

export default SiteOrderPositionItem;
