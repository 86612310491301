/* eslint-disable eqeqeq */
import React from 'react';
import eye from '../custom_views/custom_icons/eye.svg'
import close_order from '../custom_views/custom_icons/close_order.svg'

import InputLoader from '../custom_views/utils_android/InputLoader';
import { getRequestParams, getRequestUrl } from '../custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import { AppsType, getHttpParams } from '../custom_views/analyze_review/utils/Utils';
import { AppsApi } from '../../api/AppsApi';
var strings = getLocalizedStrings()
class OrderPositionItem extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            isPositionLoading : false,
            isPositionLoaded : false
        }
        
    }
    loadPosition = () => {
        this.setState({isPositionLoading : true})
        if (this.props.type_os == AppsType.android || this.props.type_os == AppsType.rustore){
            this.searchApp(this.props.searchObject.text)
        } else if (this.props.type_os == AppsType.ios){
            this.searchIosApp(this.props.searchObject.text)
        }
    }
    searchApp = (text) => {
        if (this.props.bundle != "" && this.props.type_os != ""){
            var params = getRequestParams()
            params.bundle = this.props.bundle
            params.query = text
            params.country = this.props.country ? this.props.country : "RU"
            params.type_os = this.props.type_os
            console.log("searchApp params = ", params)
            AppsApi.searchApp(params, (responseData) => {
                this.setState({isPositionLoading : false, isPositionLoaded : true})
                this.props.searchAppPosition(responseData.info, this.props.index)
            })
        }
    }
    searchIosApp = (text) => {
        fetch("https://itunes.apple.com/search?limit=500&country=ru&entity=software&term=" + text, {
            method: 'GET'
        }).then((response) => response.json())
        .then((responseData) => {
            var info = responseData.results;
            var searchPosition = this.checkBundle(info);
        
            if (searchPosition == undefined) {
              searchPosition = 0;
            }
            this.setState({isPositionLoading : false, isPositionLoaded : true})
            this.props.searchAppPosition(searchPosition, this.props.index)
        })
    }
    checkBundle = (info) => {
        for (var i = 0; i < info.length; i++) {
            var item = info[i];

            if (item.bundleId == this.props.bundle) {
                return i + 1;
            }
        }
    }
    componentDidMount(){
        if (this.props.index == 0 || this.props.searchObject.positionBefore == undefined){
            this.loadPosition()
        }
    }
    render() {
        return (
            <div className="order_pos_container">
                <div className="flex">
                    <p className="order_pos_text">{this.props.searchObject.text}</p>
                    {this.props.deleteSearchKey && <img alt='' onClick={() => this.props.deleteSearchKey(this.props.index)} src={close_order} className="remove_order_request hover" />}
                </div>
               <div className="flex">
                    <p className="order_pos_text">{this.props.searchObject.positionBefore == 0 ? strings.notFound1 : parseInt(this.props.searchObject.positionBefore) == 300 ? ">50" : this.props.searchObject.positionBefore}</p>
                    <div className="position_item">
                        {this.state.isPositionLoading ? <InputLoader style={{position : 'initial'}}/> : ((this.props.searchObject.currentPosition || this.props.searchObject.currentPosition == 0) ? <p className="current_position">{this.props.searchObject.currentPosition >= 300 ? ">50" : this.props.searchObject.currentPosition != 0 ? this.props.searchObject.currentPosition : strings.notFound2}</p> : <img onClick={this.loadPosition} className="eye_icon hover" src={eye}/>)}
                    </div>
               </div>
               
            </div>

        );
    }
}
export default OrderPositionItem;