import React from 'react';
import help from '../../../custom_icons/help_nav.svg'
import exchange_tooltip from '../../../custom_icons/exchange_tooltip.svg'

class ExchangeTooltip extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowExchangeTooltip : false
        }
    }
   
    showTooltip = () => {
        this.setState({isShowExchangeTooltip : true})
    }
    closeTooltip = () => {
        this.setState({isShowExchangeTooltip : false})
    }
    render() {
        return (
            <div className="relative">
                <img style={this.props.style != undefined ? this.props.style : {}} onMouseEnter={this.showTooltip} onMouseLeave={this.closeTooltip} className="help_nav" src={help}/>
                <div style={this.state.isShowExchangeTooltip ? {opacity : 1, pointerEvents : 'all'} : {}} onMouseEnter={this.showTooltip} onMouseLeave={this.closeTooltip} className="exchange_tooltip">
                    <img src={exchange_tooltip}/>
                    <p className="exchange_tooltip_text">≈ ${(parseFloat(this.props.money) / parseFloat(this.props.exchangeRate)).toFixed(0)}</p>
                </div>
            </div>
        );
    }
}
export default ExchangeTooltip;