/* eslint-disable eqeqeq */
import React from 'react';
import { AdminOrderStates } from './utils/AdminUtils';

const AdminOrdersHeader = ({ selectedOrderType, setSelectedOrderType }) => {
    const isApps = selectedOrderType == AdminOrderStates.apps
    const isUniversal = selectedOrderType == AdminOrderStates.universal
    const isSocial = selectedOrderType == AdminOrderStates.social
    const isValue = selectedOrderType == AdminOrderStates.value
    const isBuyers = selectedOrderType == AdminOrderStates.buyers
    const isEmployers = selectedOrderType == AdminOrderStates.employers
    return (
        <div>
            <p className="main_title">Админка</p>
            <div>
                <div className="flex sdk_controlls admin_controlls_row">
                    <p onClick={() => setSelectedOrderType(AdminOrderStates.apps)} className={isApps ? "sdk_title_controll sdk_title_controll_margin hover" : "sdk_title_controll sdk_title_controll_margin sdk_title_controll_enabled hover"}>Приложения</p>
                    <p onClick={() => setSelectedOrderType(AdminOrderStates.universal)} style={{marginRight : '24px'}} className={isUniversal ? "sdk_title_controll hover" : "sdk_title_controll sdk_title_controll_enabled hover"}>Универсальные</p>
                    {/* <p onClick={() => setSelectedOrderType(AdminOrderStates.social)} style={{marginRight : '24px'}} className={isSocial ? "sdk_title_controll hover" : "sdk_title_controll sdk_title_controll_enabled hover"}>Накрутки</p> */}
                    <p onClick={() => setSelectedOrderType(AdminOrderStates.value)} style={{marginRight : '24px'}} className={isValue ? "sdk_title_controll hover" : "sdk_title_controll sdk_title_controll_enabled hover"}>Доход</p>
                    <p onClick={() => setSelectedOrderType(AdminOrderStates.buyers)} style={{marginRight : '24px'}} className={isBuyers ? "sdk_title_controll hover" : "sdk_title_controll sdk_title_controll_enabled hover"}>Заказчики</p>
                    <p onClick={() => setSelectedOrderType(AdminOrderStates.employers)} className={isEmployers ? "sdk_title_controll hover" : "sdk_title_controll sdk_title_controll_enabled hover"}>Исполнители</p>
                </div>
                <div className="toolbar_line_container admin_controll_line">
                    <div style={isApps ? {marginLeft : '0px', width : '90px'} : isUniversal ? {marginLeft : '112px', width : '110px'} : isValue ? {marginLeft : '246px', width : '44px'} : isBuyers ? {marginLeft : '312px', width : '73px'} : {marginLeft : '408px', width : '94px'}} className="toolbar_line"/>
                </div>
            </div>
                
        </div>
    )        
    
}
export default AdminOrdersHeader

