/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import { UserApi } from '../api/UserApi';

var strings = getLocalizedStrings()

const Settings = ({setMyInfo, info}) => {
    
    const [isEnabled, setEnabled] = useState(false)
    useEffect(() => {
        if (info && info.mailing_enable != undefined){
            setEnabled(info.mailing_enable)
        }
    }, [info])
    const toggleEnable = (event) => {
        if (event){
            var value = event.target.checked
            setEnabled(value)
            UserApi.setMailingEnable(value, (responseData) => {
                if (responseData.user){
                    var mInfo = {...info}
                    mInfo.mailing_enable = value
                    setMyInfo(mInfo)
                }
            }, () => {})
        }
    }
    
    return (
        
        <div className="main_container order_main">
            <div className="main_title">
                {strings.settings}
            </div>
            <div style={{marginTop : '24px'}} className='flex'>
                <label className="switch">
                    <input type="checkbox" onChange={toggleEnable} checked={isEnabled} />
                    <div className="slider round"></div>
                </label>
                <div className='settings_email'>{strings.settingsEmailing}</div>
            </div>
        </div>
    );
}

export default Settings;
