/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';

var strings = getLocalizedStrings()

function SiteRepeats(props) {
    const isRepeat = props.isRepeat
    const setRepeat = props.setRepeat
    const onChanged = (event) => {
        if (event) {
            var value = event.target.checked
            setRepeat(value)
        }
    }
    return (
        <div className="display_block">
            <div className="line-row" />
            <div className="order_row">
                <div className="switch_container flex">
                    <label className="switch">
                        <input type="checkbox" onChange={onChanged} checked={isRepeat} />
                        <div className="slider round"></div>
                    </label>

                    <p className="text-pre-subscr">{strings.siteRepeats}</p>
                </div>
                <div className="input_description_container">
                    <p className="input_description_text">{strings.siteRepeatsDesc}</p>
                </div>
            </div>

            {/* {count < minCount && <p className="installs_error">{strings.minInstallsCOunt} {minCount} {declension(minCount, strings.siteCount1, strings.siteCount2, strings.siteCount5)}</p>} */}
        </div>
    )
}
export default SiteRepeats;