import React from 'react';
import './dialogs.css'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class GetMoneyDialog extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowError : false
        }
        this.cardNumberChanged = this.cardNumberChanged.bind(this);
    }
    closeNextSdkStep = () => {
        this.props.toggleCloseDialog()
        this.props.closeNextSdkStep()
    }
    cardNumberChanged(event) {
        if (event){
            var text = event.target.value
            this.setState({cardNumberET : text})
            if (this.state.isShowError){
                this.setState({isShowError : false, errorText : ""})
            }
        }
    }
    getMoney = () => {
        this.props.toggleCloseDialog()
    }
    render(){
        return (
            <div className="error_bg">
                <div className="error_banner_container sdk_dialog_container get_m_pad">
                    {/* <img style={{right : '22px', top : '36px'}} className="dialog-close" onClick={this.props.toggleCloseDialog} src={close_delete} alt=""/>    */}
                    <p className="get_money_d_title">{strings.getMoneyTitle}</p>  
                    <p className="get_m_phone">{strings.getMoneyPhone}</p>
                    <input onChange={this.cardNumberChanged} value={this.state.cardNumberET} className="get_m_input" type="number" name="cardNumber"
                            placeholder="Начинается с 4100"/>
                    <p className="text_refs_red">Выплаты на стадии интеграции</p>
                    <p onClick={this.getMoney} style={(this.state.cardNumberET == "" || this.state.cardNumberET == undefined) ? {opacity : '0.5', pointerEvents : 'none'} : {}} className="get_m_btn hover center_get_m">{strings.getMoneyBtn}</p>
                    <p onClick={this.props.toggleCloseDialog} className="get_m_cancel hover">{strings.cancel}</p>
                </div>  
            </div>
        ); 
    }
}
export default GetMoneyDialog;