/* eslint-disable eqeqeq */
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = window.document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  };
export const setCookie = (name,value) => {
    var expires = "";
    var days = 9999
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    window.document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export const getRequestParams = () => {
        var requestParams = {
            'token': getCookie("token"),
            'user_id': getCookie("token"),
            'language' : navigator.language
        }
        
        return requestParams;
    };

    export const saveRegistrCookie = (token) => {
       
        if (token != undefined && token != ""){
            setCookie('token', token)
            setCookie('user_id', token)
        }
       
    };
    
    export const getRequestUrl = () => {
        return "https://upmob.ru/api/";
    };
    export const getToken = () => {
        return getCookie("token");
    };
    export const setCookieData = (email, name) => {
        setCookie('email', email)
        setCookie('first_name', name)
    };
    export const setAuthCookie = (email, token, myId) => {
        setCookie('email', email)
        setCookie('token', token)
        setCookie('myId', myId)
    };
    export const setToken = (token) => {
        setCookie('token', token)
    };
    export const setMore3Orders = () => {
        setCookie('isMore3Orders', 'isMore3Orders')
    };
    export const isMore3Orders = () => {
        return (getCookie("isMore3Orders") == 'isMore3Orders')
    };
    export const setWriteReviewShown = () => {
        setCookie('isWriteReviewShown', 'isWriteReviewShown')
    };
    export const isWriteReviewShown = () => {
        return (getCookie("isWriteReviewShown") == 'isWriteReviewShown')
    };
    export const getEmail = () => {
        return getCookie("email");
    };
    export const getName = () => {
        return getCookie("first_name");
    };
    export const getReferalID = () => {
        return getCookie("referal_id");
    };
  
    export const getCookieStandart = (name) => {
        var nameEQ = name + "=";
        var ca = window.document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return undefined;
    };
