import React from 'react';
class ChooseTypeDropdown extends React.Component {
    constructor(props){
        super(props)
    }
    onTypePicked = (type, index) => {
        this.props.onFilterPicked(type, index)
    }
    onCloseFilters = () => {
        console.log("onClose")
        if (this.props.onCloseFilters){
            this.props.onCloseFilters()
        }
        
    }
    render(){
      
            return (
            
                    <div style={this.props.style} className="apps_dropdown filter_dropdown choose_type_dropdown">
                        <div onClick={this.onCloseFilters} className="tooltip_bg"></div>
                        {this.props.filters.map((type, index) => (
                            <div onClick={() => this.onTypePicked(type, index)} className="flex apps_line" type={type} key={index}>
                                <img className="choose_type_icon" src={type.icon}/>
                                <p className="apps_drop_text choose_type_text">{type.text}</p>
                            </div>   
                        ))}
                        
                    </div>
             
            ); 
        
        
    }
}
export default ChooseTypeDropdown;
