import React from 'react';
import '../tooltips/tooltips.css'
import gift from '../custom_icons/gift.svg'
import close from '../custom_icons/close.svg'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class WriteReviewTooltip extends React.Component {
    constructor(props){
        super(props)
        
    }
    writeReviewClicked = () => {
        window.location.href = 'https://vk.com/topic-157374277_42745947'
    }
    render(){
      
            return (
                <div className="reviewPlashka">
                    <img className="img_write_review" src={gift}/>
                    <div className="review-texts">
                        <p className="title_write_review">{strings.writeReviewTitle}</p>
                        <p className="text_write_review">{strings.forThis} <span onClick={this.writeReviewClicked} className="text_write_review_click hover">{strings.writeReview}</span> {strings.writeReviewText}</p>
                    </div>
                    <img onClick={this.props.closeWriteReview} className="close-review" src={close}/>
                </div>
            ); 
          
    }
}
export default WriteReviewTooltip;
