import React from 'react';
import arrow_filter from '../custom_icons/drop_arrow.svg'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import ChooseTypeDropdown from './ChooseTypeDropdown';
import { getUniversalTypes } from '../utils_android/Utils';
import tg_ads from '../custom_icons/tg_ads.svg'
var strings = getLocalizedStrings()

var arrowRotate = {
    transform: 'rotate(180deg)',
    marginTop: '6px'
}
var types = getUniversalTypes()
class ChooseType extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowTypes : false,
            filterPicked : strings.typeNotPicked
        } 
    }
    toogleTypes = () => {
        this.setState({isShowTypes : !this.state.isShowTypes})
    }
    onTypePicked = (type) => {
        this.setState({filterPicked : type.text})
        this.props.typePicked(type.text)
    }
    openInstarlike = () => {
        window.open('https://instarlike.com', '_blank');
    }
    render() {
        return (
            <div className="display_block">
                    <div className="order_row">
                        <div style={this.props.isClickedCreate && this.state.filterPicked == strings.typeNotPicked ? {border : "1px solid rgba(255, 98, 98, 0.5)"} : {}} onClick={this.toogleTypes} className="button_choose_app choose_type new_border new_border_hover">
                                <p className="choose_type_title">{strings.typePickTitle}</p>
                                <div className="just_content">
                                    <p className="button_choose_text">{this.state.filterPicked}</p>
                                    <img style={this.state.isShowTypes ? arrowRotate : {}} className="choose_drop_arrow" src={arrow_filter}/>
                                </div>
                                {this.state.isShowTypes && <ChooseTypeDropdown filters={types} onFilterPicked={this.onTypePicked}/>}
                        </div>
                        <div className="input_description_container">
                            <p className="input_description_text">{strings.typePickDesc}</p>
                        </div>
                    </div>
                    {this.state.filterPicked == strings.typeInstagram && <div className="flex top_24_banner">
                        <div className="review_banner banner_instarlike flex">
                            <img className="instarlike_img" src={tg_ads}/>
                            <p className="instarlike_banner_text">{strings.instaBanner} <span onClick={this.openInstarlike} className="hover" style={{color : 'rgba(22,150,236, 0.8)', cursor : 'pointer'}}>{strings.instaBannerHref}</span></p>
                            
                        </div>
                    </div>}
            </div>
           
        );
    }
}
export default ChooseType;