/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { getOptionsIcon, getOptions } from '../utils_android/Utils';
import review_icon from '../custom_icons/review.svg';
import third_day_icon from '../custom_icons/third_day.svg';
import likes_up_icon from '../custom_icons/likes_up.svg';
import progress_icon from '../custom_icons/progress.svg';
var strings = getLocalizedStrings()
class OrderInfoProperties extends React.Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (
            <div className="info_props_container">
                <p className="grey_text bot_8">{strings.wrapOptions}</p>
                <div style={getOptionsIcon(0, this.props.order)} className="prop_line">
                    <img alt='' className="prop_line_img" src={third_day_icon}></img>
                    <p className="order_prop_text">{strings.open3DayProp}</p>
                </div>
                <div style={getOptionsIcon(1, this.props.order)} className="prop_line">
                    <img alt='' className="prop_line_img" src={review_icon}></img>
                    <p className="order_prop_text">{strings.reviewWord} {(this.props.order.review_words && this.props.order.review_words != "") ? "(" + this.props.order.review_words + ")" : ""}</p>
                </div>
                <div style={getOptionsIcon(2, this.props.order)} className="prop_line">
                    <img alt='' className="prop_line_img" src={likes_up_icon}></img>
                    <p className="order_prop_text">{strings.goodReviewTopProp}</p>
                </div>
                <div style={getOptionsIcon(3, this.props.order)} className="prop_line">
                    <img alt='' className="prop_line_img" src={progress_icon}></img>
                    <p className="order_prop_text">{strings.progressProp}</p>
                </div>
                {getOptions(this.props.order) == strings.noOptions && <p className="order_prop_text margin_0">{getOptions(this.props.order)}</p>}
                <div className={(this.props.order.limit_day && this.props.order.limit_day != "" && this.props.order.limit_day != 99999 && this.props.order.limit_day != "99999") ? "max_installs_container" : "not_display"}>
                    <p className="grey_text bot_8">{strings.maxInstallInfo}</p>
                    <div style={this.props.order.limit_day && this.props.order.limit_day != "" ? { display: 'block' } : { display: 'none' }} className="prop_line">
                        <p className="order_prop_max">{this.props.order.limit_day} {strings.onDay}</p>
                    </div>
                    <div style={(this.props.order.limit_hour && this.props.order.limit_hour != "" && this.props.order.limit_hour != 99999 && this.props.order.limit_hour != "99999") ? { display: 'block' } : { display: 'none' }} className="prop_line">
                        <p className="order_prop_max">{this.props.order.limit_hour} {strings.onOur}</p>
                    </div>
                    <div style={(this.props.order.limit_minute && this.props.order.limit_minute != "" && this.props.order.limit_minute != 99999 && this.props.order.limit_minute != "99999") ? { display: 'block' } : { display: 'none' }} className="prop_line">
                        <p className="order_prop_max">{this.props.order.limit_minute} {strings.onMinute}</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default OrderInfoProperties;
