import React from 'react';
import { getLocalizedStrings } from '../../../utils_android/LocalizedStrings';
import arrow_filter from '../../icons/drop.svg'
import check_blue from '../../icons/check_blue.svg'
import plus from '../../icons/plus.svg'
import Keywords from '../Keywords';
import NewKeywords from '../NewKeywords';
import NewTagName from '../tooltips/NewTagName';
import ReviewText from './ReviewText';
import tags_new from '../../icons/tag_new.svg'
import { getRequestUrl, getHttpParams } from '../../utils/Utils';
import { getRequestParams, getEmail } from '../../../utils_android/CookieHelper';
import EmptyStats from '../../../empty_views/EmptyStats';
import TagListRedactor from '../tooltips/TagListRedactor';
import TagListRefactorController from '../TagListRefactorController';
var disabledStyle = {pointerEvents : 'none', opacity : 0}
var strings = getLocalizedStrings()
var arrowRotate = {
    transform: 'rotate(180deg)'
}
class TagName extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            tagName : "",
            isShowFilters : false,
            currentIndex : -1,
            currentItem : {},
            categories : this.props.categories,
            filteredCategories : this.props.categories,
            isSearching : false,
            isNewTag : false,
            isShowNewTag : false,
            isRefactorTags : false,
            newName : "",
            newTagKeyword : "",
            newTagIgnore : ""
        }
        this.newWords = []
        this.newWordsNot = []
        this.oldWords = []
        this.oldWordsNot = []
        
        this.onTagNameChanged = this.onTagNameChanged.bind(this);
        this.toogleFilters = this.toogleFilters.bind(this);
        this.selectTag = this.selectTag.bind(this);
        this.newTagClicked = this.newTagClicked.bind(this);
        this.wordsChanged = this.wordsChanged.bind(this);
        this.wordsChangedNew = this.wordsChangedNew.bind(this);
        this.wordsNotChanged = this.wordsNotChanged.bind(this);
        this.wordsNotChangedNew = this.wordsNotChangedNew.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.cancelNewTag = this.cancelNewTag.bind(this);
        this.addKeyword = this.addKeyword.bind(this);
        this.addIgnor = this.addIgnor.bind(this);
        this.clearNewTags = this.clearNewTags.bind(this);
        this.updateNewTag = this.updateNewTag.bind(this);
        this.toogleRefactorTags = this.toogleRefactorTags.bind(this);
    }
    componentDidUpdate(prevProps){
        if (prevProps != this.props){
            this.setState({categories : this.props.categories, filteredCategories : this.props.categories})
        }
    }
    toogleRefactorTags(){
        this.setState({isRefactorTags : !this.state.isRefactorTags})
    }
    onTagNameChanged(event){
        if (event){
            var text = event.target.value
            const filteredArray = this.state.categories.filter(category => category.tag_name.includes(text))
            this.setState({tagName : text, filteredCategories : filteredArray, isShowFilters : true, isSearching : (text != "" && text != undefined)})
            if (text == "" || text == undefined){
                this.setState({currentIndex : -1, currentItem : {}})
            }
        }
    }
    toogleFilters(){
        this.setState({isShowFilters : !this.state.isShowFilters, isSearching : false})
    }
    selectTag(index, tag){
        console.log("currentItem = " + JSON.stringify(tag))
        this.setState({currentIndex : index, tagName : tag.tag_name, currentItem : tag, isNewTag : false})
        this.toogleFilters()
    }
    newTagClicked(){
        this.setState({isShowNewTag : true, pickedTag : ""})
        this.toogleFilters()
    }
    cancelNewTag(){
        this.setState({isShowNewTag : false})
    }
    wordsChangedNew(words){
        this.newWords = words
        console.log("wordsChanged = " + words)
    }
    wordsNotChangedNew(words){
        this.newWordsNot = words
        console.log("wordsNotChanged = " + words)
    }
    wordsChanged(words){
        this.oldWords = words
        console.log("oldwordsChanged = " + words)
    }
    wordsNotChanged(words){
        this.oldWordsNot = words
        console.log("oldwordsNotChanged = " + words)
    }
    addCategory(category){
        var categories = this.state.categories
        categories.push(category)
        console.log("category = " + JSON.stringify(category))
        this.setState({currentIndex : (categories.length - 1), currentItem : category, categories : categories, isSearching : false, filteredCategories : categories, tagName: category.tag_name, isNewTag : true})
        this.clearNewTags()
    }
    addKeyword(keyword){
        var currentItem = this.state.currentItem
        if (currentItem.tag_name != undefined){
            if (currentItem.words == ""){
                currentItem.words = keyword
            } else {
                currentItem.words = currentItem.words + "|" + keyword
            }
        }
        console.log("currentItem = " + JSON.stringify(currentItem))
        this.setState({currentItem : currentItem, newTagKeyword : keyword})
    }
    addIgnor(ignore){
        var currentItem = this.state.currentItem
        if (currentItem.tag_name != undefined){
            if (currentItem.words_not == ""){
                currentItem.words_not = ignore
            } else {
                currentItem.words_not = currentItem.words_not + "|" + ignore
            }
        }
        this.setState({currentItem : currentItem, newTagIgnore : ignore})
    }
    clearNewTags(){
        console.log("clearNewTags")
        this.setState({newTagKeyword : "", newTagIgnore : ""})
    }
    updateNewTag(){
        var name = ""
        var words = []
        var wordsNot = []
        var type = 0
        if (this.state.currentItem._id == undefined){
            name = this.state.currentItem.tag_name
            words = this.newWords
            wordsNot = this.newWordsNot
        } else {
            name = this.state.currentItem.tag_name
            words = this.oldWords
            wordsNot = this.oldWordsNot
        }
        if(words.length > 0){
            words = words.join("|")
        } else {
            words = ""
        }
        if(wordsNot.length > 0){
            wordsNot = wordsNot.join("|")
        } else {
            wordsNot = ""
        }
        
        var params = getRequestParams()
        params.tag_name = name
        params.words = words
        params.words_not = wordsNot
        params.type = type
        params.bundle = this.props.bundle
        params.owner_email = getEmail()
        console.log("params setConfigReviews = " + JSON.stringify(params))
        fetch(getRequestUrl() + "setConfigReviews", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("setConfigReviews = " + JSON.stringify(responseData))
            this.props.toogleAddTags()
            this.props.infoCategoryChanged()
        })
    }
    render(){
            return (
                <div>
                    
                    <div className="review_text_container">
                        <div className="flex_input_cont">
                            <input className="enter_tag_name" placeholder={strings.tagNamePlaceholder} onChange={this.onTagNameChanged} value={this.state.tagName}/>
                            <div className="flex">
                                <div className="line_tag_name"/>
                                <img onClick={this.toogleFilters} className="drop_arrow_tagname hover" style={this.state.isShowFilters ? arrowRotate : {} } src={arrow_filter}/>
                            </div>
                                    <div style={this.state.isShowFilters ? {pointerEvents : 'auto', opacity : 1} : disabledStyle} className="tags_name_list">
                                        <div onClick={this.toogleFilters} className="tooltip_bg_analize"></div>
                                        {!this.state.isSearching && <div onClick={this.newTagClicked} className="add_tag_item">
                                            <img src={plus}/>
                                            <p className="add_tag_item_text">{strings.createNewCategory}</p>
                                        </div>}
                                        {this.state.filteredCategories.length > 0 && this.state.filteredCategories.map((item, index) => (
                                                <div onClick={() => this.selectTag(index, item)} style={this.state.currentIndex == index ? {background : 'rgba(227, 240, 255, 0.5)'} : {}} className="tag" index={index} key={index}>
                                                    {item.tag_name}
                                                    <img style={this.state.currentIndex == index ? {display : 'block'} : {display : 'none'}} src={check_blue}/>
                                                </div>
                                        ))}
                                        {this.state.isSearching && <div onClick={this.newTagClicked} className="add_tag_item">
                                            <img src={plus}/>
                                            <p className="add_tag_item_text">{strings.createNewCategory}</p>
                                        </div>}
                                    
                                    </div>
                        </div>
                        <div className="info_tag_desc_cont info_tag_text top_0_m">{strings.tagNameDesc} <span onClick={this.toogleRefactorTags} className="hover redactor_tag">{strings.tagNameDesc2}</span></div>
                        
                    </div>
                    <ReviewText currentItem={this.state.currentItem} addIgnor={this.addIgnor} addKeyword={this.addKeyword} review={this.props.review}/>
                    <div className="add_info_tag_line"></div>
                    {this.state.currentIndex == -1 && <EmptyStats botStyle={{marginBottom : '34px'}} topStyle={{marginTop : '34px'}} text1={strings.pickTagsCategory} text2={''} text3={""} icon={tags_new}/>}
                    {(this.state.currentIndex != -1) && <Keywords wordsChanged={this.wordsChanged} wordsNotChanged={this.wordsNotChanged} currentItem={this.state.currentItem} isShow={this.state.currentIndex != -1}/>}       
                    {/* {this.state.isNewTag && <NewKeywords currentItem={this.state.currentItem} clearNewTags={this.clearNewTags} newTagKeyword={this.state.newTagKeyword} newTagIgnore={this.state.newTagIgnore} isCategory={true} nameChanged={this.nameChanged} wordsChanged={this.wordsChangedNew} wordsNotChanged={this.wordsNotChangedNew} isShow={this.state.isNewTag}/>} */}
                    <div className="add_info_tag_line"></div>
                    {this.state.isShowNewTag && <NewTagName tagName={this.state.tagName} cancelNewTag={this.cancelNewTag} addCategory={this.addCategory} categories={this.state.categories}/>}
                    <div onClick={this.updateNewTag} className={this.state.currentItem.tag_name != undefined ? "update_tag_btn update_tag_btn_active hover" : "update_tag_btn hover"}>{strings.updateNewTag}</div>
                    {this.state.isRefactorTags && <TagListRefactorController bundle={this.props.bundle} toogleRefactorTags={this.toogleRefactorTags} onDeleteConfirmed={this.props.onDeleteConfirmed} onRenameConfirmed={this.props.onRenameConfirmed} categories={this.props.categories}/>}
                </div>
            );         
    }
}
export default TagName;
