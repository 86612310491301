/* eslint-disable eqeqeq */
import qs from 'querystring'
import android_order from '../../custom_icons/android_order.svg';
import ios_order from '../../custom_icons/ios_order.svg';
import ic_rustore from '../../custom_icons/ic_rustore.svg';
import rustore from '../../custom_icons/rustore.svg';
import android_icon from '../../custom_icons/android.svg'
import ios_icon from '../../custom_icons/apple.svg'
export function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? undefined : decodeURIComponent(sParameterName[1]);
        }
    }
};

export const getRequestUrl = () => {
    return "https://upmob.ru/api/";
};
export const getBotRequestUrl = () => {
    return "https://upmob.ru/api/bot_boost/";
};
export const getHttpParams = (params) => {
    var httpParams = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        body: qs.stringify(params)
    }
    return httpParams;
};
export const Pages = {
    orders: '/orders',
    ordersFull: '/orders/full',
    ordersOrder: '/orders/order',
    auth: '/auth',
    newOrder: '/neworder',
    createInstallsOrder: '/create_installs_order',
    createOrderSite: '/create_order_site', 
    siteSeoOrders: '/site_seo_orders',
    siteSeoOrdersAll: '/site_seo_orders/all',
    siteSeoOrdersOrder: '/site_seo_orders/order',
    installsOrders: '/installs_orders',
    installsOrderInfo: '/installs_order_info',
    referals: '/referals',
    statistics: '/statistics',
    seo: '/seo',
    pay: '/pay',
    policy: '/policy',
    universalOrders: '/universal_orders',
    createUniversal: '/create_universal',
    social: '/social',
    reviews: '/reviews',
    settings: '/settings',
    admin: '/jj0312admin',
    employerInfo: '/employer_info',
    agreement: 'https://upmob.ru/agreement'
}
export const declension = (count, one, two, five) => {
    var realCount = parseInt(count);
  
    if (realCount > 100)
    realCount %= 100;
  
    if (realCount > 20)
    realCount %= 10;
  
    switch (realCount) {
        case 1:
            return one;
        case 2:
        case 3:
        case 4:
            return two;
        default:
            return five;
    }
  }

export const getUnityCode = () =>{
    const code = 'using System.Collections;\n' + 
    'using System.Collections.Generic;\n' + 
    'using UnityEngine;\n\n' +
    'public class Upmob : MonoBehaviour {\n\n' +
        '   public static void InitUpmob(){\n' + 
            '       object[] parameters = new object[1];\n' + 
            '       parameters[0] = GetUnityActivity();\n' + 
            '       GetLibrary().Call("InitUpmob", parameters);\n' +
    '    }\n' +
    '    public static void SendEvent(string event_id){\n' +
            '       object[] parameters = new object[1];\n' +
            '       parameters[0] = event_id;\n' +
            '       GetLibrary().Call("SendEvent", parameters);\n' +
    '    }\n' +
    '    private static AndroidJavaObject GetLibrary(){\n' +
            '       AndroidJavaObject events = new AndroidJavaObject("com.hack.test.TangramEvents");\n' +
            '       return events;\n' +
    '    }\n' +
    '    private static AndroidJavaObject GetUnityActivity(){\n' +
            '       AndroidJavaClass unityPlayerClass = new AndroidJavaClass("com.unity3d.player.UnityPlayer");\n' +
            '       AndroidJavaObject unityActivity = unityPlayerClass.GetStatic<AndroidJavaObject>("currentActivity");\n' +
            '       return unityActivity;\n' +
    "    }\n" +
    '}'
    return code
}
export const splitTextByTwoSigns = (text,firstSign,secondSign) =>{
    var numFirst = text.indexOf(firstSign)
    var endPartHTML = text.substring(numFirst+firstSign.length)
    var out = endPartHTML;
    var numSecond = endPartHTML.indexOf(secondSign)
    if(numSecond != -1){
        out = endPartHTML.substring(0,numSecond)
    }else{
        return [undefined,numFirst,numFirst+numSecond+2]
    }
    return [out,numFirst,numFirst+numSecond+2]
}
export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
}


export const proccessRandomVariants = (textSource) => {
    try{
        var text = textSource+""
        var cursor = 0
        var startLength = 0
        var result = []
        var lastArrSize = undefined
        while(cursor != -1){
            var out = splitTextByTwoSigns(text,"[","]")
          cursor = out[1]
          
          if(out[0] != undefined && out[0] != ""){
                var arr = out[0].split(";")
                result.push([arr,startLength+out[1],startLength+out[2]])
                text = text.substring(out[2])
                startLength += out[2]
                if(lastArrSize != undefined && lastArrSize != arr.length){
                      return ["-1", 0]
                }
                lastArrSize = arr.length
          }
        }
        var index = getRandomInt(result[0].length-1)
        var finalText = ""
        cursor = 0
        result.forEach(item => {
            finalText += textSource.substring(cursor,item[1])
            finalText += '<b>'+ item[0][index] +'</b>'
            cursor = item[2]
        });
        
        finalText += textSource.substring(cursor)
        return [finalText,result[0].length]
    } catch{
        return [textSource, 0]
    }    
}
export const AppsType = {
    android: "android",
    ios: "ios",
    rustore: "rustore"
}

export const getAppUrl = (type_os, bundle) => {
    var url = ""
    if (type_os == AppsType.android){
        url = AppsUrls.android + bundle
    } else if (type_os == AppsType.rustore){
        url = AppsUrls.rustore + bundle
    } else {
        url = AppsUrls.ios + bundle
    }
    return url
}
export const getSearchRequestAppUrl = (type_os, bundle, search_request) => {
    var url = ""
    if (type_os == AppsType.android){
        url = AppsSearchUrls.android + search_request + '&c=apps&hl=ru'
    } else if (type_os == AppsType.rustore){
        url = AppsSearchUrls.rustore + search_request
    } else {
        if (window.innerWidth >= 769) {
            url = AppsUrls.ios + bundle
        } else {
            url = AppsSearchUrls.ios + search_request
        }
    }
    return url
}
export const getAppIconType = (type_os, isRounded) => {
    if (isRounded){
        return type_os == AppsType.ios ? ios_order : type_os == AppsType.android ? android_order : ic_rustore
    }
    return type_os == AppsType.ios ? ios_icon : type_os == AppsType.android ? android_icon : rustore

}
export const AppsUrls = {
    android : "https://play.google.com/store/apps/details?id=",
    rustore : "https://www.rustore.ru/catalog/app/",
    ios : "https://itunes.apple.com/ru/app/id"
}
export const AppsSearchUrls = {
    android : "https://play.google.com/store/search?q=",
    rustore : "https://www.rustore.ru/catalog/search?query=",
    ios : "https://search.itunes.apple.com/WebObjects/MZSearch.woa/wa/search?media=software&term="
}
// var text = '1) Перейти по ссылке [раскрутка приложений в google play; продвижение в мобильных приложений] и найти статью с заговолком [Раскрутка приложений в Google Play бесплатно; Продвижение приложений в Google Play бесплатно] 2) Нажать на нее. И найти на странице фразу [Видео c YouTube как бесплатная...;Видео c Вимео как бесплатная...]. В этой фразе не хватает одного слова. Его и нужно узнать для отчета.'
// var finalText = proccessRandomVariants(text)
// console.log("finalText:"+finalText)