import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import {DebounceInput} from 'react-debounce-input';
import { getAnimationStyle } from './utils_android/Utils';

var strings = getLocalizedStrings()
class TrackingUrl extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isChecked : false,
            trackUrl : "",
            description : "",
        }
        this.installsWordSwitchClicked = this.installsWordSwitchClicked.bind(this)
        this.trackUrlChanged = this.trackUrlChanged.bind(this)
        this.descriptionChanged = this.descriptionChanged.bind(this)
    }
    installsWordSwitchClicked(event){
        if (event){
            var value = event.target.checked
            this.setState({isChecked : value})
            this.props.onTrackUrlChanged(this.state.trackUrl, this.state.description, value)
        }
    }
    trackUrlChanged(event){
        if (event){
            var url = event.target.value
            this.setState({trackUrl : url})
            this.props.onTrackUrlChanged(url, this.state.description, this.state.isChecked)
        }
    }
    descriptionChanged(event){
        if (event){
            var desc = event.target.value
            this.setState({description : desc})
            this.props.onTrackUrlChanged(this.state.trackUrl, desc, this.state.isChecked)
        }
    }
    

    render() {
        return (
            <div className="block">
                <div style={this.state.isChecked ? {marginBottom : '30px'} : {marginBottom : '0px'}} className="order_row">
                    <div className="switch_container flex">
                        <label className="switch">
                            <input type="checkbox" onChange={this.installsWordSwitchClicked} checked={this.state.isChecked} ng-model="installsWordSwitch" />
                            <div className="slider round"></div>
                        </label>

                        <p className="text-pre-subscr">{strings.trackUrl}</p>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{strings.trackUrlDesc}</p>
                    </div>
                </div>

                <div style={this.state.isChecked ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0}} className="display_block height_animation">
                    <div className="order_row bot_16">
                        <div style={{display : 'block'}} className="input_container_border display_block">{strings.trackUrlLink}
                                <DebounceInput autoComplete="off" 
                                onChange={this.trackUrlChanged} className="input_text_search" value={this.state.trackUrl}/>
                        </div>

                        <div className="input_description_container">
                            <p className="input_description_text">{strings.trackUrlLinkDesc}</p>
                        </div>
                    </div>

                    <div className="order_row">
                        <div style={{display : 'block'}} className="input_container_border display_block">{strings.trackUrlTask}
                                <DebounceInput autoComplete="off" 
                                onChange={this.descriptionChanged} className="input_text_search" placeholder={strings.trackUrlTaskPlaceholder} value={this.state.description}/>
                        </div>
                        <div className="input_description_container">
                            <p className="input_description_text">{strings.trackUrlTaskDesc}</p>
                        </div>
                    </div>
                </div>

            </div>
           
        );
    }
}
export default TrackingUrl;