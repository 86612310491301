import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import { getUrlParameter } from './utils_android/UrlHelperAndroid';

var strings = getLocalizedStrings()

class Open3Day extends React.Component {
    constructor(props){
        super(props)
        var preOrderThirdDay = getUrlParameter("open_3_day")
        this.state = {
            isOpen3DayChecked : (preOrderThirdDay ? true : false)
        }
        if(preOrderThirdDay) {
            this.props.on3DayChanged(true)
        }
        this.day3SwitchChanged = this.day3SwitchChanged.bind(this)
    }
    day3SwitchChanged(event){
        if (event){
            var value = event.target.checked
            this.setState({isOpen3DayChecked : value})
            this.props.on3DayChanged(value)
        }
    }
   
    render() {
        return (
            <div className="order_row">
                    <div className="switch_container flex">
                        <label className="switch">
                            <input type="checkbox" onChange={this.day3SwitchChanged} checked={this.state.isOpen3DayChecked} />
                            <div className="slider round"></div>
                        </label>

                        <p className="text-pre-subscr">{strings.open3Day}</p>
                        <p className={this.props.costOpen3dDayTV > 0 ? "text-rub" : "not_display"}>+{this.props.costOpen3dDayTV}₽</p>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{strings.open3DayDesc}</p>
                    </div>
            </div>
           
        );
    }
}
export default Open3Day;