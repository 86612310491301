/* eslint-disable eqeqeq */
import React from 'react';
import add from '../custom_icons/add.svg';
import stats from '../custom_icons/stats.svg';
import trash_red from '../custom_icons/trash_red.svg';
import trash_bl from '../custom_icons/delete.svg';
import copy from '../custom_icons/copy.svg';
import users from '../custom_icons/users.svg';
import add_request from '../custom_icons/add_request.svg';
import { getSearchWords, openDuplicateOrder, convertToIDFA } from '../utils_android/Utils';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { getRequestUrl, getRequestParams } from '../utils_android/CookieHelper';
import OrderPositionItem from '../../list_items/OrderPositionItem';
import { getHttpParams, Pages } from '../analyze_review/utils/Utils';
import AddMoneyTooltip from '../AddMoneyTooltip';
import CircleProgress from '../CircleProgress';
import DeleteOrderDialog from '../dialogs/DeleteOrderDialog';
import OrderInfoHeader from './OrderInfoHeader';
import {CSSTransitionGroup} from 'react-transition-group';
import SdkInfo from './SdkInfo';
import ExchangeTooltip from '../analyze_review/customViews/tooltips/ExchangeTooltip';
import AddRequestTooltip from '../AddRequestTooltip';
import OrderInfoProperties from './OrderInfoProperties';
import OrderInfoStats from './OrderInfoStats';
import OrderBack from './OrderBack';
import DeleteEmployersDialog from '../dialogs/DeleteEmployersDialog';

var strings = getLocalizedStrings()
class OrderInfo extends React.Component {
    constructor(props){
        super(props)
        var searchWords = getSearchWords(this.props.order.search_request, this.props.order.search_position)
        if (searchWords && searchWords.length == 1){
            searchWords[0].positionBefore = this.props.order.search_position
        }
        
        this.state = {
            searchWords : searchWords,
            isShowUpBalance : false,
            isShowDeleteDialog : false,
            isShowDeleteEmployersDialog : false,
            isShowSdkControll : false,
            isGpUpdated : false,
            newEnteredKeyword : "",
            isShowAddRequest : false,
            image : ""
        }
        this.duplicateOrder = this.duplicateOrder.bind(this)
        this.closeOrderInfo = this.closeOrderInfo.bind(this)
        this.searchAppPosition = this.searchAppPosition.bind(this)
        this.setStateOrder = this.setStateOrder.bind(this)
        this.upBalance = this.upBalance.bind(this)
        this.closeUpBalance = this.closeUpBalance.bind(this)
        this.openStats = this.openStats.bind(this)
        this.deleteOrder = this.deleteOrder.bind(this)
        this.onCanceledDelete = this.onCanceledDelete.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.getOrderDetails = this.getOrderDetails.bind(this)
        this.showIdfa = this.showIdfa.bind(this)
        this.toggleSdkControll = this.toggleSdkControll.bind(this)
        this.gpUpdated = this.gpUpdated.bind(this)
        this.deleteSearchKey = this.deleteSearchKey.bind(this)
        this.toggleAddRequest = this.toggleAddRequest.bind(this)
        this.deleteEmployers = this.deleteEmployers.bind(this)
    }
    componentDidMount(){
        if (this.props.order.bundle == undefined){
            window.location.href = Pages.ordersFull
        }
    }
    closeOrderInfo() {
        this.props.orderInfoClosed()
    }
    gpUpdated(){
        this.setState({isGpUpdated : true})
    }
    setStateOrder() {
        var params = getRequestParams()
        var newState = (this.props.order.state == 1 ? 0 : 1)
        params._id = this.props.order._id
        params.state = newState
        fetch(getRequestUrl() + "setStateOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1) {  
                this.props.order.state = newState
                this.props.orderChanged(this.props.order, this.props.index)
            }
        })
    }
    searchAppPosition(position, index) {
        var changedArray = this.state.searchWords
        changedArray[index].currentPosition = position
        if (changedArray[index].positionBefore == undefined){
            changedArray[index].positionBefore = position
        }
        this.setState({searchWords : changedArray})
    }
    upBalance() {
        this.setState({isShowUpBalance : !this.state.isShowUpBalance})
    }
    closeUpBalance() {
        this.setState({isShowUpBalance : false})
    }
    deleteEmployers() {
        this.setState({isShowDeleteEmployersDialog : !this.state.isShowDeleteEmployersDialog})
    }
    openStats() {
        window.open('/statistics?bundle=' + this.props.order.bundle, '_blank');
    }
    
    deleteOrder() {
        this.setState({isShowDeleteDialog : true})
    }
    onCanceledDelete() {
        this.setState({isShowDeleteDialog : false})
    }
    onDelete() {
        var params = getRequestParams()
        params._id = this.props.order._id
        fetch(getRequestUrl() + "removeOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            this.setState({isShowDeleteDialog : false})
            this.props.deleteOrder(this.props.index)
            this.props.getMyInfo()
        })
        
        
    }
    getOrderDetails(){
        var params = getRequestParams()
        params.order_id = this.props.order._id
        fetch(getRequestUrl() + "getOrderDetails", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            var idfas = []
            responseData.order.employers_token_google.forEach(element => {
                if (element.length > 10){
                    idfas.push(convertToIDFA(element))
                }
            });
            var csvString = idfas.join("\r\n");
            var a         = document.createElement('a');
            a.href        = 'data:application/octet-stream;base64,'+btoa(csvString);
            a.download    = this.props.order._id + '.csv';
            document.body.appendChild(a);
            a.click();
        })
    }
    showIdfa(){
        this.props.showIdfa(this.props.order)
    }
    duplicateOrder(){
        openDuplicateOrder(this.props.order)
    }
    toggleSdkControll(isShowSdkControll){
        this.setState({isShowSdkControll : isShowSdkControll})
    }
    deleteSearchKey(index){
        var searchWords = this.state.searchWords
        searchWords.splice(index, 1);
        this.setState({searchWords : searchWords})
        this.save(searchWords)
    }
    save = (searchWords) => {
        var params = getRequestParams()
        var search_request = ""
        var search_position = ""
        searchWords.forEach(searchObject => {
            if (search_request == ""){
                search_request = searchObject.text
                search_position = searchObject.positionBefore
            } else {
                search_request += ("," + searchObject.text)
                search_position += ("," + searchObject.positionBefore)
            }
        });
        params.order_id = this.props.order._id
        params.search_request = search_request
        params.search_position = search_position
        fetch(getRequestUrl() + "editOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            var order = this.props.order
            order.search_request = search_request
            order.search_position = search_position
            this.props.editOrderSearchInfo(order, this.props.pickedOrderIndex)
        }) 
    }
    
    toggleAddRequest(){
        this.setState({isShowAddRequest : !this.state.isShowAddRequest})
    }
    addNewSearchWords = (newSearchWords) => {
        var searchWords = this.state.searchWords
        newSearchWords.forEach(element => {
            searchWords.push(element)
        });
        this.setState({searchWords : searchWords})
        this.save(searchWords)
    }
    
    render() {
        console.log("this.props.order = ", this.props.order)

        if (this.props.order.bundle == undefined){
            return(<div/>)
        }
        return (
            <div className="order_info">
              <OrderBack />
              <OrderInfoHeader isGpUpdated={this.state.isGpUpdated} isShowSdkControll={this.state.isShowSdkControll} order={this.props.order} setStateOrder={this.setStateOrder} toggleSdkControll={this.toggleSdkControll}/>
              <CSSTransitionGroup transitionName="example" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
                {this.state.isShowSdkControll && 
                    <SdkInfo gpUpdated={this.gpUpdated} isGpUpdated={this.state.isGpUpdated} order={this.props.order} />
                }
                {!this.state.isShowSdkControll && 
                    <div className="info_container">
                        {/* 1 column */}
                        <div className={this.state.searchWords.length > 0 ? "search_words_container" : 'not_display'}>
                            <div className="search_words_title_container">
                                <p className="search_words_title">{strings.searchRequest}</p>
                                <div className="flex">
                                    <p className={(this.state.searchWords.length == 1 || (this.props.order.search_position != undefined && this.props.order.search_position.includes(","))) ? "search_words_title" : "not_display"}>{strings.positionBefore}</p>
                                    <p className="search_words_title after_pos">{(this.state.searchWords.length == 1 || (this.props.order.search_position != undefined && this.props.order.search_position.includes(","))) ? strings.positionAfter : strings.positionNumber}</p>
                                </div>
                            </div>

                            <div onClick={this.toggleAddRequest} className="info_add_request hover">
                                <div className="flex center_hor">
                                    <img className="info_add_request_img" src={add_request}/>
                                    <p className="info_add_request_text">{strings.infoAddRequest}</p>
                                </div>
                            </div>


                            {this.state.searchWords.length > 0 && this.state.searchWords.map((item, index) => (
                                <OrderPositionItem deleteSearchKey={this.deleteSearchKey} searchAppPosition={this.searchAppPosition} bundle={this.props.order.bundle} type_os={this.props.order.type_os} index={index} searchObject={item} key={index} />
                            ))}
                            
                        </div>
                        {/* 2 column */}
                        <OrderInfoProperties order={this.props.order}/>
                        {/* 3 column */}
                        <div className="info_installs">
                            <p className="grey_text center_text">{strings.installsProp}</p>
                            <CircleProgress order={this.props.order} state={this.props.order.state} count_installed={this.props.order.count_installed} max_count_installs={this.props.order.max_count_installs} percentage={(this.props.order.count_installed/this.props.order.max_count_installs)*100 >= 100 ? 100 : (this.props.order.count_installed/this.props.order.max_count_installs)*100}/>
                            <div className="line_info"/>
                            <p className="grey_text center_text">{strings.budgetProp}</p>
                            <div className="ref_exchange_container center_hor">
                                <p className="budget_text">{this.props.order.count_money_r}₽</p>
                                <ExchangeTooltip style={{marginTop : '22px'}} money={this.props.order.count_money_r} exchangeRate={this.props.exchangeRate}/>
                            </div>
                            
                            <div className="line_info_budget"/>
                            <div onClick={this.upBalance} className="up_balance_info hover">
                                <img src={add}></img>
                                <p className="up_balance_info_text">{strings.upBalanceProp}</p>
                                
                            </div>
                            {this.state.isShowUpBalance && <AddMoneyTooltip isLeft={true} count_money_r={this.props.count_money_r} index={this.props.index} order_id={this.props.order._id} addCoinsToOrder={this.props.addCoinsToOrder} closeUpBalance={this.closeUpBalance} price_one_install={this.props.order.price_one_install} />}
                            
                            <div onClick={this.openStats} className="up_balance_info top_16 hover">
                                <img src={stats}></img>
                                <p className="up_balance_info_text">{strings.statsProp}</p>
                            </div>
                            <div onClick={this.showIdfa} className="up_balance_info top_16 hover">
                                <img src={users}></img>
                                <p className="up_balance_info_text">{strings.idfaTitle}</p>
                            </div>

                            <div onClick={this.duplicateOrder} className="up_balance_info top_16 hover">
                                <img src={copy}></img>
                                <p className="up_balance_info_text">{strings.duplicateOrder}</p>
                            </div>

                            <div onClick={this.deleteEmployers} className="up_balance_info top_16 hover">
                                <img style={{opacity: 0.8, marginLeft: '-2px', marginRight: '-4px'}} src={trash_bl}></img>
                                <p className="up_balance_info_text">{strings.deleteOrderEmployers}</p>
                            </div>
                            <div onClick={this.deleteOrder} className="up_balance_info top_16 hover">
                                <img src={trash_red}></img>
                                <p className="up_balance_info_text red_text">{strings.deleteOrderInfo}</p>
                            </div>
                        </div>
                        
                    </div>}
                </CSSTransitionGroup>
                {this.state.isShowDeleteDialog && <DeleteOrderDialog onCanceledDelete={this.onCanceledDelete} onDelete={this.onDelete}/>} 
                {this.state.isShowDeleteEmployersDialog && <DeleteEmployersDialog order_id={this.props.order._id} onCanceledDelete={() => this.setState({isShowDeleteEmployersDialog : false})}/>} 
                {this.state.isShowAddRequest && <AddRequestTooltip addNewSearchWords={this.addNewSearchWords} index={this.props.index} order={this.props.order} toggleTooltip={this.toggleAddRequest} />}
                {!this.state.isShowSdkControll && <OrderInfoStats isUniversal={false} order={this.props.order}/>}
            </div> 
           
        );
    }
}
export default OrderInfo;