/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import close_delete from '../../pages_android/custom_views/custom_icons/close_delete.svg'
import flag_eng from '../../pages_android/custom_views/custom_icons/flag_eng.png'
import flag_ru from '../../pages_android/custom_views/custom_icons/flag_ru.png'
import check_lang from '../../pages_android/custom_views/custom_icons/check_lang.svg'
import { getLocalizedStrings } from '../../pages_android/custom_views/utils_android/LocalizedStrings';
import { getCookie, setCookie } from '../../pages_android/custom_views/utils_android/CookieHelper';
import { getIsDesktop } from '../../pages_android/custom_views/utils_android/SocialHelper';
const strings = getLocalizedStrings()
const ChooseLanguageDialog = ({ isShow, setShow }) => {
    const [currentLang, setCurrentLang] = useState(getCookie("picked_lang"))
    
    useEffect(() => {
       if (isShow){
            setCurrentLang(getCookie("picked_lang"))
       }
    }, [isShow])
    const closeDialog = () => {
        setShow(false)
    }
    const save = () => {
        setCookie("picked_lang", currentLang)
        closeDialog()
        window.location.href = '/orders/full'
    }
    const isRu = currentLang ? currentLang.includes("ru") : 'ru'
    if (!isShow) return null
    return (
        <div className="error_bg">
            <div style={getIsDesktop() ? {width : '340px', padding : '24px'} : {padding : '24px'}} className="error_banner_container">
                <img style={{right : '22px'}} className="dialog-close" onClick={closeDialog} src={close_delete} alt=""/>   
                <p className="delete_order_title">{strings.chooseLangDialog}</p>  
                
                <div onClick={() => setCurrentLang("ru")} style={isRu ? {background: 'var(--light-blue)'} : {}} className='choose_lang_row hover'>
                    <div className='flex'>
                        <img  style={{height : '24px', width : '24px'}} alt='ru' src={flag_ru}/>
                        <div className='choose_lang_row_text'>Русский</div>
                    </div>
                    {isRu && <img alt='check' src={check_lang}/>}
                </div>
                
                <div onClick={() => setCurrentLang("en")} style={!isRu ? {background: 'var(--light-blue)'} : {}} className='choose_lang_row hover'>
                    <div className='flex'>
                        <img style={{height : '24px', width : '24px'}} alt='ru' src={flag_eng}/>
                        <div className='choose_lang_row_text'>English</div>
                    </div>
                    {!isRu && <img alt='check' src={check_lang}/>}
                </div>
                
                <div style={{justifyContent : 'right', marginTop : '32px'}} className='flex'>
                    <div className="dialog_btns">
                        <p style={{color : 'var(--lk-blue)', textTransform : 'none'}} onClick={closeDialog} className="error_btn color_cancel_dialog hover">{strings.cancel}</p>
                    </div>
                    <div className="dialog_btns">
                        <p onClick={save} className="save_lang_dialog hover">{strings.saveRenamTag}</p>
                    </div>
                </div>
                
            </div>  
        </div>
    )
}
export default ChooseLanguageDialog;