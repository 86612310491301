import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import {DebounceInput} from 'react-debounce-input';
import { getAnimationStyle } from './utils_android/Utils';
import { getUrlParameter } from './utils_android/UrlHelperAndroid';

var strings = getLocalizedStrings()
class UniversalLimits extends React.Component {
    constructor(props){
        super(props)
        
        this.state = {
            isLimitDayChecked : false,
            limitDay : 100,
        }
        this.limitDayClicked = this.limitDayClicked.bind(this)
        this.limitDayChanged = this.limitDayChanged.bind(this)
    }
    
    
    limitDayClicked(event){
        if (event){
            var value = event.target.checked
            this.setState({isLimitDayChecked : value})
            this.props.limitsChanged(value, this.state.limitDay)
        }
    }
    
   
    limitDayChanged(event){
        if (event){
            var value = event.target.value
            this.setState({limitDay : value})
            this.props.limitsChanged(this.state.isLimitDayChecked, value)
        }
    }
   
   

    render() {
        return (
            <div className="block">
                <div style={this.state.isLimitDayChecked ? {marginBottom : '16px'} : {marginBottom : '0px'}} className="order_row">
                    <div className="switch_container switch_container_limit flex">
                        {/* <div onClick={this.limitDayClicked} style={this.state.isLimitDayChecked ? {border : "1px solid #F5DB31"} : {}} className="radio_limits hover">
                            {this.state.isLimitDayChecked && <div className="radio_inside"/>}
                        </div> */}
                        <label className="switch">
                            <input type="checkbox" onChange={this.limitDayClicked} checked={this.state.isLimitDayChecked} />
                            <div className="slider round"></div>
                        </label>
                        <p className="text-pre-subscr screen_title">{strings.limitsUniv}</p>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{strings.limitsUnivDesc}</p>
                    </div>
                </div>

                <div style={this.state.isLimitDayChecked ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, display : 'none'}} className="display_block height_animation">
                    <div className="order_row bot_16">
                        <div style={{display : 'block'}} className="universal_input display_block">{strings.limitsDay}
                                <DebounceInput autoComplete="off" placeholder={strings.limitsDayPlaceholder}
                                onChange={this.limitDayChanged} className="input_text_search top_8" value={this.state.limitDay}/>
                        </div>
                        <div className="input_description_container">
                            <p className="input_description_text">{strings.limitsDayUnivDesc}</p>
                        </div>
                    </div>
                </div>

            </div>
           
        );
    }
}
export default UniversalLimits;