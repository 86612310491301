import moment from 'moment'
import 'moment/min/locales'
export const getReviewDate = (timestamp) => {
    moment.locale('ru');
    return moment(timestamp).format('DD MMMM YYYY')
}
export const formateDate = (range) => {
    var startDate = moment.unix(range.startDate.getTime() / 1000).format("DD-MM-YYYY")
    var endDate = moment.unix(range.endDate.getTime() / 1000).format("DD-MM-YYYY")
    return startDate + " - " + endDate
}