/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import '../../../css_android/bot_orders.css'
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';
import { declension } from '../../custom_views/utils_android/Utils';
import ProgressLine from '../../custom_views/ProgressLine';
import OrderMoreTooltip from '../../custom_views/tooltips/OrderMoreTooltip';
import { getPauseBorderStyle } from '../../custom_views/utils_android/StyleHelper';
import { BotOrderStates, getBotAppIcon, getBotAppStatusText, getBotOrderStartDate, getIsOrderCompleted } from '../utils/BotUtils';
import info from '../../custom_views/custom_icons/info.svg';
import more from '../../custom_views/custom_icons/more.svg';
import more_pressed from '../../custom_views/custom_icons/more_pressed.svg';
import btn_refresh from '../../custom_views/custom_icons/btn_refresh.svg';
import search_icon from '../../custom_views/custom_icons/search_icon.svg';
import progress_icon from '../../custom_views/custom_icons/progress.svg';
import { BotApi } from '../../../api/BotApi';
import moment from 'moment'
import Loader from '../../custom_views/analyze_review/customViews/Loader';
import { AppsType, getAppIconType, getAppUrl, getSearchRequestAppUrl } from '../../custom_views/analyze_review/utils/Utils';

var strings = getLocalizedStrings()
var borderPauseStyle = getPauseBorderStyle()

function BotOrderItem(props) {
    const [isShowDeleteDialog, setShowDeleteDialog] = useState(false)
    const [isShowMore, setShowMore] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const order = props.order
    useEffect(() => {
       
    }, [])
    
    // const openRequestUrl = () => {
    //     var link = order.keywords == undefined ? (order.type_os == 'ios' ?  'https://itunes.apple.com/ru/app/id' + order.appstore_id : 'https://play.google.com/store/apps/details?id=' + order.bundle ) : order.type_os == 'ios' ? 'https://search.itunes.apple.com/WebObjects/MZSearch.woa/wa/search?media=software&term=' + order.keywords : 'https://play.google.com/store/search?q=' + order.keywords + '&c=apps'
    //     if (order.type_os != 'android' && window.innerWidth >= 769) {
    //         link = 'https://itunes.apple.com/ru/app/id' + order.appstore_id;
    //     }
    //     window.open(link, '_blank');
    // }
    const openRequestUrl = () => {
        const withoutSearchRequestLink = getAppUrl(order.type_os, order.type_os == AppsType.ios ? order.appstore_id : order.bundle)
        const searchRequestLink = getSearchRequestAppUrl(order.type_os, order.type_os == AppsType.ios ? order.appstore_id : order.bundle, order.search_request)
        const link = order.search_request == undefined ? withoutSearchRequestLink : searchRequestLink
        // if (order.type_os != 'android' && window.innerWidth >= 769) {
        //     link = 'https://itunes.apple.com/ru/app/id' + order.appstore_id;
        //   }
        window.open(link, '_blank');
    }
   const openStoreUrl = () => {
        // var link = order.type_os == 'android' ? 'https://play.google.com/store/apps/details?id=' + order.bundle : 'https://itunes.apple.com/ru/app/id' + order.appstore_id
        const link = getAppUrl(order.type_os, order.type_os == AppsType.ios ? order.appstore_id : order.bundle)
        window.open(link, '_blank')
    }
    const cancelOrder = () => {
        BotApi.cancelOrder(order.external_id, (responseData) => {

        }, () => {})
        props.cancelOrder(order)
    }
    const getOrderDetails = () => {
        setLoading(true)
        BotApi.getOrderDetails(order.external_id, (responseData) => {
            const mOrder = responseData.order
            // mOrder.count_complete_installs = 50
            // mOrder.state = BotOrderStates.completed
            props.updateOrders(mOrder)
            setLoading(false)
        }, () => {
            setLoading(false)
        })
    }
    const isOrderCompleted = getIsOrderCompleted(order)
    const orderUrl = "/installs_order_info?id=" + order.external_id
    return (
        <div style={(order.state == BotOrderStates.canceled || order.state == BotOrderStates.waiting) ? borderPauseStyle : {}} className="order_container">
                <div className="padding_12">
                    <div className="top_order">
                        <a className="order_icon hover" rel="noopener noreferrer" target='_blank' href={orderUrl}><img alt='' className="order_icon hover" src={info}/></a>
                        <div onClick={openStoreUrl} className="order_icon_container">
                            <img alt='' className="order_app_icon" src={order.app_icon}/>
                            <img alt='' className="order_type_icon" src={getAppIconType(order.type_os, true)}/>
                        </div>
                        <div className="relative">
                            <img alt='' onClick={() => setShowMore(!isShowMore)} className="order_icon hover" src={isShowMore ? more_pressed : more}/>
                            {isShowMore && <OrderMoreTooltip fromBots showIdfa={() => {}} order={order} toogleMore={() => setShowMore(!isShowMore)} deleteOrder={cancelOrder}/>}
                        </div>
                        
                    </div>
                    <a className="order_name hover" rel="noopener noreferrer" target='_blank' href={orderUrl}><p className="order_name hover">{order.app_name ? order.app_name : strings.appBlocked}</p></a>
                    <p className="date_text_order">{getBotOrderStartDate(order)}</p>
                    <div onClick={openRequestUrl} className="center_req hover">
                        <img alt='' className="search_icon" src={search_icon}></img>
                        <p className="order_search_text">{order.keywords == undefined ? strings.withoutReq : order.keywords}</p>
                    </div>

                    <div className="center_icons">
                     
                        {order.enable_progress_distribution && <img alt='' style={{display : 'flex'}} className="tasks_icon" src={progress_icon}></img>}
                        {order.enable_progress_distribution ? 
                            <p className="order_search_text top_12">{strings.progressProp}</p> 
                        : 
                        order.day_limit ? 
                            <p className="order_search_text top_12">{strings.maxInstallsDay} {order.day_limit} {strings.onDay}</p>
                        :
                            <p className="order_search_text top_12">{strings.noOptions}</p> 
                        }

                    </div>
                </div>
                
                <div className="control_container">
                    <div className="controlls">
                        <p className="status_text_control">{strings.status}</p>
                        <div className='flex controlls_center'>
                            <img alt='' style={{pointerEvents : 'none'}} className="controlls_bot_icon" src={getBotAppIcon(order)} />
                            <p className="status_text">{getBotAppStatusText(order)}</p>
                        </div>
                        <div className='controlls_bot_update'>
                            <div className='controlls_bot_updat_text'>{order.modified != undefined ? moment(order.modified).utc().local().format("DD.MM.YYYY") : moment(order.createDate).utc().local().format("DD.MM.YYYY")}</div>
                            <div style={{height : '32px', width : '32px'}} className='relative'>
                                {isLoading ? 
                                    <Loader style={{left : 'auto', right : '8px', bottom : '12px', top : 'auto', height : '32px', width : '32px'}}/>
                                : 
                                    <img onClick={getOrderDetails} alt='' className='hover' src={btn_refresh}/>
                                }
                            </div>
                        </div>
                        
                    </div>
                 
                </div>
                
                <ProgressLine isOrderCompleted={isOrderCompleted} order={order} count_installed={order.count_complete_installs} max_count_installs={order.count_installs}/>
                <div style={{justifyContent : 'center'}} className="install_container">
                        <div className="flex">
                             {/* <p className="installs">{order.count_complete_installs ? order.count_complete_installs : 0}</p>
                             <p className="installs_desc">{declension(order.count_complete_installs, strings.installs_1, strings.installs_2, strings.installs_5)}</p> */}
                        </div>
                        <p className="installs_desc">{strings.orderedCount + " " + order.count_installs + " " + declension(order.count_installs, strings.installs_1, strings.installs_2, strings.installs_5)}</p>

                </div>
              {/* {isShowDeleteDialog && <DeleteOrderDialog onCanceledDelete={() => setShowDeleteDialog(false)} onDelete={onDelete}/>}  */}
              
        </div>
            
    )
}

export default BotOrderItem
