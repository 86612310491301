import React from 'react';
import x_close from '../custom_icons/mdi_close.svg'
import pic_email from '../custom_icons/pic_email.svg'
import './dialogs.css'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class SuccessRegister extends React.Component {
    constructor(props){
        super(props)

    }
   
    render(){
      
            return (
                <div className="error_bg">
                    <div className="error_banner_container">
                    <div className="pic_email_container">
                         <img className="pic_email" src={pic_email} alt=""/>
                    </div>
                    <img className="dialog-close" onClick={this.props.onCanceled} src={x_close} alt=""/>   
                        <p className="send_to_mail">{this.props.title}</p>  
                    </div>  
                </div>
            ); 
        
        
    }
}
export default SuccessRegister;