import React from 'react';
import x_close from '../custom_icons/mdi_close.svg'
import close_delete from '../custom_icons/close_delete.svg'
import pic_email from '../custom_icons/pic_email.svg'
import './dialogs.css'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class DeleteOrderDialog extends React.Component {
    constructor(props){
        super(props)

    }
   
    render(){
      
            return (
                <div className="error_bg">
                    <div style={{width : '340px', padding : '24px'}} className="error_banner_container">
                        <img style={{right : '22px'}} className="dialog-close" onClick={this.props.onCanceledDelete} src={close_delete} alt=""/>   
                        <p className="delete_order_title">{strings.deleteOrderTitle}</p>  
                        <p className="delete_order_text">{strings.deleteOrder}</p>  
                        <div className="dialog_btns">
                            <p onClick={this.props.onCanceledDelete} className="error_btn color_cancel">{strings.cancel}</p>
                            <p onClick={this.props.onDelete} className="btn_red">{strings.deleteOrderBtn}</p>
                        </div>
                    </div>  
                </div>
            ); 
        
        
    }
}
export default DeleteOrderDialog;