import React from 'react';
import  burger  from './icons_android/menu-burger.svg';
import  user  from './icons_android/user.svg';
import { getName, getEmail, setToken, getCookie } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from '../pages_android/custom_views/utils_android/LocalizedStrings';
import ExchangeTooltip from '../pages_android/custom_views/analyze_review/customViews/tooltips/ExchangeTooltip';
import { Link } from "react-router-dom";
import ChooseLanguageDialog from './dialogs/ChooseLanguageDialog';
import flag_eng from '../pages_android/custom_views/custom_icons/flag_eng.png'
import flag_ru from '../pages_android/custom_views/custom_icons/flag_ru.png'

var strings = getLocalizedStrings()

class NavbarAndroid extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            name : getName(),
            email : getEmail(),
            isLogoutShown : false,
            isShowExchangeTooltip : false,
            isShowLangDialog : false
        }
    }
    sideButtonClicked = function(){
        var overlay = document.createElement('button');

        // overlay.setAttribute('title', 'Закрыть меню');
        overlay.setAttribute('id', 'closemenu');
        overlay.className = 'overlay';
        document.querySelector('.app').appendChild(overlay);
        document.querySelector('.aside').style.left = '0';
        overlay.addEventListener('click', function (e) {

            document.querySelector('.aside').removeAttribute('style');
            document.querySelector('.app').removeChild(overlay);
    
        });
    }
   
    toggleDropdownMobile = () => {
        this.setState({isLogoutShown : !this.state.isLogoutShown})
    }
    logoutClicked = () => {
        setToken("")
        window.location.href = '/auth'
    }
    toggleExchangeTooltip = () => {
        this.setState({isShowExchangeTooltip : !this.state.isShowExchangeTooltip})
    }
    showSettings = () => {
        this.toggleDropdownMobile()
        window.open("/settings", '_blank')
    }
    render() {
        const imgFlag = (getCookie("picked_lang") && getCookie("picked_lang").includes('ru')) ? flag_ru : flag_eng
        return (
            <header className="header navbar_just">
                <div className='flex'>
                    <img onClick={this.sideButtonClicked} src={burger} className="header__mobile-menu" alt=""/>
                    
                    <div className="navbar_left_text">
                        <div className="flex">
                            <p className="header-top-text flex">{strings.navbarEnabled}</p>
                            <p className="bold_money"> {parseFloat(this.props.count_money_r).toFixed(2)}₽</p>
                            <ExchangeTooltip money={this.props.count_money_r} exchangeRate={this.props.exchangeRate}/>
                            
                        </div>
                        <Link className="nav-bot-row hover" to="/pay">{strings.navbarPay}</Link>
                        

                    </div>
                </div>
                <div className="navbar_right_text">
                    <div onClick={this.toggleDropdownMobile} className="row name_email hover">
                        <p className="header-top-text header_bold">{this.props.first_name == "_" ? "" : this.props.first_name}</p>
                        <p>{this.props.email}</p>
                    </div>
                    <div className='flex'>
                        <div className="row img_div_nav">
                            <img alt='' onClick={this.toggleDropdownMobile}  className="navbar_icon_right" align="center" src={user}/>
                        </div>
                        <img onClick={() => this.setState({isShowLangDialog : true})} src={imgFlag} className='menu_lang_flag_mobile hover' alt='flag'/>

                    </div>
                    
                    <div className={this.state.isLogoutShown ? "right_popup" : "not_display"}>
                        <div className="right_popup_container">
                            <p className="right_popup_bold">{this.props.first_name}</p>
                            <p className="right_popup_text">{this.props.email}</p>
                        </div>
                        <p onClick={this.showSettings} className="right_popup_exit">{strings.settings}</p>
                        <p onClick={this.logoutClicked} className="right_popup_exit">{strings.navbarExit}</p>
                    </div>

                </div>
                
               
                <ChooseLanguageDialog isShow={this.state.isShowLangDialog} setShow={() => this.setState({isShowLangDialog : false})}/>
            </header>
           
        );
    }
}
export default NavbarAndroid;