import React from 'react';
import TagListRedactor from './tooltips/TagListRedactor';
import DeleteTagDialog from './tooltips/DeleteTagDialog';
import RenameCategory from './tooltips/RenameCategory';
import { getHttpParams, getRequestUrl } from '../utils/Utils';
import { getRequestParams, getEmail } from '../../utils_android/CookieHelper';

class TagListRefactorController extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowDelete : false,
            isShowRename : false,
            category : {},
            index : -1
        }
    }
    deleteCategory = (category, index) => {
        this.setState({isShowDelete : true, category : category, index : index})
    }
    renameCategory = (category, index) => {
        this.setState({isShowRename : true, category : category, index : index})
    }
    closeDeletion = () => {
        this.setState({isShowDelete : false, category : {}, index : -1})
    }
    onDeleteConfirmed = () => {
        this.setState({isShowDelete : false})
        this.props.onDeleteConfirmed(this.state.category, this.state.index)
        var params = this.getParams()
        params._id = this.state.category._id
        fetch(getRequestUrl() + "removeConfigReviewsByID", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("onDeleteConfirmed = " + JSON.stringify(responseData))
        })
        
    }
    getParams = () => {
        var params = getRequestParams()
        params.bundle = this.props.bundle
        params.owner_email = getEmail()
        return params
        
    }
    renameCategoryClicked = (newName) => {
        var category = this.state.category
        category.tag_name = newName
        this.setState({isShowRename : false})
        this.props.onRenameConfirmed(category, this.state.index)
        var params = this.getParams()
        params._id = this.state.category._id
        params.tag_name = newName
        console.log("setNameConfigReviewsByID params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "setNameConfigReviewsByID", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("onRenameConfirmed = " + JSON.stringify(responseData))
        })
    }
    closeRename = () => {
        this.setState({isShowRename : false})
    }
    render() {
        return (
            <div>
                {(!this.state.isShowDelete && !this.state.isShowRename && this.props.categories.length > 0) && <TagListRedactor toogleRefactorTags={this.props.toogleRefactorTags} deleteCategory={this.deleteCategory} renameCategory={this.renameCategory} categories={this.props.categories}/>}
                {this.state.isShowDelete && <DeleteTagDialog onDeleteConfirmed={this.onDeleteConfirmed} closeDeletion={this.closeDeletion} tag={"asd"}/>}
                {this.state.isShowRename && <RenameCategory renameCategory={this.renameCategoryClicked} tagName={this.state.category.tag_name} closeRename={this.closeRename} categories={this.props.categories}/>}
            </div>
        );
    }
}
export default TagListRefactorController;