/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import '../../css_android/bot_orders.css'
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import { BotApi } from '../../api/BotApi';
import { getRequestParams } from '../custom_views/utils_android/CookieHelper';
import Toast from '../custom_views/utils_android/Toast';
import { getUrlParameter } from '../custom_views/utils_android/UrlHelperAndroid';
import BotOrderInfoHeader from './custom_views/BotOrderInfoHeader';
import Loader from '../custom_views/utils_android/LoaderAndroid';
import trash_red from '../custom_views/custom_icons/trash_red.svg';
import copy from '../custom_views/custom_icons/copy.svg';
import progress_icon from '../custom_views/custom_icons/progress.svg';
import OrderPositionItem from '../list_items/OrderPositionItem';
import { declension, getSearchWords, openDuplicateOrder, openDuplicateOrderThisWindow } from '../custom_views/utils_android/Utils';
import CircleProgress from '../custom_views/CircleProgress';
import ExchangeTooltip from '../custom_views/analyze_review/customViews/tooltips/ExchangeTooltip';
import { BotOrderStates, getIsOrderCompleted } from './utils/BotUtils';
import OrderInfoStats from '../custom_views/OrderInfo/OrderInfoStats';

var strings = getLocalizedStrings()

function BotOrderInfo(props) {
    const [isLoading, setLoading] = useState(true)
    const [order, setOrder] = useState({})
    const [searchWords, setSearchWords] = useState([])
    const getOrderDetails = () => {
        const id = getUrlParameter("id")
        BotApi.getOrderDetails(id, (responseData) => {
            const mOrder = responseData.order
            const searchWords = getSearchWords(mOrder.keywords, mOrder.search_position) 
            setSearchWords(searchWords)
            setOrder(mOrder)
            setLoading(false)
        }, () => {
            setLoading(false)
        })
    }
    useEffect(() => {
        getOrderDetails()
    }, [])
    const searchAppPosition = (position, index) => {
        var changedArray = [...searchWords]
        changedArray[index].currentPosition = position
        if (changedArray[index].positionBefore == undefined){
            changedArray[index].positionBefore = position
        }
        setSearchWords(changedArray)
    }
     const cancelOrder = () => {
        BotApi.cancelOrder(order.external_id, (responseData) => {

        }, () => {})
        var mOrder = {...order}
        mOrder.state = BotOrderStates.waiting
        setOrder(mOrder)
    }
    const duplicateOrder = () => {
        openDuplicateOrder(order, true)
    }
    
    if (isLoading || order.external_id == undefined){
        return(
            <div className="main_container order_main">
                <Loader/>
            </div>
        )
    }
    const selectedCountries = order.countries_long ? JSON.parse(order.countries_long) : []
    const countries = order.countries ? JSON.parse(order.countries) : []
    var country = undefined
    if (countries.length > 0){
        country = countries[0]
    }
    return (
        <div className="main_container order_main">
            <BotOrderInfoHeader order={order}/>
            <div className="info_container">
                        {/* 1 column */}
                        <div className={searchWords.length > 0 ? "search_words_container" : 'not_display'}>
                            <div className="search_words_title_container">
                                <p className="search_words_title">{strings.searchRequest}</p>
                                <div className="flex">
                                    <p className={(searchWords.length == 1 || (order.search_position != undefined && order.search_position.includes(","))) ? "search_words_title" : "not_display"}>{strings.positionBefore}</p>
                                    <p className="search_words_title after_pos">{(searchWords.length == 1 || (order.search_position != undefined && order.search_position.includes(","))) ? strings.positionAfter : strings.positionNumber}</p>
                                </div>
                            </div>

                           


                            {searchWords.length > 0 && searchWords.map((item, index) => (
                                <OrderPositionItem country={country} searchAppPosition={searchAppPosition} bundle={order.bundle} type_os={order.type_os} index={index} searchObject={item} key={index} />
                            ))}
                            
                        </div>
                        {/* 2 column */}
                        <div className="info_props_container">
                            <p className="grey_text bot_8">{strings.wrapOptions}</p>
                            <div className="flex">
                                {order.enable_progress_distribution && <img alt='' style={{display : 'flex'}} className="tasks_icon" src={progress_icon}></img>}
                                {order.enable_progress_distribution ? 
                                    <p className="order_search_text top_12">{strings.progressProp}</p> 
                                : 
                                order.day_limit ? 
                                    <p className="order_search_text top_12">{strings.maxInstallsDay} {order.day_limit + " " + declension(order.day_limit, strings.installs_1, strings.installs_2, strings.installs_5)} {strings.onDay}</p>
                                :
                                    <p className="order_search_text top_12">{strings.noOptions}</p>
                                }
                            </div>
                            {selectedCountries.length > 0 && 
                                <div>
                                    <p style={{margin : '32px 0 0 0'}} className="grey_text bot_8">{strings.pickedCountriesInfo}</p>
                                    <div className='countries_picked_contaner'>
                                        {selectedCountries.length > 0 && selectedCountries.map((tag, index) => (
                                            <div key={index}>
                                                <div className="country_tag">
                                                    <div style={{margin : 0}}>{tag}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                        {/* 3 column */}

                        <div className="info_installs">
                            <p className="grey_text center_text">{strings.installsProp}</p>
                            <CircleProgress order={order} state={order.state} count_installed={order.count_complete_installs} max_count_installs={order.count_installs} percentage={(order.count_complete_installs / order.count_installs) * 100 >= 100 ? 100 : (order.count_complete_installs / order.count_installs) * 100}/>
                            <div className="line_info"/>
                            <p className="grey_text center_text">{strings.budgetProp}</p>
                            <div className="ref_exchange_container center_hor">
                                <p className="budget_text">{order.count_money_r}₽</p>
                                <ExchangeTooltip style={{marginTop : '22px'}} money={order.count_money_r} exchangeRate={props.exchangeRate}/>
                            </div>
                            
                            <div className="line_info_budget"/>
                           
                            <div onClick={duplicateOrder} className="up_balance_info top_16 hover">
                                <img alt='' src={copy}></img>
                                <p className="up_balance_info_text">{strings.duplicateOrder}</p>
                            </div>

                            {/* {(!getIsOrderCompleted(order) && order.state != BotOrderStates.canceled && order.state != BotOrderStates.waiting) && <div onClick={cancelOrder} className="up_balance_info top_16 hover">
                                <img alt='' src={trash_red}></img>
                                <p className="up_balance_info_text red_text">{strings.cancelOrderCap}</p>
                            </div>} */}
                        </div>
                        
                    </div>
                <div style={{marginTop : '24px'}}>
                    {order.external_id != undefined && <OrderInfoStats isBots isUniversal={false} order={order}/>}
                </div>
        </div> 
    )
}

export default BotOrderInfo
