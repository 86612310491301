import React from 'react';
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import ios_icon from '../custom_views/custom_icons/smile.svg'
import { DebounceInput } from 'react-debounce-input';
import ContentEditable from 'react-contenteditable';
import trash_red from '../custom_views/custom_icons/trash_red.svg'
var strings = getLocalizedStrings()
class SdkInfoItem extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            isError : false
        }
        this.onTaskNameChanged = this.onTaskNameChanged.bind(this)
        this.onTaskPriceChanged = this.onTaskPriceChanged.bind(this)
        this.onTaskDescrChanged = this.onTaskDescrChanged.bind(this)
        this.deleteTask = this.deleteTask.bind(this)
    }
    onTaskNameChanged(event){
        if (event){
            var value = event.target.value
            var task = this.props.task
            task.name = value
            this.props.sdkTasksChanged()
        }
    }
    onTaskPriceChanged(event){
        if (event){
            var value = event.target.value
            var task = this.props.task
            task.price = value
            this.props.onTaskPriceChanged()
            if (value != undefined && value != "" && value >= 2){
                this.setState({isError : false})
            } else {
                this.setState({isError : true})
            }
        } 
    }
    onTaskDescrChanged(event){
        if (event){
            var value = event.target.value
            var task = this.props.task
            task.description = value
            this.props.sdkTasksChanged()
        }
    }
    deleteTask(){
        this.props.deleteTask(this.props.index)
    }
    
    render() {
        return (
            <div>
                <div className="flex">
                    <div className="sdk_number_container">
                        <p className="sdk_center sdk_number_text">{this.props.index + 1}</p>
                    </div>
                    <div className="full_width">
                        <div className="order_row full_width">
                            <div style={!this.state.isError ? {} : {border: '1px solid #FF6262'}} className="input_container_border">
                                <div className="full_width">
                                    {strings.sdkTaskName} {this.props.index + 1}
                                    <DebounceInput autoComplete="off" onChange={this.onTaskNameChanged} className="input_text_search full_width top_6_sdk" placeholder={strings.sdkTaskNamePlaceholder} value={this.props.task.name}/>
                                </div>
                                <div className="vertical_line"></div>
                                <div>
                                    {strings.sdkTaskPrice}
                                    <DebounceInput autoComplete="off" onChange={this.onTaskPriceChanged} className="input_text_search input_price_width top_6_sdk" placeholder={strings.sdkTaskNamePlaceholder} value={this.props.task.price}/>
                                </div>
                            </div>
                            <div className="input_description_container">
                                <p className="input_description_text">{strings.sdkTaskNameDescr}</p>
                            </div>
                        </div>
                        <div className="order_row full_width top_12_sdk">
                            <div className="input_container_border sdk_descr_height">
                                <div className="full_width">
                                    {strings.sdkTaskDescription}
                                    <ContentEditable spellCheck="false" autoComplete="off" type="text" onChange={this.onTaskDescrChanged} className="input_text_search full_width top_6_sdk sdk_decr_input" placeholder={strings.sdkTaskDescriptionPlaceholder} html={this.props.task.description}/>
                                    {/* <DebounceInput autoComplete="off" onChange={this.onTaskNameChanged} className="input_text_search full_width top_6_sdk" placeholder={strings.sdkTaskDescriptionPlaceholder} value={this.props.task.description}/> */}
                                </div>
                            </div>
                            <div className="input_description_container">
                                <p className="input_description_text sdk_description_d">{strings.sdkTaskDescription2}</p>
                                
                                <div style={this.props.index == 0 && this.props.length == 1 ? {display : 'none'} : {}} onClick={this.deleteTask} className="flex hover delete_sdk_task_cont">
                                    <img src={trash_red}/>
                                    <p className="delete_sdk_task">{strings.sdkDeleteTask}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="line-row"/>
            </div>
            
        );
    }
}
export default SdkInfoItem;