/* eslint-disable no-useless-constructor */
/* eslint-disable eqeqeq */
import React from 'react';

class ChartPart extends React.Component {
    constructor(props){
        super(props)
       
    }
    
    render() {
        return (
            <div className="display_block chart_width_1">
                <p className="text_progress_order">{this.props.day}</p>
                
                <div className="flex">
                    <div className="yellow_round"/>
                    {(this.props.isHideLine == undefined || !this.props.isHideLine) && <div className="yellow_line"/>}
                </div>
                <p className="text_progress_order progress_top">{this.props.installs}</p>     
                
            </div>
           
        );
    }
}
export default ChartPart;