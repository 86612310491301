import React from 'react';
import { getLocalizedStrings } from '../../../utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
var disabledBtn = {opacity : 0.2, pointerEvents : 'none'}
class RenameCategory extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            tagName : this.props.tagName,
            isShowError : false
        }
      
        this.tagNameChanged = this.tagNameChanged.bind(this);
        this.renameCategory = this.renameCategory.bind(this);
        this.cancel = this.cancel.bind(this);
    }
    tagNameChanged(event) {
        if (event){
            var text = event.target.value
            this.setState({tagName : text})
            if(this.state.isShowError){
                this.setState({isShowError : false})
            }
            
        }
    }
    renameCategory(){
        var isIncludes = false
        this.props.categories.forEach(element => {
            if (element.tag_name == this.state.tagName){
                isIncludes = true
            }
        });
        if (this.state.tagName != "" && this.state.tagName != " " && this.state.tagName != undefined && !isIncludes){
           this.props.renameCategory(this.state.tagName)
        } else {
            this.setState({isShowError : true})
        }
    }
    cancel(){
        this.props.closeRename()
    }
    render() {
        return (
            <div className="add_tag_bg">
               <div className="new_name_tag_container">
                    <p className="new_tag_name_title">{strings.renameTagTitle}</p>
                    <input className="input_new_tag_name" onChange={this.tagNameChanged} placeholder={strings.enterNewTagName} value={this.state.tagName}/>
                    <div style={(this.state.tagName == "" || this.state.tagName == undefined) ? disabledBtn : {}} onClick={this.renameCategory} className="create_new_tag_btn hover">{strings.saveRenamTag}</div>
                    <div onClick={this.cancel} className="cancel_new_tag_btn hover">{strings.cancelNewTagBtn}</div>
                    {this.state.isShowError && <div className="new_tag_name_error">{strings.newTagNameError}</div>}
               </div>
            </div>
        );
    }
}
export default RenameCategory;