import React from 'react';
import mass_answer from '../custom_icons/mass_answer.svg';
import accept_all from '../custom_icons/accept_all.svg';
import { getRequestUrl, getRequestParams } from '../utils_android/CookieHelper';
import { getHttpParams } from '../analyze_review/utils/Utils';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import InputLoader from '../utils_android/InputLoader';
import ContentEditable from 'react-contenteditable';
import Loader from '../analyze_review/customViews/Loader';
var disabledStyle = {opacity : 0, pointerEvents : 'none'}
var transperentStyle = {opacity : 0.2, pointerEvents : 'none'}

var strings = getLocalizedStrings()
class ProofsToolbar extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            text : ""
        } 
        this.textChanged = this.textChanged.bind(this)

    }
    approveAllProofsOrderYoutube = () => {
        this.props.toggleLoader()
        var params = getRequestParams()
        params._id = this.props.order._id
        fetch(getRequestUrl() + "approveAllProofsOrderYoutube", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            this.props.getOrdersYoutubeByID()
        })
    }
    
    approveOrderYotube = (state) => {
        if (this.props.isLoading){
            return
        }
        var params = getRequestParams()
        const proofsSelected = this.props.filteredOrderProofs.filter(proof => proof.isSelected)
        if (proofsSelected.length == 0){
            return
        }
        var ids = ""
        proofsSelected.forEach(proof => {
            if (ids == ""){
                ids = proof._id
            } else {
                ids = ids + "," + proof._id
            }
        })
        params._id = ids
        if (state == 2 || state == -1){
            params.correction = this.state.text
            params.state = state
        } else {
            params.state = -1
            params.correction = strings.badProofAnswerText
        }
        this.props.toggleLoader()
        console.log("approveOrderYotube params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "approveOrderYotube", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            this.props.toggleMassAnswer()
            this.props.getOrdersYoutubeByID()
            console.log("approveOrderYotube response = " + JSON.stringify(responseData))
        })
    }
    
    textChanged(event){
        if (event){
            var value = event.target.value
            this.setState({text : value})
        }
    }
    render() {
        const proofsSelected = this.props.filteredOrderProofs.filter(proof => proof.isSelected)
        return (
            <div>
                {!this.props.isShowMassAnswer ? <div style={(this.props.filteredOrderProofs.length > 0 && this.props.selectedProofFilter == strings.proofFilterNew) ? {} : disabledStyle} className="mass_answe_btns_container">
                    <div onClick={this.approveAllProofsOrderYoutube} style={this.props.isLoading ? {pointerEvents : 'none'} : (this.props.filteredOrderProofs.length > 0 && this.props.selectedProofFilter == strings.proofFilterNew) ? {} : disabledStyle} className="accept_all_container hover">
                        <img src={accept_all}/>
                        <p className="accept_text">{strings.acceptAllText}</p>
                        {this.props.isLoading && <InputLoader style={{top : "6px"}}/>}
                    </div>

                    <div onClick={this.props.toggleMassAnswer} style={(this.props.filteredOrderProofs.length > 0 && this.props.selectedProofFilter == strings.proofFilterNew) ? {} : disabledStyle} className="accept_all_container hover mass_answer_btn">
                        <img src={mass_answer}/>
                        <p className="accept_text">{strings.massAnswer}</p>
                    </div>
                </div> : <div className="mass_answe_btns_container mass_answe_btns_container_toggled">
                    <p className="mass_answe_decline_title">{strings.pickProofsToDecline}</p>
                    <p onClick={this.props.toggleMassAnswer} style={{marginTop : '3px'}} className="send_to_do hover">{strings.cancel}</p>
                </div>}
                <div style={this.props.isShowMassAnswer ? {maxHeight : '1000px'} : {}} className="mass_answer_container">
                    <p className="proofs_mass_selected">{strings.pickProofsSelected} {proofsSelected.length}</p>
                    <div className="proofs_mass_selected_line"/>
                    
                    <div className="mass_answer_margin">
                        <p>{strings.sayEmployerWhatFix}</p>
                        <ContentEditable contentEditable="true" placeholder={strings.proofPlaceholder} onChange={this.textChanged} className="fix_employer" html={this.state.text}/>
                        <div className="mobile_flex">
                            <div style={this.state.text == "" ? transperentStyle : {}} onClick={() => this.approveOrderYotube(2)} className="accept_btn hover">
                                <p className="accept_btn_text">{strings.sendToDo}</p>
                            </div>
                            <div style={this.state.text == "" ? transperentStyle : {}} onClick={() => this.approveOrderYotube(-1)} className="accept_btn hover rejection_bg">
                                <p className="accept_btn_text reject_left">{strings.reject}</p>
                            </div>
                            <p style={proofsSelected.length == 0 ? transperentStyle : {}} onClick={() => this.approveOrderYotube(-2)} className="reject mass_reject_bad hover">{strings.badProofAnswer}</p>
                            <p onClick={this.props.toggleMassAnswer} className="send_to_do hover">{strings.cancel}</p>
                            {this.props.isLoading && <InputLoader style={{marginTop : '9px', marginLeft : '16px'}}/>}
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
}
export default ProofsToolbar;