import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import arrow_keyword from '../custom_views/custom_icons/arrow_keywords.svg'
var disableBtn = {
    opacity: '0.2',
    pointerEvents: 'none'
  }
var enableBtn = {
    opacity: '1',
    pointerEvents: 'auto'
  }
 var openedKeywordsTaksStyle = {
    maxHeight: '458px',
    display : 'flex',
    visibility : 'visible'
  }
  var closedKeywordsTaksStyle = {
    maxHeight: '44px',
    display : 'flex',
    visibility : 'visible'
  }
  var arrowKeywordsStyle = {
    transform: 'rotate(180deg)'
  }
  var arrowRotateKeywordsStyle = {
    transform: 'rotate(0deg)'
  }
var strings = getLocalizedStrings()
class AsoCreateTask extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isOpened : false
        }
       
    }
    createNewOrderKeys = () => {
        this.props.createOrder()
    }
    openPickedKeywords = () => {
        this.setState({isOpened : !this.state.isOpened})
    }
    
    render() {
        return (
            <div className="keyword_task_container" style={this.state.isOpened ? openedKeywordsTaksStyle : closedKeywordsTaksStyle}>
                <div className="keywords_container_landscape">
                    <button style={this.props.keywordsTask.length == 0 ? disableBtn : enableBtn} onClick={this.createNewOrderKeys} type="button" className="keywords_top_btn">{strings.upPositions}</button>
                    <div className="keywords_task">
                        <div className="display_block">
                            <p ng-style="pickedTextStyle" className="keywords_picked bot_0">{strings.pickedRequests} {this.props.keywordsTask.length}</p>
                            <p id="pickedKeywords" ng-style="keywordsTextStyle" className="keywords_picked keyword_picked_bot">{this.props.checkedKeywordsString}</p>
                        </div>
                        <div onClick={this.openPickedKeywords} className="arrow_keywords_container">
                            <img style={this.state.isOpened ? arrowRotateKeywordsStyle : arrowKeywordsStyle} className="arrow_keywords" src={arrow_keyword} alt=""/>
                        </div>
                    </div>
                </div>

                <div className="keywords_container_mobile">
                    <div className="keywords_mobile">
                        <div className='flex'>
                        <button style={this.props.keywordsTask.length == 0 ? disableBtn : enableBtn} onClick={this.createNewOrderKeys} type="button"
                            className="keywords_top_btn">{strings.upPositions}</button>
                        <p className="keywords_picked picked_keywords">{strings.pickedRequests}<br/>{this.props.keywordsTask.length}</p>
                        </div>
                        
                        <div onClick={this.openPickedKeywords} className="arrow_keywords_container">
                            <img style={this.state.isOpened ? arrowRotateKeywordsStyle : arrowKeywordsStyle} className="arrow_keywords" src={arrow_keyword} alt=""/>
                        </div>
                    </div>
                    <p ng-style="keywordsTextStyle" className="keywords_picked keyword_picked_bot">{this.props.checkedKeywordsString}</p>
                </div>
            </div>
           
        );
    }
}
export default AsoCreateTask;
