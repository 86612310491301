/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import { DebounceInput } from 'react-debounce-input';
import { getRequestParams, getRequestUrl } from './utils_android/CookieHelper';
import qs from 'querystring'
import InputLoader from './utils_android/InputLoader';
import close from '../custom_views/custom_icons/close_tag.svg'
import close_x from '../custom_views/custom_icons/close_black.svg'
import close_red from '../custom_views/custom_icons/close_red.svg'
import add_tag from '../custom_views/custom_icons/add_tag.svg'
import help from '../custom_views/custom_icons/help.svg'
import not_found from '../custom_views/custom_icons/aso.svg'
import TagLoader from './TagLoader';
import AddTagsInfo from './empty_views/AddTagsInfo';
import SearchTagsInfo from './empty_views/SearchTagsInfo';
import { getAnimationStyle } from './utils_android/Utils';
import { AppsType, getHttpParams } from './analyze_review/utils/Utils';
import { AppsApi } from '../../api/AppsApi';
import { SiteSeoApi } from '../../api/SiteSeoApi';
import { SiteSeperator } from '../site_orders/utils/SiteUtils';
var strings = getLocalizedStrings()
var notFound = { background: '#FFFFFF', border: '1px solid #CB413E' }
var addTagStyle = { background: '#FFFFFF', border: '1px solid #0086C1' }
class SearchRequest extends React.Component {
    constructor(props) {
        super(props)
        var tags = []
        if (this.props.preOrderKeywords != undefined) {
            var tagsArray = this.props.preOrderKeywords.split(",")
            var positionsArray = this.props.preOrderPositions.split(",")
            for (let index = 0; index < tagsArray.length; index++) {
                const elementTag = tagsArray[index];
                const elementPosition = positionsArray[index];
                var tag = { tag: elementTag, position: ((elementPosition && elementPosition != "undefined") ? elementPosition : "") }
                tags.push(tag)
            }
        }
        console.log("tags = " + JSON.stringify(tags))
        this.state = {
            isChecked: (this.props.preOrderKeywords || this.props.fromSite ? true : false),
            searchText: "",
            isLoading: false,
            search_pos: -1,
            suggestions: [],
            tags: tags,
            isSuggestionLoading: false,
            isShowNotFound: false,
            isShowRightTooltip: false,
            isShowLeftTooltip: false
        }
        this.searchTextEvent = undefined
        this.installsWordSwitchClicked = this.installsWordSwitchClicked.bind(this);
        this.searchTextChanged = this.searchTextChanged.bind(this);

        if (this.props.preOrderKeywords) {
            this.updateTags(tags)
        }
    }
    componentDidUpdate(prevProps){
        if (this.props.isToggledSite != undefined && this.props.isToggledSite != prevProps.isToggledSite){
            this.setState({ tags: [] })
            this.updateTags([])
        }
    }
    installsWordSwitchClicked(event) {
        if (event) {
            var value = event.target.checked
            this.setState({ isChecked: value })
            this.props.searchAppPosition(value, this.state.search_pos, this.state.searchText)
        }
    }
    searchTextChanged = (event) => {
        if (event) {
            var text = event.target.value
            this.searchTextEvent = event
            this.setState({ searchText: text })
            // this.searchPosition(text)
            // this.getSuggestions(text)
        }
    }
    searchPosition = (text, index) => {
        console.log("searchPosition text = " + text)
        if (this.props.fromSite) {
            this.searchSite(text, index)
            return
        }
        if (this.props.type_os == AppsType.android || this.props.type_os == AppsType.rustore) {
            this.searchApp(text, index)
        } else if (this.props.type_os == AppsType.ios) {
            this.searchIosApp(text, index)
        }
    }
    searchApp = (text, index) => {
       
        if (this.props.bundle != "" && this.props.type_os != "") {
            var params = getRequestParams()
            params.bundle = this.props.bundle
            params.query = text
            params.country = "RU"
            params.type_os = this.props.type_os
            if (this.props.bots_countries && this.props.bots_countries.length > 0) {
                var selectedCountries = this.props.bots_countries.filter(c => c.is_selected)
                if (selectedCountries.length > 0) {
                    params.country = selectedCountries[0].cc
                }
            }
            // else {
            //     params.countries = JSON.stringify([{cc : "RU", name : "Russian Federation"}])
            // }
            try {
                console.log("searchApp params" + JSON.stringify(params))
                AppsApi.searchApp(params, (responseData) => {
                    this.setState({ isLoading: false, search_pos: responseData.info })
                    this.updateTagPosition(responseData.info, index)
                }, () => {
                    this.onError(index)
                })

            } catch (error) {
                this.onError(index)
            }
        }
    }
    searchSite = (text, index) => {
        if (this.props.url == undefined || this.props.url == ""){
            this.onError(index)
            return
        }
        var params = getRequestParams()
        params.search_platform = this.props.type
        params.domain = this.props.url
        params.query = text
        const cities = this.props.cities
        if (cities && cities.filter(c => c.is_selected).length > 0 && !cities[0].is_selected){
            const mCities = cities.filter(c => c.is_selected)
            params.city = mCities.map(x => x.name).join(",")
        }
        try {
            console.log("searchSite params" + JSON.stringify(params))
            SiteSeoApi.checkSiteInSearch(params, (responseData) => {
                if (this.props.updateMoney){
                    this.props.updateMoney(-0.3)
                }
                this.setState({ isLoading: false, search_pos: responseData.result })
                this.updateTagPosition(responseData.result, index, responseData.founded_url, responseData.founded_title)
            }, (responseData) => {
                this.onError(index)
                if (responseData.status && this.props.showToast){
                    this.props.showToast(responseData.status)
                }
            })

        } catch (error) {
            this.onError(index)
        }
    }
    onError = (index) => {
        this.setState({ isLoading: false, search_pos: 0 })
        this.updateTagPosition(0, index)
    }
    searchIosApp = (text, index) => {
        fetch("https://itunes.apple.com/search?limit=500&country=ru&entity=software&term=" + text, {
            method: 'GET'
        }).then((response) => response.json())
            .then((responseData) => {
                var info = responseData.results;
                var searchPosition = this.checkBundle(info);

                if (searchPosition == undefined) {
                    searchPosition = 0;
                }
                this.setState({ isLoading: false, search_pos: searchPosition })
                this.updateTagPosition(searchPosition, index)
                console.log("searchApp" + JSON.stringify(responseData))
            })
    }
    updateTagPosition = (position, index, founded_url, founded_title) => {
        var tags = this.state.tags
        if (index < tags.length) {
            var tag = tags[index]
            tag.position = position
            tags[index] = tag
            if (founded_url){
                tag.founded_url = founded_url
            }
            if (founded_url){
                tag.founded_title = founded_title
            }
            this.setState({ tags: tags })
            this.updateTags(tags)
        }
    }
    updateTags = (tags) => {
        const validTags = tags.filter(tag => {
            // Фильтруем теги с валидными позициями для приложений или сайтов
            return (this.props.type_os == AppsType.ios ||  this.props.type_os == AppsType.android || this.props.type_os == AppsType.rustore) || 
                   (tag.position !== 0 && tag.position !== -1);
        });
        console.log("validTags = ", validTags)
        if (validTags.length === 0) {
            this.props.searchAppPosition(this.state.isChecked, "", "", "", "");
            return;
        }

        const separator = this.props.fromSite ? SiteSeperator : ",";
        
        const result = validTags.reduce((acc, tag) => {
            acc.tags.push(tag.tag);
            acc.positions.push(tag.position);
            
            if (tag.founded_url) {
                acc.urls.push(tag.founded_url);
            }
            if (tag.founded_title) {
                acc.titles.push(tag.founded_title);
            }
            return acc;
        }, { tags: [], positions: [], urls: [], titles: [] });

        this.props.searchAppPosition(
            this.state.isChecked,
            result.positions.join(","),
            result.tags.join(separator),
            result.urls.join(SiteSeperator),
            result.titles.join(SiteSeperator)
        );
    }
    getSuggestions = (text) => {
        if (this.props.fromSite) {
            return
        }
        if (this.state.isSuggestionLoading || text == undefined || text == "") {
            return
        }


        this.setState({ isSuggestionLoading: true, suggestions: [], isShowNotFound: false })
        var params = getRequestParams()
        params.bundle = this.props.bundle
        params.query = text
        params.type_os = this.props.type_os
        if (!this.props.bots_countries) {
            params.countries = JSON.stringify(this.props.bots_countries)
        } else {
            params.countries = JSON.stringify([{ cc: "RU", name: "Russian Federation" }])
        }
        try {


            fetch(getRequestUrl() + "getSuggestionsWithPositions", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response == "1") {
                        var suggestions = []
                        for (var key in responseData.info) {
                            if (responseData.info.hasOwnProperty(key) && responseData.info[key] != 0 && key != text) {
                                var suggestion = { tag: key, position: responseData.info[key] }
                                suggestions.push(suggestion)
                            }

                        }
                        this.setState({ suggestions: suggestions })
                        if (suggestions.length == 0) {
                            this.setState({ isShowNotFound: true })
                        }
                    }
                    this.setState({ isSuggestionLoading: false })
                    console.log("getSuggestions" + JSON.stringify(responseData))
                })
        } catch (error) {
            this.setState({ isSuggestionLoading: false })
        }
    }
    checkBundle = (info) => {
        for (var i = 0; i < info.length; i++) {
            var item = info[i];

            if (item.bundleId == this.props.bundle) {
                return i + 1;
            }
        }
    }
    onSuggetionPicked = (filter, index) => {
        this.setState({ searchText: filter, suggestions: [], isLoading: true })
        this.searchPosition(filter)
    }
    onCloseFilters = () => {
        this.setState({ suggestions: [], isLoading: true })
    }
    addTags = event => {
        console.log("addTags key = " + event.key)
        if (event && event.key === "Enter" && event.target.value !== "") {
            var tag = event.target.value
            this.addTag(tag)
            this.getSuggestions(tag)

            event.target.value = "";
        }
    };
    addTag = (tag) => {
        var tags = this.state.tags
        tags.push({ tag: tag, position: -1 })
        this.setState({ tags: tags, searchText: "" })
        this.searchPosition(tag, (tags.length - 1))
    }
    deleteTag = (index) => {
        var tags = this.state.tags
        tags.splice(index, 1);
        this.setState({ tags: tags })
        this.updateTags(tags)
    }
    replaceTag = (index) => {
        var suggestions = this.state.suggestions
        var suggestion = suggestions[index]
        suggestions.splice(index, 1);
        var tags = this.state.tags
        tags.push(suggestion)
        this.setState({ suggestions: suggestions, tags: tags })
        this.updateTags(tags)
    }
    toogleRightTooltip = () => {
        this.setState({ isShowRightTooltip: !this.state.isShowRightTooltip })
    }
    toogleLeftTooltip = () => {
        this.setState({ isShowLeftTooltip: !this.state.isShowLeftTooltip })
    }
    onFocusLost = () => {
        var event = { key: "Enter", target: { value: this.state.searchText } }
        this.addTags(event)
        console.log("onFocusLost")
    }
    onPaste = (e) => {
        var text = e.clipboardData.getData('Text')
        if (text.includes("\n")) {
            var tags = text.split("\n")
            tags.forEach(tag => {
                this.addTag(tag)
            });
            setTimeout(this.delayedClear, 300);
        }
    }
    delayedClear = () => {
        if (this.searchTextEvent != undefined) {
            this.searchTextEvent.target.value = ""
            this.setState({ searchText: "" })
        }
    }
    render() {
        const hasZeroPositions = this.state.tags.filter(item => item.position == 0).length > 0
        return (
            <div className="block">
                <div style={this.props.fromSite ? {display : 'none'} :  this.state.isChecked ? { marginBottom: '30px' } : { marginBottom: '0px' }} className="order_row">
                    <div className="switch_container flex">
                        <label className="switch">
                            <input type="checkbox" onChange={this.installsWordSwitchClicked} checked={this.state.isChecked} />
                            <div className="slider round"></div>
                        </label>

                        <p className="text-pre-subscr">{strings.searchInstalls}</p>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{strings.searchInstallsDesc}</p>
                    </div>
                </div>

                <div style={this.state.isChecked ? getAnimationStyle() : { maxHeight: '0px', opacity: 0.0, pointerEvents: 'none' }} className="display_block height_animation">
                    {/* <p className={this.state.search_pos == -1 ? 'not_display' : 'bot_10'}>{this.state.search_pos == 0 ? "Приложение не найдено в выдаче по данному запросу" : "Текущая позиция по запросу: " + this.state.search_pos}</p> */}
                    <div className="order_row">
                        <div className="search_words">
                            <div className="flex">
                                <p className="installs_limits_title botm_12">{strings.searchWords}</p>
                                <div className="relative help_icon_height">
                                    <img alt='' onClick={this.toogleLeftTooltip} className="help_icon hover" src={help} />
                                    {this.state.isShowLeftTooltip && <SearchTagsInfo toogleLeftTooltip={this.toogleLeftTooltip} />}
                                </div>
                            </div>
                            <p className="botm_16">{strings.searchWordsPositions}</p>
                            <div className="input_container_border input_tag_height_1">
                                <div className="flex_input max_flex_input">
                                    {this.state.tags.map((item, index) => (
                                        <div className="tag_height_1" index={index} key={index}>
                                            <div style={item.position == -1 ? { background: 'rgba(150, 198, 240, 0.3)' } : item.position == 0 ? this.props.fromBots ? {} : notFound : {}} className="input_tag_container">
                                                <p style={item.position == -1 ? { color: '#2F3D48' } : item.position == 0 ? this.props.fromBots ? {} : { color: '#CB413E' } : {}} className="input_tag">{item.tag}</p>
                                                {item.position == -1 ? <TagLoader style={{ margin: '-19px 16px 0px -16px' }} /> : <b style={item.position == 0 ? { color: '#CB413E' } : {}} className="input_tag">{item.position == 300 ? ">50" : item.position}</b>}
                                                <img onClick={() => this.deleteTag(index)} className="input_tag_close hover" src={item.position == -1 ? close_x : item.position == 0 ? close_red : close} />
                                            </div>

                                        </div>
                                    ))}

                                    <DebounceInput style={{ marginTop: '-2px' }} autoComplete="off" onBlur={this.onFocusLost} onKeyUp={event => this.addTags(event)}
                                        onChange={this.searchTextChanged} className="input_text_search input_tag_width" onPaste={this.onPaste} placeholder={strings.searchRequestExample} value={this.state.searchText} />
                                    {this.state.isLoading ? <InputLoader style={{ top: "14px" }} /> : <div />}
                                </div>
                            </div>
                        </div>

                        {/* {this.state.suggestions && this.state.suggestions.length > 0 && <FilterDropdown onCloseFilters={this.onCloseFilters} style={{margin : '60px 0px 0px 0px', width : 'fit-content', maxWidth : '380px', minWidth : '280px'}} filters={this.state.suggestions} onFilterPicked={this.onSuggetionPicked}/>} */}

                        <div style={this.props.fromSite ? { display: 'none' } : {}} className="search_words search_words_left">
                            <div className="flex">
                                <p className="installs_limits_title botm_12">{strings.searchWordsRecommend}</p>
                                <div className="relative help_icon_height">
                                    <img onClick={this.toogleRightTooltip} className="help_icon hover" src={help} />
                                    {this.state.isShowRightTooltip && <AddTagsInfo toogleRightTooltip={this.toogleRightTooltip} />}
                                </div>

                            </div>
                            <p className="botm_16">{strings.pressToTeg}</p>
                            <div className="tags_right_container">

                                {this.state.suggestions.length == 0 ? this.state.isSuggestionLoading ?
                                    <div className="searching_requests">
                                        <TagLoader style={{ margin: '0px 80px 20px 56px' }} />
                                        <p className="input_right_placeholder">{strings.searchingRequests}</p>
                                    </div> :
                                    this.state.isShowNotFound ?
                                        <div className="searching_requests">
                                            <img className="not_found_icon" src={not_found} />
                                            <p className="input_right_placeholder">{strings.requestNotFound}</p>
                                        </div> : <p className="input_right_placeholder text_left">{strings.searchWordsPlaceholder}</p> :
                                    <div className="flex_input max_flex_input">
                                        {this.state.suggestions.map((item, index) => (
                                            <div onClick={() => this.replaceTag(index)} className="tag_height_1 hover" index={index} key={index}>
                                                <div style={addTagStyle} className="input_tag_container">
                                                    <p style={{ color: '#0086C1' }} className="input_tag">{item.tag}</p>
                                                    <b style={{ color: '#0086C1' }} className="input_tag">{item.position == 300 ? ">50" : item.position}</b>
                                                    <img className="input_tag_close" src={add_tag} />
                                                </div>

                                            </div>
                                        ))}
                                    </div>}

                            </div>
                        </div>
                    </div>
                    {(!this.props.fromSite && hasZeroPositions && this.props.type_os != AppsType.rustore) && <p style={{ color: 'rgb(255, 98, 98)', marginTop: '16px' }}>{strings.positionZeroError}</p>}
                    {this.props.fromSite && <div className='search_site_desc'>{strings.siteSearchPrice}</div>}
                </div>

            </div>

        );
    }
}
export default SearchRequest;