import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import { getUrlParameter } from './utils_android/UrlHelperAndroid';

var strings = getLocalizedStrings()

class GoodReviewsTop extends React.Component {
    constructor(props){
        super(props)
        var good_review_top = getUrlParameter("good_review_top")
        this.state = {
            isGoodReviewsTop : (good_review_top ? true : false)
        } 
        this.goodReviewsTopChanged = this.goodReviewsTopChanged.bind(this)
        if (good_review_top){
            this.props.goodReviewsTopChanged(true)
        }
        
    }
    goodReviewsTopChanged(event){
        if (event){
            var value = event.target.checked
            this.setState({isGoodReviewsTop : value})
            this.props.goodReviewsTopChanged(value)
        }
    }
   
    render() {
        return (
            <div className="order_row">
                    <div className="switch_container flex">
                        <label className="switch">
                            <input type="checkbox" onChange={this.goodReviewsTopChanged} checked={this.state.isGoodReviewsTop} />
                            <div className="slider round"></div>
                        </label>

                        <p className="text-pre-subscr">{strings.goodReviewsTop}</p>
                        <p className={this.props.costGoodReviewTop > 0 ? "text-rub" : "not_display"}>+{this.props.costGoodReviewTop}₽</p>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{strings.goodReviewsTopDesc}</p>
                    </div>
            </div>
           
        );
    }
}
export default GoodReviewsTop;