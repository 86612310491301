import React from 'react';
import qs from 'querystring'
import { getRequestParams } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getUrlParameter } from '../pages_android/custom_views/utils_android/UrlHelperAndroid';
import { declension } from './custom_views/utils_android/Utils';
import icon_warning from './custom_views/custom_icons/mdi_warning.svg'
import banner_words from './custom_views/custom_icons/banner_words.svg'
import banner_passive from './custom_views/custom_icons/banner_passive.svg'
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import ReferalItem from './list_items/ReferalItem';
import GetMoneyDialog from './custom_views/dialogs/GetMoneyDialog';
import ExchangeTooltip from './custom_views/analyze_review/customViews/tooltips/ExchangeTooltip';
var strings = getLocalizedStrings()
class Referals extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            earnedMoneyCount : 0,
            refTextBold : 0,
            refText : 0,
            refLink : "",
            isShowMoneyDialog : false
        }
        this.copy = this.copy.bind(this)
    }
    componentDidMount(){
        if (this.props.refList.length > 0){
            this.calcRefSum()
        }
    }
    componentDidUpdate(prevProps){
        if (this.props != prevProps && this.props.refList.length > 0){
            this.calcRefSum()
        }
    }
    calcRefSum = () => {
        var refSum = 0
        this.props.refList.map((item, index) => (
            refSum += item.earning
        ))
        this.setState({earnedMoneyCount : refSum})
    }
    showPayForWords = () => {
        window.location.href = 'https://blog.upmob.ru/2019/09/13/платим-за-слова/'
    }
    showPassive = () => {
        window.location.href = 'https://blog.upmob.ru/2019/07/17/passive-income/'
    }
    copy(){
        var textField = document.createElement('textarea')
        textField.innerText = ("https://upmob.ru?referal=" + this.props._id)
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }
    getMoney = () => {
        this.setState({isShowMoneyDialog : !this.state.isShowMoneyDialog})
    }
    render() {
        return (
            
            <div className="main_container">
                <div className="max-860-container">
                    <p className="main_title">{strings.earnWithUs}</p>
                    <div className="top-15 container-earned">
                        <div className="plashka-earned earn_pad">
                            <p className="plashka-title">{strings.youEarned}</p>
                            <div className="ref_exchange_container center_hor">
                                <p className="plashka-count-earned top-15">{this.state.earnedMoneyCount}₽</p>
                                <ExchangeTooltip style={{marginTop : '28px'}} money={this.state.earnedMoneyCount} exchangeRate={this.props.exchangeRate}/>
                            </div>
                            
                            <a style={this.props.refList.length == 0 ? {color : 'black', pointerEvents : 'none'} : {}} scroll-on-click="true" href="#referalsContainer"
                                className="referals-count top-15">{strings.referalsCount} {this.props.refList.length}</a>
                            <p className="top-15 plasha-description">{strings.spentMoneyOnApps}</p>
                            {/* <p className="text_earn_p">Нажимая на кнопку “Вывести заработок”,<br/>вы принимаете условия <a className="hover" href="/agreement">пользовательского соглашения</a></p>
                            <p onClick={this.getMoney} className="get_mon hover">Вывести заработок</p> */}
                        </div>
                        <div className="container-earned-right">
                        <p className="plashka-text"><span className="text_refs_bold">{strings.youEarn15}</span> {strings.fromOrders}
                        </p>
                        <div className="right-plashka">
                            
                            <p className="plashka-text text_refs_red">
                                <img className="icon_warning" src={icon_warning}/>{strings.registerByMyRef}
                            </p>
                        </div>
                        
                        
                            
                        </div>
                        
                    </div>

                    <p className="subscription-text-referals send_ref">{strings.sendRef}</p>

                    <div className="top_30">
                        <div className="ref-link-container flex">
                            <div className="display_block ref_link_container">
                                <p className="ref_link_desc">{strings.yourRefLink}</p>
                                <p className="left-link">{"https://upmob.ru?referal=" + this.props._id}</p>
                            </div>
                            
                            <div onClick={this.copy} className="copy-btn_link copy-btn_text">
                                    <p className="br-copy-btn">{strings.copyLink}</p>
                            </div>
                        </div>
                        
                    </div>
                    {/* {!navigator.language.includes("en") &&
                        <div className="container-links">
                            <div className="container-img-link-left">
                                <img onClick={this.showPayForWords} className="banner_words" src={banner_words}/>
                            </div>
                            <div className="container-img-link-right">
                                <img onClick={this.showPassive} className="banner_words" src={banner_passive}/>
                            </div>
                        </div>
                    } */}

                    {this.props.refList.length > 0 ? 
                        <div className="display_block">
                            <p className="plashka-title top_30">{strings.yourRefs}</p>
                            <div id="referalsContainer" className="max-860-container ref_container">
                            <div className="referals-header">
                                <div className="flex-cont">
                                <p className="text-referals-top text-referals-top-1">{strings.dateRef}</p>
                                <p className="text-referals-top text-referals-top-2">{strings.emailRef}</p>
                                </div>
                                
                                <p className="text-referals-top text-referals-top-3">{strings.earningRef}</p>
                            </div>
                                {this.props.refList.length > 0 && this.props.refList.map((item, index) => (
                                    <ReferalItem exchangeRate={this.props.exchangeRate} key={index} referal={item}/>
                                ))}
                            </div>
                            
                        </div> : <div/>} 
                    
                </div>
                {this.state.isShowMoneyDialog && <GetMoneyDialog toggleCloseDialog={this.getMoney}/>}
            </div>
        );
    }
}
export default Referals;