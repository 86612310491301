import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
var strings = getLocalizedStrings()

class PercentsAso extends React.Component {
    constructor(props){
        super(props)
       
    }
   
    render() {
        return (
            <div className="seo_top_container">
                <div className="seo_top_inner"></div>
                <svg  className="seo_percents_container">
                    <rect style={{width : this.props.seoPercentsTop + '%' }}  width="100%" height="100%" fill="#FFF3C9"></rect>
                </svg>
                <p className="seo_top_text">{this.props.seoPercentsTop}% в {strings.topPercents}-3</p>
             </div>
           
        );
    }
}
export default PercentsAso;
