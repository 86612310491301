/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { AdminEmployersTypes, getArrowRotate } from './utils/AdminUtils';
import { AdminApi } from '../../api/AdminApi';
import AdminPayoutsItem from './list_items/AdminPayoutsItem';
import AdminEmployerItem from './list_items/AdminEmployerItem';
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import FilterDropdown from '../custom_views/FilterDropdown';
import arrow_filter from '../custom_views/custom_icons/drop_arrow.svg'

var strings = getLocalizedStrings()
var filters = [strings.allFilters, strings.filterSumMore200, strings.filterYoomoney, strings.filterWebMoney]
var filtersRichest = [strings.allFilters, strings.filterAndroid, strings.filterIOS, strings.filterNoBundle]

const AdminEmployers = ({  }) => {
    const [employersType, setEmployersType] = useState(AdminEmployersTypes.last_payouts)
    const [lastPayouts, setLastPayouts] = useState([])
    const [richestEmployers, setRichestEmployers] = useState([])
    const [isShowFilters, setShowFilters] = useState(false)
    const [filterPicked, setFilterPicked] = useState(strings.allFilters)

    const types = [{title : "Последние выплаты", employers_type : AdminEmployersTypes.last_payouts}, {title : "Самые богатые", employers_type : AdminEmployersTypes.richest}]
    useEffect(() => {
        if (employersType == AdminEmployersTypes.last_payouts){
            getLastHistoryPayouts()
        } else if (employersType == AdminEmployersTypes.richest){
            getRichestEmployers()
        }
        setFilterPicked(strings.allFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employersType])

    const getLastHistoryPayouts = () => {
        AdminApi.getLastHistoryPayouts((responseData) => {
            setLastPayouts(responseData.result)
        }, () => {})
    }
    const getRichestEmployers = () => {
        AdminApi.getRichestEmployers((responseData) => {
            setRichestEmployers(responseData.result)
        }, () => {})
    }
    const filterRichest = (array) => {
        var richest = array
        if (filterPicked != strings.allFilters){
            richest = richest.filter(f => {
                if (filterPicked == strings.filterAndroid){
                    return f.type_os == 'android'
                } else if (filterPicked == strings.filterIOS){
                    return f.type_os == 'ios'
                } else if (filterPicked == strings.filterNoBundle){
                    return f.bundle == undefined || f.bundle == ''
                } 
            })
        }
        return richest
    }
    const filterPayouts = (array) => {
        var payouts = array.sort((a, b) => b.time - a.time)
        if (filterPicked != strings.allFilters){
            payouts = payouts.filter(f => {
                if (filterPicked == strings.filterSumMore200){
                    return f.amount >= 200
                } else if (filterPicked == strings.filterYoomoney){
                    return !f.purse.includes("Z")
                } else if (filterPicked == strings.filterWebMoney){
                    return f.purse.includes("Z")
                }
            })
        }
        return payouts
    }
    const isLastPayouts = employersType == AdminEmployersTypes.last_payouts
    const isRichest = employersType == AdminEmployersTypes.richest
    
    const sortedPayouts = filterPayouts(lastPayouts)
    const sortedRichest = filterRichest(richestEmployers)
    const count = isLastPayouts ? sortedPayouts.length : isRichest ? sortedRichest.length : 0
    return (
        <div>
            <div className='admin_item_bold' style={{marginTop : '24px'}}>Выберите тип</div>
            <div style={{marginTop : 0}} className="orders_container">
                {types.length > 0 && types.map((item, index) => (
                    <div onClick={() => setEmployersType(item.employers_type)} style={employersType == item.employers_type ? {border : '1px solid #1696EC'} : {}} className='admin_user_item hover' item={item} key={index} index={index}>
                        <div>{item.title}</div>
                    </div>
                ))}
            </div>
            <div style={{marginTop : '24px'}} className="filters">
                <div onClick={() => setShowFilters(!isShowFilters)} className="button_choose_app">
                    <div className="just_content w_100">
                        <p className="button_choose_text btn_filter_choose_text">{filterPicked}</p>
                        <img alt='' style={isShowFilters ? getArrowRotate() : {}} className="drop_arrow" src={arrow_filter}/>
                    </div>
                    {isShowFilters && <FilterDropdown filters={isLastPayouts ? filters : filtersRichest} onFilterPicked={(filter) => setFilterPicked(filter)}/>}
                </div>
            </div>
            <div className='admin_item_bold' style={{margin : '0 0 -24px 0'}}>Всего : {count}</div><br/>

            <div>
                {isLastPayouts ?
                    <div className="orders_container">
                        {sortedPayouts.length > 0 && sortedPayouts.map((item, index) => (
                            <AdminPayoutsItem item={item} key={index} index={index}/>
                        ))}
                    </div>
                : isRichest ?
                    <div className="orders_container">
                        {sortedRichest.length > 0 && sortedRichest.map((item, index) => (
                            <AdminEmployerItem item={item} key={index} index={index}/>
                        ))}
                    </div>
                :
                    <div></div>
                }
            </div>
            
        </div>
    )        
    
}
export default AdminEmployers

