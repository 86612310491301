import React from 'react';
import {DebounceInput} from 'react-debounce-input';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';

var strings = getLocalizedStrings()

class UniversalUrl extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            url : ""
        } 
        this.urlChanged = this.urlChanged.bind(this)
    }
    urlChanged(event){
        if (event){
            var value = event.target.value
            if (value.includes("!!") && this.props.csv != undefined && this.props.csv.length > 0){
                var newVal = value.split("!!")
                if (newVal.length == 3 && this.props.csv[0][newVal[1].trim()] != undefined){
                    value = this.getCsvValues(newVal[1].trim())
                }
            }
            this.setState({url : value})
            this.props.urlChanged(value)
        }
    }
    getCsvValues = (key) => {
        var values = ""
        this.props.csv.forEach(element => {
           
            if (element[key] != "" && element[key] != undefined){
                if (values == ""){
                    values = element[key]
                } else {
                    values += (";" + element[key])
                }
            }
        });
        
        return values
    }
    render() {
        return (
            <div className="display_block">
                    <div className="order_row">
                        <div style={this.props.isClickedCreate && this.state.url == "" ? {border : "1px solid rgba(255, 98, 98, 0.5)"} : {}} className="universal_input display_block">
                            <DebounceInput autoComplete="off" type="text" onChange={this.urlChanged} className="input_text_search" placeholder={strings.universalUrl} value={this.state.url}/>
                        </div>
                        <div className="input_description_container">
                            <p className="input_description_text">{strings.universalUrlDesc}</p>
                        </div>
                    </div>
            </div>
           
        );
    }
}
export default UniversalUrl;