import React from 'react';
import qs from 'querystring'

import '../pages_css/tooltip.css';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { isShowLeftTooltip } from '../utils_android/Utils';
import close_delete from '../custom_icons/close_delete.svg'

var strings = getLocalizedStrings()
class AddTagsInfo extends React.Component {
    constructor(props){
        super(props)
        this.state = {
           isShowError : false
        }
       
        
    }

    toogleRightTooltip = () => {
        this.props.toogleRightTooltip()
    }
    render() {
        return (
            <div className="display_block">
                  

                <div onClick={this.toogleRightTooltip} className="tooltip_bg_up_balance"></div>
                <div style={this.props.style ? this.props.style : {}} className="tooltip_plashka add_tags_info">
                    <div style={this.props.isLeft ? {} : {top : '0px', left : '128px'}} className="tooltip_arrow"></div>
                    <div className="display_block">
                        <div className="padding_24 tooltip_right">
                            <p className="add_tags_text">{strings.addTagsDesciptions1}<br/><br/>{strings.addTagsDesciptions2}</p>
                            <p onClick={this.toogleRightTooltip} className="up_balance_btn_univ hover">{strings.understood}</p>
                        </div>
                    </div>
                    <div className="line_white_bg_top"/>
                </div>
            </div>
           
        );
    }
}
export default AddTagsInfo;
