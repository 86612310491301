import React from 'react';

class Loader extends React.Component {
    constructor(props){
        super(props)
        this.whiteStyle = {border : '2px solid white', top : '-2px', borderColor: 'white transparent transparent transparent'}

    }
    
    render(){
            return (
                <div style={this.props.style ? this.props.style : {}} className="lds-ring loader-earn-container">
                        <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                        <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                        <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                        <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                        <p className="loader_text">{this.props.text}</p>
                </div>
            );         
    }
}
export default Loader;
