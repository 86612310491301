import React from 'react';
import { getLocalizedStrings } from '../../../utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class ReviewText extends React.Component {
    constructor(props){
        super(props)
        window.scrollTo(0, 0);
        this.state = {
            selectedRect : undefined,
            selectedText : "",
            scrolledPosition : 0,
            isShowDesc : (this.props.currentItem.tag_name != undefined)
        }
        this.menuWidth = 290
        this.navHeight = 60
        this.isMobile = (window.innerWidth <= 980)
        
        this.onScrollListener = this.onScrollListener.bind(this);
        
        
        this.constMinus = 132
        this.onSelection = this.onSelection.bind(this);
        this.onCloseSelection = this.onCloseSelection.bind(this);
        this.keywordPicked = this.keywordPicked.bind(this);
        this.ignorPicked = this.ignorPicked.bind(this);
    }
    onScrollListener(event){
            var top = window.scrollY
            // if (this.state.selectedText != "" && this.state.selectedText != undefined && this.state.selectedRect != undefined){
            //     this.setState({scrolledPosition : top})
            //     this.onSelection()
            // } else {
            //     this.setState({scrolledPosition : 0})
            // }
            this.setState({scrolledPosition : top})
            this.onSelection()
    }
    onSelection(){
        var text = window.getSelection().toString()
        this.setState({selectedText : text, selectedRect : window.getSelection().getRangeAt(0).getBoundingClientRect()})
    }
    onCloseSelection(){
        this.setState({selectedText : ""})
    }
    keywordPicked(){
        this.props.addKeyword(this.state.selectedText)
        this.onCloseSelection()
    }
    ignorPicked(){
        this.props.addIgnor(this.state.selectedText)
        this.onCloseSelection()
    }
    componentDidMount(){
        if (this.isMobile){
            this.menuWidth = 0
            window.oncontextmenu = function(event) {
                event.preventDefault();
                event.stopPropagation();
                return false;
           };
        }
        window.addEventListener("scroll", this.onScrollListener, false);
    }
    componentDidUpdate(prevProps){
        if (prevProps != this.props){
            this.setState({isShowDesc : (this.props.currentItem.tag_name != undefined)})
        }
    }
    render(){
        
        if (this.state.selectedText != ""){
            var calcSelectionLeft = (this.state.selectedRect.left - this.menuWidth - 26 + (this.state.selectedRect.width/2) - 148)
            var minusSquareLeft = 0
            if (calcSelectionLeft < 0){
                minusSquareLeft = calcSelectionLeft - 24
                calcSelectionLeft = 24
            }
            var topSelection = (this.state.selectedRect.y - (this.navHeight - this.state.scrolledPosition) + this.state.selectedRect.height - 52)
           
        }
            
            return (
                <div>
                    <div className="add_info_tag_line"></div>
                    <div className="review_text_container">
                        <div className="info_tag_text_cont">
                            <p className="info_tag_title info_tag_text">{strings.reviewTextTitle}</p>
                            <p onPointerUp={this.onSelection} className="info_tag_text">{this.props.review.content}</p>
                        </div>
                        {this.state.isShowDesc && <div className="info_tag_desc_cont info_tag_text">{strings.reviewTextDesc}</div>}
                        
                    </div>
                    
                    {/* {this.state.selectedText != "" && <div className="selected_text_cont" style={{top : (this.state.selectedRect.y - this.navHeight), left : (this.state.selectedRect.left - this.menuWidth - this.constMinus + (this.state.selectedRect.width/2))}}> */}
                    {this.state.selectedText != "" && 
                    <div className="selected_text_cont" style={{top : topSelection, left : calcSelectionLeft}}>
                        <div onClick={this.onCloseSelection} className="tooltip_bg_selection"/>
                        <div style={{left : "calc(50% - 8.5px + " + minusSquareLeft + "px)"}} className="square_selected"/>
                        <p onClick={this.keywordPicked} className="hover selection_text">{strings.reviewTextSelectedKey}</p>
                        <p onClick={this.ignorPicked} className="hover selection_text">{strings.reviewTextSelectedIgnor}</p>
                    </div>}
                </div>
                
            );         
    }
}
export default ReviewText;
