/* eslint-disable eqeqeq */
import React from 'react';
import moment from 'moment'

const AdminPayoutsItem = ({ item }) => {
    const isBlocked = item.status != 'success'
    return (
        <div style={isBlocked ? {border: '1px solid rgba(255, 98, 98, 0.7)'} : {}} className='admin_app_item'>
            <div >Сумма : <span className='admin_item_bold'>{item.amount}₽</span></div>
            <div>{item._id}</div>
            <a rel="noopener noreferrer" target='_blank' href={'/employer_info?purse=' + item.purse}>{item.purse}</a>

            <div>Дата выплаты : {moment(item.time).utc().local().format("DD.MM.YYYY HH:mm")}</div>

        </div>
    )        
    
}
export default AdminPayoutsItem

