/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';
import { DebounceInput } from 'react-debounce-input';
import InputLoader from '../../custom_views/utils_android/InputLoader';
import { SiteSeoApi } from '../../../api/SiteSeoApi';
import { isValidUrl } from '../../custom_views/utils_android/SocialHelper';

var strings = getLocalizedStrings()

function SiteUrl(props) {
    const [isLoading, setLoading] = useState(false)
    const url = props.url
    const icon = props.icon
    const setUrl = props.setUrl
    const onUrlChanged = (event) => {
        if (event) {
            var value = event.target.value
            setUrl(value)
            getSiteIcon(value)

        }
    }
    const isValid = true
    const getSiteIcon = (url) => {
        if (!isValid || url == "" || url == undefined){
            props.setIcon("")
            return
        }
        setLoading(true)
        SiteSeoApi.getSiteIcon(url, (responseData) => {

            setLoading(false)
            if (responseData.icon_url != undefined || url == undefined || url == "") {
                props.setIcon(responseData.icon_url)
            } else {
                props.setIcon("")
            }

        }, (responseData) => {
            setLoading(false)
        })
    }
    
    return (
        <div style={{ marginTop: '16px' }} className="display_block">
            <div className="order_row bot_16">
                <div style={isValid ? {} : { border: '1px solid var(--red-dark)' }} className="input_container_border display_block">
                    <img alt='site_icon' style={(icon != undefined && icon != "") ? { display: 'block', marginTop: '7px', borderRadius: '4px' } : { display: 'none' }} className="img_choose_app img_input_icon" src={icon} />

                    <DebounceInput autoComplete="off" debounceTimeout={1000} onChange={(e) => onUrlChanged(e)} placeholder={strings.siteUrl} className="input_text_search" value={url} />
                    {isLoading ? <InputLoader style={{ marginTop: "2px" }} /> : <div />}
                </div>

                <div className="input_description_container">
                    <p className="input_description_text">{strings.siteUrlDesc}</p>
                </div>
            </div>
            {!isValid && <p style={{ color: 'rgb(255, 98, 98)', marginTop: '16px' }}>{strings.siteUrlError}</p>}

        </div>

    );

}
export default SiteUrl;