/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import add from '../custom_views/custom_icons/add.svg';
import stats from '../custom_views/custom_icons/stats.svg';
import trash_red from '../custom_views/custom_icons/trash_red.svg';
import copy from '../custom_views/custom_icons/copy.svg';
import trash_bl from '../custom_views/custom_icons/delete.svg';
import users from '../custom_views/custom_icons/users.svg';
import add_request from '../custom_views/custom_icons/add_request.svg';
import { getSearchWords, openDuplicateOrder, convertToIDFA } from '../custom_views/utils_android/Utils';
import SiteOrderInfoProperties from './custom_views/SiteOrderInfoProperties';
import SiteOrderInfoHeader from './custom_views/SiteOrderInfoHeader';
import OrderBack from '../custom_views/OrderInfo/OrderBack';
import AddRequestTooltip from '../custom_views/AddRequestTooltip';
import AddMoneyTooltip from '../custom_views/AddMoneyTooltip';
import CircleProgress from '../custom_views/CircleProgress';
import { getHttpParams } from '../custom_views/analyze_review/utils/Utils';
import DeleteOrderDialog from '../custom_views/dialogs/DeleteOrderDialog';
import ExchangeTooltip from '../custom_views/analyze_review/customViews/tooltips/ExchangeTooltip';
import { getRequestParams, getRequestUrl } from '../custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import DeleteEmployersDialog from '../custom_views/dialogs/DeleteEmployersDialog';
import SiteOrderPositionItem from './custom_views/SiteOrderPositionItem';
import { SiteSeoApi } from '../../api/SiteSeoApi';
import OrderInfoStats from '../custom_views/OrderInfo/OrderInfoStats';
import Toast from '../custom_views/utils_android/Toast';

const strings = getLocalizedStrings()

function SiteOrderInfo(props) {
    // Инициализация начального состояния
    const initialSearchWords = props.order.search_request ? getSearchWords(JSON.parse(props.order.search_request).join(", "), props.order.search_position) : ""
    if (initialSearchWords && initialSearchWords.length == 1) {
        initialSearchWords[0].positionBefore = props.order.search_position
    }
    const [toastText, setToastText] = useState("")

    // Состояния с использованием useState
    const [searchWords, setSearchWords] = useState(initialSearchWords);
    const [isShowUpBalance, setIsShowUpBalance] = useState(false);
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
    const [isShowDeleteEmployersDialog, setIsShowDeleteEmployersDialog] = useState(false);
    const [isShowAddRequest, setIsShowAddRequest] = useState(false);

    // useEffect заменяет componentDidMount
    useEffect(() => {
        if (props.order._id == undefined) {
            window.location.href = '/site_seo_orders/all'
        }
    }, []);

    // Преобразование методов в функции
    const closeOrderInfo = () => {
        props.orderInfoClosed()
    }



    const setStateOrder = () => {
        var params = getRequestParams()
        const newState = (props.order.state == 1 ? 0 : 1)
        params._id = props.order._id
        params.state = newState
        SiteSeoApi.setStateOrder(params, (responseData) => {
            if (responseData.response == 1) {
                props.order.state = newState
                props.orderChanged(props.order, props.index)
            }
        }, () => { })

    }

    const searchAppPosition = (position, index) => {
        const changedArray = [...searchWords]
        changedArray[index].currentPosition = position
        if (changedArray[index].positionBefore == undefined) {
            changedArray[index].positionBefore = position
        }
        setSearchWords(changedArray)
    }

    const upBalance = () => {
        setIsShowUpBalance(!isShowUpBalance)
    }

    const closeUpBalance = () => {
        setIsShowUpBalance(false)
    }

    const deleteEmployers = () => {
        setIsShowDeleteEmployersDialog(!isShowDeleteEmployersDialog)
    }

    const openStats = () => {
        window.open('/statistics' + "?bundle=" + props.order.bundle, '_blank');
    }

    const deleteOrder = () => {
        setIsShowDeleteDialog(true)
    }

    const onCanceledDelete = () => {
        setIsShowDeleteDialog(false)
    }

    const onDelete = () => {
        var params = getRequestParams()
        params._id = props.order._id
       
        SiteSeoApi.removeOrder(params, (responseData) => {
            setIsShowDeleteDialog(false)
            props.deleteOrder(props.index)
            props.getMyInfo()
        }, () => { })
    }

    const getOrderDetails = () => {
        const params = getRequestParams()
        params.order_id = props.order._id
        fetch(getRequestUrl() + "getOrderDetails", getHttpParams(params))
            .then((response) => response.json())
            .then((responseData) => {
                const idfas = []
                responseData.order.employers_token_google.forEach(element => {
                    if (element.length > 10) {
                        idfas.push(convertToIDFA(element))
                    }
                });
                const csvString = idfas.join("\r\n");
                const a = document.createElement('a');
                a.href = 'data:application/octet-stream;base64,' + btoa(csvString);
                a.download = props.order._id + '.csv';
                document.body.appendChild(a);
                a.click();
            })
    }

    const showIdfa = () => {
        props.showIdfa(props.order)
    }

    const duplicateOrder = () => {
        openDuplicateOrder(props.order, false, true)
    }



    const deleteSearchKey = (index) => {
        const newSearchWords = [...searchWords]
        newSearchWords.splice(index, 1);
        setSearchWords(newSearchWords)
        save(newSearchWords)
    }

    const save = (newSearchWords) => {
        const params = getRequestParams()
        let search_request = ""
        let search_position = ""

        newSearchWords.forEach(searchObject => {
            if (search_request == "") {
                search_request = searchObject.text
                search_position = searchObject.positionBefore
            } else {
                search_request += ("," + searchObject.text)
                search_position += ("," + searchObject.positionBefore)
            }
        });

        params.order_id = props.order._id
        params.search_request = search_request
        params.search_position = search_position

        fetch(getRequestUrl() + "editOrder", getHttpParams(params))
            .then((response) => response.json())
            .then((responseData) => {
                const order = props.order
                order.search_request = search_request
                order.search_position = search_position
                // props.editOrderSearchInfo(order, props.pickedOrderIndex)
            })
    }


    const toggleAddRequest = () => {
        setIsShowAddRequest(!isShowAddRequest)
    }

    const addNewSearchWords = (newSearchWords) => {
        const newSearchWordsArray = [...newSearchWords]
        newSearchWordsArray.forEach(element => {
            searchWords.push(element)
        });
        setSearchWords(searchWords)
        save(searchWords)
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 3000)
    }
    if (props.order._id == undefined) {
        return null
    }

    return (
        <div className="order_info">
            <OrderBack />
            <SiteOrderInfoHeader
                order={props.order}
                setStateOrder={setStateOrder}
            />


            <div className="info_container">
                {/* 1 column */}
                <div>
                    <div className={searchWords.length > 0 ? "search_words_container" : 'not_display'}>
                        <div className="search_words_title_container">
                            <p className="search_words_title">{strings.searchRequest}</p>
                            <div className="flex">
                                <p className={(searchWords.length == 1 || (props.order.search_position != undefined && props.order.search_position.includes(","))) ? "search_words_title" : "not_display"}>{strings.positionBefore}</p>
                                <p className="search_words_title after_pos">{(searchWords.length == 1 || (props.order.search_position != undefined && props.order.search_position.includes(","))) ? strings.positionAfter : strings.positionNumber}</p>
                            </div>
                        </div>

                        {/* <div onClick={toggleAddRequest} className="info_add_request hover">
                            <div className="flex center_hor">
                                <img className="info_add_request_img" src={add_request} />
                                <p className="info_add_request_text">{strings.infoAddRequest}</p>
                            </div>
                        </div> */}


                        {searchWords.length > 0 && searchWords.map((item, index) => (
                            <SiteOrderPositionItem showToast={showToast} updateMoney={props.updateMoney} deleteSearchKey={deleteSearchKey} searchAppPosition={searchAppPosition} _id={props.order._id} order={props.order} index={index} searchObject={item} key={index} />
                        ))}
                        

                    </div>
                    
                    <div className='search_site_desc'>{strings.siteSearchPrice}</div>
                </div>
                {/* 2 column */}
                <SiteOrderInfoProperties order={props.order} />
                {/* 3 column */}
                <div className="info_installs">
                    <p className="grey_text center_text">{strings.siteCompletes}</p>
                    <CircleProgress order={props.order} state={props.order.state} count_installed={props.order.count_installs} max_count_installs={props.order.max_count_searches} percentage={(props.order.count_installs / props.order.max_count_searches) * 100 >= 100 ? 100 : (props.order.count_installs / props.order.max_count_searches) * 100} />
                    <div className="line_info" />
                    <p className="grey_text center_text">{strings.budgetProp}</p>
                    <div className="ref_exchange_container center_hor">
                        <p className="budget_text">{props.order.count_money_r}₽</p>
                        <ExchangeTooltip style={{ marginTop: '22px' }} money={props.order.count_money_r} exchangeRate={props.exchangeRate} />
                    </div>

                    <div className="line_info_budget" />
                    <div onClick={upBalance} className="up_balance_info hover">
                        <img alt='' src={add}></img>
                        <p className="up_balance_info_text">{strings.upBalanceProp}</p>

                    </div>
                    {isShowUpBalance && <AddMoneyTooltip isSeo isLeft={true} count_money_r={props.count_money_r} index={props.index} order_id={props.order._id} addCoinsToOrder={props.addCoinsToOrder} closeUpBalance={closeUpBalance} price_one_install={props.order.one_perform_price_customer} />}
{/* 
                    <div onClick={openStats} className="up_balance_info top_16 hover">
                        <img src={stats}></img>
                        <p className="up_balance_info_text">{strings.statsProp}</p>
                    </div> */}
                    {/* <div onClick={showIdfa} className="up_balance_info top_16 hover">
                        <img alt='' src={users}></img>
                        <p className="up_balance_info_text">{strings.idfaTitle}</p>
                    </div> */}

                    <div onClick={duplicateOrder} className="up_balance_info top_16 hover">
                        <img src={copy}></img>
                        <p className="up_balance_info_text">{strings.duplicateOrder}</p>
                    </div>

                    {/* <div onClick={deleteEmployers} className="up_balance_info top_16 hover">
                        <img alt='' style={{ opacity: 0.8, marginLeft: '-2px', marginRight: '-4px' }} src={trash_bl}></img>
                        <p className="up_balance_info_text">{strings.deleteOrderEmployers}</p>
                    </div> */}
                    <div onClick={deleteOrder} className="up_balance_info top_16 hover">
                        <img alt='' src={trash_red}></img>
                        <p className="up_balance_info_text red_text">{strings.deleteOrderInfo}</p>
                    </div>
                </div>

            </div>
            {isShowDeleteDialog && <DeleteOrderDialog onCanceledDelete={onCanceledDelete} onDelete={onDelete} />}
            {isShowDeleteEmployersDialog && <DeleteEmployersDialog order_id={props.order._id} onCanceledDelete={() => setIsShowDeleteEmployersDialog(false)} />}
            {isShowAddRequest && <AddRequestTooltip addNewSearchWords={addNewSearchWords} index={props.index} order={props.order} toggleTooltip={toggleAddRequest} />}
            <OrderInfoStats isSite isUniversal={false} order={props.order}/>
            {toastText != "" && <Toast text={toastText}/>}
        </div>
    );

}

export default SiteOrderInfo;