/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../../utils_android/LocalizedStrings';
import help_circle_blue from '../../custom_icons/help_circle_blue.svg'
import {CSSTransitionGroup} from 'react-transition-group';
import SdkMoneyDialog from '../../dialogs/SdkMoneyDialog';
import Loader from './Loader';

var strings = getLocalizedStrings()
class CreateOrderButton extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowSdkMoneyDialog : false
        }
        this.toggleSdkMoneyDialog = this.toggleSdkMoneyDialog.bind(this)
    }
    toggleSdkMoneyDialog(){
        this.setState({isShowSdkMoneyDialog : !this.state.isShowSdkMoneyDialog})
    }

    render() {
        
        return (
                    <div style={this.props.isSdkError ? {pointerEvents : 'none', opacity : 0.5} : {}} className="create_order_container">
                        <div className="mobile_cost">
                            <p className="text-cost-left">{strings.orderPrice}</p>
                            <p className="text-cost-right">{this.props.sumOrder}₽</p>
                            <p className="create_order_exchange">≈ ${(parseFloat(this.props.sumOrder) / parseFloat(this.props.exchangeRate)).toFixed(2)}</p>

                            {this.props.isShowHelp && <img alt='' onClick={this.toggleSdkMoneyDialog} className="hover help_sdk" src={help_circle_blue}/>}
                        </div>
                        <div className="mobile_cost">
                            <p className="text-cost-left">{this.props.isSite ? strings.siteOrderPrice : strings.orderPriceOneInstall}</p>
                            <p className="text-cost-right">{this.props.priceOnInstall}₽</p>
                            
                        </div>
                        <div onClick={this.props.createOrder} style={this.props.isLoading ? {pointerEvents : 'none', minHeight : '16px', marginTop : '4px', position : 'relative', minWidth : '60px'} : this.props.isEnabledButton == undefined ? (this.props.installCount < 10 ? {opacity : '0.2', pointerEvents : 'none'} : {}) : this.props.isEnabledButton ? {} : {opacity : '0.2', pointerEvents : 'none'}} className="yellow_btn create_order_btn relative">{this.props.isLoading ? <Loader isWhite/> : this.props.isSdkControll ? strings.nextBtn : strings.saveOrder}</div>
                        <div className="display_block">
                            <div className="land_cost top_4_m">
                                <p className="text-cost-left">{strings.orderPrice}</p>
                                <p className="text-cost-right">{this.props.sumOrder}₽</p>
                                <p className="create_order_exchange">≈ ${(parseFloat(this.props.sumOrder) / parseFloat(this.props.exchangeRate)).toFixed(2)}</p>
                                {this.props.isShowHelp && <img alt='' onClick={this.toggleSdkMoneyDialog} className="hover help_sdk" src={help_circle_blue}/>}
                            </div>
                            <div className="land_cost top_4">
                                <p className="text-cost-left">{this.props.isSite ? strings.siteOrderPrice : strings.orderPriceOneInstall}</p>
                                <p className="text-cost-right">{this.props.priceOnInstall}₽</p>
                            </div>
                        </div>
                        <CSSTransitionGroup transitionName="dialog_animation" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                            {this.state.isShowSdkMoneyDialog && <SdkMoneyDialog toggleCloseDialog={this.toggleSdkMoneyDialog}/>}
                        </CSSTransitionGroup>
                    </div>
        );
    }
}
export default CreateOrderButton;