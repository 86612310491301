/* eslint-disable eqeqeq */
import React from 'react';
import qs from 'querystring'
import { getRequestUrl } from './utils_android/CookieHelper';
import './pages_css/auth.css'
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import ForgotPassDialog from './dialogs/ForgotPassDialog';
import SuccessRegister from './dialogs/SuccessfullyRegistrated';
import ResetPassDialog from './dialogs/ResetPassDialog';
import { getUrlParameter } from './utils_android/UrlHelperAndroid';
import EmailCodeDialog from './dialogs/EmailCodeDialog';
import { isValidEmail } from './utils_android/Utils';
import Loader from './analyze_review/customViews/Loader';
var strings = getLocalizedStrings()

class AuthPage extends React.Component {
    constructor(props) {
        super(props)
        var passHash = getUrlParameter('hash')
        var email = getUrlParameter('email')
        this.state = {
            authErrorText: "asd",
            authErrorText2: "asd",
            email: "",
            password: "",
            isShowError: false,
            isShowForgotPass: false,
            isShowSuccessRecovered: false,
            isShowCodeDialog: false,
            isShowResetPassword: passHash ? true : false,
            passHash: passHash ? passHash : "",
            emailHash: email ? email : "",
            isLoading : false
        }

    }
    loginInputChanged = (event) => {
        if (event) {
            this.setState({ email: event.target.value })
        }
    }
    passInputChanged = (event) => {
        if (event) {
            this.setState({ password: event.target.value })
        }
    }
    authUser = () => {
        this.setState({ isShowError: false, isLoading : true })
        var params = {
            'email': this.state.email,
            // 'pass_hash': SHA256(this.state.password)
        }
        console.log("authUser params = ", params)
        fetch(getRequestUrl() + "authCustomerNew", {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: qs.stringify(params)
        }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == -1) {
                    this.setState({ isShowError: true, authErrorText: strings.wrongLogin, authErrorText2: strings.orPass })
                } else if (responseData.response == 1) {
                    // setAuthCookie(responseData.email, responseData.token, responseData._id)
                    // window.location.href = '/neworder'
                    this.setState({isShowCodeDialog : true})
                } 
                this.setState({ isLoading : false })
                // else if (responseData.response == 3) {
                //     this.setState({isShowCodeDialog : true})
                // }
            })
    }
    forgotPass = () => {
        this.setState({ isShowForgotPass: true })
    }
    closePassDialog = () => {
        this.setState({ isShowForgotPass: false, isShowResetPassword: false })
    }
    emailSent = () => {
        this.setState({ isShowForgotPass: false, isShowSuccessRecovered: true })
    }
    onCanceled = () => {
        this.setState({ isShowSuccessRecovered: false })
    }
    closeError = () => {
        this.setState({ isShowError: false })
    }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.authUser()
        }
    }
    render() {
        return (
            <div onClick={this.closeError} className="block">
                <input onChange={this.loginInputChanged} type="text" name="username" className="form-control" placeholder={strings.email} value={this.state.email} />
                {/* <input onKeyDown={this.handleKeyDown} onChange={this.passInputChanged} type="password" name="password" className="form-control" placeholder={strings.password} value={this.state.password} /> */}
                <div className="relative">
                    <button style={isValidEmail(this.state.email) ? { opacity: 1, pointerEvents: 'auto', position : 'relative' } : { opacity: 0.2, pointerEvents: 'none', position : 'relative' }} onClick={this.authUser} id="login-submit-reg-btn" className="form-control btn btn-login">{this.state.isLoading ? <Loader style={{marginTop : '0px'}} isWhite/> : strings.enter}</button>
                    {this.state.isShowError ?
                        <div className="tooltip__container tooltip-top rate-content__tooltip-wrapper my-top-tooltip">
                            <i className="tooltip__arrow rate-content__tooltip-arrow tooltip_bottom i_error" role="button"></i>
                            <p className="errorText">{this.state.authErrorText}<br />{this.state.authErrorText2}</p>
                        </div> : <div></div>
                    }
                </div>
                {/* <div className="text-center">
                    <p onClick={this.forgotPass} className="forgot-password blue_text blue_hover text_forgot">{strings.forgotPass}</p>
                </div> */}
                {this.state.isShowForgotPass && <ForgotPassDialog emailSent={this.emailSent} email={this.state.email} closePassDialog={this.closePassDialog} />}
                {this.state.isShowResetPassword && <ResetPassDialog email={this.state.emailHash} passHash={this.state.passHash} closePassDialog={this.closePassDialog} />}
                {this.state.isShowSuccessRecovered && <SuccessRegister title={strings.passRecoveryLink} onCanceled={this.onCanceled} />}
                {this.state.isShowCodeDialog && <EmailCodeDialog email={this.state.email} closeCodeDialog={() => this.setState({isShowCodeDialog : false})} />}
            </div>
        );
    }
}
export default AuthPage;