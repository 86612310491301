import React from 'react';
import './dialogs.css'
class ImageDialog extends React.Component {
    constructor(props){
        super(props)
    }
   
    render(){
      
            return (
                <div className="error_bg">
                    <div onClick={this.props.onCancel} className="tooltip_bg_analize"></div>
                    <div className="image_dialog">
                        {/* <img style={{right : '22px'}} className="dialog-close" onClick={this.props.onCancel} src={close_delete} alt=""/>    */}
                        <img className="image_dialog_img" src={this.props.image}/>
                    </div>  
                </div>
            ); 
        
        
    }
}
export default ImageDialog;