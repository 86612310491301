import React from 'react';

class ProgressLine extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            width : (this.props.count_installed/this.props.max_count_installs)*100
        }
    }
    componentDidUpdate(prevProps){
        if (prevProps != this.props){
            this.setState({width : ((this.props.count_installed/this.props.max_count_installs)*100)})
        }
    }
    
    render() {
        return (
            <div className="progress_line_container">
                  <div className="progress_line_bg"></div>
                  <div style={(this.state.width >= 100 || this.props.isOrderCompleted) ? {width : "100%", background : '#5FD7A4'} : ((this.props.order.state == 0 || this.props.order.moderated == -1) ? {width : this.state.width + "%", background : '#FF6262'} : {width : this.state.width + "%", background : '#FFC28C'})} className="progress_line"></div>
            </div>
        );
    }
}
export default ProgressLine;
