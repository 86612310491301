/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';
import { declension } from '../../custom_views/utils_android/Utils';


var strings = getLocalizedStrings()

function SiteCount(props) {
    const count = props.count
    const setCount = props.setCount
    const installCountChanged = (event) => {
        if(event) {
            var value = event.target.value
            setCount(value)
        }
    }
    const minCount = 10
    return (
        <div className="display_block">
            <div className="line-row"/>
            <div className="order_row">
                <div style={count < minCount ? { display: 'block', border: '2px solid rgba(255, 98, 98, 0.5)' } : { display: 'block', border: '2px solid #e4ecef' }} className="input_container_border display_block">{strings.siteCount}
                    <input type="number" onChange={installCountChanged} className="input_text_search" placeholder={strings.installCountPlaceholder} value={count} />
                </div>
                <div className="input_description_container">
                    <p className="input_description_text">{strings.siteCountDesc}</p>
                </div>
            </div>
            {count < minCount && <p className="installs_error">{strings.minInstallsCOunt} {minCount} {declension(minCount, strings.siteCount1, strings.siteCount2, strings.siteCount5)}</p>}
        </div>
    )
}
export default SiteCount;