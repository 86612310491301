/* eslint-disable eqeqeq */
import sn_insta from '../custom_icons/sn_insta.svg'
import sn_yt from '../custom_icons/sn_yt.svg'
import sn_vk from '../custom_icons/sn_vk.svg'
import sn_tg from '../custom_icons/sn_tg.svg'
import sn_tiktok from '../custom_icons/sn_tiktok.svg'
import sn_h_tt from '../custom_icons/sn_h_tt.svg'
import sn_h_yt from '../custom_icons/sn_h_yt.svg'
import sn_h_vk from '../custom_icons/sn_h_vk.svg'
import sn_h_insta from '../custom_icons/sn_h_insta.svg'
import sn_h_tg from '../custom_icons/sn_h_tg.svg'
import { getLocalizedStrings } from './LocalizedStrings'
export const arrowRotateStyle = () => {
    const arrowRotate = {
        transform: 'rotate(180deg)',
        marginTop: '6px'
    }
    return arrowRotate
}
export const formatSocialServices = (config, standartPrice) => {
    const strings = getLocalizedStrings()
    var c = {...config}
    var arrayTitles = []
    for (var k in config) {
        if (config.hasOwnProperty(k)) {
            var item = {}
            var mService = config[k]
            item.sn = k
            item.title = k
            item.img = config[k].icon
            mService.services.forEach(element => {
                element.title_price = (element.title ? element.title : element.name) + " " + strings.socialPrice + " " + (element.service == "10002" ? 3 : element.price == undefined ? (standartPrice) : parseFloat(standartPrice * element.price).toFixed(2)) + "₽ " + strings.socialFor1
            });
            
            arrayTitles.push(item)
        }
    }
    // arrayTitles[0].isSelected = true
    c.titles = arrayTitles
    return c
}
export const formatSocialConfig = (config, standartPrice) => {
    var c = {...config}
    for (var k in config) {
        if (config.hasOwnProperty(k)) {
            var innerConfig = config[k]
            var arr = []
            for (var key in innerConfig) {
                if (innerConfig.hasOwnProperty(key)) {
                    var item = innerConfig[key]
                    item.service = key
                    item.sn = k
                    item.is_auto_likes = key == '519' || key == "509"
                    item.is_poll = key == '777'
                    item.title_price = item.title + " (цена " + (key == "10002" ? 3 : item.price == undefined ? (standartPrice) : (standartPrice * item.price)) + "₽ за 1 шт.)"  
                    arr.push(item)
                    
                }
            }
            config[k].services = arr
        }
    }
    console.log("element = ", c)
    return c
}
export const getSnItems = () => {
    return [{index : 0, title : 'Instagram*', img : sn_insta, isSelected : true, tag : 'INSTA'},
            {index : 1, title : 'ВКонтакте', img : sn_vk, tag : 'VK'},
            {index : 2, title : 'Telegram', img : sn_tg, tag : 'TG'},
            {index : 3, title : 'TikTok', img : sn_tiktok, tag : 'TIK'},
            {index : 4, title : 'YouTube', img : sn_yt, tag : 'YOUTUBE'}
            ]
}
export function isValidUrl (urlString) {
	  	var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
	  return !!urlPattern.test(urlString);
}
export function parseYoutubeSearch(response){
    var resp = {...response}
    var type = 0
    if (resp.result != "-1" && resp.new_query == ""){
        type = YoutubeSearchType.SUCCESS
    } else if (resp.result == "-1"){
        type = YoutubeSearchType.FAILED
    } else if (resp.result != "-1" && resp.new_query != ""){
        type = YoutubeSearchType.HAS_NEW_QUERY
    }
    resp.type = type
    return resp
}
export function getYoutubeId(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : "";
}
export const getSocialHistoryIcon = (type) => {
    switch (type) {
        case SocialOrderType.INSTA:
            return sn_h_insta
        case SocialOrderType.TG:
            return sn_h_tg
        case SocialOrderType.TIK:
            return sn_h_tt
        case SocialOrderType.VK:
            return sn_h_vk
        case SocialOrderType.YOUTUBE:
            return sn_h_yt
        default:
            return sn_h_insta;
    }
}
export const getSocTitle = (title) => {
    if (title == SocialOrderType.INSTA){
        return 'Instagram'
    } else if (title == SocialOrderType.VK){
        return 'Вконтакте'
    } else if (title == SocialOrderType.TG){
        return 'Telegram'
    } else if (title == SocialOrderType.TIK){
        return 'Tik Tok'
    } else if (title == SocialOrderType.YOUTUBE){
        return 'Youtube'
    } else if (title == SocialOrderType.TV){
        return 'Трансляции'
    } else {
        return title
    }
}
export const getIsDesktop = () => {
    return window.innerWidth >= 960
}
export const YoutubeSearchType = {
    SUCCESS: 0,
    FAILED: 1,
    HAS_NEW_QUERY: 2,
}
export const SocialOrderType = {
    TG: "TG",
    VK: "VK",
    INSTA: "INSTA",
    TIK: "TIK",
    YOUTUBE: "YOUTUBE",
    TV: "TV",
}