/* eslint-disable eqeqeq */
import React from 'react';
import info from '../custom_views/custom_icons/info.svg';
import more from '../custom_views/custom_icons/more.svg';
import more_pressed from '../custom_views/custom_icons/more_pressed.svg';
import play from '../custom_views/custom_icons/play.svg';
import finished from '../custom_views/custom_icons/finished.svg';
import pause from '../custom_views/custom_icons/pause.svg';
import add from '../custom_views/custom_icons/add.svg';
import search_icon from '../custom_views/custom_icons/search_icon.svg';
import review_icon from '../custom_views/custom_icons/review.svg';
import third_day_icon from '../custom_views/custom_icons/third_day.svg';
import likes_up_icon from '../custom_views/custom_icons/likes_up.svg';
import progress_icon from '../custom_views/custom_icons/progress.svg';
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import { getOptions, declension, getOptionsIcon, isShowLeftTooltip, getStartDate } from '../custom_views/utils_android/Utils';
import ProgressLine from '../custom_views/ProgressLine';
import { getRequestParams, getRequestUrl } from '../custom_views/utils_android/CookieHelper';
import DeleteOrderDialog from '../custom_views/dialogs/DeleteOrderDialog';
import AddMoneyTooltip from '../custom_views/AddMoneyTooltip';
import { getPauseBorderStyle } from '../custom_views/utils_android/StyleHelper';
import OrderMoreTooltip from '../custom_views/tooltips/OrderMoreTooltip';
import { AppsType, getAppIconType, getAppUrl, getHttpParams, getSearchRequestAppUrl } from '../custom_views/analyze_review/utils/Utils';
import { Link } from "react-router-dom";
import block from '../custom_views/custom_icons/block.svg';
import block_help from '../custom_views/custom_icons/block_help.svg';
import Toast from '../custom_views/utils_android/Toast';

var borderPauseStyle = getPauseBorderStyle()
var strings = getLocalizedStrings()

class OrderItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowDeleteDialog: false,
            isShowUpBalance: false,
            isShowMore: false,
            image: null,
            toastText: ""
        }
        this.toogleMore = this.toogleMore.bind(this)
        this.setStateOrder = this.setStateOrder.bind(this)
        this.deleteOrder = this.deleteOrder.bind(this)
        this.onCanceledDelete = this.onCanceledDelete.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.showIdfa = this.showIdfa.bind(this)
    }
    // componentWillMount() {
    //     checkIfImageExists("https://" + this.props.order.icon, (result) => {
    //         this.setState({image : result})
    //     })
    // }

    setStateOrder() {
        var params = getRequestParams()
        var newState = (this.props.order.state == 1 ? 0 : 1)
        params._id = this.props.order._id
        params.state = newState
        fetch(getRequestUrl() + "setStateOrder", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1) {
                    this.props.order.state = newState
                    this.props.orderChanged(this.props.order, this.props.index)
                }
            })
    }

    deleteOrder() {
        this.setState({ isShowDeleteDialog: true })
    }
    onCanceledDelete() {
        this.setState({ isShowDeleteDialog: false })
    }
    onDelete() {
        var params = getRequestParams()
        params._id = this.props.order._id
        fetch(getRequestUrl() + "removeOrder", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                this.setState({ isShowDeleteDialog: false })
                this.props.deleteOrder(this.props.index)
                this.props.getMyInfo()
            })


    }
    onRemoveMoney = () => {
        var params = getRequestParams()
        params._id = this.props.order._id
        fetch(getRequestUrl() + "clearMoneyOrder", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                window.location.reload()
            })


    }
    showOrderInfo = () => {
        this.props.showOrderInfo(this.props.order, this.props.index)

    }
    toogleAddMoney = () => {
        this.setState({ isShowUpBalance: !this.state.isShowUpBalance })
    }
    closeUpBalance = () => {
        this.setState({ isShowUpBalance: false })
    }
    openStoreUrl = () => {
        const order = this.props.order
        // var link = order.type_os == 'android' ? 'https://play.google.com/store/apps/details?id=' + order.bundle : 'https://itunes.apple.com/ru/app/id' + order.appstore_id
        const link = getAppUrl(order.type_os, order.type_os == AppsType.ios ? order.appstore_id : order.bundle)
        window.open(link, '_blank')
    }
    openRequestUrl = () => {
        const order = this.props.order
        const withoutSearchRequestLink = getAppUrl(order.type_os, order.type_os == AppsType.ios ? order.appstore_id : order.bundle)
        const searchRequestLink = getSearchRequestAppUrl(order.type_os, order.type_os == AppsType.ios ? order.appstore_id : order.bundle, order.search_request)
        const link = order.search_request == undefined ? withoutSearchRequestLink : searchRequestLink
        // if (order.type_os != 'android' && window.innerWidth >= 769) {
        //     link = 'https://itunes.apple.com/ru/app/id' + order.appstore_id;
        //   }
        window.open(link, '_blank');
    }
    toogleMore() {
        this.setState({ isShowMore: !this.state.isShowMore })
    }
    showIdfa() {
        this.props.showIdfa(this.props.order)
    }
    showToast = () => {
        this.setState({ toastText: this.props.order.reason_block ? this.props.order.reason_block : strings.blockReason })
        setTimeout(this.onToastTimeout, 5000)
    }
    onToastTimeout = () => {
        this.setState({ toastText: "" })
    }

    render() {
        const isNeedActiveSdk = (this.props.order.sdk_tasks != undefined && this.props.order.sdk_tasks.length > 0 && !this.props.order.is_activate_sdk)
        const isOrderCompleted = (this.props.order.count_installed >= this.props.order.max_count_installs || this.props.order.count_money_r < this.props.order.price_one_install || this.props.order.count_money_r <= 6)
        const isBlocked = (this.props.order.moderated == -1 && !isOrderCompleted)
        return (
            <div style={(this.props.order.state == 0 || isBlocked) ? borderPauseStyle : {}} className="order_container">
                <div className="padding_12">
                    <div className="top_order">
                        <Link className="order_icon hover" onClick={this.showOrderInfo} to={"/orders/order?id=" + this.props.order._id}><img className="order_icon hover" src={info} /></Link>
                        <div onClick={this.openStoreUrl} className="order_icon_container">
                            <img alt='app icon' className="order_app_icon" src={"https://" + this.props.order.icon} />
                            <img alt='icon type' className="order_type_icon" src={getAppIconType(this.props.order.type_os, true)} />
                        </div>
                        <div className="relative">
                            <img alt='more' onClick={this.toogleMore} className="order_icon hover" src={this.state.isShowMore ? more_pressed : more} />
                            {this.state.isShowMore && <OrderMoreTooltip onRemoveMoney={this.onRemoveMoney} showIdfa={this.showIdfa} order={this.props.order} toogleMore={this.toogleMore} deleteOrder={this.deleteOrder} />}

                        </div>

                    </div>
                    <Link className="order_name hover" onClick={this.showOrderInfo} to={"/orders/order?id=" + this.props.order._id}><p className="order_name hover">{this.props.order.name ? this.props.order.name : strings.appBlocked}</p></Link>
                    <p className="date_text_order">{getStartDate(this.props.order)}</p>
                    <div onClick={this.openRequestUrl} className="center_req hover">
                        <img alt='search' className="search_icon" src={search_icon}></img>
                        <p className="order_search_text">{this.props.order.search_request == undefined ? strings.withoutReq : this.props.order.search_request}</p>
                    </div>

                    <div className="center_icons">
                        <img alt='' style={getOptionsIcon(0, this.props.order)} className="tasks_icon" src={third_day_icon}></img>
                        <img alt='' style={getOptionsIcon(1, this.props.order)} className="tasks_icon" src={review_icon}></img>
                        <img alt='' style={getOptionsIcon(2, this.props.order)} className="tasks_icon" src={likes_up_icon}></img>
                        <img alt='' style={getOptionsIcon(3, this.props.order)} className="tasks_icon" src={progress_icon}></img>
                        {getOptions(this.props.order) == strings.noOptions && <p className="order_search_text top_12">{getOptions(this.props.order)}</p>}
                    </div>
                </div>

                <div className="control_container">
                    {isBlocked ?
                        <div onClick={this.showToast} className="controlls hover">
                            <p className="status_text_control">{strings.status}</p>
                            <div style={{ justifyContent: 'center' }} className='flex'>
                                <p className="status_text">{strings.blocked}</p>
                                <img className='blocked_help' src={block_help} />
                            </div>
                            <img onClick={this.setStateOrder} className="controll_icon" src={block} />
                        </div>
                        :
                        <div className="controlls">
                            <p className="status_text_control">{strings.status}</p>
                            {isNeedActiveSdk ? <p style={{ marginTop: '24px', color: "rgba(255, 98, 98, 1)" }} className="status_text">{strings.sdkDoSDK}</p> :
                                <p className="status_text">{isOrderCompleted ? strings.finished : (this.props.count_money_r < 0 ? (this.props.order.state == 0 ? strings.upBalance : this.props.order.moderated == 0 ? strings.moderating : (this.props.order.moderated == -1 ? strings.blocked : (this.props.order.state == 0 ? strings.pause : strings.working))) : (this.props.order.moderated == 0 ? strings.moderating : (this.props.order.moderated == -1 ? strings.blocked : (this.props.order.state == 0 ? strings.pause : strings.working))))}</p>}
                            {!isNeedActiveSdk && <img style={isOrderCompleted ? { pointerEvents: 'none' } : {}} onClick={this.setStateOrder} className="controll_icon hover" src={isOrderCompleted ? finished : (this.props.order.count_money_r < this.props.order.price_one_install ? play : (this.props.order.state == 0 ? play : pause))} />}

                        </div>
                    }

                    <div className="line_control"></div>
                    <div className="controlls">
                        <p className="status_text_control">{strings.budget}</p>
                        <p className="status_text">{this.props.order.count_money_r}₽</p>
                        <img alt='add' onClick={this.toogleAddMoney} className="controll_icon hover" src={add} />
                        {this.state.isShowUpBalance && <AddMoneyTooltip isLeft={isShowLeftTooltip(this.props.index)} style={window.innerWidth >= 556 ? (isShowLeftTooltip(this.props.index) ? { top: "-190px", left: '-246px' } : { top: "-190px", left: '96px' }) : { top: "-190px", left: '-186px' }} count_money_r={this.props.count_money_r} index={this.props.index} order_id={this.props.order._id} addCoinsToOrder={this.props.addCoinsToOrder} closeUpBalance={this.closeUpBalance} price_one_install={this.props.order.price_one_install} />}


                    </div>
                </div>

                <ProgressLine isOrderCompleted={isOrderCompleted} order={this.props.order} count_installed={this.props.order.count_installed} max_count_installs={this.props.order.max_count_installs} />
                <div className="install_container">
                    <div className="flex">
                        <p className="installs">{this.props.order.count_installed}</p>
                        <p className="installs_desc">{declension(this.props.order.count_installed, strings.installs_1, strings.installs_2, strings.installs_5)}</p>
                    </div>
                    <p className="installs_desc">{strings.from + " " + this.props.order.max_count_installs}</p>

                </div>
                {this.state.isShowDeleteDialog && <DeleteOrderDialog onCanceledDelete={this.onCanceledDelete} onDelete={this.onDelete} />}
                {this.state.toastText != "" && <Toast text={this.state.toastText} />}
            </div>

        );
    }
}
export default OrderItem;