import {  getHttpParams } from "../pages_android/custom_views/analyze_review/utils/Utils";
import { getRequestParams, getRequestUrl } from "../pages_android/custom_views/utils_android/CookieHelper";
import { log, onResponse } from "./ApiUtils";
export class SocialApi {
   
    static getLikedromConfig(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getLikedromConfig", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getLikedromConfig response = ", responseData)
            onSuccess(responseData)
        })
    }
   
    static getAllSocialServices(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getAllSocialServices", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getAllSocialServices response = ", responseData)
            onSuccess(responseData)
        })
    }
    static checkYouTubeSearchByVideoId(params, onSuccess, onError) {
        fetch(getRequestUrl() + "checkYouTubeSearchByVideoId", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("checkYouTubeSearchByVideoId response = ", responseData)
            onSuccess(responseData)
        })
    }
    static getMyLikedromOrders(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getMyLikedromOrders", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getMyLikedromOrders response = ", responseData)
            var response = responseData
            response.response = '1'
            onResponse(response, onSuccess, onError)
        })
    }
   
}