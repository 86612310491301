/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import '../../css_android/bot_orders.css'
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import { BotApi } from '../../api/BotApi';
import { getEmail, getRequestParams, getRequestUrl } from '../custom_views/utils_android/CookieHelper';
import Toast from '../custom_views/utils_android/Toast';
import Loader from '../custom_views/utils_android/LoaderAndroid';
import EmptyOrders from '../custom_views/empty_views/EmptyOrders';
import EmptyStats from '../custom_views/empty_views/EmptyStats';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import AppsDropdown from '../custom_views/AppsDropdown';
import FilterDropdown from '../custom_views/FilterDropdown';
import empty_orders from '../custom_views/custom_icons/empty_orders.svg'
import android_icon from '../custom_views/custom_icons/android.svg'
import ios_icon from '../custom_views/custom_icons/apple.svg'
import cross from '../custom_views/custom_icons/cross.svg'
import arrow_filter from '../custom_views/custom_icons/drop_arrow.svg'
import { getHttpParams } from '../custom_views/analyze_review/utils/Utils';
import { getFilteredEmpty } from '../custom_views/utils_android/Utils';
import BotOrderItem from './custom_views/BotOrderItem';
import { BotOrderStates, getIsOrderCompleted } from './utils/BotUtils';


var strings = getLocalizedStrings()
var filters = [strings.allFilters, strings.workingFilters, strings.finishedFilters, strings.canceledFilter]
var dropdownStyle = {
    margin : "35px 0px 0px -6px",
    top : "auto"
}
var arrowRotate = {
    transform: 'rotate(180deg)',
    marginTop: '6px'
}
var notDisplay = {display : 'none'}
var display = {display : 'block'}
var androidIconStyle = {marginTop : '2px', display : 'block'}

function BotOrders(props) {
    const [orders, setOrders] = useState([])
    const [filteredOrders, setFilteredOrders] = useState([])
    const [appsList, setAppsList] = useState([])
    const [pickedApp, setPickedApp] = useState({name : strings.allApps})
    const [isLoading, setLoading] = useState(true)
    const [isShowApps, setShowApps] = useState(false)
    const [isShowFilters, setShowFilters] = useState(false)
    const [emptyFilteredText, setEmptyFilteredText] = useState(strings.emptyFinishedOrders)
    const [filterPicked, setFilterPicked] = useState(strings.allFilters)
    const [orderPrices, setOrderPrices] = useState({})
    useEffect(() => {
       BotApi.getPrices((responseData) => {
            setOrderPrices(responseData)
       })
      
    }, [])
    const getMyApps = () => {
        var params = getRequestParams()
        fetch(getRequestUrl() + "getMyApps", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1) {
                var objectApp = {name : strings.allApps};
                responseData.apps.reverse()
                responseData.apps.unshift(objectApp)
                setAppsList(responseData.apps)           
            }
        })
    }
    const getMyOrders = () => {
        BotApi.getMyOrders((responseData) => {
            setLoading(false)
            const orders = responseData.orders.reverse()
            setOrders(orders)
            setFilteredOrders(orders)
        }, () => { setLoading(false) })
    }
    useEffect(() => {
        getMyApps()
        getMyOrders()
    }, [])
    const onAppPicked = (app) => {
        setPickedApp(app)
        onFilterPicked(filterPicked, 0, app)
    }
    const toogleApps = () => {
        if (appsList && appsList.length > 0){
            setShowApps(!isShowApps)
        }
    }
    const updateOrders = (order) => {
        const foundIndex = orders.findIndex((obj => obj.external_id == order.external_id))
        var mOrders = [...orders]
        
        if (foundIndex != -1){
            mOrders[foundIndex] = order
            setOrders(mOrders)
            setFilteredOrders(mOrders)
        }
        
    }
    const cancelOrder = (order) => {
        var mOrders = [...orders]
        const index = mOrders.map(e => e.external_id).indexOf(order.external_id)
        mOrders[index].state = BotOrderStates.waiting
        setOrders(mOrders)
    }
    const onFilterPicked = (filter, index, app) => {
        setFilterPicked(filter)
        var fullOrders = [...orders]
        var mPickedApp = (app ? app : pickedApp) 
        var mOrders = fullOrders.filter(function(order) {
            const isOrderCompleted = getIsOrderCompleted(order)
            var filtApps = ((mPickedApp.name != strings.allApps) ? (mPickedApp.bundle == order.bundle) : true);
            if (filter == strings.allFilters) {
                return filtApps
            } else if (filter == strings.workingFilters){
                return (!isOrderCompleted && order.state == BotOrderStates.started && filtApps)
            } else if (filter == strings.finishedFilters) {
                return (isOrderCompleted && filtApps)
            } else if (filter == strings.canceledFilter){
                return order.state == BotOrderStates.canceled || order.state == BotOrderStates.waiting
            }
        })
        setFilteredOrders(mOrders)
        setEmptyFilteredText(getFilteredEmpty(filter))
    }
    return (
        <div className="main_container order_main">
            <div className="display_block">
               
                <div className='create_order_just'>
                         <div className="main_title">
                            {strings.myOrders}
                            <span style={{opacity : 0.3, marginLeft : '8px'}}>{strings.menuWorld}</span>
                        </div>
                    {/* <div className='create_order_prices orders_bot_prices'>
                        <div className='create_order_j_emoji'>🤑</div>
                        <div className='create_order_j_flex'>
                            <div className='flex'><div className='android_ml' style={{marginLeft : '10px'}}>{strings.androindInstallsPrice}</div> <div className='order_price_bold order_price_android'>{strings.fromM} 14₽</div></div>
                            <div className='flex '><div className='ios_ml' style={{marginLeft : '10px'}}>{strings.iosInstallsPrice}</div> <div className='order_price_bold order_price_ios order_price_ios_world'>{strings.fromM} 21₽</div></div>
                        </div>
                    </div> */}
                    {orderPrices.PRICE_ANDROID != undefined && 
                            <div className='create_order_prices orders_bot_prices'>
                                <div className='create_order_j_emoji'>🤑</div>
                                <div className='create_order_j_flex'>
                                    <div className='flex'><div className='android_ml' style={{marginLeft : '10px'}}>{strings.androindInstallsPrice}</div> <div className='order_price_bold order_price_android'>{strings.fromM} {orderPrices.PRICE_ANDROID}₽</div></div>
                                    <div className='flex '><div className='ios_ml' style={{marginLeft : '10px'}}>{strings.iosInstallsPrice}</div> <div className='order_price_bold order_price_ios order_price_ios_world'>{strings.fromM} {orderPrices.PRICE_IOS}</div></div>
                                </div>
                            </div>}
                </div>
                {isLoading ? 
                    <Loader />
                : 
                (orders && orders.length > 0) ?
                    <div>
                        <div className="universal_order_filters">
                            <div className="filters">
                                <div onClick={() => setShowFilters(!isShowFilters)} className="button_choose_app">
                                    <div className="just_content w_100">
                                        <p className="button_choose_text btn_filter_choose_text">{filterPicked}</p>
                                        <img alt='' style={isShowFilters ? arrowRotate : {}} className="drop_arrow" src={arrow_filter}/>
                                    </div>
                                    {isShowFilters && <FilterDropdown filters={filters} onFilterPicked={onFilterPicked}/>}
                                </div>
                                    
                                <div onClick={toogleApps} className="button_choose_app left_32">
                                    <img style={pickedApp.icon ? display : notDisplay} className="img_choose_app left_16" src={pickedApp.icon} alt=""/>
                                    <img style={pickedApp.icon ? pickedApp.type_os == "android" ? androidIconStyle : display : notDisplay} className="img_choose_app_icon" src={pickedApp.type_os == "android" ? android_icon : ios_icon} alt=""/>
                                    <div className="just_content w_100">
                                        <p className="button_choose_text btn_filter_choose_text">{pickedApp.name}</p>
                                        <img alt='' style={isShowApps ? arrowRotate : {}} className="drop_arrow" src={arrow_filter}/>
                                    </div>
                                    {isShowApps && <AppsDropdown dropdownStyle={dropdownStyle} onAppPicked={onAppPicked} apps={appsList} />}
                                </div>

                            </div>
                            <Link to='/create_installs_order'><div className="yellow_btn empty_orders_create new_univ_order">{strings.createNewOrder}</div></Link>

                        </div>

                        <div className="orders_container">
                            {filteredOrders.length > 0 && filteredOrders.map((item, index) => (
                                <BotOrderItem updateOrders={updateOrders} count_money_r={props.count_money_r} index={index} order={item} key={index} getMyInfo={props.getMyInfo} cancelOrder={cancelOrder}/>
                            ))}
                        </div>
                    </div>
                : 
                    <EmptyOrders fromBots icon={empty_orders} text1={strings.emptyOrders} text2="" text3=""/>}
                    {filteredOrders.length == 0 && !isLoading && orders.length > 0 ? <EmptyStats icon={cross} text1={emptyFilteredText} text2="" text3=""/> : <div/>}
            </div>
            
            {orders.length > 0 && <div className="mobile_create_order_container">
                <a href='/create_installs_order'><div className="yellow_btn empty_orders_create mobile_univ_btn">{strings.createNewOrder}</div></a>
            </div>}
        </div> 
    )
}

export default BotOrders
