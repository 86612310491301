/* eslint-disable eqeqeq */
import moment from 'moment'
import React from 'react';
import { getEmail } from '../../custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';
import { isValidUrl } from '../../custom_views/utils_android/SocialHelper';
var strings = getLocalizedStrings()

export const filterAdminAppsOrders = (appsOrders, selectedEmail, filterPicked) => {
    var array = appsOrders.sort((a, b) => moment(b.date_run).unix() - moment(a.date_run).unix()).filter(item => selectedEmail == "" || item.owner_email == selectedEmail)
    return sortByOrdersFilters(array, filterPicked)
}
export const filterAdminUniversalOrders = (universalOrders, selectedEmail, filterPicked) => {
    var array = universalOrders.sort((a, b) => moment(b.create_date).unix() - moment(a.create_date).unix()).filter(item => selectedEmail == "" || item.owner_email == selectedEmail)
    var sortedArray = sortByOrdersFilters(array, filterPicked) 
    if (filterPicked == strings.notValidUrl){
        sortedArray = sortedArray.filter(f => !isValidUrl(encodeURI(f.url)))
    }
    if (filterPicked == strings.siteOrdersFilter){
        sortedArray = sortedArray.filter(f => f.name.includes("Найти сайт в поиске"))
    }
    if (filterPicked == strings.adminAppsFilter){
        sortedArray = sortedArray.filter(f => f.icon.includes("order_type_app_android.svg") || (f.bundle != undefined && f.bundle != ""))
    }
    if (filterPicked == strings.youtubeOrdersFilter){
        sortedArray = sortedArray.filter(f => f.icon.includes("order_type_youtube.svg"))
    }
    return sortedArray
}
export const sortByOrdersFilters = (array, filterPicked) => {
    if (filterPicked == strings.allApps){
        return array
    }
    return array.sort((a, b) => {
        if (filterPicked == strings.filterCheap){
            return a.count_money_r - b.count_money_r
        } else if (filterPicked == strings.filterExpensive){
            return b.count_money_r - a.count_money_r
        } else if (filterPicked == strings.filterPriceTask){
            return (b.count_price_customer_r ? (b.count_price_customer_r - a.count_price_customer_r) : (b.price_one_install - a.price_one_install))
        } 

    })
}
export const isAdmins = () => {
    return getEmail() == "zhenekvv@gmail.com" || getEmail() == "bumsun@yandex.ru"
}

export const getSameEmailOrders = (array) => {
    var sameEmailOrder = []
    array.forEach(element => {
        const foundIndex = sameEmailOrder.findIndex((obj => obj.email == element.owner_email))
        if (foundIndex == -1){
            sameEmailOrder.push({email : element.owner_email, count : 1})
        } else {
            sameEmailOrder[foundIndex].count = sameEmailOrder[foundIndex].count + 1
        }
    })
    return sameEmailOrder

}
export const getArrowRotate = () => {
    const arrowRotate = {
        transform: 'rotate(180deg)',
        marginTop: '6px'
    }
    return arrowRotate
}
export const getSameIp = (array) => {
    var sameEmailOrder = []
    array.forEach(element => {
        const foundIndex = sameEmailOrder.findIndex((obj => obj.ip == element.ip))
        if (foundIndex == -1){
            sameEmailOrder.push({ip : element.ip, count : 1})
        } else {
            sameEmailOrder[foundIndex].count = sameEmailOrder[foundIndex].count + 1
        }
    })
    return sameEmailOrder

}
export const AdminOrderStates = {
    apps: 0,
    universal: 1,
    social: 2,
    value : 3,
    buyers : 4,
    employers : 5,
}
export const AdminEmailTypes = {
    orders: 'orders',
    orders_youtube: 'orders_youtube',
    likedrom_orders: 'likedrom_orders',    
}
export const AdminEmployersTypes = {
    last_payouts: 'last_payouts',
    richest: 'richest',
   
}
export const MyImage = React.memo(function Image({ src, className }) {
    return <img alt="" src={src} className={className} />;
});