import React from 'react';
import close_order from '../../custom_icons/close_order.svg'

import { getLocalizedStrings } from '../../utils_android/LocalizedStrings';
import TagName from './add_info/TagName';

var strings = getLocalizedStrings()
class AddTagInfo extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
            return (
                <div className="tag_info">
                    <img onClick={this.props.toogleAddTags} src={close_order} className="close_order_icon hover" />
                    <p className="add_tag_info_title">{strings.addTagInfoTitle}</p>
                    <TagName onRenameConfirmed={this.props.onRenameConfirmed} onDeleteConfirmed={this.props.onDeleteConfirmed} bundle={this.props.bundle} infoCategoryChanged={this.props.infoCategoryChanged} toogleAddTags={this.props.toogleAddTags} review={this.props.review} categories={this.props.categories}/>
                </div>
            );         
    }
}
export default AddTagInfo;
