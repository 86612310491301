/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import moment from 'moment'
import more from '../../custom_views/custom_icons/more.svg';
import more_pressed from '../../custom_views/custom_icons/more_pressed.svg';
import AdminMoreTooltip from './AdminMoreTooltip';
import { AdminOrderStates } from '../utils/AdminUtils';
import { AppsType, getAppIconType, getAppUrl } from '../../custom_views/analyze_review/utils/Utils';

const AdminAppItem = ({ item, selectedOrderType, deleteOrder, blockOrder, setDescriptionDialog }) => {
    const [isShowMore, setShowMore] = useState(false)

    // const url = item.type_os == 'ios' ? 'https://itunes.apple.com/ru/app/id' + item.appstore_id : 'https://play.google.com/store/apps/details?id=' + item.bundle
    const url = getAppUrl(item.type_os, item.type_os == AppsType.ios ? item.appstore_id : item.bundle)
    const isBlocked = (!item.icon && selectedOrderType == AdminOrderStates.apps)

    return (
        <div style={isBlocked ? {border: '1px solid rgba(255, 98, 98, 0.7)'} : {}} className='admin_app_item'>
            {/* {item.icon && 
            <a rel="noopener noreferrer" target='_blank' href={url}>
                <div className="order_icon_container relative">
                    <img alt='' className="order_app_icon" src={item.icon}/>
                    <img alt='' className="order_type_icon" src={item.type_os == "android" ? android_order : ios_order}/>
                </div>
            </a>} */}
            <div className='admin_more_icons'>
                <div></div>
                <div className="relative">
                    <img alt='' onClick={() => setShowMore(!isShowMore)} className="admin_more_icon hover" src={isShowMore ? more_pressed : more}/>
                    {isShowMore && <AdminMoreTooltip item={item} toogleMore={() => setShowMore(false)} blockOrder={blockOrder} deleteOrder={deleteOrder} setDescriptionDialog={setDescriptionDialog ? () => setDescriptionDialog({isShow : true, info : item}) : undefined}/>}
                </div>
            </div>
            <div className="admin_apps_header">
                <div className="order_icon_container">
                    <img alt='app icon' className="order_app_icon" src={item.icon} />
                    {selectedOrderType == AdminOrderStates.apps && <img alt='icon type' className="order_type_icon" src={getAppIconType(item.type_os, true)} />}
                </div>
            </div>
            <div className='admin_item_bold' style={{marginBottom : '10px'}}>{item.name}</div>
            <div>{item._id}</div>
            <a rel="noopener noreferrer" target='_blank' href={url}>{item.bundle}</a>
            {item.url && <a rel="noopener noreferrer" target='_blank' href={item.url}><p className='admin_url' >{item.url}</p></a>}
            {item.count_price_customer_r && <div>Цена для заказчика : <span className='admin_item_bold'>{item.count_price_customer_r}₽</span></div>}
            {item.count_earning_employer_r && <div>Цена для исполнителя : <span className='admin_item_bold'>{item.count_earning_employer_r}₽</span></div>}
            <div style={{marginTop : '8px'}}>Остаток : <span className='admin_item_bold'>{item.count_money_r}₽</span></div>
            {item.price_one_install && <div>Одна установка : <span className='admin_item_bold'>{item.price_one_install}₽</span></div>}

            <div>Заказчик : {item.owner_email}</div>
            <div>Дата создания : {moment(item.date_run ? item.date_run : item.create_date).utc().local().format("DD.MM.YYYY HH:mm")}</div>
            {isBlocked && <div className='admin_item_bold'>Возможно, заблокирована?</div>}
        </div>
    )        
    
}
export default AdminAppItem

