import React from 'react';
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import ios_icon from '../custom_views/custom_icons/smile.svg'
var strings = getLocalizedStrings()
class IdfaItem extends React.Component {
    
    constructor(props){
        super(props)
       
    }
  
    render() {
        return (
            <div className="idfa_item_container">
                <img className="idfa_image" src={this.props.employer.photo_url == "" ? ios_icon : this.props.employer.photo_url}/>
                <div>
                    <div className="idfa_title_cont">
                        <p className="idfa_text idfa_title">{this.props.employer.nick_name}</p>
                        <p className="idfa_text">{this.props.employer.ip}</p>
                    </div>
                    {this.props.employer.google_user_id != "" && <p className="idfa_text_id">IDFA {this.props.employer.google_user_id}</p>}
                </div>
            </div>
            

        );
    }
}
export default IdfaItem;