/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import play from '../../custom_views/custom_icons/play.svg'
import pause from '../../custom_views/custom_icons/pause.svg'
import { getOrderInfoStatusText, getOrderInfoStatusColor, getStartDate, checkIfImageExists} from '../../custom_views/utils_android/Utils';
import { getAppIconType, getUrlParameter } from '../../custom_views/analyze_review/utils/Utils';
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';
import google_order from '../../custom_views/custom_icons/google_order.svg';
import yandex_order from '../../custom_views/custom_icons/yandex_order.svg';
import { SiteType } from '../utils/SiteUtils';
const strings = getLocalizedStrings()

function SiteOrderInfoHeader(props) {
    const [image, setImage] = useState("");

    useEffect(() => {
        // Эквивалент componentDidMount
        if (getUrlParameter('sdk_order_id') !== undefined) {
            props.toggleSdkControll(true)
        }

        // Эквивалент componentWillMount
        checkIfImageExists(props.order.icon, (result) => {
            setImage(result)
        })
    }, []); // Пустой массив зависимостей означает, что эффект выполнится только при монтировании

    const isOrderCompleted = (props.order.count_installs >= props.order.max_count_searches || props.order.count_money_r < props.order.one_perform_price_customer)

    return (
        <div>
            <div className="flex">
                <div className="order__info_icon_container">
                    <img alt='' className="order_app_icon" src={image}/>
                    <img alt='' className="order_type_icon" src={props.order.search_platform == SiteType.yandex ? yandex_order : google_order}/>
                </div>
                <div className="info_title_container">
                    <p className="info_title">{props.order.site_name}</p>
                    <div className="order_info_status_container">
                     
                        <div className="order_info_mob">
                            <p style={getOrderInfoStatusColor(props.order)} className="order_info_status">{getOrderInfoStatusText(props.order)}</p>
                            {(!isOrderCompleted && props.order.moderated == -1) ? <div/> : isOrderCompleted ? <p className="finished_task_status">{strings.finishedTaskStatus}</p> : 
                            <div onClick={props.setStateOrder} className="pause_play_info hover">
                                <img alt='' src={props.order.state == 0 ? play : pause}></img>
                                <p className="pause_play_info_text">{props.order.state == 0 ? strings.continuePlay : strings.pauseOrder}</p>
                            </div>}
                        </div>
                        
                        <p className="date_text_order_info">{strings.createdAt} {getStartDate(props.order)}</p>
                    </div>
                </div>
            </div>
        
        </div>
    );
}

export default SiteOrderInfoHeader;
