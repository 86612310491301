/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import arrow_filter from '../../custom_icons/drop_arrow.svg'
import x from '../../custom_icons/x.svg'
import { getLocalizedStrings } from '../../utils_android/LocalizedStrings';
import CountriesDropDown from './CountriesDropDown';
import { BotApi } from '../../../../api/BotApi';
import { getUrlParameter } from '../utils/Utils';

var strings = getLocalizedStrings()

var arrowRotate = {
    transform: 'rotate(180deg)'
    
}


function CityPicker(props) {
    
    const [searchText, setSearchText] = useState("")
    const [isShowFilter, setShowFilter] = useState(false)

    const cities = props.cities
    const setСities = props.setСities
    useEffect(() => {
        BotApi.getAllCities((responseData) => {
            var mCities = []
            responseData.cities.forEach(element => {
                mCities.push({name : element, is_selected : false})
            });
           
            mCities.splice(0, 0, {name : strings.allCities, is_selected : true})
            setСities(mCities)
        })
    }, [])
    const onTypePicked = (pickedCountry, index) => {
        var c = [...cities]
        const idx = c.indexOf(pickedCountry)
        c[idx].is_selected = !c[idx].is_selected
        if (idx == 0 && c[idx].is_selected){
            c.forEach(c => c.is_selected = (c.name == strings.allCities ? true : false))
        } else if (c[idx].is_selected) {
            c[0].is_selected = false
        }
        setСities(c)
        closeFilters()
    }
     const removeCountry = (pickedCountry) => {
        var c = [...cities]
        const idx = c.indexOf(pickedCountry)
        c[idx].is_selected = false
        setСities(c)
    }
    const closeFilters = () => {
        setShowFilter(false)
        setSearchText("")
    }
    const showFilters = () => {
        document.getElementById("text_field_countries").focus()
        setShowFilter(!isShowFilter)
    }
    var filteredCountries = cities.filter(c => (isShowFilter && searchText == "") ? true : searchText.length > 0 ? (c.name.toLowerCase().includes(searchText.toLowerCase())) : isShowFilter)
    var selectedCountries = cities.filter(c => c.is_selected)
    return (
        <div className="display_block">
                <div className="order_row">
                    <div style={{width : '-webkit-fill-available'}} className="input_container_border country_picker countries_picker_container">
                            <div className="flex just_content relative">
                                <input id="text_field_countries" className="countries_search_input" type='text' placeholder={strings.enterCity} value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
                                <div className='flex'>
                                    <div className='countries_input_line'></div>
                                    <img alt='' style={isShowFilter ? arrowRotate : {}} className="choose_drop_arrow countries_input_arrow" src={arrow_filter}/>
                                </div>
                                <div className='country_toggler hover' onClick={showFilters}></div>
                            </div>
                            {filteredCountries.length > 0 && <CountriesDropDown style={{maxHeight : '248px'}} setShowFilter={closeFilters} filters={filteredCountries} onFilterPicked={onTypePicked}/>}
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{strings.chooseCityDesc}</p>
                    </div>
                </div>
                {selectedCountries.length > 0 && 
                <div className='countries_picked_contaner'>
                     {selectedCountries.length > 0 && selectedCountries.map((tag, index) => (
                        <div className="hover" key={index}>
                            <div onClick={() => removeCountry(tag, index)} className="country_tag hover">
                                <div>{tag.name}</div>
                                <img alt='' src={x}/>
                            </div>
                        </div>
                    ))}
                </div>}
        </div>
           
    )

}
export default CityPicker;