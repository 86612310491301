import React from 'react';
import check from '../../../../pages_android/custom_views/custom_icons/check.svg'
class CountriesDropDown extends React.Component {
    constructor(props){
        super(props)
    }
    onTypePicked = (type, index) => {
        this.props.onFilterPicked(type, index)
    }
    onCloseFilters = () => {
        console.log("onClose")
        if (this.props.setShowFilter){
            this.props.setShowFilter(false)
        }
        
    }
    render(){
      
            return (
            
                    <div style={this.props.style} className="apps_dropdown filter_dropdown_countries choose_type_dropdown">
                        <div onClick={this.onCloseFilters} className="tooltip_bg"></div>
                        {this.props.filters.map((type, index) => (
                            <div onClick={() => this.onTypePicked(type, index)} className="flex apps_line just_content" type={type} key={index}>
                                {/* <img className="country_drop_down_icon" src={type.icon}/> */}
                                <p className="country_picker_text_drop">{type.name}</p>
                                {type.is_selected && <img alt='' src={check}/>}
                            </div>   
                        ))}
                        
                    </div>
             
            ); 
        
        
    }
}
export default CountriesDropDown;
