/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import {DebounceInput} from 'react-debounce-input';
import LimitsSwitch from './LimitsSwitch';
import { declension, getAnimationStyle } from './utils_android/Utils';
import { getUrlParameter } from './utils_android/UrlHelperAndroid';

var strings = getLocalizedStrings()
class Limits extends React.Component {
    constructor(props){
        super(props)
        this.fromBots = props.fromBots || props.fromSite
        this.state = {
            isLimitDayChecked : this.props.isLimitDayChecked,
            isLimitHourChecked : false,
            isLimitMinutesChecked : false,
            limitDay : 100,
            limitHour : 10,
            limitMinutes : 5
        }
        this.limitDayClicked = this.limitDayClicked.bind(this)
        this.limitHourClicked = this.limitHourClicked.bind(this)
        this.limitMinuteClicked = this.limitMinuteClicked.bind(this)
        this.limitDayChanged = this.limitDayChanged.bind(this)
        this.limitHourChanged = this.limitHourChanged.bind(this)
        this.limitMinutesChanged = this.limitMinutesChanged.bind(this)
    }
    componentDidUpdate(prevProps){
        if(this.props != prevProps){
            this.setState({isLimitDayChecked : this.props.isLimitDayChecked})
        }
    }
    componentDidMount(){
        var limit_day = getUrlParameter("limit_day")
        var limit_hour = getUrlParameter("limit_hour")
        var limit_minute = getUrlParameter("limit_minute")
        if (limit_day){
            this.setState({isLimitDayChecked : true, limitDay : parseInt(limit_day)})
            if (limit_hour){
                this.setState({isLimitHourChecked : true, limitHour : parseInt(limit_hour)})
            }
            if (limit_minute){
                this.setState({isLimitMinutesChecked : true, limitMinutes : parseInt(limit_minute)})
            }
            this.props.limitsChanged(true, parseInt(limit_day), (limit_hour ? true : false), parseInt(limit_hour), (limit_minute ? true : false), parseInt(limit_minute))
        }
    }
    limitDayClicked(){
        // if (event){
        //     var value = event.target.checked
        //     this.setState({isLimitDayChecked : value})
        //     if (!value){
        //         this.setState({isLimitHourChecked : false, isLimitMinutesChecked : false})
        //         this.props.limitsChanged(value, this.state.limitDay, false, this.state.limitHour, false, this.state.limitMinutes)
        //     } else {
        //         this.props.limitsChanged(value, this.state.limitDay, this.state.isLimitHourChecked, this.state.limitHour, this.state.isLimitMinutesChecked, this.state.limitMinutes)
        //     }
            
        // }
        this.setState({isLimitDayChecked : true})
        this.props.limitsChanged(true, this.state.limitDay, this.state.isLimitHourChecked, this.state.limitHour, this.state.isLimitMinutesChecked, this.state.limitMinutes)
    }
    limitHourClicked(event){
        if (event){
            var value = event.target.checked
            this.setState({isLimitHourChecked : value})
            if (!value){
                this.setState({isLimitMinutesChecked : false})
                this.props.limitsChanged(this.state.isLimitDayChecked, this.state.limitDay, value, this.state.limitHour, false, this.state.limitMinutes)
            } else {
                this.props.limitsChanged(this.state.isLimitDayChecked, this.state.limitDay, value, this.state.limitHour, this.state.isLimitMinutesChecked, this.state.limitMinutes)
            }
        }
    } 
    limitMinuteClicked(event){
        if (event){
            var value = event.target.checked
            this.setState({isLimitMinutesChecked : value})
            this.props.limitsChanged(this.state.isLimitDayChecked, this.state.limitDay, this.state.isLimitHourChecked, this.state.limitHour, value, this.state.limitMinutes)
        }
    } 
    limitDayChanged(event){
        if (event){
            var value = event.target.value
            this.setState({limitDay : value})
            this.props.limitsChanged(this.state.isLimitDayChecked, value, this.state.isLimitHourChecked, this.state.limitHour, this.state.isLimitMinutesChecked, this.state.limitMinutes)
        }
    }
    limitHourChanged(event){
        if (event){
            var value = event.target.value
            this.setState({limitHour : value})
            this.props.limitsChanged(this.state.isLimitDayChecked, this.state.limitDay, this.state.isLimitHourChecked, value, this.state.isLimitMinutesChecked, this.state.limitMinutes)
        }
    }
    limitMinutesChanged(event){
        if (event){
            var value = event.target.value
            this.setState({limitMinutes : value})
            this.props.limitsChanged(this.state.isLimitDayChecked, this.state.limitDay, this.state.isLimitHourChecked, this.state.limitHour, this.state.isLimitMinutesChecked, value)
        }
    }

    render() {
        const minInstallsCount = this.props.minInstallsCount
        const isMinInstallError = (minInstallsCount && this.state.limitDay < minInstallsCount)
        return (
            
            <div className="block">
                <div style={this.state.isLimitDayChecked ? {marginBottom : '16px'} : {marginBottom : '0px'}} className="order_row">
                    <div className="switch_container flex">
                        {/* <label className="switch">
                            <input type="checkbox" onChange={this.limitDayClicked} checked={this.state.isLimitDayChecked}/>
                            <div className="slider round"></div>
                        </label> */}
                        <div onClick={this.limitDayClicked} style={this.state.isLimitDayChecked ? {border : "1px solid #F5DB31"} : {}} className="radio_limits hover">
                            {this.state.isLimitDayChecked && <div className="radio_inside"/>}
                        </div>

                        <p className="text-pre-subscr">{strings.limits}</p>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{this.props.fromSite ? strings.siteDayLimitsDesc : strings.limitsDesc}</p>
                    </div>
                </div>

                <div style={this.state.isLimitDayChecked ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, display : 'none'}} className="display_block height_animation display_limits">
                    <div className="order_row bot_16">
                        <div style={isMinInstallError ? {display : 'block', border: '2px solid rgba(255, 98, 98, 0.5)'} : {display : 'block'}} className="input_container_border display_block">{strings.limitsDay}
                            <DebounceInput autoComplete="off" placeholder={strings.limitsDayPlaceholder} onChange={this.limitDayChanged} className="input_text_search" value={this.state.limitDay}/>
                        </div>
                        <div className="input_description_container">
                            <p className="input_description_text">{this.props.fromSite ? "" : minInstallsCount ? strings.limitsDayBotDesc + " " + minInstallsCount + " " + declension(minInstallsCount, strings.installs_1, strings.installs_2, strings.installs_5) : strings.limitsDayDesc}</p>
                        </div>
                    </div>
                    {isMinInstallError && <p className="installs_error">{strings.minInstallsCOunt} {minInstallsCount} {declension(minInstallsCount, strings.installs_1, strings.installs_2, strings.installs_5)}</p>}
                    {this.fromBots == undefined && <div>
                        <div style={this.state.isLimitHourChecked ? {marginBottom : '16px'} : {marginBottom : '0px'}} className="order_row">
                            <div className="switch_container flex">
                                <label className="switch">
                                    <input type="checkbox" onChange={this.limitHourClicked} checked={this.state.isLimitHourChecked}/>
                                    <div className="slider round"></div>
                                </label>

                                <p className="text-pre-subscr">{strings.limitsHour}</p>
                            </div>
                            <div className="input_description_container">
                                <p className="input_description_text">{strings.limitsHourDesc}</p>
                            </div>
                        </div>

                        <div style={this.state.isLimitHourChecked ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, display : 'none'}} className="display_block height_animation">
                            <div className="order_row bot_16">
                                <div style={{display : 'block'}} className="input_container_border display_block">{strings.limitsHourTitle}
                                        <DebounceInput autoComplete="off" placeholder={strings.limitsHourPlaceholder}
                                        onChange={this.limitHourChanged} className="input_text_search" value={this.state.limitHour}/>
                                </div>
                                <div className="input_description_container">
                                    <p className="input_description_text">{strings.limitsHourDesc2}</p>
                                </div>
                            </div>

                            {/* limit minutes start*/}
                            
                            <LimitsSwitch isLimitHourChecked={this.state.isLimitHourChecked} limitMinuteClicked={this.limitMinuteClicked} isLimitMinutesChecked={this.state.isLimitMinutesChecked} limitsMinute={strings.limitsMinute} limitsMinuteDesc={strings.limitsMinuteDesc}/>

                            <div style={this.state.isLimitMinutesChecked ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, display : 'none'}} className="display_block height_animation">
                                <div className="order_row bot_16">
                                    <div style={{display : 'block'}} className="input_container_border display_block">{strings.limitsMinuteTitle}
                                            <DebounceInput autoComplete="off" placeholder={strings.limitsMinutePlaceholder}
                                            onChange={this.limitMinutesChanged} className="input_text_search" value={this.state.limitMinutes}/>
                                    </div>
                                    <div className="input_description_container">
                                        <p className="input_description_text">{strings.limitsMinuteDesc2}</p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>}
                </div>

            </div>
           
        );
    }
}
export default Limits;