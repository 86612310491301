/* eslint-disable eqeqeq */
import React from 'react';
import { getToken, setCookie } from '../pages_android/custom_views/utils_android/CookieHelper';
import logo from '../components_android/icons_android/logo.png'
import './custom_views/pages_css/auth.css'
import AuthPage from './custom_views/AuthPage';
import RegisterPage from './custom_views/RegisterPage';
import SuccessRegister from './custom_views/dialogs/SuccessfullyRegistrated';
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import { getAuthUrlRedirect, getUrlParameter } from './custom_views/utils_android/UrlHelperAndroid';
var strings = getLocalizedStrings()
class Auth extends React.Component {
    constructor(props){
        super(props)
        var isShowAuth = getUrlParameter("isRegister")
        if (isShowAuth != undefined && isShowAuth != null){
            isShowAuth = false
        } else {
            isShowAuth = true
        }
        this.state = {
            isShowAuth : isShowAuth,
            isShowSuccessRegistrated : false
        }
       //this.getMyAds()
        var token = getToken()
        if (getUrlParameter("type")){
            setCookie("auth_type", getUrlParameter("type"))
        } 
        if (getUrlParameter("is_new")){

        } else if (token && token != "" ){
            window.location.href = getAuthUrlRedirect()
        }
    }
    onCanceled = () => {
        this.setState({isShowSuccessRegistrated : false})
    }
    showSuccessRegistated = () => {
        this.setState({isShowSuccessRegistrated : true})
    }
    showAuth = () => {
        this.setState({isShowAuth : true})
    }
    showrRegister = () => {
        this.setState({isShowAuth : false})
    }
    render() {
        return (
            <div className="register_container auth-table">
                <div ng-click="hideTooltips()">
                   
                    <img alt='' src={logo} align="center" className="logo-auth"/>

                    <div className="panel panel-login">
                        <div className="panel-heading">
                    
                            <div className="text_top_reg">
                             <a onClick={this.showAuth} href="#" className={this.state.isShowAuth ? "active_title" : "blue_title"} id="login-form-link">{strings.enter}</a>
                            </div>
                            <div className="text_top_reg">
                             <a  onClick={this.showrRegister} href="#" className={!this.state.isShowAuth ? "active_title" : "blue_title"} id="register-form-link">{strings.register}</a>
                            </div>
                      
                        
                        </div>
                        <div className="panel-body">
                            {this.state.isShowAuth ? <AuthPage /> : <RegisterPage showSuccessRegistated={this.showSuccessRegistated}/>}
                  
                    </div>
                    </div>
                </div>
          
                {this.state.isShowSuccessRegistrated ? <SuccessRegister title={strings.emailSent} onCanceled={this.onCanceled}/> : <div></div>}
            </div>
        );
    }
}
export default Auth;