import React from 'react';
import exchange_tooltip_ref from '../custom_views/custom_icons/exchange_tooltip_ref.svg'
class ReferalItem extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            isShowExchangeTooltip : false
        }
       
    }
    getDate = (referal) => {
        var date = referal.modified.substring(0, 10)
        var array = date.split("-")
        var dateString = array[2] + "." + array[1] + "." + array[0]
        return dateString
    }
    toggleExchangeTooltip = () => {
        this.setState({isShowExchangeTooltip : !this.state.isShowExchangeTooltip})
    }
    render() {
        return (
            <div className="ref_min">
                
                <div className="referals-list">
                    <div className="flex-cont">
                        <p className="text-referals text-referals-1">{this.getDate(this.props.referal)}</p>
                        <p className="text-referals text-referals-2">{this.props.referal.email_referal}</p>
                    </div>
                    <div className="relative">
                        {this.state.isShowExchangeTooltip && <div onClick={this.toggleExchangeTooltip} className="ref_exchange_tooltip">
                            <img src={exchange_tooltip_ref}/>
                            <p style={{top : '20px'}} className="exchange_tooltip_text">≈ ${(parseFloat(this.props.referal.earning) / parseFloat(this.props.exchangeRate)).toFixed(0)}</p>
                        </div>}
                        <p onClick={this.toggleExchangeTooltip} className="text-referals text-referals-3 hover">{this.props.referal.earning}</p>
                    </div>
                </div>
            </div>

        );
    }
}
export default ReferalItem;