import React from 'react';
import {DebounceInput} from 'react-debounce-input';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { getAnimationStyle } from '../utils_android/Utils';

var strings = getLocalizedStrings()
class AutoAnswerControll extends React.Component {
    constructor(props){
        super(props)
        
        this.autoAnswerClicked = this.autoAnswerClicked.bind(this)
        this.textChanged = this.textChanged.bind(this)
    }
    
    
    autoAnswerClicked(event){
        if (event){
            var value = this.props.autoAnswer
            value.isPicked = event.target.checked
            this.props.autoAnswerChanged(value)
        }
    }
    
   
    textChanged(event){
        if (event){
            var value = this.props.autoAnswer
            value.text = event.target.value
            if (value.text != undefined && value.text.includes("!!") && this.props.csv != undefined && this.props.csv.length > 0){
                var newVal = value.text.split("!!")
                if (newVal.length == 3 && this.props.csv[0][newVal[1].trim()] != undefined){
                    value.text = this.getCsvValues(newVal[1].trim())
                }
            }
            this.props.autoAnswerChanged(value)
        }
    }
    getCsvValues = (key) => {
        var values = ""
        this.props.csv.forEach(element => {
            if (element[key] != "" && element[key] != undefined){
                if (values == ""){
                    values = element[key]
                } else {
                    values += (";" + element[key])
                }
            }
        });
        
        return values
    }
   

    render() {
        return (
            <div style={{marginTop : '30px'}} className="block">
                <div style={this.props.autoAnswer.isPicked ? {marginBottom : '16px'} : {marginBottom : '0px'}} className="order_row">
                    <div className="switch_container switch_container_limit flex">
                        <label className="switch">
                            <input type="checkbox" onChange={this.autoAnswerClicked} checked={this.props.autoAnswer.isPicked} />
                            <div className="slider round"></div>
                        </label>
                        <p className="text-pre-subscr screen_title">{strings.univAutoAnswer}</p>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{strings.univAutoAnswerDescr}</p>
                    </div>
                </div>

                <div style={this.props.autoAnswer.isPicked ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, display : 'none'}} className="display_block height_animation">
                    <div className="order_row bot_16">
                        <div style={{display : 'block'}} className="universal_input display_block">{strings.univAutoAnswerInput}
                                <DebounceInput autoComplete="off" placeholder={strings.limitsDayPlaceholder}
                                onChange={this.textChanged} className="input_text_search top_8" value={this.props.autoAnswer.text}/>
                        </div>
                        <div className="input_description_container">
                            <p className="input_description_text">{strings.univAutoAnswerInputDescr}</p>
                        </div>
                    </div>
                </div>

            </div>
           
        );
    }
}
export default AutoAnswerControll;