import React from 'react';
import x_close from '../custom_icons/mdi_close.svg'
import qs from 'querystring'
import './dialogs.css'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { getRequestUrl } from '../utils_android/CookieHelper';
import { SHA256 } from '../utils_android/Sha256Helper';
var strings = getLocalizedStrings()
class ResetPassDialog extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            password : "",
            isShowError : false,
            isPasswordChanged : false
        }
    }
   
    sendForgot = () => {
        this.setState({isShowError : false})
        var params = {
            'old_pass_hash' : this.props.passHash,
            'new_pass_hash' : SHA256(this.state.password),
            'email' : this.props.email ? this.props.email : ""
        }
        
        fetch(getRequestUrl() + "setPassword", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
           if (responseData.response == 1){
               this.setState({isPasswordChanged : true})
            }
        })
    }
    onPassChanged = (event) => {
        if (event){
            this.setState({password : event.target.value})
        }
    }
    cancel = () => {
        this.props.closePassDialog()
    }
    render(){
      
            return (
                <div className="error_bg">
                    {!this.state.isPasswordChanged ?
                    <div className="error_banner_container">
                        <p className="title-forgot-pass">{strings.enterNewPass}</p>
                        <div>
                            <input style={{width : '-webkit-fill-available'}} onChange={this.onPassChanged} type="password" name="password" className="forgot_pass_input" value={this.state.password}/>
                        </div>
                        <div className="flex">
                            <button onClick={this.cancel} id="cancel-forgot-btn" className="form-control btn btn-login ">{strings.cancelEmail}</button>
                            <button onClick={this.sendForgot} id="login-submit-reg-btn-2" className="form-control btn btn-login forgot_pass_send">{strings.sendEmail}</button>
                        </div>
                    </div>  : 

                    <div className="error_banner_container">
                        <p className="title-forgot-pass">{strings.passwordChanged}</p>
                        <button onClick={this.cancel} id="login-submit-reg-btn-2" className="form-control btn btn-login forgot_pass_send">{strings.okChanged}</button>
                    </div>}
                </div>
            ); 
        
        
    }
}
export default ResetPassDialog;