/* eslint-disable eqeqeq */
import React from 'react';
import { getBotAppStatusText, getBotOrderInfoStatusColor, getBotOrderStartDate } from '../utils/BotUtils';
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';
import { AppsType, getAppIconType, getAppUrl } from '../../custom_views/analyze_review/utils/Utils';
var strings = getLocalizedStrings()

const BotOrderInfoHeader = ({ order }) => {
    const openStoreUrl = () => {
        // var link = order.type_os == 'android' ? 'https://play.google.com/store/apps/details?id=' + order.bundle : 'https://itunes.apple.com/ru/app/id' + order.appstore_id
        const link = getAppUrl(order.type_os, order.type_os == AppsType.ios ? order.appstore_id : order.bundle)
        window.open(link, '_blank')
    }
        return (
            <div>
                <div className="flex">
                    <div className="order__info_icon_container">
                        <img onClick={openStoreUrl} alt='' className="order_app_icon" src={order.app_icon}/>
                        <img alt='' className="order_type_icon" src={getAppIconType(order.type_os, true)}/>
                    </div>
                    <div className="info_title_container">
                        <p className="info_title">{order.app_name ? order.app_name : strings.appBlocked}</p>
                        <div className="order_info_status_container">
                            <div className="order_info_mob">
                                <p style={getBotOrderInfoStatusColor(order)} className="order_info_status">{getBotAppStatusText(order)}</p>
                                {/* {isOrderCompleted ? <p className="finished_task_status">{strings.finishedTaskStatus}</p> : 
                                <p className="finished_task_status">{strings.finishedTaskStatus}</p>} */}
                                {/* <div className="pause_play_info hover">
                                    <img alt='' src={play}></img>
                                    <p className="pause_play_info_text">{this.props.order.state == 0 ? strings.continuePlay : strings.pauseOrder}</p>
                                </div>} */}
                            </div>
                            
                            <p className="date_text_order_info">{strings.createdAt} {getBotOrderStartDate(order)}</p>
                        </div>
                    </div>
                </div>
                
            </div>
        )        
    
}
export default BotOrderInfoHeader
