import React from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import copy from '../custom_icons/copy.svg'
var strings = getLocalizedStrings()
class SdkCodeContainer extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowToast : false
        }
        this.copy = this.copy.bind(this)
    }
    copy(){
        // var textField = document.createElement('textarea')
        // textField.innerText = this.props.text
        // document.body.appendChild(textField)
        // textField.select()
        // document.execCommand('copy')
        // textField.remove()
        navigator.clipboard.writeText(this.props.text)
        this.setState({isShowToast : true})
        setTimeout(this.closeToast, 2000);
    }
    closeToast = () => {
        this.setState({isShowToast : false})
    }
    render(){
        const hasId = this.props.task_id != undefined
        return (
            <div style={hasId ? {padding : '10px 30px 14px 10px'} : {}} className="sdk_code_cont">
                {hasId && <p className="sdk_code_id">{this.props.task_id}</p>}
                <div className="top_order">
                    <p className="normal_text_14">{this.props.text}</p>
                    <img onClick={this.copy} style={hasId ? {top : '30px'} : {}} className="sdk_copy_code hover" src={copy}/>
                </div>
                <div style={this.state.isShowToast ? {opacity : 1, bottom : '24px'} : {}} className="sdk_copied">{strings.sdkCopied}</div>
            </div>
        );         
    }
}
export default SdkCodeContainer;
