var isShowLogs = true

export const error = (onError, responseData) => {
    if(onError){
        onError(responseData)
    }
}
export const log = (text, object) => {
    if (isShowLogs){
        console.log(text, object)
    }
}
export const onResponse = (responseData, onSuccess, onError) => {
    if (responseData.response > 0){
        onSuccess(responseData)
    } else {
        error(onError, responseData)
    }
}