import React from 'react';
import './dialogs.css'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class SdkMoneyDialog extends React.Component {
    constructor(props){
        super(props)

    }
    toggleCloseDialog = () => {
        this.props.toggleCloseDialog()
    }
   
    render(){
        return (
            <div className="error_bg">
                <div className="error_banner_container sdk_dialog_container">
                    <p className="sdk_money_text">{strings.sdkMoneyDialog}</p>  
                    <div className="sdk_money_btn">
                        <p onClick={this.toggleCloseDialog} className="btn_red sdk_money_btn">{strings.understood}</p>                        
                    </div>
                </div>  
            </div>
        ); 
    }
}
export default SdkMoneyDialog;