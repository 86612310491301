/* eslint-disable eqeqeq */
import React from 'react';
import play from '../custom_icons/play.svg'
import pause from '../custom_icons/pause.svg'
import help_circle from '../custom_icons/help_circle.svg'
import { getOrderInfoStatusText, getOrderInfoStatusColor, getStartDate, checkIfImageExists} from '../utils_android/Utils';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { AppsType, getAppIconType, getAppUrl, getUrlParameter } from '../analyze_review/utils/Utils';
var strings = getLocalizedStrings()
class OrderInfoHeader extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            image : ""
        }
    }
    componentDidMount(){
        if (getUrlParameter('sdk_order_id') != undefined){
            this.props.toggleSdkControll(true)
        }
    }
    componentWillMount() {
        checkIfImageExists("https://" + this.props.order.icon, (result) => {
            this.setState({image : result})
        })
    }
    openStoreUrl = () => {
        const order = this.props.order
        // var link = order.type_os == 'android' ? 'https://play.google.com/store/apps/details?id=' + order.bundle : 'https://itunes.apple.com/ru/app/id' + order.appstore_id
        const link = getAppUrl(order.type_os, order.type_os == AppsType.ios ? order.appstore_id : order.bundle)
        window.open(link, '_blank')
    }
    render(){
        const isOrderCompleted = (this.props.order.count_installed >= this.props.order.max_count_installs || this.props.order.count_money_r < this.props.order.price_one_install)
        const isBlocked = (this.props.order.moderated == -1 && !isOrderCompleted)
        
        const isSdkTask = this.props.order.sdk_tasks.length > 0
        return (
            <div>
                <div className="flex">
                    <div className="order__info_icon_container">
                        <img alt='app_icon' onClick={this.openStoreUrl} className="order_app_icon" src={this.state.image}/>
                        <img alt='app_type' className="order_type_icon" src={getAppIconType(this.props.order.type_os, true)}/>
                    </div>
                    <div className="info_title_container">
                        <p className="info_title">{this.props.order.name ? this.props.order.name : strings.appBlocked}</p>
                        <div className="order_info_status_container">
                            {(isSdkTask && !this.props.order.is_activate_sdk) ? 
                                <div className="flex sdk_enable_bg_red">
                                    <p className="sdk_enable_sdk_text">{!this.props.isGpUpdated ? strings.sdkDoSDK : strings.sdkUpdateGP}</p>
                                    <img alt='help_circle' className="sdk_enable_sdk_icon" src={help_circle}/>
                                </div>
                            : 
                            <div className="order_info_mob">
                                <p style={getOrderInfoStatusColor(this.props.order)} className="order_info_status">{getOrderInfoStatusText(this.props.order)}</p>
                                {(!isOrderCompleted && this.props.order.moderated == -1) ? <div/> : isOrderCompleted ? <p className="finished_task_status">{strings.finishedTaskStatus}</p> : 
                                <div onClick={this.props.setStateOrder} className="pause_play_info hover">
                                    <img alt='play-pause' src={this.props.order.state == 0 ? play : pause}></img>
                                    <p className="pause_play_info_text">{this.props.order.state == 0 ? strings.continuePlay : strings.pauseOrder}</p>
                                </div>}
                            </div>}
                            
                            <p className="date_text_order_info">{strings.createdAt} {getStartDate(this.props.order)}</p>
                        </div>
                    </div>
                </div>
                {isSdkTask && 
                <div>
                    <div className="flex sdk_controlls">
                        <p onClick={() => this.props.toggleSdkControll(false)} className={this.props.isShowSdkControll ? "sdk_title_controll sdk_title_controll_margin hover" : "sdk_title_controll sdk_title_controll_margin sdk_title_controll_enabled hover"}>{strings.sdkAbout}</p>
                        <p onClick={() => this.props.toggleSdkControll(true)} className={!this.props.isShowSdkControll ? "sdk_title_controll hover" : "sdk_title_controll sdk_title_controll_enabled hover"}>{strings.sdkControll}</p>
                    </div>
                    <div className="toolbar_line_container">
                        <div style={!this.props.isShowSdkControll ? {marginLeft : '0px'} : {marginLeft : '84px', width : '130px'}} className="toolbar_line"/>
                    </div>
                </div>
                }
                {(isBlocked) && <div className='order_info_block_reason'>{strings.orderBlockReason + " " + (this.props.order.reason_block ? this.props.order.reason_block : strings.orderBlockReasonNot)}</div>}
            </div>
        );         
    }
}
export default OrderInfoHeader;
