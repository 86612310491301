/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../../css_android/dateRangeStyle.css'; // theme css file
import { DateRange } from 'react-date-range';
import ruLocale from 'date-fns/locale/ru'
import moment from 'moment';
import { AdminApi } from '../../api/AdminApi';
import { formateDate } from '../custom_views/analyze_review/utils/DateHelper';
import { declension } from '../custom_views/utils_android/Utils';

const AdminValue = ({ }) => {
    const [payments, setPayments] = useState([])
    const [payouts, setPayouts] = useState([])
    const [adsPayouts, setAdsPayouts] = useState(0)
    const [instarlikePayments, setInstarlikePayments] = useState([])
    const [iosAppPayments, setIosAppPayments] = useState([])
    const [isShowDates, setShowDates] = useState(false)
    const [priceAdsPerDay, setPriceAdsPerDay] = useState(1000)
    const [likedromPrice, setLikedromPrice] = useState(1500)
    const [selectionRange, setSelectionRange] = useState({
            startDate: new Date(),
            endDate: new Date(moment().add(1, "days")),
            key: 'selection',
        })
    useEffect(() => {
        if (selectionRange && !isShowDates && selectionRange.startDate != selectionRange.endDate){
            getStatisticPayments()
            getStatisticPayouts()
            getYaDirrectStat()
        } else if (selectionRange.startDate == selectionRange.endDate && !isShowDates){
            var mSel = {...selectionRange}
            mSel.endDate = new Date(moment(selectionRange.startDate).add(1, "days"))
            setSelectionRange(mSel)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectionRange, isShowDates])
    const getStatisticPayments = () => {
        const start_date = moment(selectionRange.startDate).format("YYYY.MM.DD")
        var end_date = moment(selectionRange.endDate).format("YYYY.MM.DD")
        AdminApi.getStatisticPayments(start_date, end_date, (responseData) => {
            setPayments(responseData.result)
            if (responseData.instarlike){
                setInstarlikePayments(responseData.instarlike)
            }
            if (responseData.insta_ios_app){
                setIosAppPayments(responseData.insta_ios_app)
            }
            console.log("getStatisticPayments = ", responseData)
        }, () => {})
    }
    const getStatisticPayouts = () => {
        const start_date = moment(selectionRange.startDate).format("YYYY.MM.DD")
        var end_date = moment(selectionRange.endDate).format("YYYY.MM.DD")
        AdminApi.getStatisticPayouts(start_date, end_date, (responseData) => {
            setPayouts(responseData.result)
        }, () => {})
    }
    const getYaDirrectStat = () => {
        const start_date = moment(selectionRange.startDate).format("YYYY-MM-DD")
        var end_date = moment(selectionRange.endDate).subtract(1, "days").format("YYYY-MM-DD")
        AdminApi.getYaDirrectStat(start_date, end_date, (responseData) => {
            setAdsPayouts(responseData.result)
            const countDays = moment(selectionRange.endDate).diff(moment(selectionRange.startDate), 'days')
            setPriceAdsPerDay(parseInt(responseData.result / countDays))
        }, () => {})
    }
    const handleSelectRange = (ranges) => {
        var mRanges = {...ranges.selection}
        if (mRanges.startDate == mRanges.endDate){
            //setSelectionRange(ranges.selection)
            //mRanges.endDate = moment(mRanges.endDate).add(1, "days")
        }
        setSelectionRange(mRanges)
    }
    const getSumHTML = (array, title) => {
        return(
            <div style={{margin : '0 24px 24px 0'}}>
                <div className='admin_payouts_title'>{title}:</div>
                {(array && array.length > 0) ?
                    <div>
                        <div>Сумма : <span className='admin_item_bold'>{parseInt(array[0].SUM)}₽</span></div>
                        <div>Количество : <span className='admin_item_bold'>{array[0].COUNT}</span></div>
                    </div>
                :
                    <div>
                        <div>Сумма : <span className='admin_item_bold'>0₽</span></div>
                    </div>
                }
            </div>
        )
    }
    const priceAdsPerDayChanged = (event) => {
        if (event){
            var value = event.target.value
            setPriceAdsPerDay(value)
        }
    }
    const priceLikedromPerDayChanged = (event) => {
        if (event){
            var value = event.target.value
            setLikedromPrice(value)
        }
    }
    const countDays = moment(selectionRange.endDate).diff(moment(selectionRange.startDate), 'days')

    const getSum = () => {
        return parseInt((payments[0].SUM - payouts[0].SUM) * 0.9) - countDays * priceAdsPerDay
    }
    const getInstaSum = () => {
        return parseInt((instarlikePayments[0].SUM + iosAppPayments[0].SUM) * 0.9) - countDays * likedromPrice
    }
    const koefJulia = 0.25
    const koefZhenyaAndVova = (1 - koefJulia) / 2
    const instaSum = (instarlikePayments.length > 0 && iosAppPayments.length > 0) ? parseInt(getInstaSum() / 3) : 0
    const vovaZhenyaUpmob = (payments.length > 0 && payouts.length > 0) ? parseInt(getSum() * koefZhenyaAndVova) : 0
    const juliaUpmob = (payments.length > 0 && payouts.length > 0) ? parseInt(getSum() * koefJulia) : 0
    const sumAll = instaSum * 3 + vovaZhenyaUpmob * 2 + juliaUpmob
    const serverPrice = 1500 * countDays
    return (
        <div>
            <div style={{marginTop : '24px'}} className='flex'>
                <div className="filter_dates admin_period_picker">
                    <p className="black_text">Период:</p>
                    <p onClick={() => setShowDates(!isShowDates)} className="blue_text_filter blue_hover">{formateDate(selectionRange)}</p>
                    {isShowDates && <div onClick={() => setShowDates(false)} className="tooltip_bg_dates"></div>}
                    {isShowDates && <DateRange locale={ruLocale} ranges={[selectionRange]} onChange={handleSelectRange}/>}
                </div>
                <div>
                    <div style={{marginLeft : '16px'}}>Начало отсчета Upmob 14.08.2024</div>
                    <div style={{marginLeft : '16px'}}>Начало отсчета Накруток 23.08.2024</div>
                </div>
            </div>
            <div className='flex'>
                {getSumHTML(payments, "Upmob")}
                {getSumHTML(instarlikePayments, "Instarlike")}
                {getSumHTML(iosAppPayments, "iOS прила")}
                {getSumHTML(payouts, "Выплаты")}
            </div>
            {(payments.length > 0 && payouts.length > 0) && 
            <div>
                {/* <input autoComplete="off" className='admin_price_ads' type="number" onChange={priceAdsPerDayChanged} value={priceAdsPerDay}/> */}
                <div className='admin_item_bold' style={{margin : '0 0 -16px 0'}}>Доход с Upmoba</div><br/>
                <div style={{marginBottom : '12px'}}>Чистый доход разница*(0.9) <span className='admin_item_bold'>{parseInt((payments[0].SUM - payouts[0].SUM) * 0.9)}₽</span> - выбранные дни(<span>{countDays}</span>) * <span className='admin_item_bold'>{priceAdsPerDay}₽</span> (реклама) = <span className='admin_item_bold'>{parseInt(getSum())}₽</span></div>
                <div>Доход Вовы : <span className='admin_item_bold'>{vovaZhenyaUpmob}₽</span> всего / <span className='admin_item_bold'>{parseInt(vovaZhenyaUpmob / countDays)}₽</span> в день</div>
                <div>Доход Жени : <span className='admin_item_bold'>{vovaZhenyaUpmob}₽</span> всего / <span className='admin_item_bold'>{parseInt(vovaZhenyaUpmob / countDays)}₽</span> в день</div>
                <div>Доход Юли : <span className='admin_item_bold'>{juliaUpmob}₽</span> всего / <span className='admin_item_bold'>{parseInt(juliaUpmob / countDays)}₽</span> в день</div>                
            </div>}
            {(instarlikePayments.length > 0 && iosAppPayments.length > 0) && 
                <div>
                    <div className='admin_item_bold' style={{margin : '24px 0 -16px 0'}}>Доход с накруток инсты</div><br/>
                    <div style={{marginBottom : '12px'}}>Чистый доход сумма*(0.9) - выбранные дни(<span>{countDays}</span>) *<input autoComplete="off" className='admin_price_ads' type="number" onChange={priceLikedromPerDayChanged} value={likedromPrice}/>(лайкдром) = <span className='admin_item_bold'>{parseInt(getInstaSum())}₽</span></div>
                    <div>Доход Вовы : <span className='admin_item_bold'>{instaSum}₽</span> всего / <span className='admin_item_bold'>{parseInt(instaSum / countDays)}₽</span> в день</div>
                    <div>Доход Жени : <span className='admin_item_bold'>{instaSum}₽</span> всего / <span className='admin_item_bold'>{parseInt(instaSum / countDays)}₽</span> в день</div>
                    <div>Доход Юли : <span className='admin_item_bold'>{instaSum}₽</span> всего / <span className='admin_item_bold'>{parseInt(instaSum / countDays)}₽</span> в день</div>
                </div>
            }
            <div>
                <div className='admin_item_bold' style={{margin : '24px 0 -16px 0'}}>Общий доход за {countDays + " " + declension(countDays, 'день', 'дня', 'дней')} - оплата серваков(500$ в мес.)</div><br/>
                <div>Всего : <span className='admin_item_bold'>{parseInt(sumAll - serverPrice)}₽</span> всего / <span className='admin_item_bold'>{parseInt((sumAll - serverPrice) / countDays)}₽</span> в день</div>
                <div>Всего для Вовы : <span className='admin_item_bold'>{parseInt(instaSum + vovaZhenyaUpmob - serverPrice / 3)}₽</span> всего / <span className='admin_item_bold'>{parseInt(instaSum / countDays + vovaZhenyaUpmob / countDays - serverPrice / countDays / 3)}₽</span> в день</div>
                <div>Всего для Жени : <span className='admin_item_bold'>{parseInt(instaSum + vovaZhenyaUpmob - serverPrice / 3)}₽</span> всего / <span className='admin_item_bold'>{parseInt(instaSum / countDays + vovaZhenyaUpmob / countDays - serverPrice / countDays / 3)}₽</span> в день</div>
                <div>Всего для Юли : <span className='admin_item_bold'>{parseInt(instaSum  + juliaUpmob - serverPrice / 3)}₽</span> всего / <span className='admin_item_bold'>{parseInt(instaSum / countDays + juliaUpmob / countDays - serverPrice / countDays / 3)}₽</span> в день</div>

            </div>
        </div>
    )        
    
}
export default AdminValue

