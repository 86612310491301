import React from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class EmptyOrders extends React.Component {
    constructor(props){
        super(props)
    }
    createOrder = () => {
        if (this.props.fromSite){
            window.location.href = '/create_order_site'
            return
        }
        if (this.props.fromBots){
            window.location.href = '/create_installs_order'
            return
        }
        if (window.location.href.includes("universal_orders")){
            window.location.href = '/create_universal'
        } else {
            window.location.href = '/neworder'
        }
        
    }
    render() {
        return (
            <div>
                <div className="icon-placeholder-bank no-stats-container">
                    <img className="center stats_img" src={this.props.icon}/>
                </div>
                <div className="empty_order_text center">
                    <p className="center stats_text">{this.props.text1}<br/>{this.props.text2}<br/>{this.props.text3}</p>
                </div>
                <div onClick={this.createOrder} className="yellow_btn empty_orders_create">{strings.createNewOrder}</div>
            </div>
            
           
        );
    }
}
export default EmptyOrders;
