import React from 'react';
import '../custom_views/custom_style/checkbox.css'
var colorBg = {
    background : '#F6F9FB'
}
var whiteBg = {
    background : '#FFFFFF'
}

class KeywordItem extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            checked : this.props.checkedKeywords.includes(this.props.keyword.keyword)
        }
        this.onChecked = this.onChecked.bind(this)
    }
    getKeywordsStyle = () => {
        if (this.props.index & 1) {
            return whiteBg
          } else {
            return colorBg
          }
    }
    onChecked(event){
        if (event){
            var checked = event.target.checked
            this.setState({checked : checked})
            this.props.keywordChecked(this.props.index, this.props.keyword.keyword, this.props.keyword.position)
        }
    }
    componentDidUpdate(prevProps){
        if (prevProps != this.props){
            this.setState({checked : this.props.checkedKeywords.includes(this.props.keyword.keyword)})
        }
    }
    
    
    render() {
        
        return (
            <div className="keyword_line_bg" style={this.getKeywordsStyle()}>
                {/* <Checkbox
                    checked={this.state.checked}
                    onChange={this.onChecked}
                    style ={{
                        color: "rgba(0, 134, 193, 0.9)"
                      }}
                /> */}
                <div>
                    <input style ={{color: "rgba(0, 134, 193, 0.9)"}} checked={this.state.checked} onChange={this.onChecked} type="checkbox" id="horns" name="horns" />
                </div>
                <div className="keyword_text_cont">
                    <p className="text-keywords-first">{this.props.keyword.keyword}</p>
                    <p className="text_keywords_position">{this.props.keyword.position}</p>
                </div>
            </div>
        );
    }
}
export default KeywordItem;