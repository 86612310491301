import React from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import sdk_enabled from '../custom_icons/sdk_enabled.svg'
import { getRequestUrl, getRequestParams } from '../utils_android/CookieHelper';
import qs from 'querystring'
import InputLoader from '../utils_android/InputLoader';
var strings = getLocalizedStrings()
class SdkInfoInstructionHeader extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isAppInfoLoading : false,
        }
    }
    componentDidMount(){
        if (!this.props.order.is_activate_sdk){
            this.checkUpdateAndroidApp()
        }
    }
    checkUpdateAndroidApp = () => {
        this.setState({isAppInfoLoading : true})
        var params = getRequestParams()
        params.order_id = this.props.order._id
        fetch(getRequestUrl() + "checkUpdateAndroidApp", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if(responseData.response == 1 && responseData.url != undefined && responseData.url != ""){
                this.props.gpUpdated()
                this.props.updateDonwloadLink(responseData.url)
            }
            this.setState({isAppInfoLoading : false})
        })
    }
    render(){
        const isEnabledSdk = this.props.order.is_activate_sdk
        const isUpdatedGP = this.props.order.is_activate_sdk ? true : this.props.isGpUpdated
        return (
            <div className="flex">
                <div className="sdk_info_desc_container">
                    <div  className="sdk_info_item">
                        {isUpdatedGP ? 
                             <img className="sdk_enabled_img" src={sdk_enabled}/>
                             : <div className="sdk_number_container sdk_item_number">
                            <p className="sdk_center sdk_number_text sdk_number_regular">1</p>
                        </div>}
                        <div>
                            <p className="sdk_item_title">{strings.sdkEnableTitle}</p>
                            <p className="sdk_item_desc">{strings.sdkInstructionDesc}</p>
                        </div>
                        {(this.state.isAppInfoLoading ? <InputLoader style={{left : 'unset', bottom : 'unset'}}/> : 
                        <p className={isUpdatedGP ? "sdk_waiting sdk_enabled_text_green" : "sdk_waiting"}>{isUpdatedGP ? strings.sdkEnabledText : strings.sdkWaiting}</p>)}
                        
                    </div>
                    <div style={isUpdatedGP ? {} : {opacity : 0.2}} className="sdk_info_item">
                        <div className="flex">
                            {isEnabledSdk ? 
                                <img className="sdk_enabled_img" src={sdk_enabled}/>
                                : <div className="sdk_number_container sdk_item_number">
                                <p className="sdk_center sdk_number_text sdk_number_regular">2</p>
                            </div>}
                            <div>
                                <p className="sdk_item_title">{strings.sdkGoogleUpdate}<br/>{strings.sdkGoogleUpdate2}</p>
                                <p className="sdk_item_desc">{strings.sdkGoogleUpdateDesc}</p>
                            </div>
                        </div>
                        <p className={isEnabledSdk ? "sdk_waiting sdk_enabled_text_green" : "sdk_waiting"}>{isEnabledSdk ? strings.sdkEnabledText : strings.sdkWaiting}</p>
                    </div>
                    
                </div>
            </div>
        );         
    }
}
export default SdkInfoInstructionHeader;
