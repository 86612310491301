/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import {DebounceInput} from 'react-debounce-input';
import { getUrlParameter } from './utils_android/UrlHelperAndroid';
import { declension } from './utils_android/Utils';

var strings = getLocalizedStrings()

class InstallsCount extends React.Component {
    constructor(props){
        super(props)
        var preOrderInstallCount = getUrlParameter("count_installs")

        this.state = {
            installCount : (preOrderInstallCount ? preOrderInstallCount : 100)
        } 
        this.installCountChanged = this.installCountChanged.bind(this)
        if(preOrderInstallCount){
            this.props.installCountChanged(parseInt(preOrderInstallCount))
        }
    }
    installCountChanged(event){
        if (event){
            var value = event.target.value
            this.setState({installCount : value})
            this.props.installCountChanged(value)
        }
    }
    
    render() {
        const countKeywords = this.props.countKeywords ? this.props.countKeywords : 1
        const minInstallsCount = (this.props.minInstallsCount ? this.props.minInstallsCount * countKeywords : 10)
        return (
            <div className="display_block">
                    <div className="order_row">
                        <div style={this.state.installCount < minInstallsCount ? {display : 'block',border: '2px solid rgba(255, 98, 98, 0.5)'} : {display : 'block', border: '2px solid #e4ecef'}} className="input_container_border display_block">{strings.installCount}
                                <DebounceInput debounceTimeout={500} autoComplete="off" type="number" onChange={this.installCountChanged} className="input_text_search" placeholder={strings.installCountPlaceholder} value={this.state.installCount}/>
                        </div>
                        <div className="input_description_container">
                            <p className="input_description_text">{this.props.minInstallsCount ? (strings.installCountBotDesc + " " + this.props.minInstallsCount + " " + declension(this.props.minInstallsCount, strings.installs_1, strings.installs_2, strings.installs_5) + " " + strings.installCountBotDesc2) : strings.installCountDesc}</p>
                        </div>
                    </div>
                    {this.state.installCount < minInstallsCount && <p className="installs_error">{strings.minInstallsCOunt} {minInstallsCount} {declension(minInstallsCount, strings.installs_1, strings.installs_2, strings.installs_5)} {this.props.isProgressOrder ? strings.progressDescriptionError : ""}</p>}
                    {(this.props.type_os == "ios" && this.props.fromBots == undefined && this.state.installCount >= 800) && <p className="installs_error">{strings.installsLimit}</p>}
            </div>
           
        );
    }
}
export default InstallsCount;