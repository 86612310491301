/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import moment from 'moment'

const AdminReferalItem = ({ item }) => {
    const isBlocked = false
    return (
        <div style={isBlocked ? {border: '1px solid rgba(255, 98, 98, 0.7)'} : {}} className='admin_app_item'>
             <div>Имя : <span className='admin_item_bold'>{item.nick_name}</span></div>
            <div>_id : <span className='admin_item_bold'>{item._id}</span></div>
            <div>IP адрес : <span className='admin_item_bold'>{item.ip}</span></div>
        </div>
    )        
    
}
export default AdminReferalItem

