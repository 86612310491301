import React from 'react';
import arrow_dropdown from '../pages_android/custom_views/analyze_review/icons/arrow_dropdown.svg'
import ReviewItem from './custom_views/analyze_review/list_items/ReviewItem';
import Filters from './custom_views/analyze_review/customViews/Filters';
import Toolbar from './custom_views/analyze_review/customViews/Toolbar';
import { filterByText, filterByParams } from './custom_views/analyze_review/utils/FilterHelper';
import AddTag from './custom_views/analyze_review/customViews/tooltips/AddTag';
import Loader from './custom_views/analyze_review/customViews/Loader';
import './custom_views/analyze_review/analize_review.css'
import { getRequestParams, getRequestUrl, getEmail } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getHttpParams } from './custom_views/analyze_review/utils/Utils';
import NewOrderSearchLine from './custom_views/NewOrderSearchLine';
import aso_icon from './custom_views/custom_icons/pick_app.svg'
import aso from './custom_views/custom_icons/aso.svg'
import cross_icon from './custom_views/custom_icons/cross.svg'
import filter from './custom_views/analyze_review/icons/filter.svg'
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import EmptyStats from './custom_views/empty_views/EmptyStats';
import AddTagInfo from './custom_views/analyze_review/customViews/AddTagInfo';
import book from '../pages_android/custom_views/custom_icons/book.svg'

var strings = getLocalizedStrings()
class Reviews extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            reviews : [],
            filteredReviews : [],
            categories : [],
            isLoading : false,
            isShowAddTags : false,  
            addTagReview : {},
            isIos : false,
            isShowFilter : false
        }
        
        this.isMobile = (window.innerWidth <= 980)
        this.ratingPickedFilter = -1 
        this.categoriesPickedFilter = []
        this.teamPickedFilter = []
        this.bundle = ""
        this.removeCategory = this.removeCategory.bind(this);
        this.toogleAddTags = this.toogleAddTags.bind(this);
        this.infoCategoryChanged = this.infoCategoryChanged.bind(this);
        this.ratingPicked = this.ratingPicked.bind(this);
        this.filter = this.filter.bind(this);
        this.categoryPicked = this.categoryPicked.bind(this);
        this.teamPicked = this.teamPicked.bind(this);
        this.appPicked = this.appPicked.bind(this)
        this.inputSearchChanged = this.inputSearchChanged.bind(this)
        this.addTagClicked = this.addTagClicked.bind(this)
        this.toogleFilters = this.toogleFilters.bind(this)
        this.disableFilters = this.disableFilters.bind(this)
        this.onRenameConfirmed = this.onRenameConfirmed.bind(this)
        this.showReviewBlog = this.showReviewBlog.bind(this)
    }
    removeCategory(category, index){
        // var filteredReviews = this.state.filteredReviews
        // var filteredReview = filteredReviews[index]
        // filteredReview[category._id] = 0

        // var reviews = this.state.reviews
        // const indexReview = reviews.indexOf(category)
        // if (indexReview > -1) {
        //     reviews[indexReview][category._id] = 0
        // }
        
        var categories = this.state.categories
        const indexCategory = categories.indexOf(category)
        if (indexCategory > -1) {
            categories.splice(indexCategory, 1);
        }
        this.setState({categories : categories})
    }
    onRenameConfirmed(category, index){
        var categories = this.state.categories
        categories[index] = category
       
        this.setState({categories : categories})
    }
    componentDidMount(){
        window.document.getElementById("vk_community_messages").style.display = "none";
    }
    getParams = () => {
        var params = getRequestParams()
        params.bundle = this.bundle
        params.owner_email = getEmail()
        return params
        
    }
    getReviews = () => {
        this.setState({isLoading : true, isIos : false})
        fetch(getRequestUrl() + "getReviewsForSber", getHttpParams(this.getParams())).then((response) => response.json())
        .then((responseData) => {
            if (responseData.out != undefined){
                this.setState({reviews : responseData.out, filteredReviews : responseData.out, isLoading : false})
                this.filter()
            } else {
                this.setState({isLoading : false, isIos : true})
            }            
        })
    }
    getConfigReviews = () => {
        fetch(getRequestUrl() + "getConfigReviews", getHttpParams(this.getParams())).then((response) => response.json())
        .then((responseData) => {
            var categories = responseData
            categories.forEach(element => {
                if (element.words == undefined){
                    element.words = ""
                }
                if (element.words_not == undefined){
                    element.words_not = ""
                }
            });
            this.setState({categories : categories})
        })
    }
    searchTextChanged = (text) => {
        if (this.state.reviews.length > 0){
            this.setState({filteredReviews : filterByText(this.state.reviews, text)})
        }
    }
    toogleAddTags(){
        this.setState({isShowAddTags : !this.state.isShowAddTags})
    }
    infoCategoryChanged(){
        this.setState({isLoading : true, filteredReviews : [], categories : [], reviews : []})
        this.getConfigReviews()
        this.getReviews()
    }
    ratingPicked(rating){
        this.ratingPickedFilter = rating
        this.filter()
    }
    categoryPicked(categories){
        this.categoriesPickedFilter = categories
        this.filter()
    }
    teamPicked(teams){
        this.teamPickedFilter = teams
        this.filter()
    }
    filter(){
       this.setState({filteredReviews : filterByParams(this.state.reviews, this.ratingPickedFilter, this.categoriesPickedFilter, this.teamPickedFilter)})  
    }
    disableFilters(){
        this.ratingPickedFilter = -1 
        this.categoriesPickedFilter = []
        this.teamPickedFilter = []
        this.setState({filteredReviews : this.state.reviews})
    }
    addTagClicked (review){
        this.setState({isShowAddTags : true, addTagReview : review})
    }
    appPicked(name, icon, type_os, bundle, price, version_os, id, category){
        this.bundle = bundle
        this.setState({name : name, icon : icon, bundle : bundle, type_os : type_os, price : price, version_os : version_os, id : id, category : category})
        this.getConfigReviews()
        this.getReviews()
    }
    inputSearchChanged(text){
        if (text == "" || text == undefined){
            this.setState({name : "", icon : undefined, bundle : undefined, type_os : undefined, price : undefined, version_os : undefined, id : undefined, category : undefined, filteredReviews : [], reviews : []})
        }
    }
    toogleFilters(){
        this.setState({isShowFilter : !this.state.isShowFilter})
    }
    showReviewBlog(){
        window.location.href = "http://blog.upmob.ru/2020/08/26/reviews-analysis/"
    }

    render(){
            return (
                <div>
                    <div onClick={this.showReviewBlog} className="link_to_universal hover">
                        <img className="img_univ_link" src={book}/>
                        <p className="link_text">{strings.readAboutReviews}</p>
                    </div>
                    <div style={this.state.isShowAddTags ? {overflow : "visible"} : {}} className="main_container">
                        <p className="main_title">{strings.reviewsTitle}</p>
                        {/* <Toolbar searchTextChanged={this.searchTextChanged}/> */}
                        <NewOrderSearchLine inputSearchChanged={this.inputSearchChanged} appPicked={this.appPicked} description={strings.searchDescAso} />
                        <div className="review_line"></div>
                        {!this.state.isLoading && this.state.reviews.length > 0 ? 
                        <div className="reviews_flex">
                            <div style={this.state.isShowAddTags ? {height : '0vh', overflow : 'hidden', opacity : '0'} : {}} className="reviews_list_container">
                                {this.state.filteredReviews.length > 0 && this.state.filteredReviews.map((item, index) => (
                                    <ReviewItem toogleAddTags={this.addTagClicked} removeCategory={this.removeCategory} categories={this.state.categories} review={item} index={index} key={index}/>
                                ))}
                                {this.state.filteredReviews.length == 0 && <EmptyStats text1={strings.noReviewsFound} text2={strings.noReviewsFound2} text3={""} icon={aso}/>}                    
                            </div>
                            {(!this.isMobile && this.state.reviews.length > 0) && <Filters disableFilters={this.disableFilters} teamPicked={this.teamPicked} categoryPicked={this.categoryPicked} categories={this.state.categories} ratingPicked={this.ratingPicked}/>}
                            {!this.state.isShowAddTags && <div onClick={this.toogleFilters} className="mobile_floating_filter">
                                <img className="mobile_floating_icon" src={filter}/>
                            </div>}
                        </div>
                        : this.state.isLoading ? <Loader text={strings.loadingReviews}/>
                        : this.state.isIos ? <EmptyStats text1={strings.forAndroid1} text2={strings.forAndroid2} text3={""} icon={cross_icon}/>
                        : <EmptyStats text1={strings.pickAppReviews} text2={strings.pickAppReviews2} text3={""} icon={aso_icon}/>}
    
                        {this.state.isShowAddTags && <AddTagInfo onRenameConfirmed={this.onRenameConfirmed} onDeleteConfirmed={this.removeCategory} bundle={this.bundle} review={this.state.addTagReview} infoCategoryChanged={this.infoCategoryChanged} toogleAddTags={this.toogleAddTags} categories={this.state.categories}/>}
                        {/* <AddTagInfo toogleAddTags={this.toogleAddTags} categories={this.state.categories}/> */}
                    </div>
                    
                    <div onClick={this.toogleFilters} className={(this.state.isShowFilter && !this.state.isShowAddTags) ? "mobile_filter_bg mobile_filter_bg_active" : "mobile_filter_bg"}/>
                    <div className={(this.state.isShowFilter && !this.state.isShowAddTags) ? "mobile_filter_cont mobile_filter_cont_active" : "mobile_filter_cont"}>
                        <Filters disableFilters={this.disableFilters} dropdowStyle={{top: '26px',left: '-60px'}} teamPicked={this.teamPicked} categoryPicked={this.categoryPicked} categories={this.state.categories} ratingPicked={this.ratingPicked}/>     
                    </div>
                    
                </div>
            ); 
        
        
    }
}
export default Reviews;