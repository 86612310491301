/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { getRequestParams, isMore3Orders, isWriteReviewShown, setWriteReviewShown } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import NewOrderSearchLine from './custom_views/NewOrderSearchLine';
import SearchRequest from './custom_views/SearchRequest';
import TrackingUrl from './custom_views/TrackingUrl';
import Open3Day from './custom_views/Open3Day';
import InstallsCount from './custom_views/InstallsCount';
import Reviews from './custom_views/Reviews';
import GoodReviewsTop from './custom_views/GoodReviewsTop';
import ProgressOrder from './custom_views/ProgressOrder';
import Limits from './custom_views/Limits';
import { getUrlParameter } from './custom_views/utils_android/UrlHelperAndroid';
import WriteReviewTooltip from './custom_views/tooltips/WriteReviewTooltip';
import CreateOrderButton from './custom_views/analyze_review/customViews/CreateOrderButton';
import SdkCreateOrder from './custom_views/OrderInfo/SdkCreateOrder';
import { AppsType, Pages } from './custom_views/analyze_review/utils/Utils';
import { AppsApi } from '../api/AppsApi';
import { ConfigApi } from '../api/ConfigApi';
var strings = getLocalizedStrings()


class CreateOrder extends React.Component {
    constructor(props) {
        super(props)
        var bundle = getUrlParameter("bundle")
        var type_os = getUrlParameter("os")
        var keywords = getUrlParameter("word")
        var positions = getUrlParameter("positions")

        var isShowWriteReview = (isMore3Orders() && !isWriteReviewShown())
        if (isShowWriteReview) {
            setWriteReviewShown()
        }
        this.state = {
            bundle: "",
            type_os: "",
            icon: "",
            name: "",
            price: "",
            version_os: "",
            id: "",
            category: "",

            trackUrl: "",
            trackUrlDesc: "",
            isCheckedTrackUrl: false,
            is3DayChecked: false,
            installCount: 100,
            reviewWords: "",
            reviewWish: "",
            isCheckedReview: false,
            isGoodReviewsTop: false,
            isProgressOrder: false,
            isLimitDayChecked: false,
            limitDay: 100,
            isLimitHourChecked: false,
            limitHour: 10,
            isLimitMinuteChecked: false,
            limitMinute: 5,
            isWriteReview: false,

            MINIMAL_PRICE_CUSTOMER: 0,
            REVIEW_PRICE_CUSTOMER: 0,
            THIRD_DAY_PRICE_CUSTOMER: 0,
            GOOD_REVIEW_TOP_CUSTOMER: 0,

            MINIMAL_PRICE_CUSTOMER_IOS: 0,
            REVIEW_PRICE_CUSTOMER_IOS: 0,
            THIRD_DAY_PRICE_CUSTOMER_IOS: 0,
            GOOD_REVIEW_TOP_CUSTOMER_IOS: 0,

            preOrderBundle: bundle,
            preOrderKeywords: keywords,
            preOrderPositions: positions,
            preOrderTypeOs: type_os,
            sumOrder: 400,
            priceOnInstall: 4,
            isNoLimits: true,

            isShowError: false,
            isShowWriteReview: isShowWriteReview,
            isSdkControll: false,
            isSdkNextStep: false,
            sdkPrice: 0,
            isSdkError: true,
            isLoading: true,
            sdkTasks: []
        }

        this.appPicked = this.appPicked.bind(this)
        this.searchAppPosition = this.searchAppPosition.bind(this)
        this.onTrackUrlChanged = this.onTrackUrlChanged.bind(this)
        this.on3DayChanged = this.on3DayChanged.bind(this)
        this.installCountChanged = this.installCountChanged.bind(this)
        this.onReviewChanged = this.onReviewChanged.bind(this)
        this.goodReviewsTopChanged = this.goodReviewsTopChanged.bind(this)
        this.progressChanged = this.progressChanged.bind(this)
        this.limitsChanged = this.limitsChanged.bind(this)
        this.noLimitsPicked = this.noLimitsPicked.bind(this)
        this.closeWriteReview = this.closeWriteReview.bind(this)
        this.sdkControllChanged = this.sdkControllChanged.bind(this)
        this.sdkPriceChanged = this.sdkPriceChanged.bind(this)
        this.sdkTasksChanged = this.sdkTasksChanged.bind(this)
        this.getPriceConfig()
    }
    appPicked(name, icon, type_os, bundle, price, version_os, id, category) {
        this.setState({ name: name, icon: icon, bundle: bundle, type_os: type_os, price: price, version_os: version_os, id: id, category: category })
        this.calcPrice(this.state.is3DayChecked, this.state.isCheckedReview, this.state.isGoodReviewsTop, type_os, price, this.state.installCount)

    }
    searchAppPosition(isSearchChecked, searchPostion, searchText) {
        this.setState({ isSearchChecked: isSearchChecked, searchPostion: searchPostion, searchText: searchText })
    }
    onTrackUrlChanged(url, description, isCheckedTrackUrl) {
        this.setState({ trackUrl: url, trackUrlDesc: description, isCheckedTrackUrl: isCheckedTrackUrl })
    }
    on3DayChanged(is3DayChecked) {
        this.setState({ is3DayChecked: is3DayChecked })
        this.calcPrice(is3DayChecked, this.state.isCheckedReview, this.state.isGoodReviewsTop, this.state.type_os, this.state.price, this.state.installCount)
    }
    installCountChanged(installCount) {
        this.setState({ installCount: installCount })
        this.calcPrice(this.state.is3DayChecked, this.state.isCheckedReview, this.state.isGoodReviewsTop, this.state.type_os, this.state.price, installCount)

    }
    onReviewChanged(reviewWords, reviewWish, isCheckedReview, isWriteReview) {
        this.setState({ reviewWords: reviewWords, reviewWish: reviewWish, isCheckedReview: isCheckedReview, isWriteReview: isWriteReview })
        this.calcPrice(this.state.is3DayChecked, isCheckedReview, this.state.isGoodReviewsTop, this.state.type_os, this.state.price, this.state.installCount)
    }
    goodReviewsTopChanged(isGoodReviewsTop) {
        this.setState({ isGoodReviewsTop: isGoodReviewsTop })
        this.calcPrice(this.state.is3DayChecked, this.state.isCheckedReview, isGoodReviewsTop, this.state.type_os, this.state.price, this.state.installCount)
    }
    progressChanged(isProgressOrder) {
        this.setState({ isProgressOrder: isProgressOrder, isLimitDayChecked: false, isNoLimits: false })
    }
    limitsChanged(isLimitDayChecked, limitDay, isLimitHourChecked, limitHour, isLimitMinuteChecked, limitMinute) {
        this.setState({ isProgressOrder: false, isNoLimits: false, isLimitDayChecked: isLimitDayChecked, limitDay: limitDay, isLimitHourChecked: isLimitHourChecked, limitHour: limitHour, isLimitMinuteChecked: isLimitMinuteChecked, limitMinute: limitMinute })
    }
    noLimitsPicked() {
        this.setState({ isNoLimits: true, isLimitDayChecked: false, isProgressOrder: false })
    }
    closeWriteReview() {
        this.setState({ isShowWriteReview: false })
    }
    sdkControllChanged() {
        this.setState({ isSdkControll: !this.state.isSdkControll })
    }
    getPriceConfig = () => {
        ConfigApi.getPriceConfig((responseData) => {
            this.setState({
                MINIMAL_PRICE_CUSTOMER: responseData.MINIMAL_PRICE_CUSTOMER, REVIEW_PRICE_CUSTOMER: responseData.REVIEW_PRICE_CUSTOMER,
                THIRD_DAY_PRICE_CUSTOMER: responseData.THIRD_DAY_PRICE_CUSTOMER, GOOD_REVIEW_TOP_CUSTOMER: responseData.GOOD_REVIEW_TOP_CUSTOMER,
                MINIMAL_PRICE_CUSTOMER_IOS: responseData.MINIMAL_PRICE_CUSTOMER_IOS, REVIEW_PRICE_CUSTOMER_IOS: responseData.REVIEW_PRICE_CUSTOMER_IOS, THIRD_DAY_PRICE_CUSTOMER_IOS: responseData.THIRD_DAY_PRICE_CUSTOMER_IOS, GOOD_REVIEW_TOP_CUSTOMER_IOS: responseData.GOOD_REVIEW_TOP_CUSTOMER_IOS
            })
        })
    }
    calcPrice = (isThirdDay, isReview, isGoodReview, type_os, price, count) => {
        var priceOnInstall = 0
        if (type_os == AppsType.android || type_os == AppsType.rustore) {
            priceOnInstall = this.state.MINIMAL_PRICE_CUSTOMER + price
            if (isThirdDay) {
                priceOnInstall += this.state.THIRD_DAY_PRICE_CUSTOMER
            }
            if (isReview) {
                priceOnInstall += this.state.REVIEW_PRICE_CUSTOMER
            }
            if (isGoodReview) {
                priceOnInstall += this.state.GOOD_REVIEW_TOP_CUSTOMER
            }
        } else if (type_os == AppsType.ios) {
            priceOnInstall = this.state.MINIMAL_PRICE_CUSTOMER_IOS + price
            if (isThirdDay) {
                priceOnInstall += this.state.THIRD_DAY_PRICE_CUSTOMER_IOS
            }
            if (isReview) {
                priceOnInstall += this.state.REVIEW_PRICE_CUSTOMER_IOS
            }
            if (isGoodReview) {
                priceOnInstall += this.state.GOOD_REVIEW_TOP_CUSTOMER_IOS
            }
        }
        var sum = priceOnInstall * count
        this.setState({ sumOrder: sum, priceOnInstall: priceOnInstall })
    }
    createOrder = () => {
        if (this.state.isSdkControll && !this.state.isSdkNextStep) {
            this.setState({ isSdkNextStep: true, sdkPrice: (2 * this.state.installCount) })
        } else {
            var params = getRequestParams()
            params.bundle = this.state.bundle
            params.with_review = this.state.isCheckedReview
            params.open_3_day = this.state.is3DayChecked
            params.good_review_top = this.state.isGoodReviewsTop
            this.setState({ isShowError: false })
            if (this.state.priceOnInstall != undefined && this.state.priceOnInstal != 0 && parseInt(this.state.price) > 15) {
                this.setState({ isShowError: true })
                return
            }
            if (this.state.isSearchChecked) {
                params.search_request = this.state.searchText
                params.search_position = this.state.searchPostion
            }
            if (this.state.isLimitDayChecked) {
                params.limit_day = this.state.limitDay

                if (this.state.isLimitHourChecked) {
                    params.limit_hour = this.state.limitHour
                }
                if (this.state.isLimitMinuteChecked) {
                    params.limit_minute = this.state.limitMinute
                }
            }

            params.count_money_r = this.state.sumOrder
            params.max_count_installs = this.state.installCount
            params.name = this.state.name
            params.icon = this.state.icon
            if (this.state.isCheckedReview) {
                if (this.state.isWriteReview) {
                    params.review_words = this.state.reviewWords
                    params.review_wish = this.state.reviewWish
                } else {
                    params.review_words = strings.reviewWordsParams
                    params.review_wish = ""
                }
            }
            params.category = this.state.category
            params.price_one_install = this.state.priceOnInstall
            params.type_os = this.state.type_os
            params.price_app_in_store = this.state.price
            params.version_os = this.state.version_os
            params.appstore_id = this.state.id
            if (this.state.isCheckedTrackUrl) {
                params.track_url = this.state.trackUrl
                params.description_step1 = this.state.trackUrlDesc
            }
            params.progress_distribution_count_days = (this.state.isProgressOrder ? 1 : 0)
            if (this.state.isSdkControll && this.state.isSdkNextStep) {
                params.SDKTasks = JSON.stringify(this.state.sdkTasks)
                params.count_money_r = (this.state.sumOrder + this.state.sdkPrice)
                params.price_one_install = this.state.priceOnInstall + (this.state.sdkPrice / this.state.installCount)
            }
            AppsApi.addNewOrder(params, (responseData) => {
                const ordersUrl = Pages.ordersFull + ((this.state.isSdkControll && this.state.isSdkNextStep) ? ('?sdk_order_id=' + responseData.order_id) : '')
                window.location.href = ordersUrl
            }, () => { })

        }
    }
    componentDidMount() {
        var progress_order = getUrlParameter("progress_order")
        if (progress_order) {
            this.progressChanged(true, false, false)
        }
    }
    sdkPriceChanged(tasks) {
        var price = 0
        tasks.forEach(task => {
            if (task.price != undefined && task.price != "" && task.price >= 2) {
                this.setState({ isSdkError: false })
                price += parseInt(task.price)
                this.setState({ isSdkError: (task.name == "" || task.description == "") })
            } else {
                this.setState({ isSdkError: true })
            }

        });
        this.setState({ sdkPrice: price * this.state.installCount, sdkTasks: tasks })
    }
    sdkTasksChanged(tasks) {
        this.setState({ sdkTasks: tasks })
        this.sdkPriceChanged(tasks)
    }
    closeNextSdkStep = () => {
        this.setState({ isSdkNextStep: false, sdkPrice: 0, sdkTasks: [] })
    }

    render() {
        //const isTest = (getEmail() == "zhenekvv@gmail.com" || getEmail() == "bumsun@yandex.ru" || getEmail() == "lexus199126@ya.ru")
        const isTest = true
        const isAndroidOrRustoreApp = this.state.type_os == AppsType.android || this.state.type_os == AppsType.rustore
        return (
            <div>

                {/* <CSSTransitionGroup
                        transitionName="example"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={500}
                        > */}

                <div style={this.state.isSdkNextStep ? { display: 'none' } : {}}>
                    <div>
                        <div className="main_container" style={{ minHeight: 'auto' }}>
                            <div className='create_order_just'>
                                <div className="main_title">
                                    {strings.newOrder}
                                    <span style={{ opacity: 0.3, marginLeft: '8px' }}>{strings.menuRussia}</span>
                                </div>
                                <div className='create_order_prices'>
                                    <div className='create_order_j_emoji'>🤑</div>
                                    <div className='create_order_j_flex'>
                                        <div className='flex'><div className='android_ml' style={{ marginLeft: '10px' }}>{strings.androindInstallsPrice}</div> <div className='order_price_bold order_price_android'>{strings.fromM} 4₽</div></div>
                                        <div className='flex '><div className='ios_ml' style={{ marginLeft: '10px' }}>{strings.iosInstallsPrice}</div> <div className='order_price_bold order_price_ios'>{strings.fromM} 12₽</div></div>
                                    </div>
                                </div>
                            </div>
                            {this.state.isShowWriteReview && <WriteReviewTooltip closeWriteReview={this.closeWriteReview} />}
                            <NewOrderSearchLine preOrderBundle={this.state.preOrderBundle} preOrderTypeOs={this.state.preOrderTypeOs} appPicked={this.appPicked} description={strings.nameDescription} />
                            <div className="line-row" />
                            <SearchRequest preOrderPositions={this.state.preOrderPositions} preOrderKeywords={this.state.preOrderKeywords} preOrderTypeOs={this.state.preOrderTypeOs} searchAppPosition={this.searchAppPosition} type_os={this.state.preOrderTypeOs ? this.state.preOrderTypeOs : this.state.type_os} bundle={this.state.preOrderBundle ? this.state.preOrderBundle : this.state.bundle} />
                            <div className="line-row" />
                            <Reviews onReviewChanged={this.onReviewChanged} costReview={this.state.type_os == "" ? "" : (isAndroidOrRustoreApp ? this.state.REVIEW_PRICE_CUSTOMER : this.state.REVIEW_PRICE_CUSTOMER_IOS)} />
                            <div className="line-row" />
                            <GoodReviewsTop goodReviewsTopChanged={this.goodReviewsTopChanged} costGoodReviewTop={this.state.type_os == "" ? "" : (isAndroidOrRustoreApp ? this.state.GOOD_REVIEW_TOP_CUSTOMER : this.state.GOOD_REVIEW_TOP_CUSTOMER_IOS)} />
                            <div className="line-row" />
                            <Open3Day on3DayChanged={this.on3DayChanged} costOpen3dDayTV={this.state.type_os == "" ? "" : (isAndroidOrRustoreApp ? this.state.THIRD_DAY_PRICE_CUSTOMER : this.state.THIRD_DAY_PRICE_CUSTOMER_IOS)} />
                            <div className="line-row" />
                            <TrackingUrl onTrackUrlChanged={this.onTrackUrlChanged} />
                            {/* <div className="line-row"/>
                                    <CountryPicker /> */}
                            <div className="line-row" />
                            <InstallsCount type_os={this.state.type_os} installCountChanged={this.installCountChanged} />
                        </div>
                    </div>

                    {/* </CSSTransitionGroup> */}
                    {(isTest && this.state.type_os == "android") && <div className="main_container" style={{ minHeight: 'auto', marginTop: '8px' }}>
                        <div className="order_row">
                            <div className="switch_container flex">
                                <label className="switch">
                                    <input type="checkbox" onChange={this.sdkControllChanged} checked={this.state.isSdkControll} />
                                    <div className="slider round"></div>
                                </label>
                                <p className="text-pre-subscr">{strings.sdkControllTitle}</p>
                            </div>
                            <div className="input_description_container">
                                <p className="input_description_text pos_relative">{strings.sdkControllDescr}<br /><br />{strings.sdkControllDescr2}</p>
                            </div>
                        </div>
                    </div>}
                </div>

                {/* limitControlls */}
                <div className="main_container" style={{ minHeight: 'auto', marginTop: this.state.isSdkNextStep ? '20px' : '8px' }}>
                    <div style={this.state.isSdkNextStep ? { display: 'none' } : {}}>
                        <p className="installs_limits_title">{strings.installsLimits}</p>
                        <div className="line-row left_40" />
                        <div className="flex">
                            <div onClick={this.noLimitsPicked} style={this.state.isNoLimits ? { border: "1px solid #F5DB31" } : {}} className="radio_limits hover">
                                {this.state.isNoLimits && <div className="radio_inside" />}
                            </div>
                            <p className="text-pre-subscr">{strings.noLimitsTitle}</p>
                        </div>
                        <div className="line-row left_40" />
                        <ProgressOrder isProgressOrder={this.state.isProgressOrder} progressChanged={this.progressChanged} installCount={this.state.installCount} />
                        <div className="line-row left_40" />
                        <Limits isLimitDayChecked={this.state.isLimitDayChecked} limitsChanged={this.limitsChanged} />
                        <div className="line-row" />
                        {this.state.isShowError && <p className="error_money_order">{strings.priceAppError}</p>}
                    </div>
                    {this.state.isSdkNextStep && <SdkCreateOrder sdkTasks={this.state.sdkTasks} closeNextSdkStep={this.closeNextSdkStep} sdkTasksChanged={this.sdkTasksChanged} sdkPriceChanged={this.sdkPriceChanged} orderState={this.state} />}
                    <CreateOrderButton exchangeRate={this.props.exchangeRate} isSdkError={(this.state.isSdkNextStep && this.state.isSdkError)} sumOrder={this.state.isSdkNextStep ? (this.state.sumOrder + this.state.sdkPrice) : this.state.sumOrder} priceOnInstall={this.state.isSdkNextStep ? (this.state.priceOnInstall + (this.state.sdkPrice / this.state.installCount)) : this.state.priceOnInstall} createOrder={this.createOrder} installCount={this.state.installCount} isShowHelp={this.state.isSdkControll} isSdkControll={this.state.isSdkControll && !this.state.isSdkNextStep} />
                </div>

            </div>

        );
    }
}
export default CreateOrder;