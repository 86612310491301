/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import moment from 'moment'
import 'moment/min/locales'
import { DateRange } from 'react-date-range';
import ruLocale from 'date-fns/locale/ru'
import { formateDate } from '../analyze_review/utils/DateHelper';
import { getOrderStatsParams, getDates, getFormattedDate } from '../utils_android/Utils';
import { getHttpParams, getRequestUrl } from '../analyze_review/utils/Utils';
import cross_icon from '../custom_icons/cross.svg'

import Loader from '../analyze_review/customViews/Loader';
import StatsChart from '../StatsChart';
import EmptyStats from '../empty_views/EmptyStats';
import { SiteSeoApi } from '../../../api/SiteSeoApi';
var colors = ['#2F80ED', '#1ED9C7', '#F2994A', '#EB5757', '#9B51E0', '#6FCF97', '#56CCF2', '#219653', '#4F4F4F', '#828282']
var strings = getLocalizedStrings()

const OrderInfoStats = (props) => {
    const isBots = props.isBots;
    const isSite = props.isSite;
    const getInitialRange = () => {
        const lastWeekDay = new Date(moment().subtract(6, "days"));
        const startTaskDate = new Date(isBots ? props.order.createDate : props.isUniversal ? props.order.create_date : props.order.date_run);
        let stDate = startTaskDate <= lastWeekDay ? lastWeekDay : startTaskDate;

        if (moment(stDate).isSame(moment(), 'day')) {
            stDate = new Date(moment().subtract(1, "days"));
        }

        return {
            startDate: stDate,
            endDate: isBots ? new Date(moment().subtract(1, "days")) : new Date(),
            key: 'selection',
        };
    };

    const [isShowDates, setIsShowDates] = useState(false);
    const [selectedRange, setSelectedRange] = useState(getInitialRange());
    const [installsData, setInstallsData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const toogleDates = () => setIsShowDates(!isShowDates);

    const handleSelect = (ranges) => {
        if (ranges.selection.startDate != ranges.selection.endDate) {
            setIsShowDates(false);
            getStats(ranges.selection);
        }
        setSelectedRange(ranges.selection);
    };

    const getStats = (ranges) => {
        setInstallsData([]);
        setIsLoading(true);

        var params = getOrderStatsParams(ranges, props.order);
        if (isSite){
            getSiteOrderStats(params, ranges)
            return
        }
        fetch(getRequestUrl() + (isBots ? 'bot_boost/getOrderStatistic' : props.isUniversal ? "getOrderStatisticYoutube" : "getOrderStatistic"),
            getHttpParams(params))
            .then((response) => response.json())
            .then((responseData) => {
                parseStatisticResponse(responseData, ranges)
            });
    };
    const getSiteOrderStats = (params, ranges) => {
        
        params._id = params.order_id
        console.log("getSiteOrderStats params = ", params)
        SiteSeoApi.getOrderStatistic(params, (responseData) => {
            parseSiteStatisticResponse(responseData, ranges)
            setIsLoading(false)
        }, () => { setIsLoading(false) })
    }
    const parseSiteStatisticResponse = (responseData, ranges) => {
        const dateLabels = getDates(new Date(moment(ranges.startDate).subtract(1, "days")), ranges.endDate);
        const newInstallsData = [];

        responseData.orders.forEach((orderDataM, colorIndex) => {
            const orderData = orderDataM["0"] ? orderDataM["0"] : orderDataM
       
            const employers_dates = orderData.employers_dates || []

            if (employers_dates.length > 0) {
                const installs = dateLabels.map(date => {
                    const foundDate = employers_dates.find(ed => date == getFormattedDate(ed.date));
                    return foundDate ? foundDate.count_installs : 0;
                });

                const searchReq = orderData.url;
                const color = colorIndex < colors.length ? colors[colorIndex] : "black";

                newInstallsData.push({
                    data: installs,
                    label: searchReq,
                    borderColor: color,
                    backgroundColor: color,
                    pointBorderColor: color,
                    pointRadius: 3,
                    borderWidth: 1,
                    pointBackgroundColor: "white",
                    fill: false,
                });
            }
        });
        console.log("newInstallsData = ", newInstallsData)
        setInstallsData(newInstallsData);
        setLabels(dateLabels);
        setIsLoading(false);
    }
    const parseStatisticResponse = (responseData, ranges) => {
        const dateLabels = getDates(new Date(moment(ranges.startDate).subtract(1, "days")), ranges.endDate);
        const newInstallsData = [];

        Object.entries(responseData).forEach(([_, orderData], colorIndex) => {
            const employers_dates = orderData.employers_dates || orderData.employers_dates_review || [];

            if (employers_dates.length > 0) {
                const installs = dateLabels.map(date => {
                    const foundDate = employers_dates.find(ed => date === getFormattedDate(ed.date));
                    return foundDate ? (foundDate.count_installs || foundDate.count_reviews || 0) : 0;
                });

                const searchReq = orderData.search_request || (props.isUniversal ? strings.installsCountStats : strings.withoutSearchReq);
                const color = colorIndex < colors.length ? colors[colorIndex] : "black";

                newInstallsData.push({
                    data: installs,
                    label: searchReq,
                    borderColor: color,
                    backgroundColor: color,
                    pointBorderColor: color,
                    pointRadius: 3,
                    borderWidth: 1,
                    pointBackgroundColor: "white",
                    fill: false,
                });
            }
        });

        setInstallsData(newInstallsData);
        setLabels(dateLabels);
        setIsLoading(false);
    }
    useEffect(() => {
        getStats(selectedRange);
    }, []);

    return (
        <div style={{ minHeight: '400px' }} className="relative">
            <div className="order_info_stats_title_cont">
                <p className="order_info_stats_title">
                    {isSite ? strings.siteStatisticCount : props.isUniversal ? strings.univOrderTriesCount : strings.orderInfoInstallStats}
                </p>
                <div className="order_info_stats_date_cont">
                    <p className="order_info_stats_filter">{strings.period}</p>
                    <p onClick={toogleDates} className="order_info_stats_filter order_info_stats_filter_date hover">
                        {formateDate(selectedRange)}
                    </p>
                    {isShowDates && <div onClick={toogleDates} className="tooltip_bg_dates"></div>}
                    {isShowDates && (
                        <div className="dates_info_cont">
                            <DateRange
                                disabledDates={props.isBots ? [new Date()] : []}
                                locale={ruLocale}
                                ranges={[selectedRange]}
                                onChange={handleSelect}
                            />
                        </div>
                    )}
                </div>
            </div>
            {isLoading ? <Loader /> :
                (installsData && installsData.length > 0) ? (
                    <div>
                        <div style={{ marginLeft: '-16px', marginBottom: '16px', marginTop: '16px' }}>
                            <StatsChart installsData={installsData} labels={labels} />
                        </div>
                    </div>
                ) : (
                    <EmptyStats text1={strings.orderInfoInstallStatsEmpty} icon={cross_icon} />
                )
            }
            <div style={{ marginBottom: '16px', opacity: 0 }}>aa</div>
        </div>
    );
};

export default OrderInfoStats;
