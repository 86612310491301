import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import '../custom_views/dialogs/dialogs.css';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class CircleProgress extends React.Component {
    
    constructor(props){
        super(props)
       
    }
   
   
    render() {
        var blockedOrder = false
        if (this.props.order != undefined && this.props.order.count_installed != undefined){
            const isOrderCompleted = (this.props.order.count_installed >= this.props.order.max_count_installs || this.props.order.count_money_r < this.props.order.price_one_install)
            blockedOrder = !isOrderCompleted && this.props.order.moderated == -1
        } 
        
        return (
            
            <div className="circle_progress_container">
                <CircularProgressbar
                    value={this.props.percentage}
                    
                    styles={buildStyles({
                    
                
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',
                
                    // Text size
                    textSize: '16px',
                
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,
                
                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',
                
                    // Colors
                    
                    pathColor: blockedOrder ? 'rgb(255, 98, 98)' : this.props.percentage == 100 ? '#5FD7A4' : (this.props.state == 0 ? '#FF6262' : '#FFC28C'),
                    trailColor: '#F5F5F5',
                    })}
                />
                <div className="cicle_progress_text">
                    <p className="top_text">{this.props.count_installed}</p>
                    <p>{strings.from} {this.props.max_count_installs}</p>
                </div>
                
            </div>
            

        );
    }
}
export default CircleProgress;