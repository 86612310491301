import React from 'react';
import qs from 'querystring'

import '../pages_css/tooltip.css';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { isShowLeftTooltip } from '../utils_android/Utils';
var strings = getLocalizedStrings()
class AddMoneyUniversal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
           countInstalls : 100,
           isShowError : false
        }
       
        
    }
    onCountChanged = (event) => {
        if (event){
            var count = event.target.value
            this.setState({countInstalls : count})
        }
    }
    closeTooltip = () => {
        this.props.closeUpBalance()
    }
    addMoney = () => {        
        this.setState({isShowError : false})
        var sum = this.props.price_one_install*this.state.countInstalls
        if (this.props.count_money_r >= sum){
            this.props.addCoinsToOrder(this.props.order_id, sum, this.state.countInstalls, this.props.index)
            this.closeTooltip()
        } else {
            this.setState({isShowError : true})
        }
    }
    openPay = () => {
        window.location.href = '/pay'
    }
    render() {
        return (
            <div className="display_block">
                <div onClick={this.closeTooltip} className="tooltip_bg_up_balance"></div>
                <div style={this.props.style ? this.props.style : {}} className="tooltip_plashka">
                 
                    <div style={this.props.isLeft ? {} : {right : 'auto', left : '-8px'}} className="tooltip_arrow"></div>
                    <div className="display_block">
                        <div className="padding_24 tooltip_right">
                            <p className="up_balance_title">{strings.addUniversalTitle}</p>
                            <input value={this.state.countInstalls} onChange={this.onCountChanged} className="input_up_balance"/>
                            <div className="price_container">
                                <p>{strings.addUniversalOne}</p>
                                <p className="bold"> {this.props.price_one_install}₽</p>
                            </div>
                            <div className="price_container">
                                <p >{strings.upSum}</p>
                                <p className="bold">{this.props.price_one_install*this.state.countInstalls}₽</p>
                            </div>
                            <p onClick={this.addMoney} className="add_btn hover">{strings.upAdd} {this.props.price_one_install*this.state.countInstalls}₽</p>
                            <p onClick={this.closeTooltip} className="cancel_btn hover">{strings.upCancel}</p>
                            <div className="line_white_bg"/>
                            <div className="line_white_bg_left"/>
                            <div onClick={this.openPay} className={this.state.isShowError ? "red_container" : "not_display"}>
                                <p className="error_text_tooltip">{strings.notEnoughtMoney}</p>
                                <p className="error_text_tooltip bold hover">{strings.addMoney}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        );
    }
}
export default AddMoneyUniversal;
