import React from 'react';
import {DebounceInput} from 'react-debounce-input';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';

var strings = getLocalizedStrings()

class UniversalNameTask extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            name : ""
        } 
        this.nameChanged = this.nameChanged.bind(this)
    }
    nameChanged(event){
        if (event){
            var value = event.target.value
            this.setState({name : value})
            this.props.nameChanged(value)
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps != this.props){
            this.setState({name : this.props.name})
        }
    }
   
    render() {
        return (
            <div className="display_block">
                    <div className="order_row">
                        <div style={this.props.isClickedCreate && this.state.name == "" ? {border : "1px solid rgba(255, 98, 98, 0.5)"} : {}} className="universal_input display_block">
                            <DebounceInput autoComplete="off" type="text" onChange={this.nameChanged} className="input_text_search" placeholder={strings.universalName} value={this.state.name}/>
                        </div>
                        <div className="input_description_container">
                            <p className="input_description_text">{strings.universalNameDesc}</p>
                        </div>
                    </div>
            </div>
           
        );
    }
}
export default UniversalNameTask;