/* eslint-disable eqeqeq */

import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import {DebounceInput} from 'react-debounce-input';
import { getRequestParams } from './utils_android/CookieHelper';
import close_order from './custom_icons/close_order.svg'
import InputLoader from './utils_android/InputLoader';
import AppsDropdown from './AppsDropdown';
import { getUrlParameter } from './utils_android/UrlHelperAndroid';
import { AppsType, AppsUrls, getAppIconType, getAppUrl } from './analyze_review/utils/Utils';
import { AppsApi } from '../../api/AppsApi';
var strings = getLocalizedStrings()
var notDisplay = {display : 'none'}
var display = {display : 'block', marginTop : '7px'}

class NewOrderSearchLine extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            pickedAppIcon : "",
            pickedAppDeviceIcon : "",
            searchText : "",
            type_os : "",
            isLoading : false,
            isShowApps : false,
            isBlocked : false,
            appsList : [],
            bundle : ""
        }
        this.searchTextChanged = this.searchTextChanged.bind(this)
        this.clearSearchLine = this.clearSearchLine.bind(this)
       
    }
    componentWillMount(){
        if (this.props.preOrderBundle){
            // var url = ""
            // if (this.props.preOrderTypeOs == AppsType.android){
            //     url = AppsUrls.android + this.props.preOrderBundle
            // } else if (this.props.preOrderTypeOs == AppsType.rustore){
            //     url = AppsUrls.rustore + this.props.preOrderBundle
            // } else {
            //     url = AppsUrls.ios + getUrlParameter("app_id")
            // }
            const url = getAppUrl(this.props.preOrderTypeOs, this.props.preOrderTypeOs == AppsType.ios ? getUrlParameter("app_id") : this.props.preOrderBundle)
            this.searchApp(url, this.props.preOrderTypeOs)
       }
    }
    searchTextChanged(event){
        if (event){
            var text = event.target.value
            this.setState({searchText: text})
            if (text.includes("https://")){
                this.searchApp(text)
            } 
            // else {
            //     this.searchAppsByText(text)
            // }
            if (this.props.inputSearchChanged){
                this.props.inputSearchChanged(text)
            }
        }
    }

    searchApp = (text, type_os) => {
        this.setState({isLoading : true})
        var params = getRequestParams()
        params.url = text
        if (type_os){
            params.type_os = type_os
        }
        
        console.log("getAppInfo = ", params)
        AppsApi.getAppInfo(params, (responseData) => {
                var response = responseData
                if (response.info.icon == undefined){
                    response.is_blocked = true
                }
                this.setInfoData(response)
                this.setState({isLoading : false, isShowApps : false})
        }, (responseData) => {
                if (responseData.response == -20) {
                    this.setState({pickedAppIcon : "", pickedAppDeviceIcon: ""})
                }
                this.setState({isLoading : false, isShowApps : false})
        })
    }
    setInfoData = (data) => {
        var info = data.info
        var icon = info.icon
        var type_os = data.type_os
        var name = ""
        var price = info.price
        var version_os = ""
        var id = ""
        var category = ""
        if (data.type_os == AppsType.ios){
            name = info.trackCensoredName
            version_os = info.requiredOsVersion
            id = info.id
            category = info.genres[0]
        } else {
            name = info.title
            version_os = info.version_os
            id = info.appId
            category = info.genre
        }

        if (!data.is_blocked){
            this.setState({pickedAppIcon : icon, searchText : name, pickedAppDeviceIcon : getAppIconType(type_os), type_os : type_os, bundle : info.appId, isBlocked : false})
            this.props.appPicked(name, icon, type_os, info.appId, price, version_os, id, category)
        } else {
            this.setState({pickedAppIcon : "", searchText : "", pickedAppDeviceIcon : "", type_os : "", bundle : "", isBlocked : data.is_blocked})
            this.props.appPicked("", "", "", "", "", "", "", "")
        }
    } 

    searchAppsByText = (text) => {
    //$scope.appsListStyle = invisStyle
        this.setState({isLoading : true})
        var params = getRequestParams()
        params.query = text
        AppsApi.searchAppAllData(params, (responseData) => {
            if (responseData.gplay && responseData.appstore){
                var apps2 = []
                responseData.gplay.forEach(element => {
                    var app = {
                        'bundle': element.appId,
                        'name': element.title,
                        'type_os': AppsType.android,
                        'appstore_id': element.appId,
                        'icon': element.icon
                       
                    }
                    apps2.push(app)
                })
                responseData.appstore.forEach(element => {
                    var app = {
                        'bundle': element.bundleId,
                        'name': element.trackName,
                        'type_os': AppsType.ios,
                        'appstore_id': element.trackId,
                        'icon': element.icon
            
                    }
                    apps2.push(app)
                })
                this.setState({appsList : apps2, isShowApps : true, isLoading : false})  
            }
        }, () => {
            this.setState({isLoading : false})
        })
    }
    onAppPicked = (app) => {
        console.log("onAppPicked app = ", app)
        if (app.type_os == AppsType.android){
            this.searchApp(AppsUrls.android + app.bundle, app.type_os)
        } else if (app.type_os == AppsType.rustore){
            this.searchApp(AppsUrls.rustore + app.bundle, app.type_os)
        } else {
            this.searchApp(AppsUrls.ios + app.appstore_id, app.type_os)
        }
        this.setState({isShowApps : false})
    }
    
    getMyApps = () => {
        AppsApi.getMyApps((responseData) => {
            this.setState({appsList : responseData.apps.reverse()})
        })
    }
    toogleApps = () => {
        if (this.state.appsList && this.state.appsList.length > 0){
            this.setState({isShowApps : !this.state.isShowApps})
        }
    }
    onCloseApps = () => {
        this.setState({isShowApps : false})
    }
    componentDidMount(){
        this.getMyApps()
    }
    clearSearchLine(){
        this.setState({pickedAppIcon : "", pickedAppDeviceIcon : "", searchText : "", isShowApps : false})
    }
    render() {
        return (
            <div>
                <div className="order_row">
                    <div className="input_container_border search_test_border">
                        <img alt='app' style={(this.state.pickedAppIcon == "" || this.state.searchText == "") ? notDisplay : display} className="img_choose_app img_input_icon" src={this.state.pickedAppIcon}/>
                        <img alt='type os' style={(this.state.pickedAppDeviceIcon == "" || this.state.searchText == "") ? notDisplay : display} src={getAppIconType(this.state.type_os)} className="img_device_icon_input"/>
                
                        <DebounceInput autoComplete="off" debounceTimeout={1000} onClick={this.toogleApps}
                        onChange={this.searchTextChanged} className="input_text_search" placeholder={strings.inputName} value={this.state.searchText}/>
                        {this.state.isLoading ? <InputLoader style={{top : "6px"}}/> : <div/>}
                        {(this.state.searchText != "" && this.state.searchText != undefined && !this.state.isLoading) && <img alt='close' onClick={this.clearSearchLine} src={close_order} className="search_close_order_icon hover" />}
                        {this.state.isShowApps ? <AppsDropdown onCloseApps={this.onCloseApps} onAppPicked={this.onAppPicked} apps={this.state.appsList} /> : <div/>}
                        
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{this.props.description}</p>
                    </div>
                </div>
                {this.props.isDisabled && <p className="installs_error">{strings.disabledIosTask}</p>}
                {this.state.isBlocked && <p className="installs_error">{strings.blockedApp}</p>}
                {/* {this.state.type_os == AppsType.rustore && <p className="installs_error">Заказы для RuStore создавайте через Универсальные заказы</p>} */}

          </div>
        );
    }
}
export default NewOrderSearchLine;

