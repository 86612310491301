import React from 'react';
import qs from 'querystring'
import { getRequestParams, getRequestUrl } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getUrlParameter } from '../pages_android/custom_views/utils_android/UrlHelperAndroid';
import { declension } from './custom_views/utils_android/Utils';
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import NewOrderSearchLine from './custom_views/NewOrderSearchLine';
import aso_icon from './custom_views/custom_icons/aso.svg'
import PercentsAso from './custom_views/PercentsAso';
import { DebounceInput } from 'react-debounce-input';
import search_icon from './custom_views/custom_icons/search.svg'
import cross_icon from './custom_views/custom_icons/cross.svg'
import close_icon from './custom_views/custom_icons/close.svg'
import KeywordItem from './list_items/KewordItem';
import AsoCreateTask from './custom_views/AsoCreateTask';
import EmptyStats from './custom_views/empty_views/EmptyStats';
var strings = getLocalizedStrings()
class Aso extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            bundle : "",
            type_os : "",
            icon : "",
            name : "",
            price : "",
            version_os : "",
            id : "",
            category : "",
            seoPercentsTop : 0,
            isShowSearch : false,
            searchText : "",
            keyWordsResponse : [],
            filteredKeywords : [],
            textError1 : strings.enterAppData,
            textError2 : strings.enterAppData2,
            textError3 : "",
            errorIcon : aso_icon,
            keywordsTask : [],
            keywordsPositions : [],
            checkedKeywordsString : "",
            checkedPositionsString : ""
        }
        this.appPicked = this.appPicked.bind(this)
        this.searchTextChanged = this.searchTextChanged.bind(this)
    }
    appPicked(name, icon, type_os, bundle, price, version_os, id, category){
        this.setState({name : name, icon : icon, bundle : bundle, type_os : type_os, price : price, version_os : version_os, id : id, category : category})
        this.getKeywords(bundle, type_os)
    }
    searchTextChanged(event){
        if (event){
            var value = event.target.value
            this.setState({searchText : value})
            this.filterKeywords(value)
        }
    }
    searchClicked = () => {
        this.setState({isShowSearch : !this.state.isShowSearch})
    }
    getKeywords = (bundle, type_os) => {
        if (type_os == "android"){
            var params = getRequestParams()
            params.bundle = bundle
            params.typeOs = type_os
            fetch(getRequestUrl() + "getKeyWordsForApp", {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: qs.stringify(params)
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.length > 0) {
                    var countInTop3 = 0
                    responseData.forEach(element => {
                        if (element.position <= 3){
                            countInTop3++
                        }
                    });
                
                    var seoPercentsTop = Math.round(((parseFloat(countInTop3) / parseFloat(responseData.length)) * 100))
                    this.setState({keyWordsResponse : responseData, filteredKeywords : responseData, seoPercentsTop : seoPercentsTop})
                    
                } else {
                    this.setState({textError1 : strings.keywordsNotFound1, textError2 : strings.keywordsNotFound2, textError3 : strings.keywordsNotFound3, errorIcon : cross_icon})
                }
            })
        } else {
            this.setState({textError1 : strings.forAndroid1, textError2 : strings.forAndroid2, textError3 : "", errorIcon : cross_icon, keyWordsResponse : []})
        }
        
    }
    filterKeywords = (text) => {
        if (text == undefined || text == "") {
            this.setState({filteredKeywords : this.state.keyWordsResponse})
          return;
        }
         // $scope.closeInputStyle = visStyle
         var filteredKeywords = this.state.keyWordsResponse.filter(item => item.keyword.indexOf(text) !== -1);
         this.setState({filteredKeywords : filteredKeywords})
      };
      deleteFilter = () => {
        this.setState({searchText : ""})
        this.filterKeywords("")
      }
    keywordChecked = (index, word, position) => {
        var checkedKeywords = this.state.keywordsTask
        var keywordsPositions = this.state.keywordsPositions
        var checkedKeywordsString = ""
        var checkedPositionsString = ""
        if (checkedKeywords.includes(word)) {
            var index = checkedKeywords.indexOf(word);
            if (index > -1) {
              checkedKeywords.splice(index, 1);
              keywordsPositions.splice(index, 1);
            }
        } else {
          checkedKeywords.push(word);
          keywordsPositions.push(position);
        }
        if (checkedKeywords.length > 0) {
          checkedKeywordsString = checkedKeywords.join(",");
          checkedPositionsString = keywordsPositions.join(",");
        } else {
          checkedKeywordsString = "";
          checkedPositionsString = "";
        }
        this.setState({checkedKeywordsString : checkedKeywordsString, keywordsTask : checkedKeywords, checkedPositionsString : checkedPositionsString})

    }
    createOrder = () => {
       // window.open('https://upmob.ru/neworder' + "?bundle=" + this.state.bundle + "&word=" + this.state.checkedKeywordsString + "&os=" + this.state.type_os, '_blank');
        window.open('/neworder' + "?bundle=" + this.state.bundle + "&word=" + this.state.checkedKeywordsString + "&positions=" + this.state.checkedPositionsString + "&os=" + this.state.type_os, '_blank');
    }
    render() {
        return (
            <div className="main_container">
                <p className="main_title">{strings.aso}</p>
                <NewOrderSearchLine appPicked={this.appPicked} description={strings.searchDescAso} />
                {this.state.keyWordsResponse && this.state.keyWordsResponse.length > 0 ?
                <div className="display_block bot_40">
                    <p className="seo_text_header">{strings.keywordsStats}</p>
                    <PercentsAso seoPercentsTop={this.state.seoPercentsTop}/>
                    <div style={this.state.isShowSearch ? {display : 'block'} : {display : 'none'}} className="input_container_border search_aso">{strings.filterByWord}
                        <DebounceInput debounceTimeout={200} autoComplete="off" type="text" onChange={this.searchTextChanged} className="input_text_search" placeholder={strings.searchRequestExample} value={this.state.searchText}/>
                        {this.state.searchText && this.state.searchText.length > 0 && <img onClick={this.deleteFilter} class="close_input_icon" src={close_icon} alt=""></img>}
                    </div>
                       
                    <div className="keywords_cont_all">
                        <div className="search_keys_header">
                            <div onClick={this.searchClicked} className="search_container">
                                <p className="search_keys_header_request">{strings.asoRequest}</p>
                                <div style={this.state.isShowSearch ? {'background' : '#E4ECEF'} : {'background' : 'none'}} className="search_icon_container">
                                    <img className="search_icon_seo" src={search_icon} alt=""/>
                                </div>
                            </div>
                            <p className="search_keys_header_position">{strings.asoPosition}</p>
                        </div>

                        
                        {this.state.filteredKeywords.length > 0 ? this.state.filteredKeywords.map((item, index) => (
                            <KeywordItem checkedKeywords={this.state.keywordsTask}  keywordChecked={this.keywordChecked} keyword={item} index={index} key={index}/>
                        )) : 
                        <div className="not_found_container">
                            <p className="not_found_text">{strings.notFoundKeywords}</p>
                        </div>
                        }
                        
                    </div>
                     
                </div>
                :<EmptyStats text1={this.state.textError1} text2={this.state.textError2} text3={this.state.textError3} icon={this.state.errorIcon}/>} 
                
                {this.state.keyWordsResponse && this.state.keyWordsResponse.length > 0 && <AsoCreateTask createOrder={this.createOrder} keywordsTask={this.state.keywordsTask} checkedKeywordsString={this.state.checkedKeywordsString}/>}
            </div>
           
        );
    }
}
export default Aso;