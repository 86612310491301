/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import {DebounceInput} from 'react-debounce-input';
import FilterDropdown from '../FilterDropdown';
import arrow_filter from '../custom_icons/drop_arrow.svg'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { declension, getAnimationStyle } from '../utils_android/Utils';
import ContentEditable from 'react-contenteditable'
import AutoAnswerControll from './AutoAnswerControll';

var strings = getLocalizedStrings()
var controlTypes = [strings.controlTypeReview, strings.controlTypeTime]
var reviewDays = [strings.reviewDays1, strings.reviewDays2, strings.reviewDays3, "1 " + strings.reviewDays7, strings.reviewDays14]
var arrowRotate = {
    transform: 'rotate(180deg)',
    marginTop: '6px'
}

const ControlType = (props) => {
    const [state, setState] = useState({
        isShowTypes: false,
        isShowAutoReview: false,
        reviewText: "",
        secCount: 15,
        secCountDeclencion: strings.sec5,
        filterPicked: strings.typeNotPicked,
        autoReviewPicked: strings.reviewDays1,
        isNeedScreen: false,
        isNeedMinTimeReview: false
        
    });

    const toogleTypes = () => {
        setState(prev => ({ ...prev, isShowTypes: !prev.isShowTypes }));
    };

    const toogleAuto = () => {
        setState(prev => ({ ...prev, isShowAutoReview: !prev.isShowAutoReview }));
    };

    const onTypePicked = (type) => {
        setState(prev => ({ ...prev, filterPicked: type }));
        props.typePicked(type);
    };

    const onAutoReviewPicked = (type) => {
        setState(prev => ({ ...prev, autoReviewPicked: type }));
        props.autoReviewPicked(type);
    };

    const reviewTextChanged = (event) => {
        if (event) {
            const text = event.target.value;
            setState(prev => ({ ...prev, reviewText: text }));
            props.reviewTextChanged(text);
        }
    };

    const secundsChanged = (event) => {
        if (event) {
            let text = event.target.value;
            if (text !== "" && text !== undefined) {
                const sec = parseInt(text);
                if (sec >= 3000) {
                    text = 3000;
                    event.target.value = 3000;
                }
            }
            setState(prev => ({
                ...prev,
                secCount: text,
                secCountDeclencion: declension(text, strings.sec1, strings.sec2, strings.sec5)
            }));
            props.secundsChanged(text);
        }
    };

    const isNeedScreenChanged = (event) => {
        if (event) {
            const value = event.target.checked;
            setState(prev => ({ ...prev, isNeedScreen: value }));
            props.isNeedScreenChanged(value);
        }
    };
    const isNeedMinTimeReview = (event) => {
        if (event) {
            const value = event.target.checked;
            setState(prev => ({ ...prev, isNeedMinTimeReview: value }));
            props.isNeedMinTimeReview(value);
        }
    };

    return (
        <div className="display_block">
            <div className="order_row">
                <div style={props.isClickedCreate && state.filterPicked === strings.typeNotPicked ? {border: "1px solid rgba(255, 98, 98, 0.5)"} : {}} 
                     onClick={toogleTypes} 
                     className="button_choose_app choose_type new_border new_border_hover">
                    <p className="choose_type_title">{strings.controlType}</p>
                    <div className="just_content">
                        <p className="button_choose_text">{state.filterPicked}</p>
                        <img alt='' style={state.isShowTypes ? arrowRotate : {}} className="choose_drop_arrow" src={arrow_filter}/>
                    </div>
                    {state.isShowTypes && <FilterDropdown style={{top: '34px', width: 'calc(100% + 4px)'}} filters={controlTypes} onFilterPicked={onTypePicked}/>}
                </div>
                <div className="input_description_container">
                    <p className="input_description_text">{strings.controlTypeDesc}</p>
                </div>
            </div>
            <div style={state.filterPicked == strings.controlTypeReview ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, pointerEvents : 'none'}} className="order_row screen_row">
                <div className="switch_container flex screen_row_descr">
                    <label className="switch">
                        <input type="checkbox" onChange={isNeedScreenChanged} checked={state.isNeedScreen} />
                        <div className="slider round"></div>
                    </label>

                    <p className="text-pre-subscr screen_title">{strings.needScreenTitle}</p>
                </div>
                <div className="input_description_container">
                    <p className="input_description_text screen_descr_text">{strings.needScreenDescr}</p>
                </div>
            </div>
            <div style={state.filterPicked == strings.controlTypeReview ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, pointerEvents : 'none'}} className="display_block height_animation">
                <div className="order_row">
                    <div style={props.isClickedCreate && state.reviewText == "" ? {border : "1px solid rgba(255, 98, 98, 0.5)"} : {}} className="universal_input universal_review_input display_block">
                        <ContentEditable spellCheck="false" autoComplete="off" type="text" onChange={reviewTextChanged} className="input_text_search text_area_input desc_input_text" placeholder={strings.controlTypeReviewPlaceholder} html={state.reviewText}/>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{strings.controlTypeReviewDesc}</p>
                    </div>
                </div>
                <AutoAnswerControll csv={props.csv} autoAnswer={props.autoAnswer} autoAnswerChanged={props.autoAnswerChanged}/>
                
                <div className="order_row top_30">
                    <div onClick={toogleAuto} className="button_choose_app choose_type new_border new_border_hover">
                            <p className="choose_type_title">{strings.autoReviewTitle}</p>
                            <div className="just_content">
                                <p className="button_choose_text">{state.autoReviewPicked}</p>
                                <img alt='' style={state.isShowAutoReview ? arrowRotate : {}} className="choose_drop_arrow" src={arrow_filter}/>
                            </div>
                            {state.isShowAutoReview && <FilterDropdown style={{top: '34px', width: 'calc(100% + 4px)'}} filters={reviewDays} onFilterPicked={onAutoReviewPicked}/>}
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{strings.autoReviewDesc}</p>
                    </div>
                </div>

            </div>
            
           
            <div style={state.filterPicked == strings.controlTypeReview ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, pointerEvents : 'none'}} className="order_row screen_row">
                <div className="switch_container flex screen_row_descr">
                    <label className="switch">
                        <input type="checkbox" onChange={isNeedMinTimeReview} checked={state.isNeedMinTimeReview} />
                        <div className="slider round"></div>
                    </label>

                    <p className="text-pre-subscr screen_title">{strings.needMinTimeReview}</p>
                </div>
                <div className="input_description_container">
                    <p className="input_description_text screen_descr_text ">{strings.needMinTimeReviewDesc}</p>
                </div>
            </div>

            <div style={(state.filterPicked == strings.controlTypeTime || (state.isNeedMinTimeReview && state.filterPicked == strings.controlTypeReview)) ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, pointerEvents : 'none'}} className="display_block height_animation">
                <div className="order_row">
                    <div style={{display : 'block'}} className="universal_input top_30 display_block">{strings.secundsTitle}
                            <DebounceInput autoComplete="off" debounceTimeout={100}
                            onChange={secundsChanged} className="input_text_search top_8" placeholder={strings.searchRequestExample} value={state.secCount}/>
                            <p className="universal_desc secs_text">{state.secCountDeclencion}</p>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text min_time_review_descr">{strings.secundsDesc}</p>
                    </div>
                </div>
            </div>

            
        </div>
    );
};

export default ControlType;