import { getHttpParams } from "../pages_android/custom_views/analyze_review/utils/Utils";
import { getRequestParams, getRequestUrl } from "../pages_android/custom_views/utils_android/CookieHelper";
import { log, onResponse } from "./ApiUtils";
export class AppsApi {
    
    static addNewOrder(params, onSuccess, onError) {
        fetch(getRequestUrl() + "addNewOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("addNewOrder response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static searchAppAllData(params, onSuccess, onError) {
        fetch(getRequestUrl() + "searchAppAllData", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("searchAppAllData response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getAppInfo(params, onSuccess, onError) {
        fetch(getRequestUrl() + "getAppInfo", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getAppInfo response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static searchApp(params, onSuccess, onError) {
        fetch(getRequestUrl() + "searchApp", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("searchApp response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getMyApps(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getMyApps", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getMyApps response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   

}