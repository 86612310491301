import React from 'react';
class LimitsSwitch extends React.Component {
    constructor(props){
        super(props)
        
    }
   
    render() {
        return (
            <div style={this.props.isLimitHourChecked ? {marginBottom : '16px'} : {marginBottom : '0px'}} className="order_row">
                            <div className="switch_container flex">
                                <label className="switch">
                                    <input type="checkbox" onChange={this.props.limitMinuteClicked} checked={this.props.isLimitMinutesChecked}/>
                                    <div className="slider round"></div>
                                </label>

                                <p className="text-pre-subscr">{this.props.limitsMinute}</p>
                            </div>
                            <div className="input_description_container">
                                <p className="input_description_text">{this.props.limitsMinuteDesc}</p>
                            </div>
                        </div>
           
        );
    }
}
export default LimitsSwitch;