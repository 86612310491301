/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import ChartPart from './ChartPart';
import { getAnimationStyle } from './utils_android/Utils';

var strings = getLocalizedStrings()

class ProgressOrder extends React.Component {
    constructor(props){
        super(props)
        
        this.state = {
            isProgressShown : this.props.isProgressOrder,
            percents_array : [1,2,3,4,6,6.5,7,7.5,8,8.5,9.5,11,12],
            progress_array : this.props.progress_array ? this.props.progress_array : []
        } 
        this.onProgressClicked = this.onProgressClicked.bind(this)
    }
    onProgressClicked(){
        this.setState({isProgressShown : true})
        this.props.progressChanged(true)
    }
    componentDidUpdate(prevProps){
        if(this.props != prevProps){
            this.setState({isProgressShown : this.props.isProgressOrder, progress_array : this.props.progress_array ? this.props.progress_array : []})
        }
    }
   
    render() {
        return (
            <div className="block">
                <div style={this.state.isProgressShown ? {marginBottom : '30px'} : {marginBottom : '0px'}} className="order_row">
                    <div className="switch_container flex">
                        <div onClick={this.onProgressClicked} style={this.state.isProgressShown ? {border : "1px solid #F5DB31"} : {}} className="radio_limits hover">
                            {this.state.isProgressShown && <div className="radio_inside"/>}
                        </div>

                        <p className="text-pre-subscr">{strings.progressOrder}</p>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{this.props.fromBots ? strings.progressOrderDesc7days : strings.progressOrderDesc1} {this.props.installCount} {strings.progressOrderDesc2}</p>
                    </div>
                </div>

                <div style={this.state.isProgressShown ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, display : 'none'}} className="flex progress_orders_margin height_animation">
                    <div className="display_block margin_r_16">
                        <p className="text_progress_order bot_16 right_text">{strings.progressOrderChart1}</p>
                        <p className="text_progress_order right_text">{strings.progressOrderChart2}</p>
                    </div>
                    <div className="flex width_100">
                        {this.state.progress_array.length > 0 ? 
                            this.state.progress_array.map((item, index) => (
                                <ChartPart isHideLine={this.state.progress_array.length == index + 1} installs={item} day={index + 1} key={index}/>
                            ))
                        :
                            this.state.percents_array.map((item, index) => (
                                <ChartPart installs={parseInt(item * this.props.installCount / 100)} day={index + 1} key={index}/>
                            ))
                        }
                        {this.state.progress_array.length == 0 &&
                            <div className="display_block">
                                <p className="text_progress_order">{14}</p>
                                <div className="flex">
                                    <div className="yellow_round"/>
                                </div>
                                <p className="text_progress_order progress_top">{parseInt(14 * this.props.installCount / 100)}</p>
                                
                            </div>
                        }
                        
                    </div>
                </div>

            </div>
           
        );
    }
}
export default ProgressOrder;