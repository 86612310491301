import React from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import ContentEditable from 'react-contenteditable'
import bold_icon from '../custom_icons/bold_icon.svg'
import image_pick from '../custom_icons/image_pick.svg'
import upload from '../custom_icons/upload.svg'
import eye_preview from '../custom_icons/eye_preview.svg'

import { getRequestUrl, getEmail } from '../utils_android/CookieHelper';
import InputLoader from '../utils_android/InputLoader';
import CsvItem from '../../list_items/CsvItem';
import { string } from 'prop-types';
import { getAnimationStyle } from '../utils_android/Utils';
var strings = getLocalizedStrings()
var img = new Image; 
var fr = new FileReader;
var pickedFile = {}
const tag1 = '<div class="csv_item_val"><p class="csv_item_container">'
const tag2 = '</p></div> <br/>'
class UniversalDescription extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            description : "",
            pickedImageUrl: "",
            isShowWidthError : false,
            isImageLoading : false,
            isCsvError : false,
            
        } 
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.fileCsvChangedHandler = this.fileCsvChangedHandler.bind(this);
        this.descriptionChanged = this.descriptionChanged.bind(this)
        this.isShuffleChanged = this.isShuffleChanged.bind(this)
        this.fileUpload = React.createRef();
        this.fileCsvUpload = React.createRef();

        this.pickImage = this.pickImage.bind(this);
    }
    descriptionChanged(event){
        if (event){
            var value = event.target.value
            if (value.includes("!!")){
                var newVal = value.split("!!")
                if (newVal.length == 3 && this.props.csvKeys.includes(newVal[1])){
                    value = newVal[0] + tag1 + newVal[1] + tag2
                }
            }
            this.setState({description :  value})
            
            this.props.descriptionChanged(value)
        }
    }
    
    toogleBold = () => {
        document.execCommand('bold');
    }
   
    fileChangedHandler(event) {
        if(event && event.target.files && event.target.files.length > 0){
            this.uploadFile(event.target.files[0])
        }
       
    }
    isShuffleChanged(event) {
        if (event){
            var value = event.target.checked
            this.props.isShuffleChanged(value)
        }
    }
    fileCsvChangedHandler(event){
        if(event && event.target.files && event.target.files.length > 0){
            const file = event.target.files[0]
            var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/;
            if (typeof (FileReader) != "undefined") {
                var reader = new FileReader();
                reader.onload = this.parseCSV
                reader.readAsText(file);
            } else {
                alert("This browser does not support HTML5.");
            }
        }
    }
    csvJSON = (str) => {
        var arr = [];
        var quote = false;  // 'true' means we're inside a quoted field
    
        // Iterate over each character, keep track of current row and column (of the returned array)
        for (var row = 0, col = 0, c = 0; c < str.length; c++) {
            var cc = str[c], nc = str[c+1];        // Current character, next character
            arr[row] = arr[row] || [];             // Create a new row if necessary
            arr[row][col] = arr[row][col] || '';   // Create a new column (start with empty string) if necessary
    
            // If the current character is a quotation mark, and we're inside a
            // quoted field, and the next character is also a quotation mark,
            // add a quotation mark to the current column and skip the next character
            if (cc == '"' && quote && nc == '"') { arr[row][col] += cc; ++c; continue; }
    
            // If it's just one quotation mark, begin/end quoted field
            if (cc == '"') { quote = !quote; continue; }
    
            // If it's a comma and we're not in a quoted field, move on to the next column
            if (cc == ',' && !quote) { ++col; continue; }
    
            // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
            // and move on to the next row and move to column 0 of that new row
            if (cc == '\r' && nc == '\n' && !quote) { ++row; col = 0; ++c; continue; }
    
            // If it's a newline (LF or CR) and we're not in a quoted field,
            // move on to the next row and move to column 0 of that new row
            if (cc == '\n' && !quote) { ++row; col = 0; continue; }
            if (cc == '\r' && !quote) { ++row; col = 0; continue; }
    
            // Otherwise, append the current character to the current column
            arr[row][col] += cc;
        }
        var isCsvError = false

        var finalArray = []
        var headers = []
        for (let index = 0; index < arr.length; index++) {
            const elements = arr[index];
            if (index == 0){
                headers = elements
            } else {
                var object = {}
                for (let j = 0; j < elements.length; j++) {
                    const element = elements[j]
                    if (element != "" && element != undefined){
                        object[headers[j]] = element
                    } else {
                        isCsvError = true
                    }
                }
                finalArray.push(object)
            }
        }
        return [finalArray, isCsvError, headers];
    }
   
    parseCSV = (e) => {
        const str = e.target.result
        const csvResult = this.csvJSON(str)
        this.props.setCsv(csvResult[0], csvResult[1], csvResult[2])
    }
    uploadFile = (file) => {
        this.setState({isShowWidthError : false, isImageLoading : true})
        pickedFile = file
        fr.onload = this.onLoadedFileReader
        fr.readAsDataURL(file);
    }
    onImgLoaded = () => {
        if (img.width > 1280){
            this.setState({isShowWidthError : true, isImageLoading : false})
        } else {
            var scale = img.height / img.width 
            var height = 120 * scale
            this.uploadToServer(height, pickedFile)
        }
        
    }
    onLoadedFileReader = () => {
             
        img.onload = this.onImgLoaded
        img.src = fr.result;
    }
    uploadToServer = (height, file) => {
        var data = new FormData()
        data.append('file1', file)
     
        fetch(getRequestUrl() + "uploadFile", {
            method: 'POST',
            mimeType: "multipart/form-data",
            body: data
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1){
                var img = "<img id='pickedImg' src=" + responseData.url + " style='height : " + height + "px;width : 120px;cursor:pointer;margin-top:16px;margin-bottom:16px;'/>"
                const value = this.state.description += img
                this.setState({description : value, pickedImageUrl : responseData.url, isImageLoading : false})
                this.props.descriptionChanged(value)
            } else {
                this.setState({isImageLoading : false})
            }
            
        })
    }
    componentDidUpdate(){
        var pickedImg = document.getElementById("pickedImg")
        if  (pickedImg != null && pickedImg != undefined){
            pickedImg.onclick = this.showFullImg
        }
    }
    showFullImg = () => {

    }
    pickImage() {
        this.fileUpload.current.click();
    }
    pickCsv = () => {
        this.fileCsvUpload.current.click()
    }
    openBlog = () => {
        window.open('https://blog.upmob.ru/kak-nakrutit-zagotovlennye-otzyvy-v-google-play/', '_blank');
    }
    
    render() {
        return (
            <div className="display_block">
                    <p className="universal_desc">{strings.universalDescriptionTitle}</p>
                    <div className="order_row">
                        <div className="desc_row">
                            <div className="edit_controls">
                                <div className="flex">
                                    <img onClick={this.toogleBold} className="hover" src={bold_icon}/>
                                    <img onClick={this.pickImage} className="m_left_8 hover" src={image_pick}/>
                                    <input id="fileInput" className="custom-file-input hover" type="file" accept="image/*" onChange={this.fileChangedHandler} style={{ display: "none" }} ref={this.fileUpload}/>

                                </div>
                                {this.state.isShowWidthError && <p className="width_error">{strings.maxWidthError}</p>}
                                {this.state.isImageLoading && <InputLoader style={{position : 'initial'}}/>}
                                
                            </div>
                                
                            <div style={this.props.isClickedCreate && this.state.description == "" ? {border : "1px solid rgba(255, 98, 98, 0.5)"} : {}} className="universal_input display_block edit_input_controls">
                                <ContentEditable spellCheck="false" autoComplete="off" type="text" onChange={this.descriptionChanged} className="input_text_search text_area_input desc_input_text" placeholder={strings.universalDescription} html={this.state.description}/>
                            </div>
                        </div>
                        
                        <div className="input_description_container">
                            <p className="univ_csv_description">{strings.univDescriptionCsv}</p>
                            <div>
                                <div onClick={this.pickCsv} className="upload_csv_container hover">
                                    <img className="upload_csv_img" src={upload}/>
                                    <p className="upload_csv_text">{strings.uploadCSV}</p>
                                </div>
                                <p className="univ_csv_description">{strings.univDescriptionCsv2} <b onClick={this.openBlog} className="hover upload_csv_text">{strings.univDescriptionCsv3}</b></p>
                                <input id="fileCsvInput" className="custom-file-input hover" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={this.fileCsvChangedHandler} style={{ display: "none" }} ref={this.fileCsvUpload}/>
                            </div>
                        </div>
                    </div>
                        
                    {(this.props.csv != undefined) && <div className="csv_container_result">
                        {this.state.isCsvError ? <div>
                            <p className="csv_error_text">{strings.csvErrorText}</p>
                            <p>{strings.csvErrorDesc}</p>
                        </div> : 
                        <div>
                            <p className="csv_error_text csv_params">{strings.csvParams}</p>
                            <div style={{flexWrap : 'wrap'}} className="flex">
                                {this.props.csv != undefined  && this.props.csvKeys.map((item, index) => (
                                    <CsvItem index={index} item={item} key={index}/>
                                ))}
                            </div>
                            <p>{strings.csvParamsDesc} <b>{strings.csvParamsDescKey}</b></p>
                            <div className="flex_m">                           
                                <div style={this.props.csv != undefined ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0, pointerEvents : 'none'}} className="order_row screen_row">
                                    <div className="switch_container flex screen_row_descr">
                                        <label className="switch">
                                            <input type="checkbox" onChange={this.isShuffleChanged} checked={this.props.isCsvShuffle} />
                                            <div className="slider round"></div>
                                        </label>

                                        <p style={{opacity : 1}} className="text-pre-subscr screen_title">{strings.isShuffleCsv}</p>
                                    </div>
                                </div>
                                <div onClick={this.props.togglePreviewDialog} className="flex hover preview_task_cont">
                                    <img className="preview_task_img" src={eye_preview}/>
                                    <p className="hover preview_task_text">{strings.previewTask}</p>
                                </div>
                                
                            </div>
                        </div>}
                    </div>}
            </div>
           
        );
    }
}
export default UniversalDescription;