import React from 'react';

import '../pages_css/tooltip.css';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { getRequestParams } from '../utils_android/CookieHelper';
import { getRequestUrl, getHttpParams } from '../analyze_review/utils/Utils';
var strings = getLocalizedStrings()
class ChangeUnvirsalPrice extends React.Component {
    constructor(props){
        super(props)
        this.state = {
           price : this.props.price_one_install
        }
       
        
    }
    onUniversalPriceChange = (event) => {
        if (event){
            var count = event.target.value
            this.setState({price : count})
        }
    }
    closeTooltip = () => {
        this.props.closeUpBalance()
    }
   
    changeUniversalPrice = () => {
        var params = getRequestParams()
        params._id = this.props.order_id
        params.count_price_customer_r = this.state.price
        fetch(getRequestUrl() + "setPriceYoutubeOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            const order = this.props.order
            order.count_price_customer_r = this.state.price
            order.max_count_installs = (Math.round(this.props.order.count_money_r * 10) / 10) / this.state.price
            this.props.orderChanged(order, this.props.index)
        })
        this.closeTooltip()
    }
    render() {
        return (
            <div className="display_block">
                <div onClick={this.closeTooltip} className="tooltip_bg_up_balance"></div>
                <div style={this.props.style ? this.props.style : {}} className="tooltip_plashka">
                 
                    <div style={this.props.isLeft ? {} : {right : 'auto', left : '-8px'}} className="tooltip_arrow"></div>
                    <div className="display_block">
                        <div className="padding_24 tooltip_right">
                            <p className="up_balance_title">{strings.oneUniversalPriceChange}</p>
                            <input value={this.state.price} onChange={this.onUniversalPriceChange} className="input_up_balance"/>
                            <p className="minimal_price_univ">{strings.oneUniversalPriceMin} {this.props.price_one_install}₽</p>
                            {/* <div className="price_container">
                                <p>{strings.oneUniversalPriceCount}</p>
                                <p className="bold"> {this.props.price_one_install }₽</p>
                            </div>
                            <div className="price_container">
                                <p >{strings.upSum}</p>
                                <p className="bold">{this.props.price_one_install*this.state.countInstalls}₽</p>
                            </div> */}
                            <p style={(this.state.price == "" || this.state.price <= this.props.price_one_install) ? {pointerEvents : 'none', opacity : '0.2'} : {}} onClick={this.changeUniversalPrice} className="add_btn hover">{strings.saveRenamTag}</p>
                            <p onClick={this.closeTooltip} className="cancel_btn hover">{strings.upCancel}</p>
                            <div className="line_white_bg"/>
                            <div className="line_white_bg_left"/>
                            
                        </div>
                    </div>
                </div>
            </div>
           
        );
    }
}
export default ChangeUnvirsalPrice;
