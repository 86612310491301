import { getBotRequestUrl, getHttpParams, getRequestUrl } from "../pages_android/custom_views/analyze_review/utils/Utils";
import { getRequestParams } from "../pages_android/custom_views/utils_android/CookieHelper";
import { log, onResponse } from "./ApiUtils";
export class BotApi {
    
    static getCountries(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getBotRequestUrl() + "getCountries", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getCountries response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getAllCities(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getAllCities", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getAllCities response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getPrices(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getBotRequestUrl() + "getPrices", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getPrices response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getMyOrders(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getBotRequestUrl() + "getMyOrders", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getMyOrders response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getOrderDetails(id, onSuccess, onError) {
        const params = getRequestParams()
        params.external_id = id
        fetch(getBotRequestUrl() + "getOrderDetails", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getOrderDetails response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static cancelOrder(id, onSuccess, onError) {
        const params = getRequestParams()
        params.external_id = id
        fetch(getBotRequestUrl() + "cancelOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("cancelOrder response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    
    static addOrder(params, onSuccess, onError) {
        fetch(getBotRequestUrl() + "addOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("addOrder response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getProgressionDistribution(count_installs, countKeywords, onSuccess, onError) {
        fetch(getBotRequestUrl() + "getProgressionDistribution", getHttpParams({count_installs : count_installs, count_keywords : countKeywords})).then((response) => response.json())
        .then((responseData) => {
            log("getProgressionDistribution response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }

}