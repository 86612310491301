/* eslint-disable eqeqeq */
import React from 'react';
import close_order from '../custom_views/custom_icons/close_order.svg'
import play from '../custom_views/custom_icons/play.svg'
import pause from '../custom_views/custom_icons/pause.svg'
import order_type_rate from '../custom_views/custom_icons/order_type_rate.svg';
import add from '../custom_views/custom_icons/add.svg';
import trash_red from '../custom_views/custom_icons/trash_red.svg';
import empty_orders from '../custom_views/custom_icons/cross.svg';
import { getOrderInfoStatusText, getOrderInfoStatusColor, getSearchWords, getStartUniversalDate, getOrderTypeName, getControlTimeText, declension, getEmptyProofText, getIsDesktop } from './utils_android/Utils';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import { getRequestUrl, getRequestParams } from './utils_android/CookieHelper';
import CircleProgress from './CircleProgress';
import DeleteOrderDialog from './dialogs/DeleteOrderDialog';
import AddMoneyUniversal from './universal_tasks/AddMoneyUniversal';
import ToolbarUniversalInfo from './universal_tasks/ToolbarUniversalInfo';
import ProofItem from '../list_items/ProofItem';
import { CSSTransitionGroup } from 'react-transition-group';
import EmptyStats from './empty_views/EmptyStats';
import ExchangeTooltip from './analyze_review/customViews/tooltips/ExchangeTooltip';
import { getHttpParams } from './analyze_review/utils/Utils';
import OrderInfoStats from './OrderInfo/OrderInfoStats';
import ChangeUnvirsalPrice from './universal_tasks/ChangeUnvirsalPrice';
import ProofsToolbar from './universal_tasks/ProofsToolbar';
import ChangeUniversalLimits from './universal_tasks/ChangeUniversalLimits';
var strings = getLocalizedStrings()
class UniversalTaskInfo extends React.Component {
    constructor(props) {
        super(props)
        var searchWords = getSearchWords(this.props.order.search_request)
        if (searchWords && searchWords.length == 1) {
            searchWords[0].positionBefore = this.props.order.search_position
        }
        this.state = {
            searchWords: searchWords,
            isShowUpBalance: false,
            isShowDeleteDialog: false,
            isShowAbout: true,
            proof_orders_youtube: this.props.order.proof_orders_youtube,
            selectedProofFilter: strings.proofFilterNew,
            filteredOrderProofs: [],
            inProcess: 0,
            finished: 0,
            isLoading: false,
            isShowChangePrice: false,
            isShowMassAnswer: false,
            isShowChangeLimits : false

        }

    }
    componentDidUpdate(prevProps) {
        if (prevProps != this.props) {
            this.setState({ proof_orders_youtube: this.props.order.proof_orders_youtube })
            this.filterProofs(this.state.selectedProofFilter, this.props.order.proof_orders_youtube)
        }
    }
    componentDidMount() {
        this.filterProofs(this.state.selectedProofFilter, this.state.proof_orders_youtube)
        this.getOrdersYoutubeByID()
    }
    toggleMassAnswer = () => {
        if (this.state.isShowMassAnswer) {
            var proofs = this.state.filteredOrderProofs
            proofs.forEach(proof => {
                if (proof.isSelected) {
                    proof.isSelected = false
                }
            })
            this.setState({ filteredOrderProofs: proofs })
        }
        this.setState({ isShowMassAnswer: !this.state.isShowMassAnswer })

    }
    getOrdersYoutubeByID = () => {
        var params = getRequestParams()
        params._id = this.props.order._id
        fetch(getRequestUrl() + "getOrderYoutubeByID", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == "1" && responseData.order && responseData.order.proof_orders_youtube) {
                    var orderProofs = responseData.order.proof_orders_youtube.reverse()
                    this.setState({ proof_orders_youtube: orderProofs })
                    this.filterProofs(this.state.selectedProofFilter, orderProofs)
                    this.getInProccess(orderProofs)
                    this.props.setNewOrderProofs(responseData.order.proof_orders_youtube, this.props.index)
                }
                this.setState({ isLoading: false })
            })
    }

    closeOrderInfo = () => {
        this.props.orderInfoClosed()
    }
    filterProofs = (filter, fullOrders) => {
        this.setState({ selectedProofFilter: filter })
        var proofs = fullOrders.filter(function (proof) {
            if (filter == strings.proofFilterNew) {
                return (proof.state == 0);
            } else if (filter == strings.proofFilterOndo) {
                return (proof.state == 2);
            } else if (filter == strings.proofFilterAccepted) {
                return (proof.state == 1);
            } else if (filter == strings.proofFilterRejected) {
                return (proof.state == -1);
            }
        });

        if (filter == strings.proofFilterNew) {
            this.setState({ badgeCount: proofs.length })
            this.props.badgeCountChanged(proofs.length, this.props.index)
        }
        this.setState({ filteredOrderProofs: proofs, emptyFilteredText: getEmptyProofText(filter) })

    }

    getInProccess = (proofs) => {
        var inProcess = 0
        var finished = 0
        proofs.forEach(element => {
            if (element.state == 3) {
                inProcess += 1
            }
            if (element.state == 1) {
                finished += 1
            }
        });
        this.setState({ inProcess: inProcess, finished: finished })
    }
    setStateOrder = () => {
        var params = getRequestParams()
        var newState = (this.props.order.state == 1 ? 0 : 1)
        params._id = this.props.order._id
        params.state = newState
        this.setState({ isLoading: true })
        fetch(getRequestUrl() + "setStateYoutubeOrder", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1) {
                    this.props.order.state = newState
                    this.props.orderChanged(this.props.order, this.props.index)
                }
                this.setState({ isLoading: false })
            })
    }
    searchAppPosition = (position, index) => {
        var changedArray = this.state.searchWords
        changedArray[index].currentPosition = position
        this.setState({ searchWords: changedArray })
    }
    upBalance = () => {
        this.setState({ isShowUpBalance: !this.state.isShowUpBalance })
    }
    closeUpBalance = () => {
        this.setState({ isShowUpBalance: false })
    }
    openStats = () => {
        window.open('/statistics' + "?bundle=" + this.props.order.bundle, '_blank');
    }
    openTaskUrl = () => {
        window.open(this.props.order.url, '_blank');
    }

    deleteOrder = () => {
        this.setState({ isShowDeleteDialog: true })
    }
    onCanceledDelete = () => {
        this.setState({ isShowDeleteDialog: false })
    }
    onDelete = () => {
        var params = getRequestParams()
        params._id = this.props.order._id
        fetch(getRequestUrl() + "removeOrderYoutube", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                this.setState({ isShowDeleteDialog: false })
                this.props.deleteOrder(this.props.index)
                this.props.getMyInfo()
            })
    }
    onToolbarChanged = (state) => {
        this.setState({ isShowAbout: state })
    }
    changeProofState = (state, index, comment, isOnWorkProof) => {
        this.props.changeProofState(state, index, this.props.index, comment, isOnWorkProof)
        this.props.removeBadge()
    }

    onProofFilterPicked = (filter) => {
        this.filterProofs(filter, this.state.proof_orders_youtube)
    }
    getOrderDesc = () => {
        var htmlObject = document.createElement('div');
        htmlObject.innerHTML = this.props.order.description;
        var containers = htmlObject.getElementsByClassName("csv_item_container")
        for (let index = 0; index < containers.length; index++) {
            const element = containers[index];
            element.style.background = "white";
        }
        return htmlObject.innerHTML
    }
    toggleChangePrice = () => {
        this.setState({ isShowChangePrice: !this.state.isShowChangePrice })
    }
    toggleChangeLimits = () => {
        this.setState({ isShowChangeLimits: !this.state.isShowChangeLimits })
    }

    toggleLoader = () => {
        this.setState({ isLoading: !this.state.isLoading })
    }
    proofsChanged = (proof, index) => {
        var proofs = this.state.filteredOrderProofs
        proofs[index] = proof
        this.setState({ filteredOrderProofs: proofs })
    }
    render() {
        const isOrderCompleted = this.props.order.count_installed >= this.props.order.max_count_installs
        const isBlocked = (this.props.order.moderated == -1 && !isOrderCompleted)
        return (
            <div className="order_info">
                <img onClick={this.closeOrderInfo} src={close_order} className="close_order_icon hover" />
                <div className="flex">
                    <div className="order__info_icon_container">
                        <img className="order_app_icon" src={this.props.order.icon ? this.props.order.icon : order_type_rate} />
                    </div>
                    <div className="info_title_container">
                        <p className="info_title">{this.props.order.name}</p>
                        <div className="order_info_status_container">
                            <p style={getOrderInfoStatusColor(this.props.order)} className="order_info_status">{getOrderInfoStatusText(this.props.order)}</p>
                            {isOrderCompleted ? <p className="finished_task_status">{strings.finishedTaskStatus}</p> :
                                <div onClick={this.setStateOrder} className="pause_play_info hover">
                                    <img src={this.props.order.state == 0 ? play : pause}></img>
                                    <p className="pause_play_info_text">{this.props.order.state == 0 ? strings.continuePlay : strings.pauseOrder}</p>
                                </div>}
                            <p className="date_text_order_info">{strings.createdAt} {getStartUniversalDate(this.props.order)}</p>
                        </div>
                    </div>
                </div>
                {(isBlocked) && <div className='order_info_block_reason'>{strings.orderBlockReason + " " + (this.props.order.reason_block ? this.props.order.reason_block : strings.orderBlockReasonNot)}</div>}

                <div className="info_container">
                    <div className="universal_info_container">
                        {this.state.proof_orders_youtube && this.state.proof_orders_youtube.length > 0 && <ToolbarUniversalInfo badgeCount={this.state.badgeCount} onProofFilterPicked={this.onProofFilterPicked} onToolbarChanged={this.onToolbarChanged} />}
                        <CSSTransitionGroup transitionName="example" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
                            {/* <div style={this.state.isShowAbout ? {opacity : 1.0, zIndex : 9} : {opacity : 0.0, zIndex : -9, display: 'none'}} className="flex_land"> */}
                            {this.state.isShowAbout && <div className="flex_land">
                                <div className="search_words_container pad_0 transition_opacity left_universal">
                                    <div className="universal_info_pad">
                                        <p className="universal_info_title">{strings.universalInfoTitle}</p>
                                        <p className="universal_info_desc" dangerouslySetInnerHTML={{ __html: this.getOrderDesc() }} />
                                    </div>
                                    {this.props.order.control_text != undefined && <div className="proof_container">
                                        <p className="universal_info_proof">{strings.universalInfoProof}</p>
                                        <p className="proof_desc" dangerouslySetInnerHTML={{ __html: this.props.order.control_text }} />
                                    </div>}

                                </div>

                                <div className="info_props_container transition_opacity right_universal">
                                    <p className="grey_text bot_8">{strings.universalTaskType}</p>
                                    <p>{getOrderTypeName(this.props.order.type_order)}</p>

                                    <p className="grey_text top_32">{strings.universalTaskUrl}</p>
                                    <p onClick={this.openTaskUrl} className="url_universal hover">{this.props.order.url}</p>

                                    <div>
                                        <p className="grey_text top_32">{strings.universalTaskProofType}</p>
                                        <p>{this.props.order.control_text ? strings.controlTypeReview : strings.controlTypeTime}</p>

                                        <p className="grey_text top_32">{strings.universalTaskProofAuto}</p>
                                        <p>{this.props.order.control_text ? getControlTimeText(this.props.order.hour_autocomplete) : (this.props.order.seconds_count + " " + declension(this.props.order.seconds_count, strings.sec1, strings.sec2, strings.sec5))}</p>
                                        {(this.props.order.limit_count_in_day != undefined && this.props.order.limit_count_in_day > 0 && this.props.order.limit_count_in_day != 10000) && <div>
                                            <p className="grey_text top_32">{strings.universalTaskProofLimit}</p>
                                            <div className="relative">
                                                <span onClick={this.toggleChangeLimits} className="url_universal hover">{this.props.order.limit_count_in_day}</span>
                                                {this.state.isShowChangeLimits && <ChangeUniversalLimits style={getIsDesktop() ? {top : '-180px'} : {top : '-166px', left : '32px'}} orderChanged={this.props.orderChanged} isLeft={getIsDesktop()} count_money_r={this.props.count_money_r} index={this.props.index} order_id={this.props.order._id} closeUpBalance={this.toggleChangeLimits} price_one_install={this.props.order.count_price_customer_r} order={this.props.order} />}

                                            </div>
                                        </div>}

                                    </div>
                                    {(this.props.order.city_ru != undefined && this.props.order.city_ru != "") && <div>
                                        <p className="grey_text top_32">{strings.cityTitle}</p>
                                        <p>{this.props.order.city_ru}</p>

                                    </div>}
                                   
                                </div>
                            </div>}

                            {/* {!this.state.isShowAbout && <div style={!this.state.isShowAbout ? {opacity : 1.0, zIndex : 9} : {opacity : 0.0, zIndex : -9, display: 'none'}} className="proofs_container transition_opacity"> */}
                            {!this.state.isShowAbout && <div className="proofs_container">
                                <div className="waitin_review_title">
                                    <p className="transition_opacity proof_list_title" style={this.state.selectedProofFilter == strings.proofFilterNew ? {} : { opacity: 0 }}>{strings.waitingForReviewTitle}</p>

                                </div>
                                <ProofsToolbar order={this.props.order} toggleMassAnswer={this.toggleMassAnswer} isShowMassAnswer={this.state.isShowMassAnswer} selectedProofFilter={this.state.selectedProofFilter} filteredOrderProofs={this.state.filteredOrderProofs} toggleLoader={this.toggleLoader} isLoading={this.state.isLoading} getOrdersYoutubeByID={this.getOrdersYoutubeByID} />
                                {(this.state.filteredOrderProofs.length > 0) ? this.state.filteredOrderProofs.map((item, index) => (
                                    <ProofItem proofsChanged={this.proofsChanged} isShowMassAnswer={this.state.isShowMassAnswer} isLoading={this.state.isLoading} changeProofState={this.changeProofState} proof={item} index={index} key={index} />
                                )) : <EmptyStats icon={empty_orders} text1={this.state.emptyFilteredText} text2="" text3="" />}

                            </div>}
                        </CSSTransitionGroup>
                    </div>

                    <div className="info_installs">
                        <p className="grey_text center_text">{strings.finishedTasksText}</p>
                        <CircleProgress state={this.props.order.state} count_installed={this.props.order.complete_count} max_count_installs={this.props.order.max_count_installs} percentage={(this.props.order.complete_count / this.props.order.max_count_installs) * 100 >= 100 ? 100 : (this.props.order.complete_count / this.props.order.max_count_installs) * 100} />
                        <div className="line_info" />
                        <p className="grey_text center_text">{strings.universalInProccess}</p>
                        <p className="budget_text">{this.state.inProcess}</p>
                        <div style={{ marginTop: '32px' }} className="line_info" />
                        <p className="grey_text center_text">{strings.budgetProp}</p>
                        <div className="ref_exchange_container center_hor">
                            <p className="budget_text">{Math.round(this.props.order.count_money_r * 10) / 10}₽</p>
                            <ExchangeTooltip style={{ marginTop: '22px' }} money={Math.round(this.props.order.count_money_r * 10) / 10} exchangeRate={this.props.exchangeRate} />
                        </div>
                        <p className="grey_text center_text">{strings.oneUniversalPrice} <span onClick={this.toggleChangePrice} className="url_universal hover">{Math.round(this.props.order.count_price_customer_r * 10) / 10}₽</span></p>
                        <div className="line_info_budget" />
                        <div onClick={this.upBalance} className="up_balance_info hover">
                            <img src={add}></img>
                            <p className="up_balance_info_text">{strings.upBalanceProp}</p>

                        </div>
                        {this.state.isShowUpBalance && <AddMoneyUniversal style={{ top: '266px' }} isLeft={true} count_money_r={this.props.count_money_r} index={this.props.index} order_id={this.props.order._id} addCoinsToOrder={this.props.addCoinsToOrder} closeUpBalance={this.closeUpBalance} price_one_install={this.props.order.count_price_customer_r} />}
                        {this.state.isShowChangePrice && <ChangeUnvirsalPrice orderChanged={this.props.orderChanged} style={getIsDesktop() ? { top: '242px', left: '-180px' } : { top: '242px', left: '-20px'}}  isLeft={true} count_money_r={this.props.count_money_r} index={this.props.index} order_id={this.props.order._id} closeUpBalance={this.toggleChangePrice} price_one_install={this.props.order.count_price_customer_r} order={this.props.order} />}

                        {/* <div onClick={this.openStats} className="up_balance_info top_16">
                            <img src={stats}></img>
                            <p className="up_balance_info_text">{strings.statsProp}</p>
                        </div> */}

                        <div onClick={this.deleteOrder} className="up_balance_info top_16 hover">
                            <img src={trash_red}></img>
                            <p className="up_balance_info_text red_text">{strings.deleteOrderInfo}</p>
                        </div>
                    </div>

                </div>
                {this.state.isShowDeleteDialog && <DeleteOrderDialog onCanceledDelete={this.onCanceledDelete} onDelete={this.onDelete} />}
                <div style={{ marginTop: '24px' }}>
                    <OrderInfoStats isUniversal={true} order={this.props.order} />
                </div>

            </div>
        );
    }
}
export default UniversalTaskInfo;