/* eslint-disable eqeqeq */
import React from 'react';
import { getRequestParams, getRequestUrl, isMore3Orders, setMore3Orders, getEmail } from '../pages_android/custom_views/utils_android/CookieHelper';
import OrderItem from './list_items/OrderItem';
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import arrow_filter from './custom_views/custom_icons/drop_arrow.svg'
import AppsDropdown from './custom_views/AppsDropdown';
import FilterDropdown from './custom_views/FilterDropdown';
import android_icon from './custom_views/custom_icons/android.svg'
import ios_icon from './custom_views/custom_icons/apple.svg'
import empty_orders from './custom_views/custom_icons/empty_orders.svg'
import cross from './custom_views/custom_icons/cross.svg'
import Loader from './custom_views/utils_android/LoaderAndroid';
import EmptyOrders from './custom_views/empty_views/EmptyOrders';
import EmptyStats from './custom_views/empty_views/EmptyStats';
import { getFilteredEmpty, convertToIDFA } from './custom_views/utils_android/Utils';
import IdfaDialog from './custom_views/dialogs/IdfaDialog';
import { AppsType, getHttpParams, getUrlParameter, Pages } from './custom_views/analyze_review/utils/Utils';
import OrderInfo from './custom_views/OrderInfo/OrderInfo';
import { Link, Route, withRouter } from "react-router-dom";
import { isAdmins } from './admin_views/utils/AdminUtils';

var strings = getLocalizedStrings()
var filters = [strings.allFilters, strings.workingFilters, strings.pauseFilters, strings.finishedFilters, strings.blockedFilters]
var dropdownStyle = {
    margin : "35px 0px 0px -6px",
    top : "auto"
}
var arrowRotate = {
    transform: 'rotate(180deg)',
    marginTop: '6px'
}
var notDisplay = {display : 'none'}
var display = {display : 'block'}
var androidIconStyle = {marginTop : '2px', display : 'block'}
class Orders extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading : true,
            orders : [],
            filteredOrders : [],
            appsList : [],
            isShowApps : false,
            isShowFilters : false,
            filterPicked : strings.allFilters,
            pickedApp : {name : strings.allApps},
            isShowOrderInfo : false,
            isShowIdfa : false,
            pickedOrderInfo : {},
            pickedOrderIndex : 0,
            totalSpend : 0,
            emptyFilteredText : strings.emptyFinishedOrders
            
        }
        this.toogleIdfa = this.toogleIdfa.bind(this)
        this.showIdfa = this.showIdfa.bind(this)
        this.sdk_order_id = getUrlParameter('sdk_order_id')
       
        
    }
    componentDidMount(){
        this.getOrders()
    }
    addMoney = (money) => {

    }
    orderChanged = (order, index) => {
        var changedArray = this.state.filteredOrders
        changedArray[index] = order
        this.setState({filteredOrders : changedArray})
        
    }
    deleteOrder = (index) => {
        var changedArray = this.state.filteredOrders
        changedArray.splice(index, 1);
        this.setState({filteredOrders : changedArray, isShowOrderInfo : false})
    }
    getMyApps = () => {
        var params = getRequestParams()
        fetch(getRequestUrl() + "getMyApps", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1) {
                var objectApp = {name : strings.allApps};
                responseData.apps.reverse()
                responseData.apps.unshift(objectApp)
                this.setState({appsList : responseData.apps})               
              }
        })
    }
    addCoinsToOrder = (id, count_money_r, count_installs, index) => {
        var params = getRequestParams()
        params.count_installs = count_installs
        params.count_money_r = count_money_r
        params._id = id
        fetch(getRequestUrl() + "addCoinsToOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
                if (responseData.response == 1) {
                    var changedArray = this.state.filteredOrders
                    var order = changedArray[index]
                    order.count_money_r = parseInt(order.count_money_r) + parseInt(count_money_r)
                    order.max_count_installs = parseInt(order.max_count_installs) + parseInt(count_installs)
                    changedArray[index] = order
                    this.setState({filteredOrders : changedArray})
                    this.props.updateMoney(-count_money_r)
              }
        })
    }
    getOrders = () => {
        var params = getRequestParams()
        fetch(getRequestUrl() + "getMyOrders", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1) {      
                if (responseData.orders.length > 0) {
                  if (responseData.orders.length >= 3 && !isMore3Orders()) {
                        setMore3Orders()
                  }
                  var orders = responseData.orders.reverse()
                  this.setState({orders : orders, filteredOrders : orders})
                  this.calculateSpending(orders)

                //   if (this.sdk_order_id != undefined && this.sdk_order_id != null && this.sdk_order_id != ""){
                //         for (let index = 0; index < responseData.orders.length; index++) {
                //             const ord = responseData.orders[index];
                //             if (ord._id == this.sdk_order_id){
                //                 this.showOrderInfo(ord, index)
                //                 break
                //             }
                            
                //         }
                //   }
                }
            }
            this.setState({isLoading : false})
            this.getMyApps()
            
            //console.log("getMyOrders = " + JSON.stringify(responseData.orders))
        })
        
    }
    onAppPicked = (app) => {
        this.setState({pickedApp : app})
        this.onFilterPicked(this.state.filterPicked, 0 , app);
    }
    calculateSpending = (orders) => {
        if (isAdmins() || getEmail() == "playhardbillions@yandex.ru" || getEmail().toLocaleLowerCase() == "xarok3267742@gmail.com"){
            var sum = 0
            var installCount = 0
            orders.forEach(element => {
                if (isAdmins()){
                    var price = element.price_one_install
                    if (price == 4){
                        price = 1
                    }
                    if (price == 6){
                        price = 2
                    }
                    if (price == 8){
                        price = 3
                    }
                    if (price == 10){
                        price = 4
                    }
                    sum += (element.max_count_installs * price)
                    installCount += element.max_count_installs
                } else {
                    sum += (element.max_count_installs * element.price_one_install)
                    installCount += element.max_count_installs
                }
            });
            //console.log("installCount = " + installCount)
            this.setState({totalSpend : sum})
            //this.loadIdfas(orders)
            
        }
    }
    onFilterPicked = (filter, index, app) => {
        this.setState({filterPicked : filter})
        var fullOrders = this.state.orders
        var pickedApp = (app ? app : this.state.pickedApp) 
            var orders = fullOrders.filter(function(order) {
                const isOrderCompleted = (order.count_installed >= order.max_count_installs || order.count_money_r < order.price_one_install || order.count_money_r < 7)
                var filtApps = ((pickedApp.name != strings.allApps) ? (pickedApp.bundle == order.bundle) : true);
                if (filter == strings.allFilters) {
                    return filtApps;
                } else if(filter == strings.workingFilters){
                    return (!isOrderCompleted && order.state == 1 && filtApps && order.moderated != -1);
                } else if(filter == strings.pauseFilters){
                    return (order.count_money_r >= order.price_one_install && order.state == 0 && filtApps);
                } else if (filter == strings.finishedFilters) {
                    return (isOrderCompleted && filtApps);
                } else if (filter == strings.blockedFilters){
                    return order.moderated == -1 && !isOrderCompleted && filtApps
                }
            });
        this.setState({filteredOrders : orders, emptyFilteredText : getFilteredEmpty(filter)})
        this.calculateSpending(orders)
    }
    
    loadIdfas = (orders) => {
        var idfas = []
        var i = 0
        orders.forEach(order => {
           // console.log(" i = " + i)
            i += 1
            setTimeout(function() {  
                var params = getRequestParams()
                params.order_id = order._id
               // console.log("order_id = " + order._id)
                fetch(getRequestUrl() + "getOrderEmployersWithExtra", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    responseData.employersInfo.forEach(element => {
                        if (element.photo_url == "" && element.google_user_id.length > 20){
                            element.google_user_id = convertToIDFA(element.google_user_id).toLowerCase()
                        } else {
                            element.google_user_id = ""
                        }
                        idfas.push(element)
                       
                        
                    });
                    
                    if (orders[(orders.length - 1)]._id == order._id){
                        var csvString = ""
                        if (order.type_os == "ios"){
                            idfas.forEach(element => {
                                if (element.google_user_id.length > 10){
                                    csvString = csvString + element.google_user_id + "\r\n"
                                }
                            });
                        } else {
                            idfas.forEach(element => {
                                csvString = csvString + element.ip + "\r\n"
                            });
                        }
                        var a         = document.createElement('a');
                        //a.href        = 'data:attachment/csv,' +  encodeURIComponent(csvString);
                        a.href        = 'data:application/octet-stream;base64,'+btoa(csvString);
                        //  a.target      = '_blank';
                        a.download    = order._id + '.csv';
                        document.body.appendChild(a);
                        a.click();
                    }
                })           
              }, (i * 1000))
        });
    }
   
   
    createOrder = () => {
        window.location.href = Pages.newOrder
    }
    toogleApps = () => {
        if (this.state.appsList && this.state.appsList.length > 0){
            this.setState({isShowApps : !this.state.isShowApps})
        }
        
    }
    toogleIdfa(){
        this.setState({isShowIdfa : !this.state.isShowIdfa})
    }
    showIdfa(order){
        this.setState({isShowIdfa : true, pickedIdfaOrderId : order._id})
    }
    toogleFilters = () => {
        this.setState({isShowFilters : !this.state.isShowFilters})
    }
    showOrderInfo = (order, index) => {
        this.setState({isShowOrderInfo : true, pickedOrderInfo : order, pickedOrderIndex : index})
    }
    orderInfoClosed = () => {
        this.setState({isShowOrderInfo : false})
    }
    editOrderSearchInfo = (order, pickedOrderIndex) => {

    }
    render() {
        const notIosApp = this.state.pickedApp.type_os == AppsType.android || this.state.pickedApp.type_os == AppsType.rustore
        return (
            
            // <div style={this.state.isShowOrderInfo ? {width : '-webkit-fill-available', overflowY : 'auto'} : {}} className="main_container order_main">
            <div className="main_container order_main">
                {/* <CSSTransitionGroup transitionName="example" transitionEnterTimeout={0} transitionLeaveTimeout={300}> */}
                    {/* <div className={this.state.isShowOrderInfo ? "hidden_list" : "display_block"}> */}
                    <Route path={Pages.ordersFull}>
                        <div className="display_block">
                            
                            <div className='create_order_just'>
                                <div className="main_title">
                                    {strings.myOrders}
                                    <span style={{opacity : 0.3, marginLeft : '8px'}}>{strings.menuRussia}</span>
                                </div>
                                <div className='create_order_prices orders_bot_prices'>
                                    <div className='create_order_j_emoji'>🤑</div>
                                    <div className='create_order_j_flex'>
                                        <div className='flex'><div className='android_ml' style={{marginLeft : '10px'}}>{strings.androindInstallsPrice}</div> <div className='order_price_bold order_price_android'>{strings.fromM} 4₽</div></div>
                                        <div className='flex '><div className='ios_ml' style={{marginLeft : '10px'}}>{strings.iosInstallsPrice}</div> <div className='order_price_bold order_price_ios'>{strings.fromM} 12₽</div></div>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading ? <Loader />
                            : this.state.orders && this.state.orders.length > 0 ?
                            <div>
                                
                                <div className="universal_order_filters">
                                    <div className="filters">
                                        <div onClick={this.toogleFilters} className="button_choose_app">
                                            <div className="just_content w_100">
                                                <p className="button_choose_text btn_filter_choose_text">{this.state.filterPicked}</p>
                                                <img alt='dropdown' style={this.state.isShowFilters ? arrowRotate : {}} className="drop_arrow" src={arrow_filter}/>
                                            </div>
                                            {this.state.isShowFilters && <FilterDropdown filters={filters} onFilterPicked={this.onFilterPicked}/>}
                                        </div>
                                    
                                        <div onClick={this.toogleApps} className="button_choose_app left_32">
                                            <img style={this.state.pickedApp.icon ? display : notDisplay} className="img_choose_app left_16" src={this.state.pickedApp.icon} alt=""/>
                                            <img style={this.state.pickedApp.icon ? notIosApp ? androidIconStyle : display : notDisplay} className="img_choose_app_icon" src={notIosApp ? android_icon : ios_icon} alt=""/>
                                            <div className="just_content w_100">
                                                <p className="button_choose_text btn_filter_choose_text">{this.state.pickedApp.name}</p>
                                                <img alt='filter' style={this.state.isShowApps ? arrowRotate : {}} className="drop_arrow" src={arrow_filter}/>
                                            </div>
                                            {this.state.isShowApps && <AppsDropdown dropdownStyle={dropdownStyle} onAppPicked={this.onAppPicked} apps={this.state.appsList} />}
                                        </div>

                                    </div>
                                    <Link to={Pages.newOrder}><div className="yellow_btn empty_orders_create new_univ_order">{strings.createNewOrder}</div></Link>

                                </div>
                                    {(isAdmins() || getEmail() == "playhardbillions@yandex.ru") && <p style={{marginBottom : '48px'}}>Затраты : {this.state.totalSpend} руб.</p>}
                                    <div className="orders_container">
                                    
                                    {this.state.filteredOrders.length > 0 && this.state.filteredOrders.map((item, index) => (
                                        <OrderItem showIdfa={this.showIdfa} addCoinsToOrder={this.addCoinsToOrder} showOrderInfo={this.showOrderInfo} orderChanged={this.orderChanged} count_money_r={this.props.count_money_r} index={index} order={item} key={index} getMyInfo={this.props.getMyInfo} deleteOrder={this.deleteOrder}/>
                                    ))}
                                    </div>
                                </div>
                                : <EmptyOrders icon={empty_orders} text1={strings.emptyOrders} text2="" text3=""/>}
                                {this.state.filteredOrders.length == 0 && !this.state.isLoading && this.state.orders.length > 0 ? <EmptyStats icon={cross} text1={this.state.emptyFilteredText} text2="" text3=""/> : <div/>}
                        </div>
                    </Route>
                    <Route path={Pages.ordersOrder}>
                        <OrderInfo editOrderSearchInfo={this.editOrderSearchInfo} exchangeRate={this.props.exchangeRate} showIdfa={this.showIdfa} getMyInfo={this.props.getMyInfo} deleteOrder={this.deleteOrder} count_money_r={this.props.count_money_r} addCoinsToOrder={this.addCoinsToOrder} orderChanged={this.orderChanged} index={this.state.pickedOrderIndex} orderInfoClosed={this.orderInfoClosed} order={this.state.pickedOrderInfo}/>
                    </Route>
                    {/* {this.state.isShowOrderInfo && <OrderInfo editOrderSearchInfo={this.editOrderSearchInfo} exchangeRate={this.props.exchangeRate} showIdfa={this.showIdfa} getMyInfo={this.props.getMyInfo} deleteOrder={this.deleteOrder} count_money_r={this.props.count_money_r} addCoinsToOrder={this.addCoinsToOrder} orderChanged={this.orderChanged} index={this.state.pickedOrderIndex} orderInfoClosed={this.orderInfoClosed} order={this.state.pickedOrderInfo}/>} */}
                {/* </CSSTransitionGroup> */}
                {this.state.isShowIdfa && <IdfaDialog toogleIdfa={this.toogleIdfa} order_id={this.state.pickedIdfaOrderId}/>}
                {!this.state.isShowOrderInfo && <div className="mobile_create_order_container">
                    <a href={Pages.newOrder}><div className="yellow_btn empty_orders_create mobile_univ_btn">{strings.createNewOrder}</div></a>
                </div>}
                  
            </div>
        
           
        );
    }
}
export default withRouter(Orders);