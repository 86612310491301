import { getHttpParams } from "../pages_android/custom_views/analyze_review/utils/Utils";
import { getRequestParams, getRequestUrl } from "../pages_android/custom_views/utils_android/CookieHelper";
import { log, onResponse } from "./ApiUtils";
export class ConfigApi {
    
    static getPriceConfig(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getPriceConfig", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onSuccess(responseData)
        })
    }
   

}