/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

import { getUrlParameter } from '../custom_views/analyze_review/utils/Utils';
import { AdminApi } from '../../api/AdminApi';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../../css_android/dateRangeStyle.css'; // theme css file
import { DateRange } from 'react-date-range';
import ruLocale from 'date-fns/locale/ru'
import moment from 'moment';
import { formateDate } from '../custom_views/analyze_review/utils/DateHelper';
import Loader from '../custom_views/analyze_review/customViews/Loader';
import Toast from '../custom_views/utils_android/Toast';
import AdminReferalItem from './list_items/AdminReferalItem';
import { getSameIp } from './utils/AdminUtils';


const AdminEmployerInfo = ({  }) => {
    const [info, setInfo] = useState({})
    const [isShowDates, setShowDates] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [periodEarn, setPeriodEarn] = useState(undefined)
    const [toastText, setToastText] = useState("")
    const [referals, setReferals] = useState([])
    const [selectedIp, setSelectedIp] = useState("")
    const [countRef, setCountRef] = useState(0)

    const [selectionRange, setSelectionRange] = useState({
            startDate: new Date(moment().subtract(5, "days")),
            endDate: new Date(),
            key: 'selection',
        })
    useEffect(() => {
        getEmployerInfoByPurse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getEmployerInfoByPurse = () => {
        const purse = getUrlParameter('purse')
        AdminApi.getEmployerInfoByPurse(purse, (responseData) => {
            if (responseData.result && responseData.result.length > 0){
                setInfo(responseData.result[0])
                getMyReferals(responseData.result[0])
            }
            
        }, () => {})
    }
    const getMyReferals = (info) => {
        AdminApi.getMyFriends(info.google_user_id, (responseData) => {
            if (responseData.friends && responseData.friends.length > 0){
                setReferals(responseData.friends)
                setCountRef(responseData.countFriends)
            }
            
        }, () => {})
    }
    const handleSelectRange = (ranges) => {
        var mRanges = {...ranges.selection}
        setSelectionRange(mRanges)
    }
    const loadPerionEarn = () => {
        setLoading(true)
        const start_date = moment(selectionRange.startDate).format("YYYY.MM.DD")
        const end_date = moment(selectionRange.endDate).format("YYYY.MM.DD")
        AdminApi.getEarningEmployerByDates(info.google_user_id, start_date, end_date, (responseData) => {
            setPeriodEarn(responseData)
            setLoading(false)
        }, () => {setLoading(false)})
    }
    const blockEmployer = () => {
        AdminApi.blockEmployerById(info._id, (responseData) => {
            showToast("Исполнитель заблокирован")
        })
    }
    const removeEmployer = () => {
        AdminApi.removeEmployerById(info._id, (responseData) => {
            showToast("Исполнитель удален из базы")
        })
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 3000)
    }
    if(info.google_user_id == undefined){
        return null
    }
    const sameIp = referals.length > 0 ? getSameIp(referals).filter(s => s.count > 1) : []
    const filteredReferals = selectedIp != "" ? referals.filter(r => r.ip == selectedIp) : referals
    return (
        <div className="main_container order_main">
            <p className="main_title">{info.nick_name}</p>
            <div>Баланс : <span className='admin_item_bold'>{info.count_money_r}₽</span></div>
            <div>Бандл прилы : <span className='admin_item_bold'>{info.bundle}</span></div>
            <div>_id юзера : <span className='admin_item_bold'>{info._id}</span></div>
            <div>Страна : <span className='admin_item_bold'>{info.country}</span></div>
            <div>email : <span className='admin_item_bold'>{info.email}</span></div>
            <div>Количество выплат : <span className='admin_item_bold'>{info.payments_history.length}</span></div>
            <div>Выполнено заказов : <span className='admin_item_bold'>{info.progress_orders.length}</span></div>
            <div>Выполнено универсальных заказов : <span className='admin_item_bold'>{info.proof_orders_youtube.length}</span></div>
            <div>Массив бандлов : <span className='admin_item_bold'>{info.installed_app_bundles.length}</span></div>
            <div>Тип ОС : <span className='admin_item_bold'>{info.type_os}</span></div>
            <div>Регистрация : <span className='admin_item_bold'>{moment(info.register_date).utc().local().format("DD.MM.YYYY HH:mm")}</span></div>
            <div>Последнее изменение : <span className='admin_item_bold'>{moment(info.modified).utc().local().format("DD.MM.YYYY HH:mm")}</span></div>
            <div>IP адрес : <span className='admin_item_bold'>{info.ip}</span></div>
            <div>Google user id : <span className='admin_item_bold'><a rel="noopener noreferrer" target='_blank' href={'https://app-coins.ru/profile?google_user_id=' + info.google_user_id + '&device_id=' + info.device_id + '&api_key=upmob'}>{info.google_user_id}</a></span></div>
            {info.wallet_youkassa_paid && <div>Вывел за год на юкассу : <span className='admin_item_bold'>{info.wallet_youkassa_paid}₽</span></div>}
            <div  style={{margin : '24px 0 0 0'}} className='flex'>
                <div style={{margin : '0 24px 24px 0'}} className="filter_dates admin_period_picker">
                    <p className="black_text">Период:</p>
                    <p onClick={() => setShowDates(!isShowDates)} className="blue_text_filter blue_hover">{formateDate(selectionRange)}</p>
                    {isShowDates && <div onClick={() => setShowDates(false)} className="tooltip_bg_dates"></div>}
                    {isShowDates && <DateRange locale={ruLocale} ranges={[selectionRange]} onChange={handleSelectRange}/>}
                </div>
                <div onClick={loadPerionEarn} style={isLoading ? {pointerEvents : 'none', minHeight : '16px', marginTop : '0px', position : 'relative', minWidth : '60px'} : {}} className="yellow_btn create_order_btn relative">{isLoading ? <Loader isWhite/> : "Загрузить"}</div>

            </div>      
            {periodEarn && 
            <div>
                <div>Заработано на прилах : <span className='admin_item_bold'>{periodEarn.earningApp}₽</span></div>
                <div>Заработано на универсальных заказах : <span className='admin_item_bold'>{periodEarn.earningUniversal}₽</span></div>
                <div style={{marginTop : '8px'}}>Всего за период : <span className='admin_item_bold'>{periodEarn.earningFull}₽</span></div>
            </div>}
            <div style={{marginTop : '60px', marginBottom : '60px'}}>
                <div onClick={blockEmployer} className="up_balance_info top_16 hover">
                    <p className="red_text">Заблокировать исполнителя</p>
                </div>
                <div onClick={removeEmployer} className="up_balance_info top_16 hover">
                    <p className="red_text">Удалить исполнителя из базы</p>
                </div>
            </div>
            {referals.length > 0 &&
            <div>
                <div style={{margin : '0px 0 -12px 0'}}>Всего рефералов : <span className='admin_item_bold'>{countRef}</span></div>
                <div style={{marginTop : '16px'}} className="orders_container">
                    {sameIp.length > 0 && sameIp.map((item, index) => (
                        <div onClick={() => setSelectedIp(item.ip)} style={selectedIp == item.ip ? {border : '1px solid #1696EC'} : {}} className='admin_user_item hover' item={item} key={index} index={index}>
                            <div>{item.ip} : {item.count}</div>
                        </div>
                    ))}
                </div>
                <div className="orders_container">
                    {filteredReferals.length > 0 && filteredReferals.map((item, index) => (
                        <AdminReferalItem item={item} key={index} index={index}/>
                    ))}
                </div>
            </div>}
            {toastText != "" && <Toast text={toastText}/>}

        </div>
    )        
    
}
export default AdminEmployerInfo

