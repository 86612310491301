import React from 'react';
class EmptyStats extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        return (
            <div>
                <div style={this.props.topStyle ? this.props.topStyle : {}} className="icon-placeholder-bank no-stats-container">
                    <img className="center stats_img" src={this.props.icon}/>
                </div>
                <div style={this.props.botStyle ? this.props.botStyle : {}} className="row stats-template center">
                    <p className="center stats_text">{this.props.text1}<br/>{this.props.text2}<br/>{this.props.text3}</p>
                </div>
            </div>
            
           
        );
    }
}
export default EmptyStats;
