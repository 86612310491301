/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';
import { SiteType } from '../utils/SiteUtils';

var strings = getLocalizedStrings()

function SiteTypePicker(props) {

    const type = props.type
    const setType = props.setType
    
    return (
        <div className="display_block">
            <div className="line-row"/>
            <div className="order_row bot_16">
                <div className="flex switch_container">
                    <div onClick={() => setType(SiteType.yandex)} className="flex hover">
                        <div style={type == SiteType.yandex ? {border : "1px solid #F5DB31"} : {}} className="radio_limits hover">
                            {type == SiteType.yandex && <div className="radio_inside"/>}
                        </div>
                        <p style={{margin : 'auto 48px auto 16px'}} className="text-pre-subscr">Yandex</p>

                    </div>
                    <div onClick={() => setType(SiteType.google)} className='flex hover'>
                        <div style={type == SiteType.google ? {border : "1px solid #F5DB31"} : {}} className="radio_limits hover">
                            {type == SiteType.google && <div className="radio_inside"/>}
                        </div>
                        <p style={{margin : 'auto 48px auto 16px'}} className="text-pre-subscr">Google</p>
                    </div>
                </div>
                <div className="input_description_container input_ml_create">
                    <p className="input_description_text">{strings.siteTypeDesc}</p>
                </div>
            </div>
            <div className="line-row"/>
        </div>

    );

}
export default SiteTypePicker;