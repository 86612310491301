/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react';
import '../css_android/social.css'
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import { YoutubeSearchType, arrowRotateStyle, formatSocialServices, getIsDesktop, getSocTitle, getSocialHistoryIcon, getYoutubeId, parseYoutubeSearch } from './custom_views/utils_android/SocialHelper';
import FilterDropdown from './custom_views/FilterDropdown';
import arrow_filter from './custom_views/custom_icons/drop_arrow.svg'
import { getRequestParams, getRequestUrl } from './custom_views/utils_android/CookieHelper';
import { getHttpParams } from './custom_views/analyze_review/utils/Utils';
import UpBalanceUniversal from './custom_views/universal_tasks/UpBalanceUniversal';
import Toast from './custom_views/utils_android/Toast';
import { SocialApi } from '../api/SocialApi';
import Loader from './custom_views/analyze_review/customViews/Loader';

var strings = getLocalizedStrings()
function Social(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingQuery, setLoadingQuery] = useState(false)
    const [historyLoading, setHistoryLoading] = useState(true)
    const [isShowFilters, setShowFilters] = useState(false)
    const [isShowUpBalance, setShowUpBalance] = useState(false)
    const [searchResponse, setSearchResponse] = useState({})
    const [allSocialServices, setAllSocialServices] = useState({})
    const [toastText, setToastText] = useState("")
    const [filters, setFilters] = useState([])
    const [type, setType] = useState("")
    const [url, setUrl] = useState("")
    const [searchUrlText, setSearchUrlText] = useState("")
    const [count, setCount] = useState(100)
    const [minTime, setMinTime] = useState(60)
    const [autoLikesCount, setAutoLikesCount] = useState(1)
    const [history, setHistory] = useState([])
    const [pollIndex, setPollIndex] = useState(1)
    const [orderHeight, setOrderHeight] = useState(660)
    const [selectedItem, setSeletedItem] = useState({})
    const priceItem = 0.5
    const view60secPrice = 0.5
    const commentYtPrice = 3
    const borderError = { border: '1px solid rgba(255, 98, 98, 0.5)' }
    const orderRef = useRef(null)
    const isDesktop = getIsDesktop()
    const [comments, setComments] = useState("")
    // const tgConditions = "Каналу или группе должно быть не менее 2-х недель. Запрещенные материалы: завышенные неподкреплённые обещания (заработок, товары), займы, порнография, казино и другие азартные игры, нацизм, наркотики, призыв к суициду, накрутка, экстремизм, политика.\n\nУслуга не подходит для чатов, так как будут большие списания. Учитывать это при заказе."
    useEffect(() => {
        //getLikedromConfig()
        getMyOrders()
        getAllSocialServices()
    }, [])
    // const getLikedromConfig = () => {
    //     SocialApi.getLikedromConfig((responseData) => {
    //         setConfig(responseData)
    //         const config = formatSocialConfig(responseData, priceItem)
    //         setConfig(config)
    //         onItemsChanged(snItems, config)
    //     }, () => {})
    // }
    const getAllSocialServices = () => {
        SocialApi.getAllSocialServices((responseData) => {
            const config = formatSocialServices(responseData, priceItem)
            setAllSocialServices(config)
        }, () => { })
    }
    const onItemPicked = (item) => {
        var services = { ...allSocialServices }
        services.titles.forEach(element => {
            element.isSelected = element.isSelected ? false : element.sn == item.sn
        })
        setSeletedItem(item)
        // setSnItems(mItems)
        onItemsChanged(services)
    }
    const onItemsChanged = (config) => {
        const selectedItems = config.titles.filter(i => i.isSelected)
        if (selectedItems.length > 0) {
            const items = config[selectedItems[0].sn].services
            setFilters(items)
            setType(items[0])
        } else {
            setFilters([])
            setType("")
        }
    }
    useEffect(() => {
        setTimeout(function () {
            if (orderRef.current != undefined) {
                setOrderHeight(orderRef.current.clientHeight + 40)
            }

        }, 50)

    }, [selectedItem])
    const onFilterPicked = (t) => {
        setType(t)
        setShowFilters(false)
    }
    const checkYoutubeSearch = () => {
        // const video_id = getYoutubeId(url)
        // if (searchUrlText == "" || video_id == "" || video_id == undefined) {
        //     showToast(strings.socialEnterLinkAndWord)
        //     return
        // }
        if (isLoadingQuery) {
            return
        }
        setLoadingQuery(true)

        var params = getRequestParams()
        params.video_url = url
        params.query = searchUrlText
        console.log("checkYouTubeSearchByVideoId params = ", params)
        SocialApi.checkYouTubeSearchByVideoId(params, (responseData) => {
            const response = parseYoutubeSearch(responseData)
            setSearchResponse(response)
            if (response.type == YoutubeSearchType.SUCCESS) {
                createOrder(searchUrlText)
            } else if (response.type == YoutubeSearchType.HAS_NEW_QUERY) {
                showToast(strings.socialNoVideoBut + " \"" + response.new_query + "\"")
            } else if (response.type == YoutubeSearchType.FAILED) {
                showToast(strings.socialNoVideoByLink)
            }
            setLoadingQuery(false)
        })
    }
    const getMyOrders = () => {
        console.log("getMyLikedromOrders request")
        SocialApi.getMyLikedromOrders((responseData) => {
            if (responseData.orders) {
                setHistory(responseData.orders)
            }
            setHistoryLoading(false)
        }, () => { setHistoryLoading(false) })
    }
    const validateComments = (text) => {
        const commentLines = text.trim().split('\n').filter(line => line.trim().length > 0);
        return commentLines.length == count;
    }
    const minimumPrice = 1
    const isTimeTask = (type.service == "10001" || type.service == "10003" || type.service == "10011" || type.service == "10013")
    const isSearchYoutubeTask = (type.service == "10003" || type.service == "10013")
    const isCommentYoutubeTask = (type.service == "10002")
    const autoLikeMultiplier = type.is_auto_likes ? autoLikesCount : 1
    const timeTaskAddPrice = isTimeTask ? minTime >= 60 ? (view60secPrice / 60 * (minTime - 60)) : 0 : 0
    console.log("timeTaskAddPrice = ", timeTaskAddPrice)
    const summ = Math.round(isCommentYoutubeTask ? commentYtPrice * count : type.price == undefined ? (count * (priceItem + timeTaskAddPrice) * autoLikeMultiplier) : (count * type.price * (priceItem + timeTaskAddPrice) * autoLikeMultiplier))
    const min = parseInt(type.min)
    const max = parseInt(type.max)
    const isUrlError = summ >= 0 && !(url && url != "" && url.length >= 3) && count >= min && count <= max
    const isMinMaxError = (url && url != "" && url.length >= 3) && summ >= 0 && (count < min || count > max)
    const isCommentsTexts = type.type == 'comments' ? validateComments(comments) : true
    const isButtonEnabled = url && url != "" && url.length >= 3 && count >= min && count <= max && summ >= 0 && isCommentsTexts
    
    const createOrder = (search_youtube_text) => {
        if (isLoading) {
            return
        }
        
        // if (isCommentsTexts && !validateComments(comments)) {
        //     showToast(strings.socialCommentsCountMismatch);
        //     return;
        // }
        
        if (props.count_money_r < summ) {
            setShowUpBalance(true)
            setIsLoading(false)
            return
        }
        if (minimumPrice > summ){
            setIsLoading(false)
            showToast(strings.minimumSocialPrice + minimumPrice + " руб.")
            return
        }
        setShowUpBalance(false)
        setIsLoading(true)
        var params = getRequestParams()
        params.count_coins = summ
        params.id_likedrom_number = type.service
        params.social_network = selectedItem.sn
        params.order_url = encodeURI(url)
        params.order_name = type.title ? type.title : type.name
        if (type.rate != undefined) {
            params.sociogr = 1
        }
        if (type.is_auto_likes) {
            params.posts = autoLikesCount
        }
        if (type.is_poll) {
            params.poll_index = pollIndex
        }
        if (isTimeTask) {
            params.one_view_price = (priceItem + timeTaskAddPrice)
            params.quantity = count
            params.min_time = minTime
            params.count_money_r = summ
            if (search_youtube_text) {
                params.query = search_youtube_text
            }
        }
        if (isCommentYoutubeTask) {
            params.one_view_price = 3
            params.quantity = count
            params.min_time = -1
            params.count_money_r = summ
            
        }
        if (isCommentsTexts){
            // params.list_comments = JSON.stringify(comments.split('\n'))
            params.list_comments = comments.replaceAll("\n", "\r\n")
            console.log("createLikedromOrder params list_comments = ", params.list_comments)
        }
        console.log("createLikedromOrder params = ", params)

       
        fetch(getRequestUrl() + "createLikedromOrder", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                setIsLoading(false)
                if (responseData.response > 0) {
                    window.location.href = '/social'
                } else {
                    showToast(responseData.error)
                }


                console.log("createLikedromOrder response = ", responseData)
            })
        // count_coins - количество монет для заказа (обяз. парам)
        // service_likedrom_number - номер заказа лайкдрома из конфига (обяз. парам)
        // social_network - название соц сети из конфига (обяз. парам)
        // order_url - ссылка на серурс для продвижения (обяз. парам)
        // posts - количество постов только для автолайков в телеге, инсте или вк
        // poll_index - номер правильного ответа только для опросов в тг и вк
    }
    const changeSearchRequest = () => {
        setSearchUrlText(searchResponse.new_query)
        setSearchResponse({})
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function () {
            setToastText("")
        }, 5000)
    }

    console.log("type = ", type)
    const makeOrderHTML = (item) => {

        const selectedRef = document.getElementById("#" + item.title)

        if (selectedRef == undefined) {
            return null
        }
        return (
            <div ref={orderRef} style={{ left: (isDesktop ? 330 : 20) - (selectedRef.getBoundingClientRect().left) + "px", width: (document.getElementsByClassName("social_left")[0].getBoundingClientRect().width - (isDesktop ? 40 : 28)) + 'px' }} id={"#container_" + item.service} className='social_order_container'>
                <div className='social_pick_title'>{strings.socialWhatToOrder}</div>
                <div onClick={() => setShowFilters(!isShowFilters)} style={{ width: '100%', position: 'relative' }} className="button_choose_app">
                    <div className="just_content w_100">
                        <p className="button_choose_text btn_filter_choose_text max_width_social">{type.title_price}</p>
                        <img alt='' style={isShowFilters ? arrowRotateStyle() : {}} className="drop_arrow" src={arrow_filter} />
                    </div>
                    {isShowFilters && <FilterDropdown style={{ width: 'calc(100% + 4px)' }} filters={filters} onFilterPicked={onFilterPicked} />}
                </div>

                <div className='social_pick_title'>{strings.socialLinkTo} {type.name_object}</div>
                <div style={isUrlError ? borderError : {}} className="button_choose_app social_w_100">
                    <input autoComplete="off" type="text" onChange={(event) => setUrl(event.target.value)} className="just_content social_w_100 button_choose_text btn_filter_choose_text max_width_social" placeholder={"Ссылка"} value={url} />
                </div>
                {/* <p className='social_url_description'>Фото, видео, пост</p> */}

                {isSearchYoutubeTask &&
                    <div>
                        <div className='social_pick_title'>{strings.socialEnterSearch}</div>
                        <div className="button_choose_app social_w_100">
                            <input autoComplete="off" type="text" onChange={(event) => setSearchUrlText(event.target.value)} className="just_content social_w_100 button_choose_text btn_filter_choose_text max_width_social" placeholder={"Поисковый запрос"} value={searchUrlText} />
                        </div>
                        {searchResponse.type == YoutubeSearchType.HAS_NEW_QUERY && <p onClick={() => changeSearchRequest()} className='soial_new_query hover'>{strings.socialChangeOn} "{searchResponse.new_query}"</p>}
                    </div>}


                {type.is_auto_likes &&
                    <div>
                        <div className='social_pick_title'>{strings.socialFuturePosts}</div>
                        <div className='flex'>
                            <MinusBtn count={autoLikesCount} setCount={setAutoLikesCount} />
                            <div style={{ width: '160px', marginBottom: 0 }} className="button_choose_app">
                                <input autoComplete="off" type="number" onChange={(event) => setAutoLikesCount(event.target.value)} className="just_content social_w_100 button_choose_text btn_filter_choose_text max_width_social" value={autoLikesCount} />
                            </div>
                            <PlusBtn count={autoLikesCount} setCount={setAutoLikesCount} />

                        </div>
                    </div>
                }
                {type.is_poll && <CountRow title={strings.socialAnswerCount} count={pollIndex} setCount={setPollIndex} />}

                <CountRow title={strings.socialCount} count={count} setCount={setCount} />
                <p style={isMinMaxError ? { color: 'rgba(255, 98, 98)' } : {}} className='social_url_description'>от {type.min} до {type.max}</p>
                {(type.type == 'comments' && type.name_ru.toLowerCase().includes(strings.commentsMain)) && 
                    <div>
                        <div className='social_pick_title'>{strings.socialCommentsTitle}</div>
                        <div className='button_choose_app social_w_100' style={!validateComments(comments) ? borderError : {}}>
                            <textarea 
                                placeholder={strings.socialCommentsPlaceholder}
                                className="just_content social_w_100 button_choose_text btn_filter_choose_text max_width_social"
                                style={{ resize: 'none', height: '100px', overflow : 'auto' }}
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                            />
                        </div>
                        <div style={{display : 'flex', justifyContent : 'end'}} className='social_url_description'>{comments.trim().split('\n').filter(line => line.trim().length > 0).length + "/" + count}</div>
                        {!validateComments(comments) && <p className='social_conditions'>{strings.minInstallsCOunt + " " + count + " " + strings.socialCommentsError}</p>}
                    </div>
                }
                
                {isTimeTask && <CountRow title={strings.socialMinimumTime} count={minTime} setCount={setMinTime} />}


                {type.conditions != undefined && <p className='social_conditions'>{type.conditions}</p>}
                {type.desc != undefined && <div className='social_conditions' dangerouslySetInnerHTML={{ __html: type.desc }}></div>}
                <div className='relative'>
                    <div onClick={isSearchYoutubeTask ? checkYoutubeSearch : createOrder} style={isButtonEnabled ? {} : { pointerEvents: 'none', opacity: '0.2' }} className='social_create_btn hover'>{(isLoading || isLoadingQuery) ? <div style={{ minHeight: '16px', marginTop: '4px', position: 'relative' }}><Loader isWhite /></div> : <div>{strings.socialCreateOrder} {summ}₽</div>}</div>
                    {isShowUpBalance && <UpBalanceUniversal isSocialTask closeUpBalance={() => setShowUpBalance(false)} count_money_r={props.count_money_r} sumOrder={summ} />}
                </div>
                <p className='social_url_description'>{strings.socialInstaExtreme}</p>
                {/* <p className='social_url_description'>{isCommentYoutubeTask ? `${strings.socialCommentsCountRequired} ${count}` : ''}</p> */}
            </div>
        )
    }
    return (

        <div className="main_container order_main">
            <div className="display_block">
                <p className="main_title">{strings.socialNetworksTitle}</p>
                <p className='social_pick_title'>{strings.pickSocial}</p>
                <div className='social_container relative'>
                    <div className='social_left'>
                        <SocialPicker allSocialServices={allSocialServices} onItemPicked={onItemPicked} makeOrderHTML={makeOrderHTML} orderHeight={orderHeight} />


                    </div>
                    <div className='social_history_container'>
                        <UniversalTaskContainer />
                        <SocialHistory history={history} historyLoading={historyLoading} />
                    </div>

                </div>

            </div>
            {toastText != "" && <Toast text={toastText} />}
        </div>
    );
}

export default Social;


const CountRow = ({ title, count, setCount }) => {
    return (
        <div>
            <div className='social_pick_title'>{title}</div>
            <div className='flex'>
                <MinusBtn count={count} setCount={setCount} />
                <div style={{ width: '160px', marginBottom: 0 }} className="button_choose_app">
                    <input autoComplete="off" type="number" onChange={(event) => setCount(event.target.value)} className="just_content social_w_100 button_choose_text btn_filter_choose_text max_width_social" value={count} />
                </div>
                <PlusBtn count={count} setCount={setCount} />
            </div>
        </div>

    )
}
const UniversalTaskContainer = () => {
    return (
        <div className='social_unversal'>
            {strings.socialOtherTasks} <a className='hover' target='_blank' href='/create_universal'>{strings.socialOtherTasks2}</a>
        </div>
    )
}
const SocialHistory = ({ history, historyLoading }) => {
    return (
        <div className='social_history_border relative'>
            <div className='social_history_title'>{strings.socialHistory}</div>
            {history.map((item, index) => (
                <div className='social_history_item' index={index} key={index}>
                    <div className='flex'>
                        <img alt='' src={item.icon ? item.icon : getSocialHistoryIcon(item.social_network)} />
                        <div>
                            <p className='social_history_item_title'>{item.order_name}</p>
                            <div className='flex'>
                                <a rel="noopener noreferrer" href={item.order_url} target='_blank' className='social_item_url hover'>{item.order_url}</a>
                            </div>
                        </div>
                    </div>
                    {item.max && <div style={item.done >= item.max ? { color: '#6edfa1' } : {}} className='social_item_progress'>{item.done}/{item.max}</div>}
                </div>
            ))}
            {historyLoading && <Loader />}
            {(!historyLoading && history.length == 0) && <div className='social_empty'>{strings.socialNoOrders}</div>}
            
        </div>
    )
}
const MinusBtn = ({ count, setCount }) => {
    return (
        <div onClick={() => setCount(count - 1)} className='social_add_btn hover'>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12.5H19" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}
const PlusBtn = ({ count, setCount }) => {
    return (
        <div onClick={() => setCount(((count != undefined && count != "") ? parseInt(count) : 0) + 1)} style={{ marginLeft: '11.5px' }} className='social_add_btn hover'>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5.5V19.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 12.5H19" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}




const SocialPicker = ({ allSocialServices, onItemPicked, makeOrderHTML, orderHeight }) => {
    const socSevices = allSocialServices.titles ? allSocialServices.titles : []
    // const orderContainerMargin = (item) => {
    //     const c = document.getElementById("#container_" + item.service)
    //     const height = c != undefined ? (c.getBoundingClientRect().height + 50) + 'px' : '600px'
    //     console.log("height = ", height)
    //     return height
    // } 
    return (
        <div>
            <div className="social_pick_flex">
                {/* {snItems.map((item, index) => (
                    <div onClick={() => onItemPicked(item)} style={item.isSelected ? {background: 'var(--lk-left-menu)'} : {}} className='social_pick_item hover' index={index} key={index}>
                        <img alt='' src={item.img}/>
                        <div style={item.isSelected ? {color : 'white'} : {}}>{item.title}</div>
                    </div>
                ))} */}
                {socSevices.map((item, index) => (
                    <div style={{ height: 'fit-content' }} className='relative' index={index} key={index}>
                        <div onClick={() => onItemPicked(item)} id={"#" + item.title} style={item.isSelected ? { background: 'var(--lk-left-menu)', marginBottom: orderHeight } : {}} className='social_pick_item'>
                            {(item.img != undefined && item.img != "") && <img alt='' src={item.img} />}
                            <div style={item.isSelected ? { color: 'white' } : {}}>{getSocTitle(item.title)}</div>
                        </div>
                        {item.isSelected && makeOrderHTML(item)}
                    </div>
                ))}

            </div>
        </div>
    )
}