/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

const Toast = ({ text }) => {

    if (text == ""){
        return (<div></div>)
    }
    return (
        <div className="profile_toast flex">
            {text}
        </div>
    )
}
export default Toast;