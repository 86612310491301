import React from 'react';
import close_delete from '../../custom_views/custom_icons/close_delete.svg'
const AdminOrderInfoDialog = ({ item, closeDialog }) => {
    console.log("dialog info = ", item)
    return (
        <div className="error_bg">
            <div style={{width : '340px', padding : '24px', maxHeight : 'calc(100vh - 200px)', overflow : 'scroll'}} className="error_banner_container">
                <img style={{right : '22px'}} className="dialog-close" onClick={closeDialog} src={close_delete} alt=""/>   
                <p className="delete_order_title">{item.name}</p>  
                <p className="custom_task_description wrap_text_dialog" dangerouslySetInnerHTML={{__html: item.description}}/>
                {item.control_text && <div style={{marginTop : '24px'}}>Контрольный ответ : <p className="wrap_text_dialog" dangerouslySetInnerHTML={{__html: item.control_text}}/></div>}
                {item.url && <a rel="noopener noreferrer" target='_blank' href={item.url}><p style={{whiteSpace: 'break-spaces'}} className='admin_url' >{item.url}</p></a>}

                <div className="dialog_btns">
                    <p onClick={closeDialog} className="error_btn color_cancel">Закрыть</p>
                </div>
            </div>  
        </div>
    )
}
export default AdminOrderInfoDialog;