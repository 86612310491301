import React from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import SdkCodeContainer from './SdkCodeContainer';
import SdkCircleProgress from '../SdkCircleProgress';
import help_circle_blue from '../custom_icons/help_circle_blue.svg'
import SdkMoneyDialog from '../dialogs/SdkMoneyDialog';
import alert_triangle from '../custom_icons/alert_triangle.svg'
import {CSSTransitionGroup} from 'react-transition-group';

var strings = getLocalizedStrings()
class SdkCreatedTaskItem extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowSdkMoneyDialog : false
        }
        this.toggleSdkMoneyDialog = this.toggleSdkMoneyDialog.bind(this)
    }
    toggleSdkMoneyDialog(){
        this.setState({isShowSdkMoneyDialog : !this.state.isShowSdkMoneyDialog})
    }
    render(){
        const taskNumber = this.props.index + 1
        const isAndroid = this.props.currentDocumentation == "android"
        var eventText = ""
        if (isAndroid){
            eventText = "UpMob.sendEvent(\"" + this.props.task._id + "\")"
        } else {
            eventText = "Upmob.SendEvent(\"" + this.props.task._id + "\");"
        }
        return (
            <div>
                <div className="flex full_width">
                    <div className="sdk_number_container">
                        <p className="sdk_center sdk_number_text">{taskNumber}</p>
                    </div>
                    <div className="full_width">
                        <div className="flex">
                            <p className="sdk_task_item_title">{this.props.task.name}</p>
                            <p className="sdk_task_item_title sdk_task_price">{this.props.task.price}₽</p>
                        </div>
                        <p className="sdk_task_item_desc" dangerouslySetInnerHTML={{__html: this.props.task.description}}/>
                        <SdkCodeContainer task_id={(strings.sdkTaskIdTask + " " + taskNumber)} text={eventText}/>
                    </div>
                    {this.props.task.complete_count && <div className="sdk_stats_item">
                        <div className="flex">
                            <p>{strings.finishedTasksText}</p>
                            <img onClick={this.toggleSdkMoneyDialog} className="hover help_sdk" src={help_circle_blue}/>
                        </div>
                        <SdkCircleProgress state={1} count_installed={this.props.task.complete_count} max_count_installs={this.props.max_installs} percentage={(this.props.task.complete_count/this.props.max_installs)*100 >= 100 ? 100 : (this.props.task.complete_count/this.props.max_installs)*100}/>

                    </div>}
                </div>
                {this.props.task.complaint && <div className="sdk_appeal_item">
                    <div className="flex">
                        <img className="alert_triangle" src={alert_triangle}/>
                        <p className="sdk_appeal_title">{strings.sdkAppealTitle}</p>
                    </div>
                    <p className="sdk_appeal_text">{this.props.task.complaint}</p>
                    <p className="sdk_appeal_desc">{strings.sdkAppealDesc}</p>
                </div>}
                {/* {this.props.task.error_stack && <div className="sdk_appeal_item">
                    <div className="flex">
                        <img className="alert_triangle" src={alert_triangle}/>
                        <p className="sdk_appeal_title">{strings.sdkAppealTitle}</p>
                    </div>
                    <p className="sdk_appeal_text">{this.props.task.error_stack}</p>
                    <p className="sdk_appeal_desc">{strings.sdkAppealDesc}</p>
                </div>} */}
                
                <div className="line-row top_line_0"/>
                <CSSTransitionGroup transitionName="dialog_animation" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                    {this.state.isShowSdkMoneyDialog && <SdkMoneyDialog toggleCloseDialog={this.toggleSdkMoneyDialog}/>}
                </CSSTransitionGroup>
            </div>
        );         
    }
}
export default SdkCreatedTaskItem;
