/* eslint-disable eqeqeq */
import React from 'react';
import qs from 'querystring'
import info from '../custom_views/custom_icons/info.svg';
import delete_icon from '../custom_views/custom_icons/delete.svg';
import play from '../custom_views/custom_icons/play.svg';
import finished from '../custom_views/custom_icons/finished.svg';
import pause from '../custom_views/custom_icons/pause.svg';
import add from '../custom_views/custom_icons/add.svg';
import proof from '../custom_views/custom_icons/proof.svg';
import price_img from '../custom_views/custom_icons/price_img.svg';
import clock from '../custom_views/custom_icons/clock.svg';
import block from '../custom_views/custom_icons/block.svg';
import block_help from '../custom_views/custom_icons/block_help.svg';
import order_type_rate from '../custom_views/custom_icons/order_type_rate.svg';

import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import { isShowLeftTooltip, getStartUniversalDate } from '../custom_views/utils_android/Utils';
import ProgressLine from '../custom_views/ProgressLine';
import { getRequestParams, getRequestUrl } from '../custom_views/utils_android/CookieHelper';
import DeleteOrderDialog from '../custom_views/dialogs/DeleteOrderDialog';
import AddMoneyUniversal from '../custom_views/universal_tasks/AddMoneyUniversal';
import { getPauseBorderStyle } from '../custom_views/utils_android/StyleHelper';
import Toast from '../custom_views/utils_android/Toast';
var strings = getLocalizedStrings()
var borderPauseStyle = getPauseBorderStyle()
class UniversalOrderItem extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowDeleteDialog : false,
            isShowUpBalance : false,
            toastText : ""
        }
    }
    setStateOrder = () => {
        var params = getRequestParams()
        var newState = (this.props.order.state == 1 ? 0 : 1)
        params._id = this.props.order._id
        params.state = newState
        fetch(getRequestUrl() + "setStateYoutubeOrder", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1) {  
                this.props.order.state = newState
                this.props.orderChanged(this.props.order, this.props.index)
            }
        })
    }
   
    deleteOrder = () => {
        this.setState({isShowDeleteDialog : true})
    }
    onCanceledDelete = () => {
        this.setState({isShowDeleteDialog : false})
    }
    onDelete = () => {
        var params = getRequestParams()
        params._id = this.props.order._id
        fetch(getRequestUrl() + "removeOrderYoutube", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            this.setState({isShowDeleteDialog : false})
            this.props.deleteOrder(this.props.index)
            this.props.getMyInfo()
        })
        
        
    }
    showOrderInfo = () => {
        this.props.showOrderInfo(this.props.order, this.props.index)
    }
    toogleAddMoney = () => {
        this.setState({isShowUpBalance : !this.state.isShowUpBalance})
    }
    showToast = () => {
        this.setState({toastText : this.props.order.reason_block ? this.props.order.reason_block : strings.blockReason})
        setTimeout(this.onToastTimeout, 3000)
    }
    onToastTimeout = () => {
        this.setState({toastText : ""})
    }
    closeUpBalance = () => {
        this.setState({isShowUpBalance : false})
    }
    
    render() {
        const isOrderBlocked = this.props.order.moderated < 0
        // console.log("this.props.order = ", this.props.order)
        return (
            <div style={(this.props.order.state <= 0 || isOrderBlocked) ? borderPauseStyle : {}} className="order_container">
                <div className="padding_12">
                    <div className="top_order">
                        <img onClick={this.showOrderInfo} className="order_icon hover" src={info}/>
                        <div onClick={this.showOrderInfo} className="order_icon_container">
                            <img className="order_app_icon" src={this.props.order.icon ? this.props.order.icon : order_type_rate}/>
                            {this.props.order.badgeCount > 0 && <div className="buble_proofs order_badge">
                                <p className="top_3">{this.props.order.badgeCount > 99 ? "99+" : this.props.order.badgeCount}</p>
                            </div>}
                        </div>
                        
                        <img onClick={this.deleteOrder} className="order_icon hover" src={delete_icon}/>
                    </div>
                    <p onClick={this.showOrderInfo} className="order_name hover">{this.props.order.name}</p>
                    <p className="date_text_order">{getStartUniversalDate(this.props.order)}</p>
                    <div className="universal_options">
                        <img src={price_img}/>
                        <p className="universal_options_text">{this.props.order.count_price_customer_r}₽</p>
                        <img className="left_16" src={this.props.order.control_text ? proof : clock}/>
                        <p className="universal_options_text">{this.props.order.control_text ? strings.universalProof : strings.universalTimeProof}</p>
                    </div>
                </div>
                <div className="control_container">
                    <div className="controlls">
                        <p className="status_text_control">{strings.status}</p>
                        {isOrderBlocked ? 
                            <div onClick={this.showToast} className='hover'>
                                <div style={{justifyContent : 'center'}} className='flex'>
                                    <p className="status_text">{strings.blocked}</p>
                                    <img className='blocked_help' src={block_help}/>
                                </div>
                                <img onClick={this.setStateOrder} className="controll_icon" src={block} />
                            </div>
                        :
                        <div>
                            <p className="status_text">{this.props.order.count_money_r < this.props.order.price_one_install ? strings.finished : (this.props.count_money_r < 0 ? ((this.props.order.state == 0 || this.props.order.state == 1) ? strings.upBalance : this.props.order.moderated == 0 ? strings.moderating : (this.props.order.moderated == -1 ? strings.blocked : (this.props.order.state == 0 ? strings.pause : strings.working))) : (this.props.order.moderated == 0 ? strings.moderating : (this.props.order.moderated == -1 ? strings.blocked : (this.props.order.state == 0 ? strings.pause : strings.working))))}</p>
                            <img style={this.props.order.count_money_r < this.props.order.price_one_install ? {pointerEvents : 'none'} : {}} onClick={this.setStateOrder} className="controll_icon hover" src={this.props.order.count_installed >= this.props.order.max_count_installs ? finished : (this.props.order.count_money_r < this.props.order.price_one_install ? play : (this.props.order.state == 0 ? play : pause))} />
                        </div>
                        }
                        

                    </div>
                    <div className="line_control"></div>
                    <div className="controlls">
                        <p className="status_text_control">{strings.budget}</p>
                        <p className="status_text">{Math.round(this.props.order.count_money_r * 10) / 10}₽</p>
                        <img onClick={this.toogleAddMoney} className="controll_icon hover" src={add} />
                        {this.state.isShowUpBalance && <AddMoneyUniversal isLeft={isShowLeftTooltip(this.props.index)} style={window.innerWidth >= 556 ? (isShowLeftTooltip(this.props.index) ? {top : "-190px", left: '-246px'} : {top : "-190px", left: '96px'}) : {top : "-190px", left: '-186px'}} count_money_r={this.props.count_money_r} index={this.props.index} order_id={this.props.order._id} addCoinsToOrder={this.props.addCoinsToOrder} closeUpBalance={this.closeUpBalance} price_one_install={this.props.order.count_price_customer_r} />}

                    </div>
                </div>
                
                <ProgressLine order={this.props.order} count_installed={this.props.order.complete_count} max_count_installs={this.props.order.max_count_installs}/>
                <div className="install_container">
                        <div className="flex">
                             <p className="installs">{this.props.order.complete_count}</p>
                             <p className="installs_desc">{strings.completed2}</p>
                        </div>
                        <p className="installs_desc">{strings.from + " " + this.props.order.max_count_installs}</p>
                        
                </div>
              {this.state.isShowDeleteDialog && <DeleteOrderDialog onCanceledDelete={this.onCanceledDelete} onDelete={this.onDelete}/>} 
              {this.state.toastText != "" && <Toast text={this.state.toastText}/>}
            </div>
           
        );
    }
}
export default UniversalOrderItem;