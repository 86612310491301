import React from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import SdkInfoInstructionHeader from './SdkInfoInstructionHeader';
import SdkCodeContainer from './SdkCodeContainer';
import SdkCreatedTaskItem from './SdkCreatedTaskItem';
import {CSSTransitionGroup} from 'react-transition-group';
import { getUnityCode } from '../analyze_review/utils/Utils';

var strings = getLocalizedStrings()
var gradleCode = "allprojects {\n   repositories {\n        ...\n       maven { url 'https://jitpack.io'}\n    }\n}"
var gradle2Code = "implementation 'com.android.installreferrer:installreferrer:2.2'\nimplementation 'com.github.bumsun:upmob_sdk_events:v2.+'"
var gradle3Code = "UpMob.init(this)"

const unityCode = getUnityCode()
class SdkInfo extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            downloadLink : "",
            isShowDocumentation : !this.props.order.is_activate_sdk,
            currentDocumentation : 'android'
        }
    }
    updateDonwloadLink = (downloadLink) => {
        this.setState({downloadLink : downloadLink})
    }
    donwloadApp = () => {
        window.open(this.state.downloadLink, '_blank');
    }
    toggleDocumentation = () => {
        this.setState({isShowDocumentation : !this.state.isShowDocumentation})
    }
    setDocumentation = (name) => {
        this.setState({currentDocumentation : name})
    }
   
    render(){
        return (
            <div >
                <SdkInfoInstructionHeader updateDonwloadLink={this.updateDonwloadLink} gpUpdated={this.props.gpUpdated} isGpUpdated={this.props.isGpUpdated} order={this.props.order}/>
                <div className="sdk_instruction_cont">
                    {(!this.props.order.is_activate_sdk && this.props.isGpUpdated) && 
                    <div>
                        <p>{strings.downloadYourApp}</p>
                        <div onClick={this.donwloadApp} className="download_app_btn_mobile hover">{strings.downloadYourAppBtn}</div>
                        <p onClick={this.donwloadApp} className="download_app_btn hover">{this.state.downloadLink}</p>
                        <p style={{marginBottom : '16px'}}>{strings.downloadYourAppDescr}</p>
                    </div>
                    }
                       
                         <p onClick={this.toggleDocumentation} style={{marginBottom : '16px'}} className="url_universal hover">{this.state.isShowDocumentation ? strings.sdkHideDocs : strings.sdkShowDocs}</p>
                         
                            {this.state.isShowDocumentation && <div>
                                <div style={{marginBottom : '16px'}}>
                                    <div className="flex sdk_controlls">
                                        <p onClick={() => this.setDocumentation('android')} className={this.state.currentDocumentation == 'android' ? "sdk_title_controll sdk_title_controll_margin hover" : "sdk_title_controll sdk_title_controll_margin sdk_title_controll_enabled hover"}>Android</p>
                                        <p onClick={() => this.setDocumentation('unity')} className={this.state.currentDocumentation != 'android'? "sdk_title_controll hover" : "sdk_title_controll sdk_title_controll_enabled hover"}>Unity</p>
                                    </div>
                                    <div className="toolbar_line_container">
                                        <div style={this.state.currentDocumentation == 'android' ? {marginLeft : '0px'} : {marginLeft : '76px', width : '36px'}} className="toolbar_line toolbar_line_doc"/>
                                    </div>
                                </div>
                                    {this.state.currentDocumentation == 'android' && <div>
                                        <p className="sdk_instruct_text">{strings.sdkInstructTitle}</p>
                                        <SdkCodeContainer text={gradleCode}/>
                                        <p className="sdk_instruct_text">{strings.sdkInstructTitle2}</p>
                                        <SdkCodeContainer text={gradle2Code}/>
                                        <p className="sdk_instruct_text">{strings.sdkInstructTitle3}</p>
                                        <SdkCodeContainer text={gradle3Code}/>
                                    </div>}
                                    {this.state.currentDocumentation == 'unity' && <div>
                                        <p className="sdk_instruct_text sdk_mb_16">{strings.unityDoc1} <a href="https://upmob.ru/upmob.aar">upmob.aar</a> {strings.unityDoc1_2} <b>Assets/Plagins/Android</b></p>
                                        <p className="sdk_instruct_text sdk_mb_16">{strings.unityDoc2} <a href="https://upmob.ru/Upmob.cs">Upmob.cs</a>, {strings.unityDoc2_2}</p>
                                        <p className="sdk_instruct_text sdk_mb_16">{strings.unityDoc3}</p>
                                        <SdkCodeContainer text="Upmob.InitUpmob();"/>
                                        <p className="sdk_instruct_text sdk_mb_16">{strings.unityDoc4}</p>

                                        
                                        {/* <SdkCodeContainer text={unityCode}/> */}
                                    </div>}                             
                            </div>}
                    
                    
                    {!this.props.order.is_activate_sdk && <div className="line-row"/>}
                    {this.props.order.sdk_tasks.length > 0 && this.props.order.sdk_tasks.map((item, index) => (
                        <SdkCreatedTaskItem currentDocumentation={this.state.currentDocumentation} max_installs={this.props.order.max_count_installs} index={index} task={item} key={index}/>
                    ))}
                    {(!this.props.order.is_activate_sdk && !this.props.isGpUpdated) && <p className="publish_app">{strings.publishApp}</p>}

                </div>
            </div>
        );         
    }
}
export default SdkInfo;
