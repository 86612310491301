import React from 'react';
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import {DebounceInput} from 'react-debounce-input';
import { getAnimationStyle } from './utils_android/Utils';
import { getUrlParameter } from './utils_android/UrlHelperAndroid';

var strings = getLocalizedStrings()
class Reviews extends React.Component {
    constructor(props){
        super(props)
        var preOrderWithReview = getUrlParameter("with_review")
        var preOrderReviewWords = getUrlParameter("review_words")
        var preOrderReviewWish = getUrlParameter("review_wish")
        this.state = {
            isChecked : (preOrderWithReview ? true : false),
            //isReview : (preOrderReviewWords == strings.reviewWordsParams ? false : true),
            isReview : (preOrderReviewWords == "Поставьте%20оценку%205%20звезд,%20отзыв%20НЕ%20пишите." ? false : true),
            reviewWords : (preOrderReviewWords ? preOrderReviewWords : ""),
            reviewWish : (preOrderReviewWish),
        }
        this.reviewSwitchClicked = this.reviewSwitchClicked.bind(this)
        this.reviewWordsChanged = this.reviewWordsChanged.bind(this)
        this.reviewWishChanged = this.reviewWishChanged.bind(this)
        this.reviewWriteSwitchClicked = this.reviewWriteSwitchClicked.bind(this)
        this.openUniversal = this.openUniversal.bind(this)
        if(preOrderWithReview){
            this.props.onReviewChanged((preOrderReviewWords ? preOrderReviewWords : ""), (preOrderReviewWish ? preOrderReviewWish : ""), preOrderWithReview, (preOrderReviewWords == "Поставьте%20оценку%205%20звезд,%20отзыв%20НЕ%20пишите." ? false : true))
        }
    }
    reviewSwitchClicked(event){
        if (event){
            var value = event.target.checked
            this.setState({isChecked : value})
            this.props.onReviewChanged(this.state.reviewWords, this.state.reviewWish, value, this.state.isReview)
        }
    }
    reviewWriteSwitchClicked(event){
        if (event){
            var value = event.target.checked
            this.setState({isReview : value})
            this.props.onReviewChanged(this.state.reviewWords, this.state.reviewWish, this.state.isChecked, value)
        }
    }
    reviewWordsChanged(event){
        if (event){
            var reviewWords = event.target.value
            this.setState({reviewWords : reviewWords})
            this.props.onReviewChanged(reviewWords, this.state.reviewWish, this.state.isChecked, this.state.isReview)
        }
    }
    reviewWishChanged(event){
        if (event){
            var reviewWish = event.target.value
            this.setState({reviewWish : reviewWish})
            this.props.onReviewChanged(this.state.reviewWords, reviewWish, this.state.isChecked, this.state.isReview)
        }
    }
    openUniversal(){
        window.open('/create_universal', '_blank');
    }

    render() {
        return (
            <div className="block">
                <div style={this.state.isChecked ? {marginBottom : '30px'} : {marginBottom : '0px'}} className="order_row">
                    <div className="switch_container flex">
                        <label className="switch">
                            <input type="checkbox" onChange={this.reviewSwitchClicked} checked={this.state.isChecked}/>
                            <div className="slider round"></div>
                        </label>

                        <p className="text-pre-subscr">{strings.setReview}</p>
                        <p className={this.props.costReview > 0 ? "text-rub" : "not_display"}>+{this.props.costReview}₽</p>
                    </div>
                    <div className="input_description_container">
                        <p className="input_description_text">{strings.reviewDesc}</p>
                    </div>
                </div>
                
                <div style={this.state.isChecked ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0}} className="display_block height_animation inside_review_row">
                    <div className="line-row"/>
                    <div className="order_row row_review_with">
                        <div className="switch_container flex">
                            <label className="switch">
                                <input type="checkbox" onChange={this.reviewWriteSwitchClicked} checked={this.state.isReview}/>
                                <div className="slider round"></div>
                            </label>

                            <p className="text-pre-subscr">{strings.writeReviewSwitch}</p>
                        </div>
                    </div>
                    <div style={this.state.isReview ? getAnimationStyle() : {maxHeight : '0px', opacity : 0.0}} className="display_block height_animation">
                        {this.props.costReview <= 2 && <div className="review_banner"><span style={{marginRight : '16px'}}>{strings.reviewBannerTextSmile}</span>{strings.reviewBannerText} <span onClick={this.openUniversal} className="hover" style={{color : 'rgba(22,150,236, 0.8)', cursor : 'pointer'}}>{strings.reviewBannerText2}</span></div>}
                        <div className="order_row bot_16">
                            <div style={{display : 'block'}} className="input_container_border display_block">{strings.reviewWords}
                                    <DebounceInput autoComplete="off" placeholder={strings.reviewWordsPlaceholder}
                                    onChange={this.reviewWordsChanged} className="input_text_search" value={this.state.reviewWords}/>
                            </div>
                            <div className="input_description_container">
                                <p className="input_description_text">{strings.reviewWordsDesc}</p>
                            </div>
                        </div>

                        <div className="order_row">
                            <div style={{display : 'block'}} className="input_container_border display_block">{strings.reviewWish}
                                    <DebounceInput autoComplete="off"
                                    onChange={this.reviewWishChanged} className="input_text_search" placeholder={strings.reviewWishPlaceholder} value={this.state.reviewWish}/>
                            </div>
                            <div className="input_description_container">
                                <p className="input_description_text">{strings.reviewWishDesc}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
           
        );
    }
}
export default Reviews;