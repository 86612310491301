/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import close_delete from '../../custom_views/custom_icons/close_delete.svg'
const AdminBlockDialog = ({ order, closeDialog, blockOrder }) => {
    const [reason, setReason] = useState("")
    
    useEffect(() => {
       if (order == undefined){
        setReason("")
       }
    }, [order])
    const onBlock = () => {
        blockOrder(order, reason)
        closeDialog()
    }
    return (
        <div className="error_bg">
            <div style={{width : '340px', padding : '24px'}} className="error_banner_container">
                <img style={{right : '22px'}} className="dialog-close" onClick={closeDialog} src={close_delete} alt=""/>   
                <p className="delete_order_title">Причина блокировки</p>  
                <input className='fix_employer' onChange={(e) => setReason(e.target.value)} value={reason}/>
                <div style={{justifyContent : 'right'}} className='flex'>
                    <div className="dialog_btns">
                        <p onClick={onBlock} style={{color : 'var(--red-dark)'}} className="error_btn color_cancel">Заблокировать</p>
                    </div>
                    <div className="dialog_btns">
                        <p onClick={closeDialog} className="error_btn color_cancel">Закрыть</p>
                    </div>
                </div>
                
            </div>  
        </div>
    )
}
export default AdminBlockDialog;