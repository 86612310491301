/* eslint-disable eqeqeq */
import React from 'react';
import {  getAppIconType } from './analyze_review/utils/Utils';
class AppsDropdown extends React.Component {
    constructor(props){
        super(props)
        this.style = this.props.dropdownStyle
        if (!this.style){
            this.style = {}
        }
    }
    onAppPicked = (app) => {
        this.props.onAppPicked(app)
    }
    onCloseApps = () => {
        if (this.props.onCloseApps){
            this.props.onCloseApps()
        }
    }
    
    render(){
      
            return (
                
                    <div style={this.style} className="apps_dropdown">
                        <div onClick={this.onCloseApps} className="tooltip_bg tooltip_bg_mobile"></div>
                        {this.props.apps.length > 0 && this.props.apps.map((app, index) => (
                            <div onClick={() => this.onAppPicked(app)} className="flex apps_line" app={app} key={index}>
                                {app.icon && <div className="img_24"><img className="img_choose_app flex" src={app.icon.includes('https://') ? app.icon : 'https://' + app.icon} alt=""/></div>} 
                                <div className="flex">
                                {app.type_os && <img alt='icon' src={getAppIconType(app.type_os)} className="img_device_icon"/>}
                                        <p className="apps_drop_text">{app.name} {app.bundle ? "(" + app.bundle + ")" : ""}</p>
                                </div>
                                
                            </div>   
                        ))}
                    </div>
               
                
            ); 
        
        
    }
}
export default AppsDropdown;
