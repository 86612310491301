/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import '../css_android/admin.css'
import Toast from './custom_views/utils_android/Toast';
import { AdminApi } from '../api/AdminApi';
import { AdminOrderStates, filterAdminAppsOrders, filterAdminUniversalOrders, getArrowRotate, getSameEmailOrders } from './admin_views/utils/AdminUtils';
import AdminOrdersHeader from './admin_views/AdminOrdersHeader';
import AdminAppItem from './admin_views/list_items/AdminAppItem';
import AdminOrderInfoDialog from './admin_views/dialogs/AdminOrderInfoDialog';
import AdminValue from './admin_views/AdminValue';
import AdminBuyers from './admin_views/AdminBuyers';
import AdminEmployers from './admin_views/AdminEmployers';
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import FilterDropdown from './custom_views/FilterDropdown';
import arrow_filter from './custom_views/custom_icons/drop_arrow.svg'
import { isValidUrl } from './custom_views/utils_android/SocialHelper';
import AdminBlockDialog from './admin_views/dialogs/AdminBlockDialog';

var strings = getLocalizedStrings()

function Admin(props) {
    
    const [isLoading, setIsLoading] = useState(true)
    const [descriptionDialog, setDescriptionDialog] = useState({info : {}, isShow : false})
    const [toastText, setToastText] = useState("")
    const [appsOrders, setAppsOrders] = useState([])
    const [universalOrders, setUniversalOrders] = useState([])
    const [selectedOrderType, setSelectedOrderType] = useState(AdminOrderStates.apps)
    const [selectedEmail, setSelectedEmail] = useState("")
    const [isShowFilters, setShowFilters] = useState(false)
    const [blockOrderDialogInfo, setBlockOrderDialogInfo] = useState(undefined)
    const [filterPicked, setFilterPicked] = useState(strings.allFilters)
    const filters = [strings.allFilters, strings.filterCheap, strings.filterExpensive, strings.filterPriceTask]
    const filtersUniversal = [strings.allFilters, strings.filterCheap, strings.filterExpensive, strings.filterPriceTask, strings.notValidUrl, strings.siteOrdersFilter, strings.adminAppsFilter, strings.youtubeOrdersFilter]


    useEffect(() => {
       getUpmobOrders()
    //    startMove()
    }, [])
    useEffect(() => {
       setSelectedEmail("")
       setFilterPicked(strings.allFilters)
    }, [selectedOrderType])
   
    const getUpmobOrders = () => {
        AdminApi.getRunOrders((responseData) => {
            setAppsOrders(responseData.appOrders)
            setUniversalOrders(responseData.extraOrders)
            setIsLoading(false)
       }, () => { setIsLoading(false) })
    }
    const getSocialOrders = () => {
        
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 3000)
    }
    const isApps = selectedOrderType == AdminOrderStates.apps
    const isUniversal = selectedOrderType == AdminOrderStates.universal
    const isValue = selectedOrderType == AdminOrderStates.value
    const isBuyers = selectedOrderType == AdminOrderStates.buyers
    const isEmployers = selectedOrderType == AdminOrderStates.employers

    const deleteOrder = (order) => {
        AdminApi.removeOrder(order._id, (responseData) => {
            showToast("Заказ успешно удален")
        }, () => {})
        removeOrderFromList(order)
    }
    
    const blockOrder = (order, reason) => {
        AdminApi.moderateOrder(order._id, reason, (responseData) => {
            showToast("Заказ успешно заблокирован")
        }, () => {})
        removeOrderFromList(order)
    }
    const removeOrderFromList = (order) => {
        var orders = isApps ? [...appsOrders] : [...universalOrders]
        const foundIndex = orders.findIndex((obj => obj._id == order._id))
        if (foundIndex != -1){
            orders.splice(foundIndex, 1)
        }
        if (isApps){
            setAppsOrders(orders)
        } else if (isUniversal){
            setUniversalOrders(orders)
        }
    }
    const getSummOrders = (array) => {
        var sum = 0
        array.forEach(element => {
            sum += element.count_money_r
        })
        return sum
    }
    const countSameEmailOrders = getSameEmailOrders(isApps ? appsOrders : isUniversal ? universalOrders : []).filter(s => s.count > 1).sort((a, b) => b.count - a.count)
    const filteredAppsOrders = filterAdminAppsOrders(appsOrders, selectedEmail, filterPicked)
    const filteredUniversalOrders = filterAdminUniversalOrders(universalOrders, selectedEmail, filterPicked)
    const countTasksPriceLess1 = universalOrders.filter(o => o.count_money_r <= 1).length
    const countNotValidUrls = universalOrders.filter(o => !isValidUrl(encodeURI(o.url))).length
    const sum = getSummOrders(isApps ? appsOrders : isUniversal ? universalOrders : [])
    const getOrdersHTML = () => {
        return(
            <div>
                <div className='admin_item_bold' style={{marginTop : '24px'}}>Заказчики, у которых больше 1 заказа</div>
                <div style={{marginTop : 0}} className="orders_container">
                    {countSameEmailOrders.length > 0 && countSameEmailOrders.map((item, index) => (
                        <div onClick={() => setSelectedEmail(item.email)} style={selectedEmail == item.email ? {border : '1px solid #1696EC'} : {}} className='admin_user_item hover' item={item} key={index} index={index}>
                            <div>{item.email} : {item.count}</div>
                        </div>
                    ))}
                </div>
                <div style={{marginTop : '24px'}} className="filters">
                    <div onClick={() => setShowFilters(!isShowFilters)} className="button_choose_app">
                        <div className="just_content w_100">
                            <p className="button_choose_text btn_filter_choose_text">{filterPicked}</p>
                            <img alt='' style={isShowFilters ? getArrowRotate() : {}} className="drop_arrow" src={arrow_filter}/>
                        </div>
                        {isShowFilters && <FilterDropdown filters={isUniversal ? filtersUniversal : filters} onFilterPicked={(filter) => setFilterPicked(filter)}/>}
                    </div>
                </div>
                <div className='admin_item_bold' style={{margin : '0 0 0 0'}}>Всего заказов : {isApps ? filteredAppsOrders.length : filteredUniversalOrders.length}</div><br/>
                {(isUniversal && countTasksPriceLess1 > 0) && <div className='admin_item_bold' style={{margin : '-12px 0 8px 0'}}>{"Заказов с остатком меньше 1₽ : "}{countTasksPriceLess1}</div>}
                {(isUniversal && countNotValidUrls > 0) && <div className='admin_item_bold' style={{margin : '-12px 0 8px 0'}}>{"Заказов с невалидным урлом : "}{countNotValidUrls}</div>}
                <div className='admin_item_bold' style={{margin : '0px 0 -24px 0'}}>Общий остаток в заказах :  <span className='admin_item_bold'>{parseInt(sum)}₽</span></div><br/>

                {isApps ? 
                    <div className="orders_container">
                        {filteredAppsOrders.length > 0 && filteredAppsOrders.map((item, index) => (
                            <AdminAppItem item={item} key={index} index={index} deleteOrder={deleteOrder} blockOrder={(order) => setBlockOrderDialogInfo(order)} selectedOrderType={selectedOrderType}/>
                        ))}
                    </div>
                : isUniversal ?
                    <div className="orders_container">
                        {filteredUniversalOrders.length > 0 && filteredUniversalOrders.map((item, index) => (
                            <AdminAppItem item={item} key={index} index={index} deleteOrder={deleteOrder} blockOrder={(order) => setBlockOrderDialogInfo(order)} selectedOrderType={selectedOrderType} setDescriptionDialog={setDescriptionDialog}/>
                        ))}
                    </div>
                :
                    <div></div>
                }
            </div>
        )
    }
    return (
        
        <div className="main_container order_main">
            <div className="display_block">
                <AdminOrdersHeader selectedOrderType={selectedOrderType} setSelectedOrderType={setSelectedOrderType}/>

                {isEmployers ?
                    <AdminEmployers />
                :
                isBuyers ?
                    <AdminBuyers />
                :
                isValue ? 
                    <AdminValue />
                :
                    getOrdersHTML()
                }
                
            </div>
            {descriptionDialog.isShow && <AdminOrderInfoDialog item={descriptionDialog.info} closeDialog={() => setDescriptionDialog({isShow : false, info : {}})}/>} 
            {blockOrderDialogInfo != undefined && <AdminBlockDialog blockOrder={blockOrder} order={blockOrderDialogInfo} closeDialog={() => setBlockOrderDialogInfo(undefined)}/>} 

            {toastText != "" && <Toast text={toastText}/>}
        </div>
    );
}

export default Admin;
