import React from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import FilterDropdown from '../FilterDropdown';
import arrow_filter from '../custom_icons/drop_arrow.svg';

var strings = getLocalizedStrings()
var arrowRotate = {
    transform: 'rotate(180deg)',
    marginTop: '6px'
}
var filters = [strings.proofFilterNew, strings.proofFilterOndo, strings.proofFilterAccepted, strings.proofFilterRejected]

class ToolbarUniversalInfo extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isAboutInfo : true,
            isShowFilters : false,
            filterPicked : strings.proofFilterNew
            
        }       
        
    }
    openAbout = () => {
        this.setState({isAboutInfo : true})
        this.props.onToolbarChanged(true)
    }
    closeAbout = () => {
        this.setState({isAboutInfo : false})
        this.props.onToolbarChanged(false)
    }
    toogleFilters = () => {
        this.setState({isShowFilters : !this.state.isShowFilters})
    }
    onFilterPicked = (text) => {
        this.setState({filterPicked : text})
        this.props.onProofFilterPicked(text)
    }
    render() {
        return (
            <div className="toolbar_info">
                <div className="toolbar_info_cont">
                    <div className="toolbar_title">
                        <p onClick={this.openAbout} style={this.state.isAboutInfo ? {color : 'black'} :{color : '#0086C1'}} className="toolbar_title_text">{strings.aboutOrder}</p>
                        <p onClick={this.closeAbout} style={this.state.isAboutInfo ? {color : '#0086C1'} :{color : 'black'}} className="toolbar_title_text m_left_32">{strings.aboutOrderReviews}</p>
                        {this.props.badgeCount > 0 && <div className="buble_proofs top_m_4">
                            <p className="top_3">{this.props.badgeCount > 99 ? "99+" : this.props.badgeCount}</p>
                        </div>}
                    </div>
                    <div className="toolbar_line_container">
                        <div style={this.state.isAboutInfo ? {marginLeft : '0px'} : {marginLeft : '87px'}} className="toolbar_line">

                        </div>
                    </div>
                </div>
                <div style={this.state.isAboutInfo ? {} : {opacity : 1.0}} onClick={this.toogleFilters} className="button_choose_app proof_filter">
                    <div className="just_content w_100">
                        <p className="button_choose_text">{this.state.filterPicked}</p>
                        <img style={this.state.isShowFilters ? arrowRotate : {}} className="drop_arrow" src={arrow_filter}/>
                     </div>
                    {this.state.isShowFilters && <FilterDropdown style={{margin: '32px 0px 0px -2px', width: '160px'}} filters={filters} onFilterPicked={this.onFilterPicked}/>}
                </div>
              </div>
           
        );
    }
}
export default ToolbarUniversalInfo;

