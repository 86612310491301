/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import {DebounceInput} from 'react-debounce-input';
import qs from 'querystring'
import { getCookie, getEmail, getRequestParams } from './custom_views/utils_android/CookieHelper';
import payment_methods_desktop from './custom_views/custom_icons/payment_methods_desktop.svg'
import ino_payment from './custom_views/custom_icons/ino_payment.svg'
import ino_payment_unselected from './custom_views/custom_icons/ino_payment_unselected.svg'
import icon_usdt_color from './custom_views/custom_icons/icon_usdt_color.svg'
import icon_usdt_bw from './custom_views/custom_icons/icon_usdt_bw.svg'

import { getHttpParams, getRequestUrl, getUrlParameter } from './custom_views/analyze_review/utils/Utils';
import webmoney from './custom_views/custom_icons/webmoney.svg'
import picked_webmoney from './custom_views/custom_icons/picked_webmoney.svg'
import picked_bank_cards from './custom_views/custom_icons/picked_bank_cards.svg'
import bank_cards from './custom_views/custom_icons/bank_cards.svg'
import pay_checked from './custom_views/custom_icons/pay_checked.svg'
var strings = getLocalizedStrings()

class Pay extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            payCount : this.props.count_money_r < 0 ? this.props.count_money_r*-1 : 1000,
            cardsPickedType : 0,
            exchangeRate : 90,
            usd_rate : 90
            
        }       
    }
    componentDidMount(){
        this.getExchangeRate()
    }
    componentDidUpdate(prevProps){
        if (this.props != prevProps){
            const hasSum = getUrlParameter("sum") != null && getUrlParameter("sum") != undefined && getUrlParameter("sum") != ""
            this.setState({payCount : this.props.count_money_r < 0 ? this.props.count_money_r*-1 : hasSum ? getUrlParameter("sum") : 1000})
        }
    }
    onCountChanged = (event) => {
        if (event){
            var count = event.target.value
            this.setState({payCount : count})
        }
    }
    getExchangeRate = () => {
        var params = getRequestParams()
        fetch(getRequestUrl() + "getExchangeRate", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1) {
                this.setState({exchangeRate : responseData.rate})   
                this.setState({usd_rate : responseData.usd_rate})   
            }
        })
        
    }
    preparePurchase = () => {
        var params = getRequestParams()
        if (this.state.cardsPickedType == 0){
            params.price = (this.state.payCount * 100)
            params.email = getEmail()
            fetch(getRequestUrl() + "preparePurchase", {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: qs.stringify(params)
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1) {
                    this.createTinkoffPurchase(responseData._id)        
                }
            })
        } else if (this.state.cardsPickedType == 1)  {
            //window.location.href = 'https://unitpay.ru/pay/115661-40e0c/card?sum=' + this.state.payCount + '&hideMenu=true&operator=card&desc=Пополнение счета&account=' + getEmail() + "&backUrl=https://upmob.ru/pay";
            window.location.href = 'https://upmob.ru/web_money_pay?purse=Z345535854150&amount=' + Number(((this.state.payCount / this.state.exchangeRate) ).toFixed(2) )+ '&mail=' + getEmail()
        } else if (this.state.cardsPickedType == 2){
            params.amount = (this.state.payCount)
            params.email = getEmail()
            fetch(getRequestUrl() + "createInvoiceCryptomus", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response > 0) {
                    window.location.href = responseData.result.result.url

                }
            })
            
        } else if (this.state.cardsPickedType == 3){
            // this.payCloudPayment()
            // return

            const email = getCookie("email")
            const signature = window.CryptoJS.MD5("upmob.ru:" + this.state.payCount + "::USD:Bkx5ewdZNa8n3PbrG64T")

            window.open("https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=upmob.ru&OutSum=" + this.state.payCount + "&Description=payment&SignatureValue=" + signature + "&Email=" + email + "&OutSumCurrency=USD&Culture=en", "_blank")
        } else if (this.state.cardsPickedType == 4){
            params.price = (this.state.payCount * 100)
            params.email = getEmail()
            params.is_cloud_payment = 1
            fetch(getRequestUrl() + "preparePurchase", {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: qs.stringify(params)
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1) {
                    this.payCloudPayment(responseData._id, responseData.price_with_komission)        
                }
            })
        }
        
    }
    payCloudPayment = (payment_id, price_with_komission) => {
        var widget = new window.cp.CloudPayments({
            language: 'ru'
        })
        widget.pay('auth', // или 'charge'
            { //options
            publicId: 'pk_68e30e0b6de2375d770783422427e', //id из личного кабинета
            description: 'Пополнение счета', //назначение
            amount: price_with_komission , //сумма
            currency: 'RUB', //валюта
            accountId: getEmail(), //идентификатор плательщика (необязательно)
            invoiceId: payment_id, //номер заказа  (необязательно)
            skin: "mini", //дизайн виджета (необязательно)
            autoClose: 3
            }, {
                onSuccess: function(options) { // success
                    //действие при успешной оплате
                },
                onFail: function(reason, options) { // fail
                    //действие при неуспешной оплате
                },
                onComplete: function(paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                    //например вызов вашей аналитики Facebook Pixel
                }
            }
        )
    }

    createTinkoffPurchase = (orderId) => {
        
        //window.location.href = 'https://unitpay.ru/pay/394001-a3192?sum=' + this.state.payCount + '&desc=Пополнение счета&account=' + getEmail() + "&backUrl=https://upmob.ru/pay";
        //window.location.href = 'https://unitpay.ru/pay/115661-40e0c/card?sum=' + this.state.payCount + '&hideMenu=true&operator=card&desc=Пополнение счета&account=' + getEmail() + "&backUrl=https://upmob.ru/pay";
       
        //const merchantID = "24122"
        //const paymentID = Math.random().toString(36)
        //const md5Key =  md5(merchantID + ':' + this.state.payCount + ':' + 'lRedKlWMytP0ikuWzkWkVZicaRSj1e7l' + ':' + paymentID)
        //window.location.href = 'https://enot.io/pay?m=' + merchantID + '&oa=' + this.state.payCount + '&cf=' + getEmail() + '&c=Пополнение счета&o=' + paymentID + "&s=" + md5Key + "&success_url=https://upmob.ru/pay&fail_url=https://upmob.ru/pay"
        const tinkoffData = {
            TerminalKey : "1580130941288",
            Amount : (this.state.payCount * 100),
            OrderId : orderId,
            Description : "Пополнение счета",
            DATA : {
                Email : getEmail()
            },
            Receipt : {
                Email : getEmail(),
                Taxation : "usn_income",
                Items : [{
                    Name : "Пополнение счета",
                    Quantity : 1,
                    Amount : (this.state.payCount * 100),
                    Price : (this.state.payCount * 100),
                    Tax : "none"
                }]
            },
            SuccessURL : "https://upmob.ru/orders?payment=success",
            FailURL : "https://upmob.ru/orders?payment=fail"

        }
        fetch("https://securepay.tinkoff.ru/v2/Init", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(tinkoffData)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.Success){
                window.location.href = responseData.PaymentURL
            }
        })
    }
    render() {
        return (
            <div className="main_container">
                <p style={{marginBottom : '36px'}} className="main_title">{strings.payTitle}</p>
                <div className="flex">
                    <div style={{display : 'block', width : '50%'}} className="input_container_border display_block">{strings.enterPaySum}, {this.state.cardsPickedType == 3 ? "$" : "₽"}
                        <DebounceInput autoComplete="off" type="number" onChange={this.onCountChanged} className="input_text_search" value={this.state.payCount}/>
                    </div>
                    {this.state.cardsPickedType == 3 ? 
                    <p className="exchange_text_pay">≈ ₽{(parseFloat(this.state.payCount != undefined ? this.state.payCount : 0) * parseFloat(this.state.usd_rate)).toFixed(2)}</p>
                    :
                    <p className="exchange_text_pay">≈ ${(parseFloat(this.state.payCount != undefined ? this.state.payCount : 0) / parseFloat(this.state.cardsPickedType == 1 ? this.props.exchangeRate : this.state.usd_rate)).toFixed(2)}</p>}

                </div>
                
                {(this.state.cardsPickedType == 1) && <p className="type_pay_picker">{strings.payWillBePayed} {this.state.exchangeRate}₽ {strings.payFor} 1 WMZ</p>}
                <p className="type_pay_picker">{strings.payPick}</p>
                <div className="pay_types_container">
                    <div onClick={() => {this.setState({cardsPickedType : 0})}} className="relative hover">
                        <img alt="" style={this.state.cardsPickedType == 0 ? {opacity: "1"} : {}} className="pay_checked" src={pay_checked}/>
                        <div style={this.state.cardsPickedType == 0 ? {border: "1px solid #1696EC"} : {}} className="type_pay_container">
                            <img alt="" className="type_pay_img" src={this.state.cardsPickedType == 0 ? picked_bank_cards : bank_cards}/>
                            <p className="type_pay_title">{strings.payRusCard}</p>
                        </div>
                    </div>
                    {/* {(getEmail() == "zhenekvv@gmail.com" || getEmail() == "bumsun@yandex.ru") &&  */}
                    <div onClick={() => {this.setState({cardsPickedType : 4})}} className="relative hover">
                            <img alt="" style={this.state.cardsPickedType == 4 ? {opacity: "1"} : {}} className="pay_checked" src={pay_checked}/>
                            <div style={this.state.cardsPickedType == 4 ? {border: "1px solid #1696EC"} : {}} className="type_pay_container">
                                <img alt="" className="type_pay_img" src={this.state.cardsPickedType == 4 ? ino_payment : ino_payment_unselected}/>
                                <p className="type_pay_title">{strings.payInoCard}</p>
                            </div>
                    </div>
                    {/* <div onClick={() => {this.setState({cardsPickedType : 3})}} className="relative hover">
                        <img alt="" style={this.state.cardsPickedType == 3 ? {opacity: "1"} : {}} className="pay_checked" src={pay_checked}/>
                        <div style={this.state.cardsPickedType == 3 ? {border: "1px solid #1696EC"} : {}} className="type_pay_container">
                            <img alt="" className="type_pay_img" src={this.state.cardsPickedType == 3 ? ino_payment : ino_payment_unselected}/>
                            <p className="type_pay_title">{strings.payInoCard}</p>
                        </div>
                    </div> */}
                    <div onClick={() => {this.setState({cardsPickedType : 1})}} className="relative hover">
                        <img alt="" style={this.state.cardsPickedType == 1 ? {opacity: "1"} : {}} className="pay_checked" src={pay_checked}/>
                        <div style={this.state.cardsPickedType == 1 ? {border: "1px solid #1696EC"} : {}} className="type_pay_container">
                            <img alt="" className="type_pay_img" src={this.state.cardsPickedType == 1 ? picked_webmoney : webmoney}/>
                            <p className="type_pay_title">WebMoney</p>
                        </div>
                    </div>
                    <div onClick={() => {this.setState({cardsPickedType : 2})}} className="relative hover">
                        <img alt="" style={this.state.cardsPickedType == 2 ? {opacity: "1"} : {}} className="pay_checked" src={pay_checked}/>
                        <div style={this.state.cardsPickedType == 2 ? {border: "1px solid #1696EC"} : {}} className="type_pay_container">
                            <img alt="" className="type_pay_img" src={this.state.cardsPickedType == 2 ? icon_usdt_color : icon_usdt_bw}/>
                            <p className="type_pay_title">USDT</p>
                        </div>
                    </div>
                    
                    
                    
                </div>
                {this.state.cardsPickedType == 4 && <div className='pay_comission_card'>{strings.payCommission}</div>}
                {/* <button onClick={this.onPayClicked} style={{cursor : 'pointer'}} type="submit" id="make-purchase-btn" className="btn btn-primary btn-md">{strings.payBtn}</button> */}
                <div onClick={this.preparePurchase} style={(this.state.payCount < 0 || this.state.payCount == "") ? {opacity : '0.2', pointerEvents : 'none'} : {}} className="yellow_btn create_order_btn btn_pay_d">{strings.payBtn}</div>
                <div style={{marginTop : '40px'}} className="line-row"/>
                <img alt="" className="desctop_payments" src={payment_methods_desktop}/>
                <img alt="" className="mobile_payments" src={payment_methods_desktop}/>
                {/* <div id='pwyw' style='width: 175px;'></div> */}

                {/* <script type='text/javascript'>{
                    window.webmoney.widgets().PWYW.create({ lang: 'ru', data: { amount: null, purse: 'R904508216938', desc: 'customer@email.ru' } })
                    .on('paymentComplete', function (data) {
                        console.log(data)
                    })
                    .mount('pwyw')}
                    </script> */}
            </div>
           
        );
    }
}
export default Pay;