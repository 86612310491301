import React from 'react';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import Loader from '../utils_android/LoaderAndroid';
import IdfaLoader from './IdfaLoader';
import IdfaItem from '../../list_items/IdfaItem.js';
import download_csv from '../custom_icons/download_csv.svg'
import { convertToIDFA } from '../utils_android/Utils';
import { getRequestUrl, getHttpParams } from '../analyze_review/utils/Utils';
import { getRequestParams } from '../utils_android/CookieHelper';
var strings = getLocalizedStrings()
class IdfaDialog extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading : true,
            idfas : [],
            type_os : ""
        }
    }
    componentDidMount(){
        this.loadIdfas()
    }
    loadIdfas = () => {
        var params = getRequestParams()
            params.order_id = this.props.order_id
            console.log("order_id = " + this.props.order_id)
            fetch(getRequestUrl() + "getOrderEmployersWithExtra", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                var idfas = []
                responseData.employersInfo.forEach(element => {
                    if (element.photo_url == "" && element.google_user_id.length > 20){
                        element.google_user_id = convertToIDFA(element.google_user_id).toLowerCase()
                    } else {
                        element.google_user_id = ""
                    }
                    if (element.ip != undefined && element.ip != "undefined"){
                        element.ip = element.ip.split("::ffff:")[1]
                        idfas.push(element)
                    }
                    
                });
                this.setState({idfas : idfas, isLoading : false, type_os : responseData.order.type_os})
            })
    }
    downloadCSV = () => {
                var csvString = ""
                if (this.state.type_os == "ios"){
                    this.state.idfas.forEach(element => {
                        if (element.google_user_id.length > 10){
                            csvString = csvString + element.google_user_id + "\r\n"
                        }
                    });
                } else {
                    this.state.idfas.forEach(element => {
                        csvString = csvString + element.ip + "\r\n"
                    });
                }
                var a         = document.createElement('a');
                //a.href        = 'data:attachment/csv,' +  encodeURIComponent(csvString);
                a.href        = 'data:application/octet-stream;base64,'+btoa(csvString);
                //  a.target      = '_blank';
                a.download    = this.props.order_id + '.csv';
                document.body.appendChild(a);
                a.click();
    }
    render() {
        return (
            <div className="add_tag_bg">
               <div className="tag_list_container">
                    <div className="flex">
                        <p className="tag_list_title">{strings.idfaTitle}</p>
                        {this.state.idfas.length > 0 && <p className="idfa_count">{this.state.idfas.length}</p>}
                    </div>
                    <div className="tag_list_line idfas_line_top"/>
                    <div className="idfas_list">
                        {this.state.idfas.length > 0 ? this.state.idfas.map((item, index) => (
                            <IdfaItem  employer={item} index={index} key={index}/>
                        )) : <IdfaLoader/>}
                    </div>
                    
                    <div className="tag_list_line"/>
                    <div className="idfas_btns">
                        <div onClick={this.props.toogleIdfa} className="idfas_btn hover">{strings.closeTagList}</div>
                        <img onClick={this.downloadCSV} src={download_csv} className="idfa_dnwload hover"/>
                    </div>
                    
               </div>
            </div>
        );
    }
}
export default IdfaDialog;