import React from 'react';
import close_delete from '../custom_icons/close_delete.svg'
import './dialogs.css'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class CloseSdkTaskDialog extends React.Component {
    constructor(props){
        super(props)

    }
    closeNextSdkStep = () => {
        this.props.toggleCloseDialog()
        this.props.closeNextSdkStep()
    }
   
    render(){
        return (
            <div className="error_bg">
                <div className="error_banner_container sdk_dialog_container">
                    <img style={{right : '22px'}} className="dialog-close" onClick={this.props.toggleCloseDialog} src={close_delete} alt=""/>   
                    <p className="delete_order_title">{strings.sdkCloseDialogTitle}</p>  
                    <p className="delete_order_text">{strings.sdkCloseDialogText}</p>  
                    <div className="dialog_btns_sdk">
                        <p onClick={this.closeNextSdkStep} className="btn_red">{strings.sdkCloseDialogNext}</p>
                        <p onClick={this.props.toggleCloseDialog} className="error_btn color_cancel sdk_close_btn">{strings.cancel}</p>
                        
                    </div>
                </div>  
            </div>
        ); 
    }
}
export default CloseSdkTaskDialog;