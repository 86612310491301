import {  getHttpParams } from "../pages_android/custom_views/analyze_review/utils/Utils";
import { getRequestParams, getRequestUrl } from "../pages_android/custom_views/utils_android/CookieHelper";
import { log, onResponse } from "./ApiUtils";
export class AdminApi {
    
    static getRunOrders(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getRunOrdersUER848390JCO", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("run orders response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static moderateOrder(_id, reason, onSuccess, onError) {
        var params = getRequestParams()
        params._id = _id
        params.moderated = -1
        params.reason = reason
        log("moderateOrder params = ", params)
        fetch(getRequestUrl() + "setModerateOrderCH738382IWXj", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static removeOrder(_id, onSuccess, onError) {
        var params = getRequestParams()
        params._id = _id
        fetch(getRequestUrl() + "removeOrderCH738382IWXj", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getStatisticPayments(from_date, to_date, onSuccess, onError) {
        var params = getRequestParams()
        params.from_date = from_date
        params.to_date = to_date
        fetch(getRequestUrl() + "getStatisticPayments", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getStatisticPayouts(from_date, to_date, onSuccess, onError) {
        var params = getRequestParams()
        params.from_date = from_date
        params.to_date = to_date
        fetch(getRequestUrl() + "getStatisticPayouts", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getYaDirrectStat(from_date, to_date, onSuccess, onError) {
        var params = getRequestParams()
        params.from_date = from_date
        params.to_date = to_date
        console.log("getYaDirrectStat params = ", params)
        fetch(getRequestUrl() + "getYaDirrectStat", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log('getYaDirrectStat = ', responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getEarningEmployerByDates(google_user_id, from_date, to_date, onSuccess, onError) {
        var params = getRequestParams()
        params.from_date = from_date
        params.google_user_id = google_user_id
        params.to_date = to_date
        fetch(getRequestUrl() + "getEarningEmployerByDates", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getEmails(type, onSuccess, onError) {
        var params = getRequestParams()
        params.type = type
        fetch(getRequestUrl() + "getEmails", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getEmails response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getLastHistoryPayouts(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getLastHistoryPayouts", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getLastHistoryPayouts response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getRichestEmployers(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getRichestEmployers", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getRichestEmployers response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static blockEmployerById(_id, onSuccess, onError) {
        var params = getRequestParams()
        params._id = _id
        fetch(getRequestUrl() + "blockEmployerByIdUUDXH82882", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static removeEmployerById(_id, onSuccess, onError) {
        var params = getRequestParams()
        params._id = _id
        fetch(getRequestUrl() + "removeEmployerByIdUUDXH82882", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getEmployerInfoByPurse(purse, onSuccess, onError) {
        var params = getRequestParams()
        params.purse = purse
        fetch(getRequestUrl() + "getEmployersByPurseUUDXH82882", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getEmployerInfoByPurse response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getMyFriends(google_user_id, onSuccess, onError) {
        var params = getRequestParams()
        params.google_user_id = google_user_id
        fetch(getRequestUrl() + "getMyFriends", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getMyFriends response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   
}