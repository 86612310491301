import React from 'react';
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import accept from '../custom_views/custom_icons/accept.svg'
import circle_select from '../custom_views/custom_icons/circle_select.svg'
import selected from '../custom_views/custom_icons/selected.svg'
import { getRequestParams, getRequestUrl } from '../custom_views/utils_android/CookieHelper';
import ContentEditable from 'react-contenteditable'
import { getHttpParams } from '../custom_views/analyze_review/utils/Utils';
import ImageDialog from '../custom_views/dialogs/ImageDialog';
import { getProofDate } from '../custom_views/utils_android/Utils';
var strings = getLocalizedStrings()
var transperantStyle = {opacity : 0.2, pointerEvents : 'none'}
class ProofItem extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            correction : "",
            rejection : "",
            isShowCorrection : false,
            isShowRejection : false,
            proofState : this.props.proof.state,
            text : "",
            imageUrl : "",
            isLoading : false
        }
        this.correctionChanged = this.correctionChanged.bind(this)
        this.rejectionChanged = this.rejectionChanged.bind(this)
    }
  
    approveOrderYotube = (state, rejectionText) => {
        if (this.state.isLoading){
            return
        }
        var params = getRequestParams()
        this.setState({isLoading : true})
        params._id = this.props.proof._id
        params.state = state
        if (state == 2){
            params.correction = this.state.correction
        }
        if (state == -1){
            if (rejectionText != undefined){
                params.correction = rejectionText
            } else {
                params.correction = this.state.rejection
            }
        }
        console.log("approveOrderYotube params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "approveOrderYotube", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
             if(responseData.response == 1){
                 this.setState({isShowCorrection : false, isShowRejection : false, correction : "", rejection : ""})
                this.props.changeProofState(state, this.props.index, params.correction, (this.props.proof.state == 2))
                 
             }
             console.log("approveOrderYotube response = " + JSON.stringify(responseData))
             this.setState({isLoading : false})
        })
    }
   
    showCorrection = () => {
        this.setState({isShowCorrection : !this.state.isShowCorrection})
    }
    componentDidUpdate(prevProps){
        if (prevProps != this.props){
            this.setState({proofState : this.props.proof.state, text : this.linkify(this.props.proof.proof_text)})
            
           
        }
        // var images = document.getElementsByClassName("img_click")
        //     for (let index = 0; index < images.length; index++) {
        //         const element = images[index];
        //         element.onclick = this.getFullImageUrl
        //         element.onclick = this.getFullImageUrl
        //     }
    }
    componentDidMount(){
        this.setState({text : this.linkify(this.props.proof.proof_text)})
    }
    showRejection = () => {
        // if (getEmail() == "zhenekvv@gmail.com"){
        //     this.approveOrderYotube(-1, "читайте задание, необходимо предоставить 2 скриншота")
        //     return
        // }
        
        this.setState({isShowRejection : !this.state.isShowRejection})
    }
    sendBadAnswer = () => {
        this.approveOrderYotube(-1, strings.badProofAnswerText)
    }
    correctionChanged(event){
        if (event){
            var value = event.target.value
            this.setState({correction : value})
        }
    }
    rejectionChanged(event){
        if (event){
            var value = event.target.value
            this.setState({rejection : value})
        }
    }
    getFullImageUrl = (e) => {
        var params = getRequestParams()
        params.url = e.target.innerHTML.toString().replaceAll("&nbsp;", "")
        fetch(getRequestUrl() + "getImageUrlForLbb", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if(responseData.response == 1){
                //  var text = this.state.text
                //  text += '<br/><img src=' + responseData.imageUrl + '/>'
                 this.setState({imageUrl : responseData.imageUrl})
            }
        })
    }
    linkify = (inputText) => {
        var replacedText, replacePattern1, replacePattern2, replacePattern3;
    
        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        //replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
        replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank" class="hover img_click">$1</a>');
    
        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        //replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
        replacedText = replacedText.replace(replacePattern2, '$1<a href="$1" target="_blank" class="hover img_click">$2</a>');
    
    
        return replacedText;
    }
    onCancel = () => {
        this.setState({imageUrl : ""})
    }
    toogleProofSelect = () => {
        var proof = this.props.proof
        if (proof.isSelected){
            proof.isSelected = false
        } else {
            proof.isSelected = true
        }
        this.props.proofsChanged(proof, this.props.index)
    }
    
    render() {
        return (
            <div>
                <div onClick={this.props.isShowMassAnswer ? this.toogleProofSelect : undefined} style={this.props.proof.isSelected ? {border : '1px solid #FF6262'} : {}} className={this.props.isShowMassAnswer ? "proof_item_container hover" : "proof_item_container"}>
                    <div className="flex toolbar_info">
                        <div className="flex">
                            {this.props.isShowMassAnswer && <img className="hover proofs_mass_selected_img" src={this.props.proof.isSelected ? selected : circle_select}/>}
                            <div className="proof_avatar_cont">
                                <img className="proof_avatar" src={this.props.proof.employer.photo_url}/>
                            </div>
                            <p className="employer_name no_wrap_proof_name">{this.props.proof.employer.nick_name}</p>
                        </div>
                        <p className="proof_date_text">{getProofDate(this.props.proof.modified)}</p>
                    </div>
                    
                    {/* <p className="employer_text">{this.props.proof.proof_text}</p> */}
                    <p className="employer_text" dangerouslySetInnerHTML={{__html: this.state.text}}/>
                    {this.state.proofState == 2 && <div style={{marginBottom : '20px'}} className="proof_finished to_do_proof"><p>{strings.toDoProof}</p><p className="rejection_comment" dangerouslySetInnerHTML={{__html: this.state.correction != "" ? this.state.correction : this.props.proof.correction}}></p></div>}

                    {(this.state.proofState == 0 || this.state.proofState == 2) ? !this.state.isShowCorrection && !this.state.isShowRejection ?
                        <div style={this.props.isShowMassAnswer ? transperantStyle : {}} className="mobile_flex">
                            <div onClick={() => this.approveOrderYotube(1)} className="accept_btn hover">
                                <img src={accept}/>
                                <p className="accept_btn_text">{strings.acceptBtnText}</p>
                            </div>
                            <div className="mobile_flex">
                                {this.state.proofState != 2 && <p onClick={this.showCorrection} className="send_to_do hover">{strings.sendToDo}</p>}
                                <p onClick={this.showRejection} style={this.state.proofState == 2 ? {marginLeft : 0} : {}} className="reject hover">{strings.reject}</p>
                                <p onClick={this.sendBadAnswer} className="reject hover">{strings.badProofAnswer}</p>
                            </div>
                        </div> : this.state.isShowCorrection ?
                        <div className="send_to_container">
                            <p>{strings.sayEmployerWhatFix}</p>
                            <ContentEditable contentEditable="true" placeholder={strings.proofPlaceholder} onChange={this.correctionChanged} className="fix_employer" html={this.state.correction}/>
                            <div className="flex">
                                <div style={this.state.correction == "" ? transperantStyle : {}} onClick={() => this.approveOrderYotube(2)} className="accept_btn hover">
                                    <p className="accept_btn_text">{strings.sendToDo}</p>
                                </div>
                                <p onClick={this.showCorrection} className="send_to_do hover">{strings.cancel}</p>
                            </div>
                            
                        </div> : 
                        <div className="send_to_container">
                            <p>{strings.rejectionDesc}</p>
                            <ContentEditable contentEditable="true" placeholder={strings.proofPlaceholder} onChange={this.rejectionChanged} className="fix_employer" html={this.state.rejection}/>
                            <div className="flex">
                                <div style={this.state.rejection == "" ? transperantStyle : {}} onClick={() => this.approveOrderYotube(-1)} className="accept_btn hover rejection_bg">
                                    <p className="accept_btn_text reject_left">{strings.reject}</p>
                                </div>
                                <p onClick={this.showRejection} className="send_to_do hover">{strings.cancel}</p>
                            </div>
                            
                        </div> : <div></div>}
                        {this.state.proofState == 1 && <div className="proof_finished"><p>{strings.acceptedProof}</p></div>}
                    {this.state.proofState == -1 && <div className="proof_finished rejected_proof"><p>{(this.props.proof.correction != "" && this.props.proof.correction != undefined) ? strings.rejectedProof : strings.revieRejectedComment}</p> 
                    {(this.props.proof.correction != "" && this.props.proof.correction != undefined) && <p className="rejection_comment" dangerouslySetInnerHTML={{__html: this.state.rejection != "" ? this.state.rejection : this.props.proof.correction}}></p>
                    }</div>}
                    

                
                </div>
                {this.state.imageUrl != "" && <ImageDialog onCancel={this.onCancel} image={this.state.imageUrl}/>}     
            </div>
        );
    }
}
export default ProofItem;