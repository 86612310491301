import React from 'react';
import qs from 'querystring'

import '../pages_css/tooltip.css';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { isShowLeftTooltip } from '../utils_android/Utils';
import close_x from '../custom_icons/close_black.svg'
import close from '../custom_icons/close_tag.svg'
import close_red from '../custom_icons/close_red.svg'
import TagLoader from '../TagLoader';
var notFound = {background: '#FFFFFF', border: '1px solid #CB413E', width : 'fit-content'}
var strings = getLocalizedStrings()
class SearchTagsInfo extends React.Component {
    constructor(props){
        super(props)
        this.state = {
           isShowError : false
        }
       
        
    }

    toogleLeftTooltip = () => {
        this.props.toogleLeftTooltip()
    }
    render() {
        return (
            <div className="display_block">
                  

                <div onClick={this.toogleLeftTooltip} className="tooltip_bg_up_balance"></div>
                <div style={this.props.style ? this.props.style : {}} className="tooltip_plashka add_tags_info">
                    <div style={this.props.isLeft ? {} : {top : '0px', left : '128px'}} className="tooltip_arrow"></div>
                    <div className="display_block">
                        <div className="padding_24 tooltip_right">
                            <p className="add_tags_text">{strings.searchTagsInfo1}</p>
                            <div style={{background: 'rgba(150, 198, 240, 0.3)'}} className="input_tag_container tooltip_tag">
                                <p style={{color : '#2F3D48'}} className="input_tag">{strings.requestChecking}</p>
                                <TagLoader style={{margin : '-9px 24px 0px 0px'}}/>
                                <img className="input_tag_close" src={close_x}/>
                            </div>
                            <p className="add_tags_text">{strings.searchTagsInfo2}</p>
                            <div style={{background: '#0086C1'}} className="input_tag_container tooltip_tag">
                                <p className="input_tag tooltip_tag_text">{strings.requestSuccess}</p>
                                <b className="input_tag">120</b>
                                <img className="input_tag_close" src={close}/>
                            </div>
                            <p className="add_tags_text">{strings.searchTagsInfo3}</p>
                            <div style={notFound} className="input_tag_container tooltip_tag">
                                <p style={{color : "#CB413E"}} className="input_tag tooltip_tag_text">{strings.requestCanceled}</p>
                                <b style={{color : "#CB413E"}} className="input_tag">0</b>
                                <img className="input_tag_close" src={close_red}/>
                            </div>
                            <p onClick={this.toogleLeftTooltip} className="up_balance_btn_univ hover">{strings.understood}</p>
                        </div>
                    </div>
                    <div className="line_white_bg_top"/>
                </div>
            </div>
           
        );
    }
}
export default SearchTagsInfo;
