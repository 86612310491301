import React, { useState } from 'react';
import logo from '../pages_android/custom_views/custom_icons/logo.svg'
import arrow_menu_active from '../pages_android/custom_views/custom_icons/arrow_menu_active.svg'
import dollar_menu_active from '../pages_android/custom_views/custom_icons/dollar_menu_active.svg'
import stats_menu_active from '../pages_android/custom_views/custom_icons/stats_menu_active.svg'
import flag from '../pages_android/custom_views/custom_icons/flag.svg'
import social_vk from '../pages_android/custom_views/custom_icons/social_vk.svg'
import social from '../pages_android/custom_views/custom_icons/social.svg'
import menu_world from '../pages_android/custom_views/custom_icons/menu_world.svg'
import smartphone from '../pages_android/custom_views/custom_icons/smartphone.svg'
import flag_eng from '../pages_android/custom_views/custom_icons/flag_eng.png'
import flag_ru from '../pages_android/custom_views/custom_icons/flag_ru.png'
import menu_site from '../pages_android/custom_views/custom_icons/menu_site.svg'
import { getLocalizedStrings } from '../pages_android/custom_views/utils_android/LocalizedStrings';
import MenuItem from '../pages_android/list_items/MenuItem';
import { isAdmins } from '../pages_android/admin_views/utils/AdminUtils';
import { getCookie } from '../pages_android/custom_views/utils_android/CookieHelper';
import ChooseLanguageDialog from './dialogs/ChooseLanguageDialog';
import { Pages } from '../pages_android/custom_views/analyze_review/utils/Utils';

const strings = getLocalizedStrings()

function MenuAndroid(props) {
    const [currentView, setCurrentView] = useState("");
    const [isShowLangDialog, setIsShowLangDialog] = useState(false);
    
    const openWindow = (location) => {
        window.location.href = '/' + location
    }
    
    const openBlog = () => {
        window.open('http://blog.upmob.ru', '_blank');
    }
    
    const openVK = () => {
        window.open('https://vk.com/upmobru', '_blank');
    }
    
    const openTG = () => {
        window.open('https://t.me/upmobyou', '_blank');
    }
    
    const openView = (view) => {
        setCurrentView(view)
    }
    
    const openInsta = () => {
        window.open('https://www.instagram.com/promotion_upmob/', '_blank');
    }

    const imgFlag = (getCookie("picked_lang") && getCookie("picked_lang").includes('ru')) ? flag_ru : flag_eng
    
    return (
        <div className="aside__wrapper" ng-controller="menuController">
            <nav>
                <ul className="left-menu padding_0">
                <li>
                    <img alt='' src={logo} align="center" className="logo margin_l_80"/>
                </li>
                <img onClick={() => setIsShowLangDialog(true)} src={imgFlag} className='menu_lang_flag hover' alt='flag'/>

                {/* <li className="active">
                    <div onClick={() => this.openWindow("neworder")} className="yellow_btn menu_btn">{strings.createNewOrder}</div>
                </li> */}
                <MenuItem desc={strings.menuRussia} flag={flag} openView={openView} activeIcon={smartphone} location={Pages.ordersFull} name={strings.myOrders}/>
                <MenuItem desc={strings.menuWorld} openView={openView} activeIcon={menu_world} location={Pages.installsOrders} isWorldTask name={strings.myOrdersWorld}/>
                {/* {this.props.email == "bumsun@yandex.ru" && <MenuItem badgeCount={this.props.badgeCount} location="/universal_orders" name={strings.universalOrders}/>} */}
                <MenuItem openView={openView} activeIcon={menu_site} location={Pages.siteSeoOrdersAll} name={strings.menuSite}/>
                <MenuItem openView={openView} activeIcon={arrow_menu_active} badgeCount={props.badgeCount} location={Pages.universalOrders} name={strings.universalOrders}/>
                <MenuItem openView={openView} activeIcon={social} location={Pages.social} name={strings.socialNetworks}/>
                <MenuItem openView={openView} activeIcon={dollar_menu_active} location={Pages.referals} name={strings.earnWithUs}/>
                {/* {!navigator.language.includes("en") && <MenuItem activeIcon={reviews_menu_active} location="/reviews" name={strings.filterCampainReviews}/>} */}
                {/* <MenuItem activeIcon={file_menu_active}  location="/seo" name={strings.aso}/> */}
                <MenuItem openView={openView} activeIcon={stats_menu_active} location={Pages.statistics} name={strings.stats}/>
                {isAdmins() && <MenuItem openView={openView} activeIcon={stats_menu_active} location={Pages.admin} name="Админка"/>}
                
                {/* <MenuItem location="/history" name={strings.spentMoney}/>
                <MenuItem location="/custom_task" name={strings.universalWarp}/> */}
                <li>
                    <div className="menu_line"></div>
                </li>
                
                {/* <MenuItem disablePadding openView={this.openView} location="/agreement" name={strings.userAgreement}/> */}
                <li style={{paddingLeft : '24px'}} onClick={() => window.open(Pages.agreement, '_blank')} className="left-menu-text-li menu_item_blog">
                    <p className="margin_b_0">{strings.userAgreement}</p>
                </li>
                <li style={{paddingLeft : '24px'}} onClick={openBlog} className="left-menu-text-li menu_item_blog">
                    <p  className="margin_b_0">{strings.ourBlog}</p>
                </li>
                <div className="flex menu_icon_s_container">
                    <img alt='' onClick={openVK} className="hover menu_icon_social" src={social_vk}/>
                    {/* <img alt='' onClick={openTG} className="hover menu_icon_social" src={social_tg}/>
                    <img alt='' onClick={openInsta} className="hover menu_icon_social" src={social_insta}/> */}

                </div>
                </ul>


            </nav>

            <ChooseLanguageDialog 
                isShow={isShowLangDialog} 
                setShow={() => setIsShowLangDialog(false)}
            />

        </div>


    );
}

export default MenuAndroid;