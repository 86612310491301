import React from 'react';
import qs from 'querystring'
import { getRequestParams, getRequestUrl } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import arrow_filter from './custom_views/custom_icons/drop_arrow.svg'
import FilterDropdown from './custom_views/FilterDropdown';
import empty_orders from './custom_views/custom_icons/empty_orders.svg'
import cross from './custom_views/custom_icons/cross.svg'
import Loader from './custom_views/utils_android/LoaderAndroid';
import EmptyOrders from './custom_views/empty_views/EmptyOrders';
import EmptyStats from './custom_views/empty_views/EmptyStats';
import { getFilteredEmpty, predicateBy, getUniversalTypes, getOrderType } from './custom_views/utils_android/Utils';
import UniversalOrderItem from './list_items/UniversalOrderItem';
import UniversalTaskInfo from './custom_views/UniversalTaskInfo';
import ChooseTypeDropdown from './custom_views/universal_tasks/ChooseTypeDropdown';
var strings = getLocalizedStrings()
var types = getUniversalTypes()
var filters = [strings.orderStatusAll, strings.workingFilters, strings.pauseFilters, strings.finishedFilters]

var arrowRotate = {
    transform: 'rotate(180deg)',
    marginTop: '6px'
}

class UniversalOrders extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading : true,
            orders : [],
            filteredOrders : [],
            appsList : [],
            isShowTypes : false,
            isShowFilters : false,
            filterPicked : strings.orderStatusAll,
            pickedType : strings.orderType,
            isShowOrderInfo : false,
            pickedOrderInfo : {},
            pickedOrderIndex : 0,

            emptyFilteredText : strings.emptyFinishedOrders
            
        }
       this.getOrders()
        
    }
 
    orderChanged = (order, index) => {
        var changedArray = this.state.filteredOrders
        changedArray[index] = order
        this.setState({filteredOrders : changedArray})
        
    }
    
    deleteOrder = (index) => {
        var changedArray = this.state.filteredOrders
        changedArray.splice(index, 1);
        this.setState({filteredOrders : changedArray, isShowOrderInfo : false})
    }
   
    addCoinsToOrder = (id, count_money_r, count_installs, index) => {
        var params = getRequestParams()
        params.count_installs = count_installs
        params.count_money_r = count_money_r
        params._id = id
        fetch(getRequestUrl() + "addMoneyToYoutubeOrder", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
                if (responseData.response == 1) {
      
                    var changedArray = this.state.filteredOrders
                    var order = changedArray[index]
                    order.count_money_r = parseInt(order.count_money_r) + parseInt(count_money_r)
                    order.max_count_installs = parseInt(order.max_count_installs) + parseInt(count_installs)
                    changedArray[index] = order
                    this.setState({filteredOrders : changedArray})
                    this.props.updateMoney(-count_money_r)
                
              }
        })
    }
    getOrders = () => {
        var params = getRequestParams()
        fetch(getRequestUrl() + "getMyOrdersYoutube", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1) {      
                if (responseData.orders.length > 0) {
                  if (responseData.orders.length >= 3) {
                    //$window.localStorage.setItem("isMore3Orders", "1")
                  }
                  var orders = responseData.orders
                  orders.forEach(element => {
                      element.count_installed = Math.round((element.max_count_money_r - element.count_money_r) / element.count_price_customer_r)
                      element.max_count_installs = Math.round(element.max_count_money_r / element.count_price_customer_r)
                      if(element.proof_orders_youtube){
                        element.badgeCount = element.proof_orders_youtube.length
                      }
                  });
                  orders.sort(predicateBy("badgeCount"));
                  this.setState({orders : orders, filteredOrders : orders})
               
                }
            }
            this.setState({isLoading : false})
            
        })
        
    }
    onTypePicked = (type) => {
        this.setState({pickedType : type.text})
        this.onFilterPicked(this.state.filterPicked, 0 , type.text);
    }
    onFilterPicked = (filter, index, type) => {
        this.setState({filterPicked : filter})
        var fullOrders = this.state.orders 
        var pickedType = type ? type : this.state.pickedType
            var orders = fullOrders.filter(function(order) {
                
              var filtApps = (pickedType == strings.orderType ? true : getOrderType(type) == order.type_order)
              if (filter == strings.orderStatusAll) {
                return filtApps;
              } else if(filter == strings.workingFilters){
                return (order.count_money_r >= order.count_price_customer_r && order.state == 1 && filtApps);
              } else if(filter == strings.pauseFilters){
                return (order.count_money_r >= order.count_price_customer_r && order.state == 0 && filtApps);
              } else {
                return (order.count_money_r < order.count_price_customer_r && filtApps);
              }
            });
            orders.sort(predicateBy("badgeCount"));
        this.setState({filteredOrders : orders, emptyFilteredText : getFilteredEmpty(filter)})

    }
    toogleTypes = () => {
        this.setState({isShowTypes : !this.state.isShowTypes})
    }
    changeProofState = (state, index, orderIndex, comment, isOnWorkProof) => {
        var changedArray = this.state.filteredOrders
        var order = changedArray[orderIndex]
        var orderProofs = order.proof_orders_youtube.filter(function(proof) {
            return (proof.state == (isOnWorkProof ? 2 : 0))
        })
        console.log("changeProofState isOnWorkProof = " + isOnWorkProof)
        console.log("changeProofState state = " + state)
        orderProofs[index].state = state
        if (state == -1 || state == 2){
            orderProofs[index].correction = comment
        }
        changedArray[orderIndex] = order

        this.setState({filteredOrders : changedArray})
    }
    badgeCountChanged = (badgeCount, orderIndex) => {
        var changedArray = this.state.filteredOrders
        var order = changedArray[orderIndex]
        if (order.badgeCount != badgeCount){
            order.badgeCount = badgeCount
            changedArray[orderIndex] = order
            this.setState({filteredOrders : changedArray})
        }
        
    }
    setNewOrderProofs = (proofs, index) => {
        var changedArray = this.state.filteredOrders
        var order = changedArray[index]
        order.proof_orders_youtube = proofs
        changedArray[index] = order
        this.setState({filteredOrders : changedArray})
    }
    toogleFilters = () => {
        this.setState({isShowFilters : !this.state.isShowFilters})
    }
    showOrderInfo = (order, index) => {
        this.setState({isShowOrderInfo : true, pickedOrderInfo : order, pickedOrderIndex : index})
    }
    orderInfoClosed = () => {
        this.setState({isShowOrderInfo : false})
    }
    createOrder = () => {
        window.location.href = '/create_universal'
    }
    render() {
        return (
            
            <div style={this.state.isShowOrderInfo ? {width : '-webkit-fill-available', overflowY : 'auto'} : {}} className="main_container order_main">
                
                <div className={!this.state.isShowOrderInfo ? "display_block" : "not_display"}>
                    <p className="main_title">{strings.universalOrders} {strings.promotion}</p>
                    {this.state.isLoading ? <Loader />
                    : this.state.orders && this.state.orders.length > 0 ?
                     <div>
                         <div className="universal_order_filters">
                            <div className="filters">
                                <div onClick={this.toogleFilters} className="button_choose_app">
                                    <div className="just_content w_100">
                                        <p className="button_choose_text btn_filter_choose_text">{this.state.filterPicked}</p>
                                        <img style={this.state.isShowFilters ? arrowRotate : {}} className="drop_arrow" src={arrow_filter}/>
                                    </div>
                                    {this.state.isShowFilters && <FilterDropdown filters={filters} onFilterPicked={this.onFilterPicked}/>}
                                </div>
                            
                                <div onClick={this.toogleTypes} className="button_choose_app left_32 relative_picker">
                                    <div className="just_content w_100">
                                        <p className="button_choose_text btn_filter_choose_text">{this.state.pickedType}</p>
                                        <img style={this.state.isShowTypes ? arrowRotate : {}} className="drop_arrow" src={arrow_filter}/>
                                    </div>
                                    {this.state.isShowTypes && <ChooseTypeDropdown style={{top: 'auto', width: 'calc(100% + 4px)'}} filters={types} onFilterPicked={this.onTypePicked}/>}
                                </div>

                            </div>
                            <a href='/create_universal'><div className="yellow_btn empty_orders_create new_univ_order">{strings.createNewOrder}</div></a>
                         </div>
                        
                        
                            <div className="orders_container">
                            
                            {this.state.filteredOrders.length > 0 && this.state.filteredOrders.map((item, index) => (
                                <UniversalOrderItem badgeCountChanged={this.badgeCountChanged} addCoinsToOrder={this.addCoinsToOrder} showOrderInfo={this.showOrderInfo} orderChanged={this.orderChanged} count_money_r={this.props.count_money_r} index={index} order={item} key={index} getMyInfo={this.props.getMyInfo} deleteOrder={this.deleteOrder}/>
                            ))}
                            </div>
                        </div>
                        : <EmptyOrders icon={empty_orders} text1={strings.emptyOrders} text2="" text3=""/>}
                        {this.state.filteredOrders.length == 0 && !this.state.isLoading && this.state.orders.length > 0 ? <EmptyStats icon={cross} text1={this.state.emptyFilteredText} text2="" text3=""/> : <div/>}
                </div>
                {this.state.isShowOrderInfo && <UniversalTaskInfo removeBadge={this.props.removeBadge} exchangeRate={this.props.exchangeRate} badgeCountChanged={this.badgeCountChanged} setNewOrderProofs={this.setNewOrderProofs} changeProofState={this.changeProofState} getMyInfo={this.props.getMyInfo} deleteOrder={this.deleteOrder} count_money_r={this.props.count_money_r} addCoinsToOrder={this.addCoinsToOrder} orderChanged={this.orderChanged} index={this.state.pickedOrderIndex} orderInfoClosed={this.orderInfoClosed} order={this.state.pickedOrderInfo}/>}
                   
                {!this.state.isShowOrderInfo && <div className="mobile_create_order_container">
                    <a href='/create_universal'><div className="yellow_btn empty_orders_create mobile_univ_btn">{strings.createNewOrder}</div></a>
                </div>}
                  
            </div>
        
           
        );
    }
}
export default UniversalOrders;