/* eslint-disable eqeqeq */
import { getLocalizedStrings } from "./LocalizedStrings";
import order_type_vk from "../custom_icons/order_type_vk.svg";
import order_type_facebook from "../custom_icons/order_type_facebook.svg";
import order_type_telegram from "../custom_icons/order_type_telegram.svg";
import order_type_tiktok from "../custom_icons/order_type_tiktok.svg";
import order_type_youtube from "../custom_icons/order_type_youtube.svg";
import order_type_other from "../custom_icons/order_type_other.svg";
import order_type_rate from "../custom_icons/order_type_rate.svg";
import order_type_insta from "../custom_icons/order_type_insta.svg";
import order_type_app_android from "../custom_icons/order_type_app_android.svg";
import order_type_app_apple from "../custom_icons/order_type_app_apple.svg";
import moment from 'moment'
import { getRequestParams } from "./CookieHelper";
import image_failed from "../custom_icons/order_type_app_android.svg";
import { Pages } from "../analyze_review/utils/Utils";

export const declension = (count, one, two, five) => {
    var realCount = parseInt(count);
  
    if (realCount > 100)
    realCount %= 100;
  
    if (realCount > 20)
    realCount %= 10;
  
    switch (realCount) {
        case 1:
            return one;
        case 2:
        case 3:
        case 4:
            return two;
        default:
            return five;
    }
  }
export const getAnimationStyle = () => {
   return {maxHeight : 'inherit', opacity : 1.0}
  }
  export const getOptions = (order) => {
    var strings = getLocalizedStrings()
    var params = undefined;
        if (order.open_3_day) {
          params = strings.return3day
        }
        if (order.with_review) {
          if (params == undefined) {
            params = strings.reviewWord;
            if (order.review_words != undefined && order.review_words != "") {
              params = strings.reviewWord;
            }
          } else {
      
            if (order.review_words != undefined && order.review_words != "") {
              params = params + ", " + strings.reviewWord;
            } else {
              params = params + ", " + strings.reviewWord;
            }
          }
        }
        if (order.good_review_top) {
          if (params == undefined) {
            params = strings.up;
          } else {
            params = params + ", " + strings.up;
          }
        }
        if (order.progress_distribution_count_days == 1) {
          if (params == undefined) {
            params = strings.progressWord;
          } else {
            params = params + ", " + strings.progressWord;
          }
        }
        if (params == undefined){
            params = strings.noOptions
        }
    return params;
}
export const getOptionsIcon = (type, order) => {
    switch (type){
      case 0:
          if(order.open_3_day){
            return {display : 'flex'}
          }
      break
      case 1:
          if(order.with_review){
            return {display : 'flex'}
          }
      break
      case 2:
          if(order.good_review_top){
            return {display : 'flex'}
          }
      break
      case 3:
          if (order.progress_distribution_count_days == 1) {
            return {display : 'flex'}
          }
      break
  }
}
export const getReviewString = (order) => {
  var result = ""
  if(order.with_review){
    result = "&with_review=true"
    if (order.review_words){
      result += "&review_words=" + order.review_words
    }
    if (order.review_wish){
      result += "&review_wish=" + order.review_wish
    }
  }
  return result
}
export const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const openDuplicateOrder = (order, fromBots, fromSite) => {
  var searchWords = ""
  var searchPositions = ""
  if (order.search_request){
      searchWords = order.search_request
      searchPositions = order.search_position
  }
  // var url = urlForDuplicate(order, searchWords, searchPositions)
  // if (fromBots){
  //     url = urlForBotDuplicate(order)
  // }
  var url = ""
  if (fromBots){
      url = urlForBotDuplicate(order)
  } else if (fromSite){
      url = urlForSiteDuplicate(order)
  } else {
      url = urlForDuplicate(order, searchWords, searchPositions)
  }
  window.open(url, '_blank')
}
export const openDuplicateOrderThisWindow = (order, fromBots, fromSite) => {
  var searchWords = ""
  var searchPositions = ""
  if (order.search_request){
      searchWords = order.search_request
      searchPositions = order.search_position
  }
  
  var url = ""
  if (fromBots){
      url = urlForBotDuplicate(order)
  } else if (fromSite){
      url = urlForSiteDuplicate(order)
  } else {
      url = urlForDuplicate(order, searchWords, searchPositions)
  }
  window.location.href = url
}
export const urlForDuplicate = (order, searchWords, searchPositions) => {
    return Pages.newOrder + '?bundle=' + order.bundle + (searchWords != "" ? ("&word=" + searchWords) : "") + (searchWords != "" ? ("&positions=" + searchPositions) : "") + "&os=" + order.type_os + (order.open_3_day ? ("&open_3_day=" + order.open_3_day) : "") +
        "&count_installs=" + order.max_count_installs + getReviewString(order) + (order.good_review_top ? ("&good_review_top=" + order.good_review_top) : "") + 
        (order.progress_distribution_count_days == 1 ? "&progress_order=true" : "") + getLimitsString(order) + (order.appstore_id ? ("&app_id=" + order.appstore_id) : "")
}
export const urlForBotDuplicate = (order) => {
    return Pages.createInstallsOrder + "?bundle=" + order.bundle + (order.keywords ? "&word=" + order.keywords : "") + (order.keywords ? ("&positions=" + order.search_position) : "") + (order.count_installs ? "&count_installs=" + order.count_installs : "") + (order.enable_progress_distribution ? "&progress_order=true" : "") + (order.countries ? "&countries=" + JSON.parse(order.countries).join(",") : "") + "&os=" + order.type_os + (order.day_limit ? "&limit_day=" + order.day_limit : "")+ (order.appstore_id ? ("&app_id=" + order.appstore_id) : "")
}
export const urlForSiteDuplicate = (order) => {
    return Pages.createOrderSite + "?order_info=" + btoa_utf8(JSON.stringify(order))
}
export const getIsDesktop = () => {
    return window.innerWidth >= 960
}
export const getLimitsString = (order) => {
  var result = ""
  if (order.limit_day != "99999" && order.limit_day != 99999 && order.limit_day != undefined && order.limit_day != "undefined"){
    result += "&limit_day=" + order.limit_day
    if (order.limit_hour != "99999" && order.limit_hour != 99999 && order.limit_hour != undefined && order.limit_hour != "undefined"){
      result += "&limit_hour=" + order.limit_hour
    }
    if (order.limit_minute != "99999" && order.limit_minute != 99999 && order.limit_minute != undefined && order.limit_minute != "undefined"){
      result += "&limit_minute=" + order.limit_minute
    }
  }
  return result
}
function btoa_utf8(value) {
    return btoa(
        String.fromCharCode(
            ...new TextEncoder('utf-8')
                   .encode(value)
        )
    );
}
export const atob_utf8 = (value) => {
    const value_latin1 = atob(value);
    return new TextDecoder('utf-8').decode(
        Uint8Array.from(
            { length: value_latin1.length },
            (element, index) => value_latin1.charCodeAt(index)
        )
    )
}

export const getOrderInfoStatusText = (order) => {
  var strings = getLocalizedStrings()
  const isOrderCompleted = (order.count_installed >= order.max_count_installs || order.count_money_r < order.price_one_install)
  if (!isOrderCompleted && order.moderated == -1){
    return strings.blockedOrderInfo
  } else if (isOrderCompleted){
    return strings.orderInfoCompleted
  } else if (order.state == 0){
    return strings.orderInfoPause
  } else {
    return strings.orderInfoInProgress
  }
}
export const getOrderInfoStatusColor = (order) => {
  const isOrderCompleted = (order.count_installed >= order.max_count_installs || order.count_money_r < order.price_one_install)
  if (!isOrderCompleted && order.moderated == -1){
    return {background : 'rgb(255, 98, 98)', color : 'white'}
  } else  if (isOrderCompleted){
    return {background : '#5FD7A4'}
  } else if (order.state == 0){
    return {background : '#FF6262', color : 'white'}
  } else {
    return {background : '#FFC28C'}
  }
}
export const convertToIDFA = (text) => {
  if(text == undefined){
      return 'undefined';
  }
  if(text.length == 0){
      return 'undefined';
  }
  var result = '';
  for(var i = text.length-1; i >= 0; i--){
      result +=  String.fromCharCode(text.charCodeAt(i)-1);
  }
  return result;
}

export const isShowLeftTooltip = (index) => {
  var myIndex = index + 1
  var width = window.innerWidth
  if (width >= 1486){
    return myIndex == 1 ? false : myIndex % 5 == 0
  } else if(width >= 1354) {
    return myIndex == 1 ? false : myIndex % 4 == 0
  } else if(width >= 1268) {
    return myIndex == 1 ? false : myIndex % 6 == 0
  } else if(width >= 1200) {
    return myIndex == 1 ? false : myIndex % 5 == 0
  } else if(width >= 970) {
    return myIndex == 1 ? false : myIndex % 4 == 0
  } else if(width >= 768) {
    return myIndex == 1? false : myIndex % 3 == 0
  } else if(width >= 560) {
    return myIndex == 1? false : myIndex % 2 == 0
  } else {
    return true
  }
}
export const getStartDate = (order) => {
  var arrayDates = order.date_run.split('T')[0].split('-')
  if (arrayDates != undefined && arrayDates.length > 0) {
    return arrayDates[2] + "." + arrayDates[1] + "." + arrayDates[0]
  } else {
      return ""
  }
}
export const getProofDate = (date) => {
  ///var date = new Date(date)
  
  return moment(date).format('HH:mm, DD.MM.YYYY')
}
export const predicateBy = (prop) =>{
  return function(a,b){
     if (a[prop] > b[prop]){
         return -1;
     } else if(a[prop] < b[prop]){
         return 1;
     }
     return 0;
  }
}
export const getStartUniversalDate = (order) => {
  if (order.create_date == undefined){
    return ""
  }
  var arrayDates = order.create_date.split('T')[0].split('-')
  if (arrayDates != undefined && arrayDates.length > 0) {
    return arrayDates[2] + "." + arrayDates[1] + "." + arrayDates[0]
  } else {
      return ""
  }
}
export const getFilteredEmpty = (filter) => {
 var strings = getLocalizedStrings()
  if (filter == strings.allFilters) {
    return "";
  } else if (filter == strings.workingFilters){
    return strings.emptyActiveOrders
  } else if (filter == strings.pauseFilters){
    return strings.emptyPauseOrders
  } else if (filter == strings.finishedFilters) {
    return strings.emptyFinishedOrders
  } else {
    return strings.emptyBlockedOrders
  }
}
export const getSearchWords = (search_request, search_position) => {
  if (!search_request){
    return []
  }
  var searchWords = []
  var positions = []
  if (search_request.includes(", ")){
      searchWords = search_request.split(", ")
  } else if (search_request.includes(",")){
      searchWords = search_request.split(",")
  } else {
      searchWords = [search_request]
  }
  if (search_position.includes(",")){
     positions = search_position.split(",")
  }
  var finalSearchWords = []
  for (let index = 0; index < searchWords.length; index++) {
    const element = searchWords[index]
    var elementPosition = undefined
    if (positions.length > index){
        elementPosition = positions[index]
    }
    var searchWordObject = {
       'text' : element,
       'positionBefore' : elementPosition
    }
    finalSearchWords.push(searchWordObject)
  }
  return finalSearchWords
}
export const formatDate = (order) => {
    var arrayDates = order.date_run.split('T')[0].split('-')
    if (arrayDates != undefined && arrayDates.length > 0) {
        return (arrayDates[2] + "." + arrayDates[1] + "." + arrayDates[0])
    }
    return "";
}
export const formatStatsDate = (date) => {
  var arrayDates = date.split('T')[0].split('-')
  if (arrayDates != undefined && arrayDates.length > 0) {
      return (arrayDates[2] + "." + arrayDates[1] + "." + arrayDates[0])
  }
  return "";
}
  export const getDates = (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate).set("hour", 23).set("minute", 59).set("second", 59)
    while (currentDate <= stopDate) {
      dateArray.push( moment(currentDate).format('DD.MM.YYYY') )
      currentDate = moment(currentDate).add(1, 'days').set("hour", 23).set("minute", 59).set("second", 59);
    }
    return dateArray;
  };
  export const getFormattedDate= (dateString) => {
      return formatStatsDate(dateString)
  }
  export const getStatsParams = (ranges, pickedApp) => {
        var params = getRequestParams()
        var startDate = ranges.startDate.getTime()/1000
        var endDate = ranges.endDate.getTime()/1000
        params.bundle = pickedApp.bundle
        params.typeOs = pickedApp.type_os
        params.day1 = moment.unix(startDate).format("DD")
        params.month1 = moment.unix(startDate).format("MM")
        params.year1 = moment.unix(startDate).format("YYYY")
        params.day2 = moment.unix(endDate).format("DD")
        params.month2 = moment.unix(endDate).format("MM")
        params.year2 = moment.unix(endDate).format("YYYY")
        return params
  };

  export const getOrderStatsParams = (ranges, order) => {
    var params = getRequestParams()
    var startDate = ranges.startDate.getTime()/1000
    var endDate = ranges.endDate.getTime()/1000
    params.order_id = order._id
    params.typeOs = order.type_os
    params.day1 = moment.unix(startDate).format("DD")
    params.month1 = moment.unix(startDate).format("MM")
    params.year1 = moment.unix(startDate).format("YYYY")
    params.day2 = moment.unix(endDate).format("DD")
    params.month2 = moment.unix(endDate).format("MM")
    params.year2 = moment.unix(endDate).format("YYYY")
    return params
};

  
  export const isNull = (param) => {
      return (param == undefined || param == "undefined" || param == "null" || param == null)
  }
  export const getUniversalTypes = () => {
    var strings = getLocalizedStrings()
    var types = [{text : strings.typeVK, icon : order_type_vk},
      {text : strings.typeInstagram, icon : order_type_insta},
       {text : strings.typeFB, icon : order_type_facebook}, 
       {text : strings.typeTG, icon : order_type_telegram},
       {text : strings.typeTikTok, icon : order_type_tiktok},
       {text : strings.typeYoutube, icon : order_type_youtube},
       {text : strings.typeVote, icon : order_type_rate},
       {text : strings.typeAndroid, icon : order_type_app_android},
       {text : strings.typeOtherSocial, icon : order_type_other}
      //  {text : strings.accountReg, icon : order_type_other},
      //  {text : strings.typeIOs, icon : order_type_app_apple},
       
      ]
    return types
  };
  export const getControlTime = (autoReview) => {
    var strings = getLocalizedStrings()
    var time = 24
    if (autoReview == strings.reviewDays1){
      time = 24
    } else if (autoReview == strings.reviewDays2) {
      time = 48
    } else if (autoReview == strings.reviewDays3) {
      time = 72
    } else if (autoReview == strings.reviewDays7) {
      time = 168
    } else if (autoReview == strings.reviewDays14) {
      time = 336
    }
    return time
  };

  export const getControlTimeText = (time) => {
    var strings = getLocalizedStrings()
    var text = strings.reviewDays1
    if (time == 24){
      text = strings.reviewDays1
    } else if (time == 48) {
      text = strings.reviewDays2
    } else if (time == 72) {
      text = strings.reviewDays3
    } else if (time == 168){
      text = "1 " + strings.reviewDays7
    } else if (time == 336){
      text = strings.reviewDays14
    }
    return text
  };
  export const getEmptyProofText = (type) => {
    var strings = getLocalizedStrings()
    var text = ""
    if (type == strings.proofFilterNew){
      text = strings.noFilterNew
    } else if (type == strings.proofFilterOndo) {
      text = strings.noFilterOndo
    } else if (type == strings.proofFilterAccepted) {
      text = strings.noFilterAccepted
    } else if (type == strings.proofFilterRejected) {
      text = strings.noFilterRejected
    }
    return text
  };

  export const getOrderType = (text) => {
    var strings = getLocalizedStrings()
    var type = -1
    switch (text) {
      case strings.typeVote:
        type = 0
        break;
        case strings.typeVK:
        type = 1
        break;
        case strings.typeInstagram:
        type = 2
        break;
        case strings.typeTG:
        type = 3
        break;
        case strings.typeTikTok:
        type = 4
        break;
        case strings.typeYoutube:
        type = 5
        break;
        case strings.typeFB:
        type = 6
        break;
        case strings.typeOtherSocial:
        type = 7
        break;
        case strings.typeAndroid:
        type = 8
        break;
        case strings.typeIOs:
        type = 9
        // case strings.accountReg:
        // type = 10
        break;
    }
    return type
  };
  export const getOrderTypeTaskName = (text) => {
    var strings = getLocalizedStrings()
    var taskName = ""
    switch (text) {
      case strings.typeVote:
        taskName = strings.taskNameVOTE
        break;
        case strings.typeVK:
          taskName = strings.taskNameVK
        break;
        case strings.typeTG:
          taskName = strings.taskNameTG
        break;
        case strings.typeTikTok:
          taskName = strings.taskNameTikTok
        break;
        case strings.typeYoutube:
          taskName = strings.taskNameYB
        break;
        case strings.typeOtherSocial:
          taskName = strings.taskNameOther
        break;
        case strings.typeFB:
          taskName = strings.taskNameFB
        break;
        case strings.typeInstagram:
          taskName = strings.taskNameInstagram
        break;
        case strings.typeAndroid:
          taskName = strings.taskNameAndroid
        break;
        case strings.typeIOs:
          taskName = strings.taskNameIOs
        break;
        // case strings.accountReg:
        //   taskName = strings.accountReg
        break;
        
    }
    return taskName
  };
  
  export const getOrderTypeName = (type) => {
    var strings = getLocalizedStrings()
    var text = 0
    switch (parseInt(type)) {
      case 0:
        text = strings.typeVote
        break;
        case 1:
        text = strings.typeVK
        break;
        case 2:
        text = strings.typeInstagram
        break;
        case 3:
        text = strings.typeTG
        break;
        case 4:
        text = strings.typeTikTok
        break;
        case 5:
        text = strings.typeYoutube
        break;
        case 6:
        text = strings.typeFB 
        break;
        case 7:
        text = strings.typeOtherSocial
        break;
        case 8:
        text = strings.typeAndroid
        break;
        case 9:
        text = strings.typeIOs
        // case 10:
        // text = strings.accountReg
        break;
    }
    return text
  };
  export const resizeImage = (onFileLoaded, file) => {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
        if (file) {

            var reader = new FileReader();
            // Set the image once loaded into file reader
            reader.onload = function(e) {

                var img = document.createElement("img");
                img.src = e.target.result;

                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 400;
                var MAX_HEIGHT = 400;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                //var imageData = ctx.getImageData(0, 0, width, height)
                
                var dataurl = canvas.toDataURL(file.type);
                 onFileLoaded(true, dataurl)
                // document.getElementById('output').src = dataurl;
            }
            reader.readAsDataURL(file);

        }

    } else {
      onFileLoaded(false, undefined)
    }
}

export const splitTextByTwoSigns = (text,firstSign,secondSign) =>{
  var numFirst = text.indexOf(firstSign)
  var endPartHTML = text.substring(numFirst+firstSign.length)
  var out = endPartHTML;
  var numSecond = endPartHTML.indexOf(secondSign)
  if(numSecond != -1){
      out = endPartHTML.substring(0,numSecond)
  }else{
      return [undefined,numFirst,numFirst+numSecond+2]
  }
  return [out,numFirst,numFirst+numSecond+2]
}
export const checkIfImageExists = (url, completion) => {
  const img = new Image();
  img.src = url;
  
  if (img.complete) {
    completion(url)
    //this.setState({isShowImg : true})
  } else {
    img.onload = () => {
      completion(url)
    };
    
    img.onerror = () => {
      completion(image_failed)
    };
  }
}

export const getRandomInt = (max) => {
return Math.floor(Math.random() * max);
}
export const proccessRandomVariants = (textSource) => {
  try{
      var needShuffleArray = false
      
      if(textSource.indexOf("[shuffle]") != -1){
          needShuffleArray = true
          textSource = textSource.replace("[shuffle]","")
      }
      if(textSource.indexOf("]\n\n[") != -1){
          textSource = textSource.split("]\n\n[").join("][")
      }
      if(textSource.indexOf(']</p></div> <div class=\"csv_item_val\"><p class=\"csv_item_container\">[') != -1){
          textSource = textSource.split("]</p></div> <div class=\"csv_item_val\"><p class=\"csv_item_container\">[").join("] [")
      }
      
      
      
      var text = textSource+""
      var cursor = 0
      var lastTextSize = 0
      var startLength = 0
      var result = []
      var lastArrSize = undefined
      while(cursor != -1){
          var out = splitTextByTwoSigns(text,"[","]")
          // console.log("out:"+out)
        if(cursor == out[1] && out[0] != undefined && lastTextSize == out[0].length){
            return ["-2", 0,0]
        }
        cursor = out[1]
        try{
            lastTextSize = out[0].length
        }catch(err){
            
        }
        
        
        if(out[0] != undefined && out[0] != ""){
              var arr = out[0].split(";")
              result.push([arr,startLength+out[1],startLength+out[2]])
              text = text.substring(out[2])
              startLength += out[2]
              if(lastArrSize != undefined && lastArrSize != arr.length && !needShuffleArray){
                    return ["-1", 0, 0]
              }
              lastArrSize = arr.length
        }
      }
      var index = getRandomInt(result[0][0].length)
      var indexFirst = index
      var finalText = ""
      cursor = 0
      result.forEach(item => {
          if(needShuffleArray){
              index = getRandomInt(item[0].length)
          }
          try{
              finalText += textSource.substring(cursor,item[1])
          }catch(err){
              
          }
          
          
          finalText += '<b>'+ item[0][index] +'</b>'
          cursor = item[2]
      });
      
      finalText += textSource.substring(cursor)
      return [finalText,result[0].length, indexFirst]
  } catch(err){
      return [textSource, 0,0]
  }    
}

