/* eslint-disable eqeqeq */
import React from 'react';
import { getRequestUrl, getCookieStandart } from './utils_android/CookieHelper';
import './pages_css/auth.css'
import { getLocalizedStrings } from './utils_android/LocalizedStrings';
import { getHttpParams } from './analyze_review/utils/Utils';
import { isValidEmail } from './utils_android/Utils';
import EmailCodeDialog from './dialogs/EmailCodeDialog';
import Loader from './analyze_review/customViews/Loader';
var strings = getLocalizedStrings()

class RegisterPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            registerErrorText : "",
            name : "",
            email : "",
            password : "",
            promo : "",
            isShowPromo : false,
            isShowCodeDialog: false,
            isLoading : false
        }
        
    }
    nameInputChanged = (event) => {
        if (event){
            this.setState({name : event.target.value})
        }
    }
    emailInputChanged = (event) => {
        if (event){
            this.setState({email : event.target.value})
        }
    }
    passwordInputChanged = (event) => {
        if (event){
            this.setState({password : event.target.value})
        }
    }
    promoInputChanged = (event) => {
        if (event){
            this.setState({promo : event.target.value})
        }
    }
    showPromo = () => {
       this.setState({isShowPromo : true})
    }
    // pixelFb = () => {
    //     console.log("pixelFb")
    //     window.fbq('trackCustom', 'RegistrationCompleted');

    // }
    registration = () => {
        //this.pixelFb()
        this.setState({isLoading : true})
        var referal_id = getCookieStandart("referal_id")
        this.setState({registerErrorText : ""})
        var params = {
            'first_name' : this.state.name,
            'email' : this.state.email,
            // 'pass_hash' : SHA256(this.state.password),
            'promo_id' : referal_id,
            'promocode' : this.state.promo,

        }
        console.log("registration params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "authCustomerNew", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
              if (responseData.response == -1) {
                this.setState({registerErrorText : strings.emailUsed})
              } else if (responseData.response == 1) {
                this.setState({isShowCodeDialog : true})
                //this.props.showSuccessRegistated()

              }
              this.setState({isLoading : false})
              console.log("registration responseData = " + JSON.stringify(responseData))
            
        })
        
    }
    openPolicy = () => {
        window.location.href = 'https://upmob.ru/policy'
    }
    openAgreement = () => {
        window.location.href = 'https://upmob.ru/agreement'
    }
    hideError = () => {
        this.setState({registerErrorText : ""})
    }
    render() {
        return (
            <div onClick={this.hideError} className="block">
                <span className="relative">
                    <input onChange={this.nameInputChanged} type="text" name="username" className="form-control" placeholder={strings.name} value={this.state.name}/>
                    
                </span>
                <input onChange={this.emailInputChanged} type="email" name="email" className="form-control" placeholder={strings.email} value={this.state.email}/>
                {/* <input onChange={this.passwordInputChanged} type="password" name="password" className="form-control" placeholder={strings.password} value={this.state.password}/> */}
                <p onClick={this.showPromo} style={this.state.isShowPromo ? {display : 'none'}:{display : 'block'}} className="blue_text blue_hover promo_text">{strings.gotPromo}</p>
                <input onChange={this.promoInputChanged} type="text" className={this.state.isShowPromo ? "form-control" : "not_display"} placeholder={strings.promo} value={this.state.promo}/>
                <div className="relative">
                    <button style={this.state.name.length > 0 && isValidEmail(this.state.email) ? {opacity : 1, pointerEvents : 'auto', position : 'relative'} : {opacity : 0.2, pointerEvents : 'none', position : 'relative'}} onClick={this.registration} className="form-control btn btn-register" id="login-submit">{this.state.isLoading ? <Loader style={{marginTop : '0px'}} isWhite/> : strings.registerBtn}</button>
                    {this.state.registerErrorText != "" && <div className="tooltip__container tooltip-top rate-content__tooltip-wrapper my-top-tooltip">
                            <i className="tooltip__arrow rate-content__tooltip-arrow tooltip_bottom i_error" role="button"></i>
                            <p className="errorText">{this.state.registerErrorText}</p>
                    </div>}
                </div>
                <p className="form__description by_register">{strings.registerText1} <span onClick={this.openPolicy} className="privacy-text blue_text blue_hover"> {strings.registerText2} </span> {strings.registerText3}
                    <span className="privacy-text blue_text blue_hover" onClick={this.openAgreement}> {strings.registerText4}</span>
                    <span>{strings.settingsEmailingAuth}</span>
                </p>
                {this.state.isShowCodeDialog && <EmailCodeDialog email={this.state.email} closeCodeDialog={() => this.setState({isShowCodeDialog : false})} />}

             </div>
        );
    }
}
export default RegisterPage;