/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import '../../css_android/bot_orders.css'
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import { getUrlParameter } from '../custom_views/utils_android/UrlHelperAndroid';
import SearchRequest from '../custom_views/SearchRequest';
import Limits from '../custom_views/Limits';
import { getRequestParams } from '../custom_views/utils_android/CookieHelper';
import CreateOrderButton from '../custom_views/analyze_review/customViews/CreateOrderButton';
import Toast from '../custom_views/utils_android/Toast';
import SiteUrl from './custom_views/SiteUrl';
import SiteTypePicker from './custom_views/SiteTypePicker';
import { SiteSeperator, SiteType } from './utils/SiteUtils';
import SiteCount from './custom_views/SiteCount';
import SiteTime from './custom_views/SiteTime';
import SiteRepeats from './custom_views/SiteRepeats';
import { SiteSeoApi } from '../../api/SiteSeoApi';
import { atob_utf8 } from '../custom_views/utils_android/Utils';
import CityPicker from '../custom_views/analyze_review/customViews/CityPicker';

var strings = getLocalizedStrings()
var orderInfo = {}
function CreateSiteOrder(props) {
    const [url, setUrl] = useState("")
    const [icon, setIcon] = useState("")
    const [type, setType] = useState(SiteType.yandex)
    const [count, setCount] = useState(100)
    const [time, setTime] = useState(5)
    const [isRepeat, setRepeat] = useState(false)
    const [cities, setCities] = useState([])

    const [isSearchChecked, setIsSearchChecked] = useState(true)
    const [isLimitDayChecked, setLimitDayChecked] = useState(false)
    const [isProgressOrder, setProgressOrder] = useState(getUrlParameter("progress_order"))
    const [isLoading, setLoading] = useState(false)
    const [searchPosition, setSearchPosition] = useState("")
    const [searchText, setSearchText] = useState("")
    const [foundedUrls, setFoundedUrls] = useState("")
    const [foundedTitles, setFoundedTitles] = useState("")

    const [limitDay, setLimitDay] = useState(100)

    const [orderPrices, setOrderPrices] = useState({})
    const [toastText, setToastText] = useState("")
    // const [preOrderInfo, setPreOrderInfo] = useState(undefined)
    
    useEffect(() => {
        SiteSeoApi.getPrices((responseData) => {
                setOrderPrices(responseData)
        })
        orderInfo = getUrlParameter("order_info")
        if (orderInfo){
            orderInfo = JSON.parse(atob_utf8(orderInfo))
            setPreOrderInfo(orderInfo)
        }
        console.log("orderInfo = ", orderInfo)
    }, [])
    const setPreOrderInfo = (orderInfo) => {
        setUrl(orderInfo.site_name)
        setIcon(orderInfo.icon)
        setType(orderInfo.search_platform)
        setCount(orderInfo.max_count_searches)
        setTime(orderInfo.seconds_on_site)
        setRepeat(orderInfo.allow_double_search)
        if (orderInfo.limit_day > 0){
            setLimitDayChecked(true)
            setLimitDay(orderInfo.limit_day)
        }
        
        setSearchPosition(orderInfo.search_position ? orderInfo.search_position : "")
        setSearchText(orderInfo.search_request ? JSON.parse(orderInfo.search_request).join(",") : "")
        setFoundedUrls(orderInfo.founded_urls ? JSON.parse(orderInfo.founded_urls).join(",") : "")
        setFoundedTitles(orderInfo.site_titles ? JSON.parse(orderInfo.site_titles).join(",") : "")
    }
    const searchSitePosition = (isSearchChecked, searchPostion, searchText, founded_urls, founded_titles) =>{
        setIsSearchChecked(isSearchChecked)
        setSearchPosition(searchPostion)
        setSearchText(searchText)
        setFoundedUrls(founded_urls)
        setFoundedTitles(founded_titles)
    }
   
    const limitsChanged = (isLimitDayChecked, limitDay) => {
        setLimitDayChecked(isLimitDayChecked)
        setLimitDay(limitDay)
        if (isLimitDayChecked){
            setProgressOrder(false)
        }
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 3000)
    }
    // const progressChanged = () => {
    //     setProgressOrder(true)
    //     setLimitDayChecked(false)
    // }
    const disbleLimits = () => {
        setProgressOrder(false)
        setLimitDayChecked(false)
    }
    
    const createOrder = () => {
        if (isLoading){
            return
        }
        setLoading(true)

        var params = getRequestParams()
        // site_name - домен
        // max_count_searches - максимальное количество выполнений
        // limit_day, limit_hour и limit_minute слать как при создании прил
        // search_request - как в прилах
        // search_position - как в прилах
        // search_platform - google или yandex
        // seconds_on_site - как долго должен исполнитель находится на сайте
        // allow_double_search - разрешать повторно выполнять заказ
        params.url = url
        params.site_name = url
        params.max_count_searches = count
        if (isLimitDayChecked){
            params.limit_day = limitDay
        }
        
        params.icon = icon
        params.search_request = JSON.stringify(searchText.length > 0 ? searchText.split(SiteSeperator) : [searchText])
        params.search_position = searchPosition
        params.search_platform = type
        params.seconds_on_site = time
        params.allow_double_search = isRepeat
        params.founded_urls = JSON.stringify(foundedUrls.length > 0 ? foundedUrls.split(SiteSeperator) : [foundedUrls])
        params.site_titles = JSON.stringify(foundedTitles.length > 0 ? foundedTitles.split(SiteSeperator) : [foundedTitles])
        if (cities.filter(c => c.is_selected).length > 0 && !cities[0].is_selected){
            const mCities = cities.filter(c => c.is_selected)
            params.city = mCities.map(x => x.name).join(",")
        }
        console.log("addOrder params = ", params)
        SiteSeoApi.addOrder(params, (responseData) => {
            showToast(strings.siteOrderCreated)
            setLoading(false)
            window.location.href = '/site_seo_orders/all'

        }, (responseData) => {
            showToast(responseData.status)
            setLoading(false)
        })
        
    }
    // const countKeywords = (isSearchChecked && searchText.includes(",")) ? searchText.split(",").length : 0
    // const minInstallsCount = type_os == "ios" ? (isProgressOrder ? 500 : 10) : (isProgressOrder ? 500 : 50)
    
    const timeTaskAddPrice = time >= 60 ? Math.ceil(orderPrices.PRICE_SEO_CUSTOMER_BASE / 60 * (time - 60)) : 0
    const priceOnInstall = orderPrices.PRICE_SEO_CUSTOMER_BASE + timeTaskAddPrice
    const sumOrder = priceOnInstall * count
    const isEnabledButton = url && count >= 10 && searchText
    const isNotEnoughtMoney = isEnabledButton && props.count_money_r != undefined && sumOrder > props.count_money_r
    return (
        <div>
            <div className="main_container" style={{minHeight : 'auto'}}>
              
                <div className='create_order_just'>
                    <div className="main_title">
                        {strings.siteTitle}
                    </div>
                       
                </div>
               
                <SiteUrl icon={icon} setIcon={setIcon} setUrl={setUrl} url={url}/>
                <SiteTypePicker type={type} setType={setType}/>
                
                <CityPicker cities={cities} setСities={setCities}/>
                <div className="line-row"/>
                <SearchRequest cities={cities} showToast={showToast} updateMoney={props.updateMoney} isToggledSite={type} type={type} url={url} fromSite searchAppPosition={searchSitePosition} type_os={""} bundle={""} />
                
                
                <SiteCount count={count} setCount={setCount}/>
                <SiteTime count={time} setCount={setTime}/>
                <SiteRepeats isRepeat={isRepeat} setRepeat={setRepeat}/>
            </div>
            <div className="main_container" style={{minHeight : 'auto', marginTop : '16px'}}>
                <p className="installs_limits_title">{strings.limitsForEachRequest}</p>
                <div className="line-row left_40"/>
                <div className="flex">
                    <div onClick={disbleLimits} style={(!isLimitDayChecked && !isProgressOrder) ? {border : "1px solid #F5DB31"} : {}} className="radio_limits hover">
                        {(!isLimitDayChecked && !isProgressOrder) && <div className="radio_inside"/>}
                    </div>
                    <p className="text-pre-subscr">{strings.noLimitsTitle}</p>
                </div>
                <div className="line-row left_40"/>

                <Limits fromSite isLimitDayChecked={isLimitDayChecked} limitsChanged={limitsChanged}/>
                <CreateOrderButton isSite isLoading={isLoading} isEnabledButton={isEnabledButton} exchangeRate={props.exchangeRate} isSdkError={false} sumOrder={sumOrder} priceOnInstall={priceOnInstall} createOrder={createOrder} installCount={count} isShowHelp={false} isSdkControll={false}/>
                {isNotEnoughtMoney && <p className="installs_error">{strings.notEnoughtMoneyBots} {Math.ceil(sumOrder - props.count_money_r)}₽</p>}
                <p className='site_price_desc'>{strings.sitePriceDesc}</p>
            </div>
            {toastText != "" && <Toast text={toastText}/>}
        </div> 
    )
}

export default CreateSiteOrder
