import React from 'react';

class LoaderCircle extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
            return (
                <div className="lds-ring loader-earn-container">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                </div>
            );         
    }
}
export default LoaderCircle;
