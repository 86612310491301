import React from 'react';
import edit from '../icons/edit.svg'
import trash from '../icons/trash.svg'
class TagListItem extends React.Component {
    constructor(props){
        super(props)
        this.deleteCategory = this.deleteCategory.bind(this)
        this.renameCategory = this.renameCategory.bind(this)
    }
    deleteCategory(){
        this.props.deleteCategory(this.props.category, this.props.index)
    }
    renameCategory(){
        this.props.renameCategory(this.props.category, this.props.index)
    }
    render() {
        return (
            <div className="tag_list_item_cont">
                <p className="tags_list_item_text">{this.props.category.tag_name}</p>
                <div className="flex">
                    <img onClick={this.renameCategory} className="hover" src={edit}/>
                    <img onClick={this.deleteCategory} className="trash_tag_list hover" src={trash}/>
                </div>
            </div>
        );
    }
}
export default TagListItem;