/* eslint-disable eqeqeq */
import LocalizedStrings from 'react-localization'
import { getCookie } from './CookieHelper';
export const getLocalizedStrings = () => {
  let strings = new LocalizedStrings({
    ru: {
      createNewOrder: "Создать новый заказ",
      myOrders: "Продвижение в сторах",
      universalOrders: "Универсальная",
      promotion: "раскрутка",
      earnWithUs: "Заработай с нами",
      aso: "ASO анализ",
      stats: "Статистика",
      spentMoney: "Потраченные деньги",
      universalWarp: "Универсальная накрутка",
      ourBlog: "Наш блог",
      userAgreement: "Пользовательское соглашение",
      emailSent: "На ваш email отправлено письмо с ссылкой для подтверждения регистрации",
      password: "Пароль",
      enter: "Вход",
      register: "Регистрация",
      email: "Email",
      signIn: "Войти",
      forgotPass: "Забыли пароль?",
      emailUsed: "Такой почтовый ящик уже используется",
      name: "Имя",
      gotPromo: "У меня есть промокод",
      registerBtn: "Зарегистрироваться",
      registerText1: "Регистрируясь, вы принимаете условия",
      registerText2: "политики обработки персональных данных",
      registerText3: "и",
      registerText4: "Пользовательского соглашения",
      promo: "Промокод",
      wrongLogin: "Неверный логин",
      orPass: "или пароль",
      appBlocked: "Удалено из стора",
      //new order
      newOrder: "Новый заказ",
      enterEmailCode: "Введите код, отправленный вам на email",
      enterCode: "Код",
      wrongCode: "Неверный код",
      inputName: "Ссылка на приложение",
      nameDescription: "Вставьте ссылку на Google Play, App Store или RuStore.",
      // nameDescription:"Вставьте ссылку на Google Play или App Store",
      searchInstalls: "Установки по поисковому запросу",
      searchInstallsDesc: "Поможет поднять ваше приложение в топе по конкретному запросу.",
      searchRequestExample: "Введите запрос и нажмите Enter",
      searchRequest: "Поисковый запрос",
      searchRequestDesc: "Чтобы продвижение по запросу работало, ваше приложение уже должно быть в поисковой выдаче по этому запросу, хоть на последнем месте.",
      trackUrl: "Tracking ссылка",
      trackUrlDesc: "Поможет отследить все установки с нашего сервиса. Если не уверены, что такое Tracking ссылка, то сначала свяжитесь с нашим менеджером.",
      trackUrlLink: "Ссылка для отслеживания",
      trackUrlLinkDesc: "Ссылка нужна, если вы хотите отслеживать установки с нашего сервиса.",
      trackUrlTask: "Описание задания",
      trackUrlTaskPlaceholder: "Например, зарегистрироваться",
      trackUrlTaskDesc: "Краткое описание задания (необязательное поле). Мы гарантируем только установку и открытие приложения. Для проверки других действий, включите функцию \"Контроль событий внутри приложения\".",
      open3Day: "Открыть через 3 дня",
      open3DayDesc: "Возврат в приложение помогает повысить позицию в поисковой выдаче.",
      installCountPlaceholder: "Например, 500",
      installCountDesc: "Какое общее количество установок вы хотите? Минимум 10 установок. После каждой установки исполнитель запускает ваше приложение минимум на 30 секунд.",
      installCount: "Укажите количество установок",
      review: "С отзывом",
      reviewDesc: "Обратите внимание, что в Google Play и App Strore есть модерация, из-за чего может быть удалена часть отзывов (иногда все отзывы). Рекомендуется заказывать менее 10% от общего числа установок.",
      reviewWords: "Ключевоe слово для отзыва",
      reviewWordsPlaceholder: "Например, лайки",
      reviewWordsDesc: "Если вы хотите продвинуться по ключевому слову, но в поиске не выдаетесь по нему, то ключевое слово в отзыве - это то, что вам нужно.",
      reviewWish: "Важное пожелание",
      reviewWishPlaceholder: "Например, писать по-русски",
      reviewWishDesc: "Можете написать, что вам достаточно только оценки или чтобы отзыв был не короче 100 символов.",
      goodReviewsTop: "Поднять хорошие отзывы",
      goodReviewsTopDesc: "Исполнители будут лайкать положительные отзывы, благодаря чему эти отзывы станут заметнее.",
      progressOrder: "Умное распределение с прогрессией",
      progressOrderDesc1: "Хитрый алгоритм, очень эффективен для популярных запросов в Google Play. Он увеличивает эффективность за счет имитации нарастания установок. За 14 дней будет совершенно в сумме",
      progressOrderDesc2: "установок.",
      progressOrderChart1: "День",
      progressOrderChart2: "Установки",
      limits: "Простые лимиты день/час/минута",
      limitsUniv: "Лимиты выполнений в день",
      univAutoAnswer: "Автоматический ответ",
      univAutoAnswerDescr: "Используйте для автоматического ответа в отчетах",
      univAutoAnswerInput: "Ключевое слово",
      uploadCSV: "Загрузить csv-файл",
      univAutoAnswerInputDescr: "Если ключевое слово будет найдено в отчете исполнителя, задание будет засчитано автоматически. Если ответов несколько, то разделите их символом \";\"",
      limitsDesc: "Используйте ограничения на скачивания, чтобы распределить установки на длительный срок",
      limitsUnivDesc: "Используйте лимиты, чтобы распределить выполнения на длительный срок",
      limitsDay: "Максимум в день",
      limitsDayPlaceholder: "Например, 50",
      limitsDayDesc: "Выберите оптимальное ограничение на скачивание приложения в день. При ограничении меньше 10 установок в день могут возникнуть неточности.",
      limitsDayUnivDesc: "Выберите оптимальное ограничение на выполнение в день. При ограничении меньше 10 в день могут возникнуть неточности.",
      limitsHour: "Установок в час",
      limitsHourPlaceholder: "Например, 3",
      limitsHourTitle: "Количество установок в час",
      limitsHourDesc: "Выберите максимальное количество установок в час",
      limitsMinute: "Установок в минуту",
      limitsMinuteTitle: "Количество установок в минуту",
      limitsMinutePlaceholder: "Например, 3",
      limitsMinuteDesc: "Выберите максимальное количество установок в минуту",
      limitsMinuteDesc2: "Выберите удобное для вас количество установок в минуту",
      limitsHourDesc2: "Выберите удобное для вас количество установок в час.",
      orderPrice: "Стоимость заказа",
      orderPriceOneInstall: "Цена установки",
      saveOrder: "Создать",
      installsLimit: "На данный момент мы не сможем предоставить больше 800 установок для iOS приложения за короткое время.",
      installsLimitAndroid: "До 5к установок в день",
      installsLimits: "Лимиты скачиваний",
      noLimitsTitle: "Не устанавливать лимиты",
      searchWords: "Поисковые запросы",
      searchWordsPositions: "Запросы и позиции по ним",
      searchWordsPlaceholder: "Здесь появятся рекомендуемые запросы. Чтобы добавить запрос в заказ, просто нажмите на него.",
      searchWordsRecommend: "Рекомендуем добавить",
      searchingRequests: "Ищем подходящие запросы",
      requestNotFound: "Подходящих запросов не найдено",
      pressToTeg: "Нажмите на тег запроса, чтобы добавить его к заказу",
      understood: "Понятно",
      setReview: "Поставить оценку",
      writeReviewSwitch: "Написать отзыв",
      reviewBannerText: "Чтобы вручную контролировать качество каждого оставленного отзыва у Android-приложений, создайте",
      reviewBannerText2: " универсальный заказ",
      reviewBannerTextSmile: "🤓",
      reviewWordsParams: "Поставьте оценку 5 звезд, отзыв НЕ пишите.",
      requestChecking: "запрос проверяется",
      requestSuccess: "запрос подтверждён",
      requestCanceled: "запрос отклонен",
      priceAppError: "К сожалению, мы не продвигаем приложения стоимостью выше 15 рублей",
      searchTagsInfo1: "По мере добавления вами запросов мы проверяем, чтобы ваше приложение было по ним в выдаче.",
      searchTagsInfo2: " По таком запросу можно продвигаться. Ваше приложение по нему на 120 позиции.",
      searchTagsInfo3: "По таком запросу продвижение невозможно. Он не будет включен в ваш заказ.",
      addTagsDesciptions1: "Здесь предложены запросы, по которым вы могли бы продвигать ваше приложение. Цифра рядом с текстом запроса означает позицию приложения в выдаче по нему.",
      addTagsDesciptions2: "Эти запросы не являются частью вашего заказа, пока вы сами не добавите их. Для добавления запроса к заказу просто нажмите на него.",
      sdkControllTitle: "Контроль событий внутри приложения 🔥",
      sdkControllDescr: "Для автоматического контроля действий внутри вашего приложения. Вы создаёте сценарий, состоящий из задач. А их выполнение мотивированным пользователем автоматически фиксируется. Можно использовать, например, для тестирования.",
      sdkControllDescr2: "В случае активации этой опции её параметры будут доступны на следующем шаге. Потребуется работа с SDK.",
      nextBtn: "Далее",
      publishApp: "Теперь опубликуйте приложение с новым кодом в Google Play и вернитесь сюда для активации заказа.",
      downloadYourApp: "Скачайте ваше приложение по данной ссылке:",
      downloadYourAppBtn: "Скачать",
      countryRussia: "Россия",
      pickCountry: "Выберите страну",
      pickCountryDescription: "Из какой страны нужны установки.",
      downloadYourAppDescr: "Выполните один из ваших эвентов. После этого заказ автоматически активируется и станет доступен исполнителям.",



      //sdkInfo
      sdkInfoTitle: "Описание задач для контроля событий",
      sdkTaskName: "Название задачи",
      sdkTaskNamePlaceholder: "Например, регистрация",
      sdkTaskNameDescr: "Дайте задаче короткое и ёмкое название и назначьте стоимость (не менее 2₽)",
      sdkTaskPrice: "Цена задачи, ₽",
      sdkTaskDescription: "Описание задачи",
      sdkTaskDescription2: "Опишите задачу так, чтобы исполнитель понял, что от него требуется.",
      sdkTaskDescriptionPlaceholder: "Например, пройти регистрацию",
      sdkAddTask: "+ Добавить задачу",
      sdkDeleteTask: "Удалить задачу",
      sdkChangeOrder: "Изменить порядок задач",
      sdkDoSDK: "Встройте SDK",
      sdkAbout: "О заказе",
      sdkControll: "Контроль событий",
      sdkEnableTitle: "Подключение SDK",
      sdkWaiting: "ожидание",
      sdkInstructionDesc: "Дополните код вашего приложения, следуя инструкциям приведённым ниже.",
      sdkGoogleUpdate: "Активация заказа",
      sdkGoogleUpdate2: "по ссылке",
      sdkGoogleUpdateDesc: "Установите приложение на телефон по нашей ссылке и выполните любой евент.",
      sdkInstructTitle: " Добавьте код в build.gradle на уровне проекта (не на уровне модуля)",
      sdkInstructTitle2: "И добавьте зависимость нашего SDK на уровне проекта в build.gradle",
      sdkInstructTitle3: "Вызвать в методе onCreate главной Activity (Которая запускается первой в приложении)",
      sdkTaskId: "ID заказа",
      sdkTaskIdDesc: "Объясняем, что пользователь должен сделать с айдишником заказа",
      sdkTaskIdTask: "ID задачи",
      sdkEnabledText: "завершено",
      sdkCopied: "Данные скопированы",
      sdkUpdateGP: "Активируйте заказ",
      sdkCloseDialogTitle: "Настройки заказа будут утеряны",
      sdkCloseDialogText: "Настройки заказа будут утеряны, так как заказ ещё не был создан.",
      sdkCloseDialogNext: "Продолжить",
      sdkReorderTitle: "Порядок задач",
      sdkMoneyDialog: "Если не все исполнители пройдут полный сценарий заказа, через некоторое время мы вернём неистраченный бюджет заказа вам на баланс.",
      unityDoc1: "1. Скачайте файл с нашей библиотекой",
      unityDoc1_2: "и переместите его в ваш Unity проект в папку",
      unityDoc2: "2. Скачайте вспомогательный файл со скриптами на C#",
      unityDoc2_2: "скопируйте его в свой проект в папку со скриптами.",
      unityDoc3: "3. Инициализируйте библиотеку при запуске вашей игры в методе Start.",
      unityDoc4: "Далее в момент выполнения задачи, вызывайте эвент с нужным ID.",
      sdkAppealTitle: "Есть ошибка, связанная с этим заданием",
      sdkAppealDesc: "Данное сообщение отправлено исполнителем заказа.",
      sdkShowDocs: "Показать документацию",
      sdkHideDocs: "Скрыть документацию",
      //orders
      request: "Запрос:",
      options: "Опции:",
      withoutReq: "без запроса",
      return3day: "возврат",
      reviewWord: "отзыв",
      up: "поднятие",
      progressWord: "распределение",
      noOptions: "без опций",
      installs_1: "установка",
      installs_2: "установки",
      installs_5: "установок",
      from: "из",
      budget: "Бюджет:",
      status: "Статус",
      working: "выполняется",
      pause: "пауза",
      blocked: "блок",
      blockedOrderInfo: "Заблокирован",
      moderating: "модерация",
      upBalance: "Пополните баланс",
      finished: "завершён",
      deleteOrder: "Заказ будет удален безвозвратно.",
      deleteOrderTitle: "Удаление заказа",
      deleteOrderEmplTitle: "Удаление исполнителей",
      deleteOrderEmpl: "Вы действительно хотите очистить заказ и приложение от исполнителей? В таком случае пользователи установившие приложение, но через время удалившие, смогут повторно выполнить заказ. Данное действие рекомендуется выполнять не ранее, чем через 28 дней после активной фазы выполнения заказа",
      deleteOrderEmployers: "Очистить исполнителей заказа",
      deleteOrderInfo: "Удалить заказ",
      deleteOrderBtn: "Удалить",
      cancel: "Отмена",
      allFilters: "Все",
      workingFilters: "Выполняются",
      pauseFilters: "Пауза",
      finishedFilters: "Завершены",
      blockedFilters: "Заблокированы",
      allApps: "Все приложения",
      emptyOrders: "Пока что нет созданных заказов",
      emptyFinishedOrders: "Нет завершённых заказов",
      emptyBlockedOrders: "Нет заблокированных заказов",
      emptyPauseOrders: "Нет приостановленных заказов",
      emptyActiveOrders: "Нет активных заказов",
      maxInstallInfo: "Максимум скачиваний",
      onDay: "в день",
      onOur: "в час",
      onMinute: "в минуту",
      createdAt: "Создан",
      duplicateOrder: "Дублировать заказ",
      idfaTitle: "Исполнители заказа",
      //referals
      youEarned: "ВЫ ЗАРАБОТАЛИ",
      referalsCount: "Рефералов",
      spentMoneyOnApps: "Деньги можно потратить только на рекламу приложений",
      registerByMyRef: "Регистрация по своей реферальной ссылке запрещена. Это приведет к бану с блокировкой средств.",
      sendRef: "Распространяйте данную ссылку и получайте деньги на свой счет.",
      yourRefLink: "Ваша реферальная ссылка",
      copyLink: "Копировать cсылку",
      youEarn15: "Вы получаете 15%",
      fromOrders: "от стоимости заказов всех пользователей, которые зарегистрируются по вашей реферальной ссылке.",
      yourRefs: "ВАШИ РЕФЕРАЛЫ",
      dateRef: "Дата",
      emailRef: "Email",
      earningRef: "Доход, ₽",
      getMoneyTitle: "Вывод заработка",
      getMoneyPhone: "Введите номер YooMoney кошелька",
      getMoneyBtn: "Вывести деньги",
      notFound1: "не найдена",
      notFound2: "нет",
      //statistics
      pickApp: "Выберите приложение",
      campainType: "Тип кампании:",
      filterCampainRequest: "Поисковый запрос",
      filterCampainReviews: "Отзывы",
      period: "Период:",
      withoutSearchReq: "без запроса",
      statTextTV1: "Статистика по данному приложению",
      statTextTV2: "за выбранный период отсутствует",
      //aso
      searchDescAso: "Введите название приложения или вставьте ссылку на Google Play. Доступно только для Android приложений.",
      enterAppData: "Введите данные приложения,",
      enterAppData2: "по которому хотите провести анализ",
      filterByWord: "Фильтр по слову",
      keywordsStats: "Статистика позиций ключевых слов",
      asoRequest: "Запрос",
      asoPosition: "Позиция",
      keywordsNotFound1: "Ключевые слова не найдены.",
      keywordsNotFound2: "Возможно, данное приложение еще",
      keywordsNotFound3: "не проиндексировано в поиске",
      forAndroid1: "На данный момент функция доступна",
      forAndroid2: "только для андроид приложений",
      notFoundKeywords: "Ничего не найдено",
      upPositions: "Повысить позиции",
      pickedRequests: "Выбрано запросов:",
      orderInfoCompleted: "Заказ выполнен",
      orderInfoInProgress: "Заказ выполняется",
      orderInfoPause: "Заказ на паузе",
      finishedTaskStatus: "Пополните баланс, чтобы возобновить выполнение",
      continuePlay: "Возобновить выполнение",
      pauseOrder: "Приостановить выполнение",
      positionBefore: "Позиция до",
      positionAfter: "после",
      positionNumber: "Позиция",
      wrapOptions: "Опции накрутки",
      open3DayProp: "открытие на 3-й день",
      goodReviewTopProp: "поднятие хороших отзывов",
      progressProp: "распределение с прогрессией",
      installsProp: "Установки",
      budgetProp: "Бюджет",
      upBalanceProp: "Пополнить баланс",
      statsProp: "Посмотреть статистику",
      upBudget: "Сколько установок хотите добавить?",
      upInstallCount: "Количество установок",
      upSum: "Итого",
      upPriceOneInstall: "Одна установка",
      upAdd: "Добавить",
      upCancel: "Отмена",
      notEnoughtMoney: "Недостаточно средств на балансе вашего аккаунта.",
      addMoney: "Пополнить",
      writeReviewTitle: "Получите 300₽ на продвижение",
      forThis: "Для этого",
      writeReview: "напишите отзыв",
      topPercents: "ТОП",
      writeReviewText: "о работе с Upmob в нашей группе Вк и укажите в отзыве ссылку на одно из своих приложений, чтобы мы знали, куда зачислить деньги.",
      //auth
      forgotPassAuth: "Восстановление пароля",
      enterNewPass: "Введите новый пароль",
      enterEmail: "Введите свой email",
      wrongEmail: "Неверный email",
      cancelEmail: "Отмена",
      sendEmail: "Отправить",
      passwordChanged: "Пароль успешно изменен. Авторизуйтесь, используя новый пароль.",
      passRecoveryLink: "На ваш email отправлено письмо с ссылкой для восстановления пароля",
      okChanged: "Хорошо",
      //pay
      payTitle: "Пополнение баланса",
      enterPaySum: "Введите сумму оплаты",
      payBtn: "Оплатить",
      payPick: "Выберите способ оплаты",
      payWillBePayed: "Будет оплачено по курсу",
      payFor: "за",
      payBank: "Банковской",
      payBankCard: "картой",
      //createUniversalOrder
      newUniversalOrder: "Новый универсальный заказ",
      mustHaveOrder: "* Звёздочкой помечены обязательные поля",
      typeNotPicked: "Не выбран",
      typePickTitle: "Тип задания *",
      typePickDesc: "Выберите вид задания из выпадающего списка",
      typeVK: "Вконтакте",
      typeFB: "Facebook",
      typeTG: "Telegram",
      typeTikTok: "TikTok",
      typeYoutube: "Youtube",
      typeOtherSocial: "Прочее",
      typeVote: "Отзыв/Проголосовать",
      typeInstagram: "Instagram",
      typeAndroid: "Android приложение",
      typeIOs: "iOS приложение",
      accountReg: "Регистрация аккаунта",
      universalName: "Название заказа *",
      universalNameDesc: "Это название будет отображаться в заказе у исполнителя, а также в списке ваших заказов.",
      universalUrl: "Ссылка (что накручиваем) *",
      universalUrlDesc: "Ссылка, куда должен перейти исполнитель в начале выполнения данного заказа. Если укажите через знак ',' несколько ссылок, то они в случайном порядке будут отображаться у исполнителей",
      universalDescription: "Опишите, что должен сделать исполнитель",
      universalDescriptionDesc: " Подробно опишите, что нужно сделать исполнителю. На выполнение задания ему даётся час (от момента, как он открыл задание до отправки отчёта/достижения целевого действия).",
      universalDescriptionTitle: "Описание задания *",
      universalTaskProofLimit: "Лимиты выполнения",
      controlTypeReview: "Отчет",
      revieRejectedComment: "Отчет отклонен",
      controlTypeTime: "Контрольное время",
      controlType: "Тип контроля *",
      controlTypeDesc: "Выберите из выпадающего списка способ, как будет происходить контроль за исполнителем.",
      controlTypeReviewPlaceholder: "Что исполнитель должен предоставить в качестве отчёта?",
      controlTypeReviewDesc: "Опишите, что исполнитель должен предоставить в качестве отчёта. Это может быть контрольный вопрос о просмотренном содержимом. Ответ исполнителя может быть текстом или ссылкой.",
      reviewDays1: "1 сутки",
      reviewDays2: "2 суток",
      reviewDays3: "3 суток",
      reviewDays7: "неделю",
      reviewDays14: "2 недели",
      autoReviewTitle: "Автоматический зачёт через *",
      autoReviewDesc: "Это время, через которое заказ будет выбран как подтверждённый автоматически (если вы долго не заходите в сервис и не принимаете работу исполнителя).",
      secundsTitle: "Время на исполнение",
      secundsDesc: "Это время, которое исполнитель должен потратить на выполнение задания, если он вернётся на экран задания раньше, то получит предупреждение, что достаточное количество времени не прошло.",
      sec1: "секунда",
      sec2: "секунды",
      sec5: "секунд",
      priceOneInstallTitle: "Цена одного выполнения *",
      countInstallsUniversal: "Количество выполнений *",
      minPriceUniversal: "Минимальная цена",
      minPriceUniversalDesc: "Укажите, сколько исполнителей должно выполнить ваш заказ",
      universalProof: "отчет",
      universalTimeProof: "контр. время",
      completed1: "выполнен",
      completed2: "выполнено",
      completed5: "выполнено",
      inWork: "в работе",
      addUniversalTitle: "Сколько выполнений хотите добавить?",
      addUniversalOne: "Одно выполнение",
      universalInfoTitle: "Описание заказа",
      universalInfoProof: "Что нужно указать в отчёте о выполненном задании:",
      universalTaskType: "Тип задания",
      universalTaskUrl: "Ссылка",
      universalTaskProofType: "Тип контроля за исполнителем",
      universalTaskProofAuto: "Автоматический зачёт через",
      aboutOrder: "О заказе",
      aboutOrderReviews: "Отчёты",
      waitingForReviewTitle: "Отчёты, ожидающие вашей проверки",
      acceptAllText: "Принять все",
      acceptBtnText: "Принять",
      sendToDo: "Отправить на доработку",
      reject: "Отклонить",
      sayEmployerWhatFix: "Поясните исполнителю, что именно нужно доработать:",
      proofPlaceholder: "Введите текст комментария",
      proofFilterNew: "Новые",
      proofFilterOndo: "На доработке",
      proofFilterAccepted: "Принятые",
      proofFilterRejected: "Отклонённые",
      noFilterNew: "Нет новых отчётов",
      noFilterOndo: "Нет отчётов на доработке",
      noFilterAccepted: "Нет принятых отчётов",
      noFilterRejected: "Нет отклонённых отчётов",
      rejectionDesc: "Укажите причину отказа:",
      universalInProccess: "В процессе выполнения",
      acceptedProof: "Отчёт принят",
      rejectedProof: "Отчёт отклонён с комментарием:",
      toDoProof: "Отчёт отправлен на доработку с комментарием:",
      orderType: "Все типы заданий",
      orderStatusAll: "Все статусы",
      taskNameVK: "Задание Вк",
      taskNameTG: "Задание Telegram",
      taskNameFB: "Задание Facebook",
      taskNameYB: "Задание Youtube",
      taskNameVOTE: "Задание-рейтинг",
      taskNameTikTok: "Задание TikTok",
      taskNameOther: "Задание",
      taskNameInstagram: "Задание Instagram",
      taskNameAndroid: "Задание Android",
      taskNameIOs: "Задание IOs",
      maxWidthError: "Максимальная ширина картинки 1280px",
      finishedTasksText: "Выполнено",
      addRedirect: "Добавить редирект",
      addRedirectDesc: "Позволит скрыть истинный источник перехода и создать видимость, что исполнитель перешёл по ссылке из Вк.",
      upBalanceUniversal: "Для создания универсального заказа необходимо пополнить баланс минимум на 100₽.",
      upBalanceUniversalDialog: "Для создания универсального заказа пополните баланс на",
      linkToUniversal: "Читай полезную статью про универсальные заказы в нашем блоге",
      needScreenTitle: "Для отчёта исполнителю нужно загрузить скриншот",
      needScreenDescr: "Если вы включаете эту опцию, мы автоматически дадим инструкцию, что исполнителю нужно загрузить скриншот на обменник и предоставить ссылку на него",
      //reviews
      pickAppReviews: "Выберите приложение,",
      pickAppReviews2: "чтобы увидеть отзывы к нему",
      addTagInfoTitle: "Добавить тег к отзыву",
      reviewTextTitle: "Текст отзыва",
      reviewTextSelectedKey: "Ключевое слово",
      reviewTextSelectedIgnor: "Исключение",
      tagNamePlaceholder: "Введите или выберите тег отзыва",
      reviewTextDesc: "Выделите нужное слово/словосочетание, чтобы добавить его в ключевые слова или исключения. По этим словам система будет понимать, что отзыв относится именно к этому тегу.",
      tagNameDesc: "Начните вводить название тега или выберите из выпадающего списка.",
      tagNameDesc2: "Редактировать список тегов",
      createNewCategory: "Создать новый тег",
      newTagTitle: "Новый тег",
      enterNewTagName: "Введите название тега",
      createNewTagBtn: "Создать",
      cancelNewTagBtn: "Отмена",
      newTagNameError: "Такой тег уже существует",
      updateNewTag: "Добавить",
      pickTagsCategory: "Выберите тег отзыва",
      keywordsTitle: "Ключевые слова",
      ignoreTitle: "Исключения",
      loadingReviews: "Загружаем отзывы",
      reviewsTitle: "Отзывы",
      noReviewsFound: "Нет отзывов,",
      noReviewsFound2: "подходящих по выбранным фильтрам",
      readtorTagsTitle: "Редактировать список тегов",
      closeTagList: "Закрыть",
      tagListRemoveTitle: "Удаление тега",
      tagRemoveText: "Тег “",
      tagRemoveText2: "” будет удалён безвозвратно.",
      renameTagTitle: "Переименовать тег",
      saveRenamTag: "Сохранить",
      instaBanner: "Попробуйте раскрутку аккаунта Instagram у нашего партнера",
      instaBannerHref: "Instarlike",
      readAboutReviews: "Читай полезную статью про отзывы в нашем блоге",
      keywordsDescription: "Чтобы тег присвоился данному отзыву, обязательно введи ключевое слово для него.",
      ignoreDescription: "Пример исключения: если слово \"хорошо\" является ключевым для тега \"похвала\", то  \"нехорошо\" будет исключением и его нужно добавить в это поле.",
      //navbar
      navbarEnabled: "Доступно",
      navbarPay: "Пополнить",
      navbarExit: "Выйти",
      //
      univDescriptionCsv: "Подробно опишите, что нужно сделать исполнителю. Или загрузите csv-файл для создания описания с переменными из таблицы.",
      univDescriptionCsv2: "Как работать с csv-файлами и какие возможности даёт эта функция, рассказали",
      univDescriptionCsv3: "в этой статье.",
      csvErrorText: "Ошибка при загрузке и обработке вашего csv-файла",
      csvErrorDesc: "Проверьте, что ваш файл корректен.",
      csvParams: "Переменные из вашего csv-файла",
      csvParamsDesc: "Значения переменных можно втроить в поля \"Ссылка\", \"Описание\" и \"Автоматический ответ\". Чтобы это сделать, в тексте вашего описания укажите необходимую переменную таким образом:",
      csvParamsDescKey: "!!переменная!!",
      isShuffleCsv: "Синхронизировать элементы массивов",
      isShuffleCsvDesc: "Если синхронизация включена, то в каждой колонке будет выбираться одинаковая строчка",

      previewTask: "Предпросмотр заказа",
      previewTaskBtn: "Предпросмотр",
      previewDialogTitle: "Предпросмотр вашего",
      previewDialogTitle2: "задания",
      previewDialogTitle3: "Так выглядит ваше задание для исполнителей",
      previewDialogProof: "Что нужно указать в отчёте о выполненном задании:",
      previewDialogProofTimer: "Таймер задания – ",
      previewDialogProofTimerSec: " сек.",
      previewDialogProofDesc: "Если вы вернётесь раньше, то задание не будет засчитано и вознаграждение не будет начислено.",
      previewDialogProofScreen: "Для отчёта нужен скриншот",
      previewDialogProofStart: "Начать выполнение",
      previewDialogProofStartTimer: "На выполнение осталось",
      previewDialogProofMin: "мин.",
      previewDialogProofShowmore: "Показать ещё",
      previewDialogProofDesc: "Вознаграждение будет начислено после того, как заказчик одобрит ваш отчёт.",
      previewDialogProofDesc2: "Или зачтено автоматически через",
      previewDialogProofDesc3: "после отправки отчёта.",

      infoAddRequest: "Добавить запрос",
      addSearchRequestInOrder: "Добавить поисковый запрос в заказ",
      addSearchRequestInOrderPlaceholder: "Введите запрос",
      addSearchRequestInOrderPos: "Позиция в выдаче",
      addSearchRequestInOrderErr: "Такой запрос не может быть добавлен.",
      addSearchRequestInOrderErr2: "Очистить поле",
      addSearchRequestInOrderDesc: "Для добавления ещё одного запроса нажмите Enter",
      addSearchRequestNotFound: "нет",
      addSearchRequestNotFoundDesc: "не будет добавлен",
      addSearchRequestAddAll: "Добавить все",

      orderInfoInstallStats: "Статистика установок",
      orderInfoInstallStatsEmpty: "Статистика отсутствует",
      badProofAnswer: "😳 Чушь в ответе",
      badProofAnswerText: "Ваш ответ отклонен. Если продолжите писать чушь в ответе, то получите бан с блокировкой средств.",
      installsCountStats: "попыток выполнений",
      univOrderTriesCount: "Статистика попыток выполнений",

      oneUniversalPrice: "одно выполнение",
      oneUniversalPriceChange: "Стоимость одного исполнения",
      oneUniversalPriceMin: "Минимальная цена",
      oneUniversalPriceCount: "Кол-во выполнений",
      massAnswer: "Массовый ответ",
      pickProofsToDecline: "Выберите отчёты, которые хотите отклонить",
      pickProofsSelected: "Выбрано отчётов: ",
      socialNetworks: "Раскрутка соцсетей 🔥 ",
      socialNetworksTitle: "Продвижение соцсетей",
      searchCountry: "Введите или выберите страны",
      minInstallsCOunt: "Минимум",
      installCountBotDesc: "Какое общее количество установок вы хотите? Минимум",
      installCountBotDesc2: "на каждый поисковый запрос",
      disabledIosTask: "Заказы для iOS временно недоступны",
      canceledOrder: "отменен",
      waitingOrder: "ожидание запуска",
      dayLimit: "дневной лимит",
      cancelOrder: "отменить заказ",
      cancelOrderCap: "Отменить заказ",
      waitingCancel: "ожидание отмены",
      canceledFilter: "Отменены",
      pickedCountriesInfo: "Выбранные страны",
      payCommission: "Комиссия при оплате иностранной картой — 12%",
      payInoCard: "Иностранной картой",
      payRusCard: "Банковской картой, Россия",
      progressDescriptionError: ". При распределении с прогрессией минимальное количество установок 500 на каждый поисковый запрос.",
      limitsDayBotDesc: "Выберите оптимальное ограничение на скачивание приложения в день. Минимум",
      notEnoughtMoneyBots: "Недостаточно денег для заказа. Пополните счет на ",
      maxInstallsDay: "лимит",
      menuRussia: "Россия",
      menuWorld: "Мир",
      myOrdersWorld: "Продвижение в сторах",
      progressOrderDesc7days: "Хитрый алгоритм, очень эффективен для популярных запросов в Google Play. Он увеличивает эффективность за счет имитации нарастания установок. За 7 дней будет совершенно в сумме",
      blockedApp: "Приложение недоступно в сторе",
      returnRemainingFunds: "Вернуть остаток средств",
      

      androindInstallsPrice: "Установки Android",
      iosInstallsPrice: "Установки iOS",
      fromM: "от",
      blockReason: "Причина блокировки не указана",
      orderedCount: "заказано",
      settings: "Настройки",
      settingsEmailing: "Получать e-mail рассылки о скидках на услуги, статусах заказов",
      settingsEmailingAuth: ", а также соглашаетесь получать e-mail рассылки о скидках на услуги и статусах заказов",
      filterSumMore200: "Сумма больше 200 руб.",
      filterYoomoney: "Yoomoney",
      filterWebMoney: "Webmoney",
      filterAndroid: "Android",
      filterIOS: "iOS",
      filterNoBundle: "без бандла",
      filterCheap: "По маленькому остатку",
      filterExpensive: "По большому остатку",
      filterPriceTask: "По стоимости 1 выполнения",
      notValidUrl: "Невалидные урлы",
      adminAppsFilter: "Заказы с приложением",
      siteOrdersFilter: "SEO заказы",
      youtubeOrdersFilter: "Youtube заказы",
      positionZeroError: "Запрос с позицией 0 необходимо проверять вручную через мобильную версию Google Play",
      //social
      pickSocial: "Выберите соцсеть",
      socialHistory: "История заказов",
      socialOtherTasks: "Для ваших авторских заданий и продвижения других соцсетей используйте",
      socialOtherTasks2: "универсальные заказы",
      socialNoOrders: "Нет заказов",
      socialInstaExtreme: "*Признана экстремистской организацией и запрещена на территории РФ.",
      socialCreateOrder: "Создать заказ",
      socialFuturePosts: "Сколько будущих публикаций?",
      socialEnterSearch: "Введите поисковый запрос",
      socialChangeOn: "Заменить на",
      socialLinkTo: "Ссылка на",
      socialWhatToOrder: "Что хотите накрутить?",
      socialEnterLinkAndWord: "Введите ссылку и поисковый запрос",
      socialNoVideoBut: "По данному запросу видео не найдено, но есть по запросу",
      socialNoVideoByLink: "По данному запросу видео не найдено",
      socialMinimumTime: "Минимальное время просмотра (в секундах)",
      socialAnswerCount: "Номер нужного ответа в опросе",
      socialCount: "Количество",
      socialPrice: "(цена",
      socialFor1: "за 1 шт.)",
      //site
      menuSite: "Продвижение сайтов",
      siteTitle: "Продвижение сайтов в поисковых системах",
      siteUrlDesc: "Введите домен сайта. Например, https://blog.upmob.ru",
      siteUrl: "Домен сайта",
      rustoreDisabled: "Rustore заказы недоступны, создавайте их в вкладке Россия",
      siteTypeDesc: "Выберите, в какой поисковой системе продвигать сайт",
      siteCount: "Количество переходов",
      siteCountDesc: "Укажите желаемое количество переходов по всем запросам. Минимум 10. Если в заказе несколько запросов, то переходы распределяются равномерно между ними",
      siteCount1: "переход",
      siteCount2: "перехода",
      siteCount5: "переходов",
      siteTimeDesc: "Укажите количество времени, которое исполнитель должен провести на сайте, в секундах",
      siteTimeOn: "Время на сайте",
      siteRepeats: "Разрешить повторные выполнения",
      siteRepeatsDesc: "Разрешает одному исполнителю переходить на сайт по разным запросам заказа. Опцию рекомендуем включить, если нужно, чтобы заказ быстрее выполнялся",
      sitePriceDesc: "На цену влияет количество переходов и времени, которое исполнители должны провести на сайте",
      siteDayLimitsDesc: "Используйте ограничения, чтобы распределить переходы на длительный срок. Указывайте ограничение для каждого поиского запроса",
      chooseLangDialog: "Выбор языка",
      siteOrderCreated: "Заказ успешно создан",
      siteUrlError: "Проверьте правильность урла, он должен начинаться с http:// или https://",
      siteOrderPromotion: "Продвижение сайтов",
      siteAllowDouble: "Повторные открытия разрешены",
      siteTimeOnInfo: "Время на сайте",
      siteLimits: "Лимиты переходов",
      siteCompletes: "Выполнения",
      orderBlockReason: "Причина блокировки: ",
      orderBlockReasonNot: "не указана",
      siteStatisticCount: "Статистика выполнений",
      minimumSocialPrice: "Минимальная сумма заказа ",

      siteSearchPrice: "Каждый запрос стоит 0.3₽",
      siteOrderPrice: "Цены выполнения",
      needMinTimeReview: "Контроль минимального времени выполнения",
      needMinTimeReviewDesc: "Позволит проконтролировать, что исполнитель уделил вашему заданию необхходимое время.",
      goodTrafficSite: "Качественный трафик из поиска для сайта",
      upBudgetSeo: "Сколько переходов хотите добавить?",
      upPriceOneInstallSeo: "Один переход",
      commentsMain: "собственные",
      socialCommentsPlaceholder: "Комментарии (каждый комментарий в отдельной строке)",
      socialCommentsTitle: "Текст комментариев",
      socialCommentsError: "комментариев",
      allCities: "Все города",
      enterCity: "Введите город",
      chooseCityDesc: "Выберите из выпадающего списка город или города исполнителя, если это для вас важно.",
      cityTitle: "Выбранные города",
      //not localized
      asd: "",

      // Universal order validation messages
      minimalOrderSum: "Минимальная сумма заказа",
      minimalPricePerTask: "Минимальная цена за задание",
      enterTaskName: "Введите название задания",
      chooseControlType: "Выберите тип контроля",
      enterUrl: "Введите ссылку",
      enterDescription: "Введите описание задания",
      chooseTaskType: "Выберите тип задания",
      limitsForEachRequest: "Лимиты переходов для каждого запроса",
      maxDayForEachRequest: "Максимум в день для каждого запроса",
      changeDayLimit: "Изменить дневной лимит выполнений",
    },
    en: {
      createNewOrder: "Create new order",
      myOrders: "Orders for stores",
      universalOrders: "Universal",
      promotion: "promotion",
      earnWithUs: "Earn with us",
      aso: "ASO analysis",
      stats: "Statistics",
      spentMoney: "Spent money",
      universalWarp: "Universal boosting",
      ourBlog: "Our blog",
      userAgreement: "User agreement",
      emailSent: "We sent confirmation to your email. Please, check it.",
      password: "Password",
      enter: "Login",
      register: "Sign Up",
      email: "Email",
      signIn: "Log in",
      forgotPass: "I forget my password",
      emailUsed: "This email is already used in our service",
      name: "Name",
      gotPromo: "I have a promo code",
      registerBtn: "Sign Up",
      registerText1: "By signup, you accept the terms",
      registerText2: "Personal data processing policies",
      registerText3: "and",
      registerText4: "User agreement",
      promo: "Promo code",
      wrongLogin: "Invalid login",
      orPass: "or password",
      appBlocked: "Removed from store",
      //new order
      newOrder: "New order",
      inputName: "Name or link to the application",
      nameDescription: "Type the app name or insert a link to Google Play or App Store.",
      searchInstalls: "Installs by search request",
      searchInstallsDesc: "It helps you to boost the application for a specific request.",
      searchRequestExample: "Enter request and press Enter",
      searchRequest: "Search request",
      searchRequestDesc: "Boosting start only if your app presents in the search results for this request, at least in last place.",
      trackUrl: "Tracking link",
      trackUrlDesc: "It helps you to track or installs that our service provided. If you want to learn more about this item, please text us in our social media.",
      trackUrlLink: "Tracking link",
      trackUrlLinkDesc: "The link is needed if you want to track installations from our service.",
      trackUrlTask: "Task description",
      trackUrlTaskPlaceholder: "For example, signup",
      trackUrlTaskDesc: "Description of your task (optional field). Краткое описание задания (необязательное поле). To check other actions, enable the \"Event Monitoring inside the application\" option.",
      open3Day: "Open in 3 days",
      open3DayDesc: "Returning to the application helps to increase the position in the search results.",
      installCountPlaceholder: "For example, 500",
      installCountDesc: "What is the total number of installations you want? Minimum 10 installations. After each installation, the performer launches your application for at least 30 seconds.",
      installCount: "Specify the number of installations",
      review: "With review",
      reviewDesc: "Please note that there is moderation in Google Play and the App Store, which may cause some reviews (sometimes all reviews) to be deleted. We recommend to order less than 10% of the total number of installations.",
      reviewWords: "Keyword for review",
      reviewWordsPlaceholder: "For example, likes",
      reviewWordsDesc: "If you want to boost on a keyword, but the app are not presented in the search results for it, then the keyword in the review is what you need.",
      reviewWish: "Important requirement",
      reviewWishPlaceholder: "For example, more than 100 characters in review",
      reviewWishDesc: "You can type that you only need a rating or that the review should be no shorter than 100 characters.",
      goodReviewsTop: "Boost good reviews",
      goodReviewsTopDesc: "Performers give likes to positive reviews, so that these reviews will become more noticeable.",
      progressOrder: "Smart promotion with progression",
      progressOrderDesc1: "A tricky algorithm, very effective for popular requests in Google Play. It increases efficiency by simulating the growth of installations. In 14 days it will be completely in the amount of",
      progressOrderDesc2: "installations.",
      progressOrderChart1: "Day",
      progressOrderChart2: "Installations",
      limits: "Simple limits day/hour/minute",
      limitsDesc: "Use download limits to distribute installations over a long period of time.",
      limitsDay: "Maximum per day",
      limitsDayPlaceholder: "For example, 50",
      limitsDayDesc: "Choose the optimal limit for downloading the application per day. If the limit is less than 10 installations per day, inaccuracies may occur.",
      limitsHour: "Installs per hour",
      limitsHourPlaceholder: "For example, 3",
      limitsHourTitle: "Installs per hour",
      limitsHourDesc: "Set the maximum number of installations per hour",
      limitsMinute: "Installs per hour",
      limitsMinuteTitle: "Installs per hour",
      limitsMinutePlaceholder: "For example, 3",
      limitsMinuteDesc: "Set the maximum number of installations per minute",
      limitsMinuteDesc2: "Set the optimal number of installations per minute",
      limitsHourDesc2: "et the maximum number of installations per hour",
      limitsUniv: "Limits of executions per day",
      univAutoAnswer: "Auto Answer",
      univAutoAnswerDescr: "Use for auto answer in reports",
      univAutoAnswerInput: "Keyword",
      univAutoAnswerInputDescr: "If the keyword is found in the executor's report, the assignment will be automatically credited.",
      orderPrice: "Total",
      orderPriceOneInstall: "Installation coast",
      saveOrder: "Create",
      installsLimit: "At the moment, we can't provide more than 800 installations for the iOS app in a short time.",
      installsLimitAndroid: "Up to 5000 installs per day",
      installsLimits: "Installation limits",
      noLimitsTitle: "No limits",
      searchWords: "Search requests",
      searchWordsPositions: "Requests and positions on them",
      searchWordsPlaceholder: "Recommended queries will appear here. To add a request to your order, just click on it.",
      searchWordsRecommend: "Recommended",
      searchingRequests: "Search for suitable requests",
      requestNotFound: "No appropriated requests found",
      pressToTeg: "Click on the request tag to add it to your order",
      understood: "I see",
      setReview: "Rate",
      countryRussia: "Russia",
      pickCountry: "Select a country",
      pickCountryDescription: "Which country do you want to install from.",
      writeReviewSwitch: "Write a review",
      reviewBannerText: "To manually control the quality of each review left for Android applications, create",
      reviewBannerText2: " universal order",
      reviewBannerTextSmile: "🤓",
      reviewWordsParams: "Give a rating of 5 stars, do NOT write a review.",
      requestChecking: "the request is being verified",
      requestSuccess: "request confirmed",
      requestCanceled: "request declined",
      priceAppError: "Unfortunately, we do not promote applications that cost more than 15 rubles",
      searchTagsInfo1: "While you are adding requests, we check that your application presents in search results.",
      searchTagsInfo2: " You can boost on the request. Your application is at 120 place on it.",
      searchTagsInfo3: "Promotion is not possible for the request. It will not be included in your order.",
      addTagsDesciptions1: "Here are suggested requests for which you could promote your application. The number next to the request text indicates the position of the application in the search results for it.",
      addTagsDesciptions2: "These requests are not added to your order until you add them yourself. To add a request to an order, just click on it.",
      sdkControllTitle: "Control of events inside the application 🔥",
      sdkControllDescr: "For automatic control of actions inside your application. You create a script consisting of tasks and their fulfillment by a motivated users is automatically recorded. It can be used, for example, for testing.",
      sdkControllDescr2: "If this option is activated, its parameters will be available in the next step. You will need to work with the SDK.",
      nextBtn: "Next",
      publishApp: "Now publish the app with the new code on Google Play and come back here to activate the order.",
      downloadYourApp: "Download your application from this link:",
      downloadYourAppBtn: "Download",
      downloadYourAppDescr: "Complete one of your events. After that, the order is automatically activated and will be available to performers.",
      notFound1: "not found",
      notFound2: "no",
      //sdkInfo
      sdkInfoTitle: "Description of tasks for event control",
      sdkTaskName: "Task name",
      sdkTaskNamePlaceholder: "For example, signup",
      sdkTaskNameDescr: "Give the task a short name and assign a cost (at least 2₽)",
      sdkTaskPrice: "Task coast, ₽",
      sdkTaskDescription: "Task description",
      sdkTaskDescription2: "Describe the task so that the performer could understand it.",
      sdkTaskDescriptionPlaceholder: "For example, to sign up",
      sdkAddTask: "+ Add task",
      sdkDeleteTask: "Delete task",
      sdkChangeOrder: "Change task order",
      sdkDoSDK: "Embed the SDK",
      sdkAbout: "About order",
      sdkControll: "Event control",
      sdkEnableTitle: "Embedding the SDK",
      sdkWaiting: "waiting",
      sdkInstructionDesc: "Supplement the code of your application by following the instructions below.",
      sdkGoogleUpdate: "Order activation",
      sdkGoogleUpdate2: "by the link",
      sdkGoogleUpdateDesc: "Install the app on your phone using our link and perform any event.",
      sdkInstructTitle: " Add the code to build.gradle at the project level (not at the module level)",
      sdkInstructTitle2: "And add the dependency of our SDK at the project level to build.gradle",
      sdkInstructTitle3: "Call the main Activity in the onCreate method (Which is launched first in the application)",
      sdkTaskId: "Order ID",
      sdkTaskIdDesc: "We explain what the user should do with the order ID",
      sdkTaskIdTask: "Task ID",
      sdkEnabledText: "completed",
      sdkCopied: "Data copied",
      sdkUpdateGP: "Activate the order",
      sdkCloseDialogTitle: "The order settings will be lost",
      sdkCloseDialogText: "The order settings will be lost because the order has not been created yet.",
      sdkCloseDialogNext: "Next",
      sdkReorderTitle: "Task order",
      sdkMoneyDialog: "If not all performers pass the full order scenario, after a while we will return the unspent budget of the order to your balance.",
      unityDoc1: "1. Download our library file",
      unityDoc1_2: "and move it to your Unity project in the folder",
      unityDoc2: "2. Download the auxiliary file with C # scripts",
      unityDoc2_2: "copy it to your project in the scripts folder.",
      unityDoc3: "3. Initialize the library when you start your game in the Start method.",
      unityDoc4: "Next, at the time of the task execution, call the event with the required ID.",
      //orders
      request: "Request:",
      options: "Options:",
      withoutReq: "no request",
      return3day: "reopenong",
      reviewWord: "review",
      up: "boosting",
      progressWord: "distribution",
      noOptions: "no option",
      installs_1: "installation",
      installs_2: "installation",
      installs_5: "installation",
      from: "from",
      budget: "Budget:",
      status: "Status",
      working: "in progress",
      pause: "pause",
      blocked: "blocked",
      blockedOrderInfo: "Blocked",
      moderating: "moderation",
      upBalance: "Pay the balance",
      finished: "completed",
      deleteOrder: "The order will be permanently deleted.",
      deleteOrderTitle: "Deleting an order",
      deleteOrderInfo: "Delete order",
      deleteOrderBtn: "Delete",
      cancel: "Cancel",
      allFilters: "All",
      workingFilters: "In progress",
      pauseFilters: "Pause",
      finishedFilters: "Completed",
      blockedFilters: "Blocked",
      allApps: "All applications",
      emptyOrders: "No created orders yet",
      emptyFinishedOrders: "No completed orders",
      emptyBlockedOrders: "No blocked orders",
      emptyPauseOrders: "No orders on pause",
      emptyActiveOrders: "No orders in progress",
      maxInstallInfo: "Maximum installations",
      onDay: "per day",
      onOur: "per hour",
      onMinute: "per minute",
      createdAt: "Created",
      duplicateOrder: "Dublicate order",
      idfaTitle: "Order performers",
      deleteOrderEmployers: "Clear order employers",
      enterEmailCode: "Enter the code sent to you by email",
      enterCode: "Code",
      wrongCode: "Wrong code",
      //referals
      youEarned: "YOU HAVE EARNED",
      referalsCount: "Referrals",
      spentMoneyOnApps: "Money can only be spent on application boosting",
      registerByMyRef: "Registering yourself using your referral link is prohibited. This will lead to blocking your account with funds being blocked.",
      sendRef: "Distribute this link and get money to your account.",
      yourRefLink: "Your referral link",
      copyLink: "Copy link",
      youEarn15: "You earn 15%",
      fromOrders: "from the cost of orders of all users who will register using your referral link.",
      yourRefs: "YOUR REFERRALS",
      dateRef: "Date",
      emailRef: "Email",
      earningRef: "Income, ₽",
      getMoneyTitle: "Withdrawal of earnings",
      getMoneyPhone: "Enter the number of YooMoney wallet",
      getMoneyBtn: "Withdraw money",
      deleteOrderEmplTitle: "Deleting executors",
      deleteOrderEmpl: "You really want to clear the order and the application from the performers. In this case, users who installed the application, but deleted it after a while, will be able to re-execute the order. It is recommended to perform this action no earlier than 28 days after the active phase of order fulfillment",
      //statistics
      pickApp: "Select an application",
      campainType: "Campaign type:",
      filterCampainRequest: "Search request",
      filterCampainReviews: "Reviews",
      period: "Period:",
      withoutSearchReq: "no request",
      statTextTV1: "Statistics for this application",
      statTextTV2: "missing for the selected period",
      //aso
      searchDescAso: "Type the app name or insert a link to Google Play or App Store. Only for Android applications.",
      enterAppData: "Enter the application data,",
      enterAppData2: "which you want to analyze",
      filterByWord: "Filter by word",
      keywordsStats: "Keyword position statistics",
      asoRequest: "Request",
      asoPosition: "Position",
      keywordsNotFound1: "Keywords aren't found.",
      keywordsNotFound2: "Perhaps this application is still",
      keywordsNotFound3: "has not indexed in the search",
      forAndroid1: "The function is currently available",
      forAndroid2: "only for Android apps",
      notFoundKeywords: "Nothing found",
      upPositions: "Boost",
      pickedRequests: "Selected requests:",
      orderInfoCompleted: "Order completed",
      orderInfoInProgress: "Order in progress",
      orderInfoPause: "On pause",
      finishedTaskStatus: "Pay your balance to resume execution",
      continuePlay: "Resume execution",
      pauseOrder: "Set on pause",
      positionBefore: "Position before",
      positionAfter: "after",
      positionNumber: "Position",
      wrapOptions: "Options of boosting",
      open3DayProp: "reopening on 3rd day",
      goodReviewTopProp: "boosting good reviews",
      progressProp: "distribution with progression",
      installsProp: "Installations",
      budgetProp: "Budget",
      upBalanceProp: "Pay balance",
      statsProp: "View statistics",
      upBudget: "How many installations do you want to add?",
      upInstallCount: "Number of installations",
      upSum: "Total",
      upPriceOneInstall: "One installation",
      upAdd: "Add",
      upCancel: "Cancel",
      notEnoughtMoney: "There are not enough funds on your account balance.",
      addMoney: "Pay",
      writeReviewTitle: "Get 300₽ for promotion",
      forThis: "For this",
      writeReview: "write a review",
      topPercents: "TOP",
      writeReviewText: "about your experience in Upmob in our VK group and indicate in the review a link to one of your applications so that we know where to deposit money.",
      //auth
      forgotPassAuth: "Password recovery",
      enterNewPass: "Enter a new password",
      enterEmail: "Enter your email",
      wrongEmail: "Invalid email",
      cancelEmail: "Cancel",
      sendEmail: "Send",
      passwordChanged: "Password is changed succesfully. Log in using a new password.",
      passRecoveryLink: "We send to your email a link to recover your password",
      okChanged: "Ok",
      //pay
      payTitle: "Replenishment of the balance",
      enterPaySum: "Enter the payment amount",
      payBtn: "Pay",
      payPick: "Select a payment method",
      payWillBePayed: "Will be paid at the rate",
      payFor: "for",
      payBank: "Banking",
      payBankCard: "by card",
      //createUniversalOrder
      newUniversalOrder: "New universal order",
      mustHaveOrder: "* Required fields",
      typeNotPicked: "Not selected",
      typePickTitle: "Task type *",
      typePickDesc: "Select the type of task from the drop-down",
      typeVK: "Vkontakte",
      typeFB: "Facebook",
      typeTG: "Telegram",
      typeTikTok: "TikTok",
      typeYoutube: "Youtube",
      typeOtherSocial: "Other",
      typeVote: "Review/Rate",
      typeInstagram: "Instagram",
      typeAndroid: "Android application",
      typeIOs: "iOS application",
      accountReg: "Account registration",
      universalName: "Order name *",
      universalNameDesc: "This name will be displayed for the performers, as well as in the list of your orders.",
      universalUrl: "Link (what are we boosting) *",
      universalUrlDesc: "The link where the performer should go at the beginning of the execution of this order. If you specify several links through the sign ',', then they will be randomly displayed by performers",
      universalDescription: "Describe what the performer should do",
      universalDescriptionDesc: " Describe in detail what the performer should do. He has an hour to complete the task (from the moment he opened the task to sending the report / achieving the target action).",
      universalDescriptionTitle: "Task description *",
      universalTaskProofLimit: "Execution Limits",
      controlTypeReview: "Report",
      revieRejectedComment: "Report is declined",
      controlTypeTime: "Control time",
      controlType: "Type of control *",
      controlTypeDesc: "Select from the drop-down the way the performer will be monitored.",
      controlTypeReviewPlaceholder: "What should the performer provide as a report?",
      controlTypeReviewDesc: "Describe what the performer should provide as a report. This may be a question about the viewed content. The performer's response can be a text or a link.",
      reviewDays1: "1 day",
      reviewDays2: "2 days",
      reviewDays3: "3 days",
      reviewDays7: "1 week",
      reviewDays14: "2 weeks",
      autoReviewTitle: "Automatic acception via *",
      autoReviewDesc: "This is the time after which the order will be set as accepted automatically (if you do not log in to the service for a long time and do not accept the performer's work).",
      secundsTitle: "Time for execution",
      secundsDesc: "This is the minimum time that the performer must spend on completing the task, if he returns to the task screen earlier, he will receive a warning that a sufficient amount of time has not passed.",
      sec1: "sec",
      sec2: "sec",
      sec5: "sec",
      priceOneInstallTitle: "The coast of one execution *",
      countInstallsUniversal: "Number of executions *",
      minPriceUniversal: "Minimum coast",
      minPriceUniversalDesc: "Specify how many performers should fulfill your order",
      universalProof: "report",
      universalTimeProof: "control time",
      completed1: "completed",
      completed2: "completed",
      completed5: "completed",
      inWork: "in progress",
      addUniversalTitle: "How many executions do you want to add?",
      addUniversalOne: "One execution",
      universalInfoTitle: "Order description",
      universalInfoProof: "What should be specified in the completed task report:",
      universalTaskType: "Task type",
      universalTaskUrl: "Link",
      universalTaskProofType: "Type of control over the performer",
      universalTaskProofAuto: "Automatic acception via",
      aboutOrder: "About order",
      aboutOrderReviews: "Reports",
      waitingForReviewTitle: "Reports awaiting your review",
      acceptAllText: "Accept all",
      acceptBtnText: "Accept",
      sendToDo: "Send for revision",
      reject: "Decline",
      sayEmployerWhatFix: "Explain to the performer exactly what needs to be finalized:",
      proofPlaceholder: "Type the comment text",
      proofFilterNew: "New",
      proofFilterOndo: "Under revision",
      proofFilterAccepted: "Accepted",
      proofFilterRejected: "Declined",
      noFilterNew: "No new reports",
      noFilterOndo: "No reports under revision",
      noFilterAccepted: "No accepted reports",
      noFilterRejected: "No declined reports",
      rejectionDesc: "Specify the reason for the refusal:",
      universalInProccess: "In progress",
      acceptedProof: "The report is accepted",
      rejectedProof: "The report was declined with a comment:",
      toDoProof: "The report has been sent for revision with a comment:",
      orderType: "All types of tasks",
      orderStatusAll: "All statuses",
      taskNameVK: "Vkontakte",
      taskNameTG: "Telegram",
      taskNameFB: "Facebook",
      taskNameYB: "Youtube",
      taskNameVOTE: "Rating",
      taskNameTikTok: "TikTok",
      taskNameOther: "Task",
      taskNameInstagram: "Instagram",
      taskNameAndroid: "Android",
      taskNameIOs: "IOs",
      maxWidthError: "The maximum width of the image is 1280px",
      universalOrders: "Universal",
      finishedTasksText: "Completed",
      addRedirect: "Add a redirect",
      addRedirectDesc: "It will hide the true source of the transition and create the appearance that the performer followed the link from the VK.",
      upBalanceUniversal: "To create a universal order, you need to top up your balance by at least 100₽.",
      upBalanceUniversalDialog: "To create a universal order, top up your balance on",
      linkToUniversal: "Read a useful article about universal orders in our blog",
      needScreenTitle: "For the report, the performer needs to upload a screenshot",
      needScreenDescr: "If you enable this option, we will automatically give instructions that the performer needs to upload a screenshot to the exchanger and provide a link to it",
      //reviews
      pickAppReviews: "Select an application,",
      pickAppReviews2: "to see the reviews for it",
      addTagInfoTitle: "Add a tag to a review",
      reviewTextTitle: "Review text",
      reviewTextSelectedKey: "Keyword",
      reviewTextSelectedIgnor: "Exception",
      tagNamePlaceholder: "Enter or select a review tag",
      reviewTextDesc: "Select the desired word/phrase to add it to keywords or exceptions. According to these words, the system will understand that the review refers specifically to this tag.",
      tagNameDesc: "Start typing the tag name or select from the drop-down.",
      tagNameDesc2: "Edit the list of tags",
      createNewCategory: "Create new tag",
      newTagTitle: "New tag",
      enterNewTagName: "Enter the tag name",
      createNewTagBtn: "Create",
      cancelNewTagBtn: "Cancel",
      newTagNameError: "Such a tag already exists",
      updateNewTag: "Add",
      pickTagsCategory: "Select a review tag",
      keywordsTitle: "Keywords",
      ignoreTitle: "Exceptions",
      loadingReviews: "Uploading reviews",
      reviewsTitle: "Reviews",
      noReviewsFound: "No reviews,",
      noReviewsFound2: "suitable for the selected filters",
      readtorTagsTitle: "Edit the list of tags",
      closeTagList: "Close",
      tagListRemoveTitle: "Deleting a tag",
      tagRemoveText: "Tag “",
      tagRemoveText2: "” will be permanently deleted.",
      renameTagTitle: "Rename the tag",
      saveRenamTag: "Save",
      instaBanner: "Try to boost an Instagram account with our partner",
      instaBannerHref: "Instarlike",
      readAboutReviews: "Read a useful article about reviews in our blog",
      keywordsDescription: "In order for the tag to be assigned to this review, be sure to enter a keyword for it.",
      ignoreDescription: "Example of an exception: if the word \"good\" is the key for the \"praise\" tag, then \"not good\" will be an exception and it needs to be added to this field.",
      uploadCSV: "Upload .csv file",
      blockReason: "No blocking reason specified",
      // navbar
      navbarEnabled: "Available",
      navbarPay: "Deposit",
      navbarExit: "Exit",
      
      //
      sdkAppealTitle: "There is an error related to this job",
      sdkAppealDesc: "This message was sent by the ordering employer.",
      sdkShowDocs: "Show Documentation",
      sdkHideDocs: "Hide Documentation",
      univDescriptionCsv: "Describe in detail what the executor needs to do. Or upload a csv file to create a description with variables from the table.",
      univDescriptionCsv2: "We told how to work with csv-files and what possibilities this function gives",
      univDescriptionCsv3: "in this article.",
      csvErrorText: "Error loading and processing your csv file",
      csvErrorDesc: "Please check that your file is correct.",
      csvParams: "Variables from your csv file",
      csvParamsDesc: "Variable values ​​can be embedded in the Link or Description fields. To do this, in the text of your description, specify the required variable as follows:",
      csvParamsDescKey: "!!variable!!",
      isShuffleCsv: "Synchronize array elements",
      isShuffleCsvDesc: "If sync is enabled, then the same line will be selected in each column",

      previewTask: "Order Preview",
      previewTaskBtn: "Preview",
      previewDialogTitle: "Your Preview",
      previewDialogTitle2: "tasks",
      previewDialogTitle3: "This is what your task looks like for performers",
      previewDialogProof: "What to include in the progress report:",
      previewDialogProofTimer: "Task timer - ",
      previewDialogProofTimerSec: "sec",
      previewDialogProofDesc: "If you return early, the quest will not be counted and the reward will not be awarded.",
      previewDialogProofScreen: "Report needs a screenshot",
      previewDialogProofStart: "Start execution",
      previewDialogProofStartTimer: "Remaining to be done",
      previewDialogProofMin: "min.",
      previewDialogProofShowmore: "Show more",
      previewDialogProofDesc: "The reward will be credited after the customer approves your report.",
      previewDialogProofDesc2: "Or automatically passed through",
      previewDialogProofDesc3: "after submitting the report.",

      infoAddRequest: "Add Request",
      addSearchRequestInOrder: "Add search query to order",
      addSearchRequestInOrderPlaceholder: "Enter a request",
      addSearchRequestInOrderPos: "Search Position",
      addSearchRequestInOrderErr: "This request cannot be added.",
      addSearchRequestInOrderErr2: "Clear field",
      addSearchRequestInOrderDesc: "Press Enter to add another request",
      addSearchRequestNotFound: "no",
      addSearchRequestNotFoundDesc: "will not be added",
      addSearchRequestAddAll: "Add All",

      orderInfoInstallStats: "Installation statistics",
      orderInfoInstallStatsEmpty: "No stats",
      badProofAnswer: "😳 Nonsense answer",
      badProofAnswerText: "Your answer was rejected. If you continue to write nonsense in the answer, you will get a ban with funds lock.",
      installsCountStats: "runs",
      univOrderTriesCount: "Order Tries Stats",
      limitsDayUnivDesc: "Select the optimal execution limit per day. If the limit is less than 10 per day, inaccuracies may occur.",
      limitsUnivDesc: "Use limits to distribute execution over a long period of time",

      oneUniversalPrice: "one execution",
      oneUniversalPriceChange: "Price per execution",
      oneUniversalPriceMin: "Minimum price",
      oneUniversalPriceCount: "Number of executions",
      massAnswer: "Mass answer",
      pickProofsToDecline: "Select the reports you want to decline",
      pickProofsSelected: "Selected reports: ",
      socialNetworks: "Social Networks 🔥 ",
      socialNetworksTitle: "Social Network Promotion",
      searchCountry: "Enter or select countries",
      minInstallsCOunt: "Minimum",
      installCountBotDesc: "What is the total number of installs you want? Minimum",
      installCountBotDesc2: "per search query",
      disabledIosTask: "iOS orders are temporarily unavailable",
      canceledOrder: "cancelled",
      waitingOrder: "waiting for launch",
      dayLimit: "daily limit",
      cancelOrder: "cancel order",
      cancelOrderCap: "Cancel order",
      waitingCancel: "waiting for cancellation",
      canceledFilter: "Canceled",
      pickedCountriesInfo: "Selected countries",
      payCommission: "Commission for payment by foreign card — 12%",
      payInoCard: "By foreign card",
      payRusCard: "By bank card, Russia",
      progressDescriptionError: ". When distributing with progression, the minimum number of installations is 500 for each search query.",
      limitsDayBotDesc: "Select the optimal limit on app downloads per day. Minimum",
      notEnoughtMoneyBots: "Not enough money for the order. Top up your account with ",
      maxInstallsDay: "limit", 
      menuRussia: "Russia",
      menuWorld: "world",
      myOrdersWorld: "Orders for stores",
      progressOrderDesc7days: "A clever algorithm, very effective for popular queries in Google Play. It increases efficiency by simulating the growth of installations. In 7 days it will be completely in total",
      blockedApp: "The application is not available in the store",
      returnRemainingFunds: "Return remaining funds",

      androindInstallsPrice: "Android installs",
      iosInstallsPrice: "iOS installs",
      fromM: "from",
      orderedCount: "ordered",
      settings: "Settings",
      settingsEmailing: "Receive email newsletters about discounts on services, order statuses",
      settingsEmailingAuth: ", and also agree to receive email newsletters about discounts on services and order statuses",
      filterSumMore200: "Amount more than 200 rubles.",
      filterYoomoney: "Yoomoney",
      filterWebMoney: "Webmoney",
      filterAndroid: "Android",
      filterIOS: "iOS",
      filterNoBundle: "no bundle",
      filterCheap: "By small balance",
      filterExpensive: "By large balance",
      filterPriceTask: "By cost of 1 execution",
      notValidUrl: "Invalid URLs",
      positionZeroError: "Request with position 0 must be checked manually through the mobile version of Google Play",
      //social
      pickSocial: "Select a social network",
      socialHistory: "Order history",
      socialOtherTasks: "For your author's tasks and promotion of other social networks, use",
      socialOtherTasks2: "universal orders",
      socialNoOrders: "No orders",
      socialInstaExtreme: "*Recognized as an extremist organization and banned in the Russian Federation.",
      socialCreateOrder: "Create an order",
      socialFuturePosts: "How many future posts?",
      socialEnterSearch: "Enter a search query",
      socialChangeOn: "Replace with",
      socialLinkTo: "Link to",
      socialWhatToOrder: "What do you want to boost?",
      socialEnterLinkAndWord: "Enter a link and search query",
      socialNoVideoBut: "No videos found for this query, but there are some for this query",
      socialNoVideoByLink: "No videos found for this query",
      socialMinimumTime: "Minimum viewing time (in seconds)",
      socialAnswerCount: "Number of the required answer in the survey",
      socialCount: "Quantity",
      socialPrice: "(price)",
      socialFor1: "per 1 item)",
      //site
      menuSite: "Website promotion", 
      siteTitle: "Website promotion in search engines",
      siteUrlDesc: "Enter the website domain. For example, https://blog.upmob.ru",
      siteUrl: "Website domain",
      rustoreDisabled: "Rustore orders are not available, create them in the Russia tab",
      siteTypeDesc: "Select which search engine to promote the website in",
      siteCount: "Number of transitions",
      siteCountDesc: "Specify the desired number of transitions for all queries. At least 10. If the order contains several queries, the transitions are distributed evenly between them",
      siteCount1: "transition",
      siteCount2: "transitions",
      siteCount5: "transitions",
      siteTimeDesc: "Specify the amount of time the performer must spend on the website, in seconds",
      siteTimeOn: "Time on website",
      siteRepeats: "Allow repeated executions",
      siteRepeatsDesc: "Allows one performer to go to the website for different order queries. We recommend enabling this option if need the order to be completed faster",
      sitePriceDesc: "The price is affected by the number of clicks and the time that performers must spend on the site",
      siteDayLimitsDesc: "Use limits to distribute clicks over a long period of time",
      chooseLangDialog: "Choose a language",
      siteOrderCreated: "Order successfully created",
      siteUrlError: "Check that the URL is correct, it should open with http:// or https://",
      siteOrderPromotion: "Website promotion",
      siteAllowDouble: "Repeated openings allowed",
      siteTimeOnInfo: "Time on site",
      siteLimits: "Transition limits",
      siteCompletes: "Completions",
      orderBlockReason: "Block reason: ",
      orderBlockReasonNot: "not specified",
      siteStatisticCount: "Completion statistics",

      // Universal order validation messages
      minimalOrderSum: "Minimum order amount",
      minimalPricePerTask: "Minimum price per task",
      enterTaskName: "Enter task name",
      chooseControlType: "Choose control type",
      enterUrl: "Enter URL",
      enterDescription: "Enter task description",
      chooseTaskType: "Choose task type",
      allCities: "All cities",
      enterCity: "Enter city",
      chooseCityDesc: "Choose from the dropdown list the city or cities of the performer if this is important to you.",
      cityTitle: "Selected cities",
      siteSearchPrice: "Each request costs 0.3₽",
      siteOrderPrice: "Prices for execution",
      needMinTimeReview: "Control of the minimum execution time",
      needMinTimeReviewDesc: "Will allow you to control that the performer has devoted the required time to your task.",
      goodTrafficSite: "Quality traffic from search for the site",
      upBudgetSeo: "How many transitions do you want to add?",
      upPriceOneInstallSeo: "One transition",
      commentsMain: "own",
      socialCommentsPlaceholder: "Comments (each comment on a separate line)",
      socialCommentsTitle: "Text of comments",
      socialCommentsError: "comments",
      limitsForEachRequest: "Download limits for each request", 
      maxDayForEachRequest: "Maximum per day for each request",
      changeDayLimit: "Change daily execution limit",
    }
  });
  strings.setLanguage(getCookie("picked_lang") ? getCookie("picked_lang") : navigator.language.includes('ru') ? 'ru' : 'en')
  return strings;
};

