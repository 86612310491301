import React from 'react';
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
var strings = getLocalizedStrings()
class CsvItem extends React.Component {
    
    constructor(props){
        super(props)
       
    }
  
    render() {
        return (
            <div className="csv_item_container csv_item">
                {this.props.item}
            </div>
            

        );
    }
}
export default CsvItem;