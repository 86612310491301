/* eslint-disable eqeqeq */
import React from 'react';
import moment from 'moment'

const AdminEmployerItem = ({ item }) => {
    const isBlocked = false
    return (
        <div style={isBlocked ? {border: '1px solid rgba(255, 98, 98, 0.7)'} : {}} className='admin_app_item'>
            <div>Имя : <span className='admin_item_bold'>{item.nick_name}</span></div>
            <div>_id : <span className='admin_item_bold'>{item._id}</span></div>
            <div>Баланс : <span className='admin_item_bold'>{parseFloat(item.count_money_r).toFixed(2)}₽</span></div>
            <div>Бандл : <span className='admin_item_bold'>{item.bundle}</span></div>
            <div>Страна : <span className='admin_item_bold'>{item.country}</span></div>
            <div>email : <span className='admin_item_bold'>{item.email}</span></div>
           
            <div>Тип ОС : <span className='admin_item_bold'>{item.type_os}</span></div>
            <div>Регистрация : <span className='admin_item_bold'>{moment(item.register_date).utc().local().format("DD.MM.YYYY HH:mm")}</span></div>
            <div>Последнее изменение : <span className='admin_item_bold'>{moment(item.modified).utc().local().format("DD.MM.YYYY HH:mm")}</span></div>
            <div>IP адрес : <span className='admin_item_bold'>{item.ip}</span></div>
            <a rel="noopener noreferrer" target='_blank' href={'/employer_info?purse=' + item.wallet_new}>{item.wallet_new}</a>
            <div><a rel="noopener noreferrer" target='_blank' href={'https://app-coins.ru/?google_user_id=' + item.google_user_id + '&device_id=' + item.device_id + '&api_key=upmob'}><p className='admin_url' >{item.google_user_id}</p></a></div>
        </div>
    )        
    
}
export default AdminEmployerItem

