import React from 'react';
import { Line } from 'react-chartjs-2';

var options = {
   
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true
        }
        }
      ]
    },
    legend: {
      display: false,
      position: 'bottom',
      
    }
  };
  
class StatsChart extends React.Component {
    constructor(props){
        super(props)
       
         
        
    }
   
    render() {
        return (
            <Line options={options} data={{datasets: this.props.installsData , labels : this.props.labels}} />
        );
    }
}
export default StatsChart;