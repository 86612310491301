import React from 'react';
import android_icon from '../custom_icons/android.svg'
import ios_icon from '../custom_icons/apple.svg'
import left_blue from '../custom_icons/left_blue.svg'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import SdkInfoItem from '../../list_items/SdkInfoItem';
import { declension } from '../analyze_review/utils/Utils';
import CloseSdkTaskDialog from '../dialogs/CloseSdkTaskDialog';
import {CSSTransitionGroup} from 'react-transition-group';
import SdkReorderDialog from '../dialogs/SdkReorderDialog';

var strings = getLocalizedStrings()
var emptyTask = {name : "", price : 2, description : "", id : Math.random().toString(36).substr(2, 9)}

class SdkCreateOrder extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            tasks : [Object.assign({}, emptyTask)],
            isShowCloseDialog : false,
            isShowReorderDialog : false,
        }
        this.addTask = this.addTask.bind(this)
        this.onTaskPriceChanged = this.onTaskPriceChanged.bind(this)
        this.deleteTask = this.deleteTask.bind(this)
        this.sdkTasksChanged = this.sdkTasksChanged.bind(this)
        this.toggleCloseDialog = this.toggleCloseDialog.bind(this)
        this.toggleReorderDialog = this.toggleReorderDialog.bind(this)
        this.reorderTasks = this.reorderTasks.bind(this)
    }
    addTask(){
        var tasks = this.state.tasks
        var emptyTask = {name : "", price : 2, description : "", id : Math.random().toString(36).substr(2, 9)}
        tasks.push(Object.assign({}, emptyTask))
        this.setState({tasks : tasks})
        this.props.sdkPriceChanged(tasks)
    }
    onTaskPriceChanged(){
        this.props.sdkPriceChanged(this.state.tasks)
    }
    deleteTask(index){
        var tasks = this.state.tasks
        tasks.splice(index, 1)
        this.setState({tasks : tasks})
        this.props.sdkPriceChanged(tasks)
    }
    sdkTasksChanged(){
        this.props.sdkTasksChanged(this.state.tasks)
    }
    reorderTasks(tasks){
        this.setState({tasks : tasks})
        this.props.sdkTasksChanged(tasks)
    }
    toggleCloseDialog(){
        this.setState({isShowCloseDialog : !this.state.isShowCloseDialog})
    }
    toggleReorderDialog(){
        this.setState({isShowReorderDialog : !this.state.isShowReorderDialog})
    }
    render(){
        return (
            <div className="">
                <div className="flex">
                    <img className="img_choose_app img_input_icon show" src={this.props.orderState.icon}/>
                    <img src={this.props.orderState.type_os == "android" ? android_icon : ios_icon} className="img_device_icon_input show"/>
                    <p className="input_text_search sdk_auto_width">{this.props.orderState.name + " (" + this.props.orderState.bundle + ")"}</p>
                    <p className="input_text_search sdk_create_installs sdk_auto_width">{this.props.orderState.installCount + " " + declension(this.props.orderState.installCount, strings.installs_1, strings.installs_2, strings.installs_5)}</p>
                </div>
                <div className="line-row sdk_line"/>
                <div className="sdk_order_cont sdk_reorder_cont">
                    <div className="flex">
                        <img onClick={this.toggleCloseDialog} className="hover" src={left_blue}/>
                        <p className="info_title sdk_description_title">{strings.sdkInfoTitle}</p>
                    </div>
                    {this.state.tasks.length > 1 && <p onClick={this.toggleReorderDialog} className="sdk_change_orders hover">{strings.sdkChangeOrder}</p>}
                </div>
                <div className="line-row"/>
                {this.state.tasks.length > 0 && this.state.tasks.map((item, index) => (
                    <SdkInfoItem length={this.state.tasks.length} sdkTasksChanged={this.sdkTasksChanged} deleteTask={this.deleteTask} onTaskPriceChanged={this.onTaskPriceChanged} index={index} task={item} key={index}/>
                ))}
                <div onClick={this.addTask} className="sdk_add_task hover">{strings.sdkAddTask}</div>
                <CSSTransitionGroup transitionName="dialog_animation" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                    {this.state.isShowCloseDialog && <CloseSdkTaskDialog closeNextSdkStep={this.props.closeNextSdkStep} toggleCloseDialog={this.toggleCloseDialog}/>}
                </CSSTransitionGroup>
                <CSSTransitionGroup transitionName="dialog_animation" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                    {this.state.isShowReorderDialog && <SdkReorderDialog reorderTasks={this.reorderTasks} sdkTasks={this.state.tasks} toggleCloseDialog={this.toggleReorderDialog}/>}
                </CSSTransitionGroup>
            </div>
        );         
    }
}
export default SdkCreateOrder;
