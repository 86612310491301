/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';


var strings = getLocalizedStrings()

function SiteTime(props) {
    const count = props.count
    const setCount = props.setCount
    const countChanged = (event) => {
        if (event) {
            var value = event.target.value
            setCount(value)
        }
    }
    const minCount = 10
    return (
        <div className="display_block">
            <div className="line-row" />
            <div className="order_row">
                <div style={{display : 'block'}} className="input_container_border display_block">{strings.siteTimeOn}
                    <input autoComplete="off" onChange={countChanged} className="input_text_search top_8" placeholder={strings.searchRequestExample} value={count} />
                    <p className="universal_desc secs_text">{strings.previewDialogProofTimerSec}</p>
                </div>
                <div className="input_description_container">
                    <p className="input_description_text">{strings.siteTimeDesc}</p>
                </div>
            </div>

            {/* {count < minCount && <p className="installs_error">{strings.minInstallsCOunt} {minCount} {declension(minCount, strings.siteCount1, strings.siteCount2, strings.siteCount5)}</p>} */}
        </div>
    )
}
export default SiteTime;