import React from 'react';
import '../custom_views/custom_style/loader.css';
class TagLoader extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
            return (
                <div style={this.props.style} className="lds-ring loader_input">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                </div>
            );         
    }
}
export default TagLoader;
