import React from 'react';
import LoaderCircle from './LoaderCircle';

class Loader extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
      
            return (
                <div ng-style="progressStyle" className="account-content-loader loader-insta progress_bgstyle">
                    <div className="account-content-loader loader-insta progress_bgstyle">
                        <LoaderCircle />
                    </div>
                </div>
            ); 
        
        
    }
}
export default Loader;
