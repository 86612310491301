import React from 'react';
import './dialogs.css'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import reorder from '../custom_icons/reorder.svg'
var strings = getLocalizedStrings()
const reorderFunc = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};
class SdkReorderDialog extends React.Component {
    constructor(props){
        super(props)
        this.state={
            containerHeight : 0,
            tasks : this.props.sdkTasks
        }
        this.onDragEnd = this.onDragEnd.bind(this);
        this.cancel = this.cancel.bind(this);

    }
    toggleCloseDialog = () => {
        this.props.reorderTasks(this.state.tasks)
        this.props.toggleCloseDialog()
    }
    onDragEnd(result) {
        // dropped outside the list
        console.log("onDragEnd")
        if (!result.destination) {
            return;
          }
      
          const items = reorderFunc(
            this.state.tasks,
            result.source.index,
            result.destination.index
          );
      
          this.setState({tasks : items})
       
    }
   
    cancel(){
        this.props.toggleCloseDialog()
    }
    render(){
        return (
            <div className="error_bg">
                <div className="sdk_reorder_container sdk_reorder_dialog_container">
                    <p className="sdk_reorder_title">{strings.sdkReorderTitle}</p> 
                    <div className="sdk_reorder_line sdk_reorder_line_title"/>
                    <div className="sdk_inner_list">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {this.state.tasks.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div className="reorder_sdk_container"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        >
                                            <div className="flex">
                                                <div className="sdk_number_container">
                                                    <p className="sdk_center sdk_number_text">{index + 1}</p>
                                                </div>
                                                <p className="sdk_reorder_text">{item.name}</p>
                                            </div>
                                            <img className="sdk_reorder_img" src={reorder}/>
                                        
                                        </div>
                                    )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <div className="sdk_reorder_bottom">
                        <div className="sdk_reorder_line sdk_full_w"/>
                        <div className="sdk_reorder_buttons">
                            <div className="sdk_money_btn">
                                <p onClick={this.toggleCloseDialog} className="btn_red sdk_money_btn hover">{strings.saveRenamTag}</p>  
                            </div>
                            <p onClick={this.cancel} className="sdk_cancel_btn hover">{strings.cancel}</p>                      
                        </div>
                    </div>
                    
                    
                </div>  
            </div>
        ); 
    }
}
export default SdkReorderDialog;