/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable eqeqeq */
import React from 'react';
import menu_plus from '../custom_views/custom_icons/menu_plus.svg'
import { getLocalizedStrings } from '../custom_views/utils_android/LocalizedStrings';
import { Link } from "react-router-dom";
import { Pages } from '../custom_views/analyze_review/utils/Utils';

var strings = getLocalizedStrings()
function MenuItem(props) {
   
    const getImgHref = () => {
        var loc = ""
        if (props.isWorldTask){
            return Pages.createInstallsOrder
        }
        if (props.name == strings.menuSite){
            loc = Pages.createOrderSite
        } else if (props.name == strings.universalOrders){
            loc = Pages.createUniversal
        } else if (props.name == strings.myOrders){
            loc = Pages.newOrder
        } else if (props.name == strings.socialNetworks){
            loc = Pages.social
        } 
        return loc
    }
    const onRedirect = () => {
        const menu = document.getElementById("closemenu")
        if (menu != undefined){
            document.getElementById("closemenu").click()
        }
        if (props.openView){
            props.openView(props.location)
        }
    }
    const isWorldTask = props.isWorldTask
    const isUniversalTask = props.name == strings.universalOrders

        return (
            <div className='relative'>
                <Link onClick={onRedirect} to={props.location}>
                    <div className="menu_relative">
                        {/* <Link to={props.location}> */}
                            <div className={window.location.pathname == props.location ? "menu_item_new menu_active_item" : "menu_item_new"}>
                                <img alt='' style={isUniversalTask ? {top : '28px'} : {}} className={window.location.pathname == props.location ? "icon_new" : "icon_new icon_new_alpha"} src={props.activeIcon}/>
                                {/* {props.flag && <img className={window.location.pathname == props.location ? "flag_icon" : "flag_icon icon_new_alpha"} alt='' src={props.flag}/>} */}
                                <div>
                                    <div style={props.desc ? {paddingTop : '11px', paddingBottom : '11px'} : {paddingLeft : props.disablePadding ? '24px' : '60px'}} className={window.location.pathname == props.location ? "selected_menu" : "left-menu-text-li"}>
                                        <p style={window.location.pathname == props.location ? {color : 'white'} : {}} className="margin_b_0">{isUniversalTask ? <div>{strings.universalOrders}<br/>{strings.promotion}</div> : props.name}</p>
                                        <div className='flex'>
                                            {props.desc && <p style={window.location.pathname == props.location ? {color : 'white'} : {}} className="margin_b_0 men_desc_text">{props.desc}</p>}
                                            {isWorldTask && <div className='up_to_max_installs_menu' >до 5к установок/день</div>}
                                        </div>

                                    </div>
           
                                </div>
                                

                                {props.badgeCount == 0 && props.location == Pages.universalOrders && <p  className="fire_icon">🔥</p>}
                                {props.location == Pages.reviews && <p className="fire_icon">🔥</p>}
                            </div>
                        {/* </Link> */}
                        
                    </div>
                </Link>
                <div className="menu_plus_item">
                    {props.badgeCount > 0 && props.location == Pages.universalOrders && <div style={props.badgeCount > 99 ? {width : '32px', marginTop : '20px'} : {marginTop : '20px'}} className="buble_proofs m_right_12">
                        <p className="top_3">{props.badgeCount > 99 ? "99+" : props.badgeCount}</p>
                    </div>}
                    {(props.name == strings.menuSite || props.name == strings.universalOrders || props.name == strings.myOrders || props.name == strings.socialNetworks) && <Link onClick={onRedirect} to={getImgHref()}><img alt='' style={isUniversalTask ? {marginTop : '20px'} : {}} className="menu_plus_bg hover" src={menu_plus}/></Link>}
                </div>
            </div>
        );
    
}
export default MenuItem;

// class MenuItem extends React.Component {
    
//     constructor(props){
//         super(props)
       
//     }
//     openWindow = () => {
//         window.location.href = this.props.location
//     }
//     createOrder = () => {
//         // if  (this.props.name == strings.universalOrders){
//         //     window.location.href = "/create_universal"
//         // } else if (this.props.name == strings.myOrders){
//         //     window.location.href = "/neworder"
//         // }
//         window.location.href = this.getImgHref()
        
//     }
//     getImgHref = () => {
//         var loc = ""
//         if  (this.props.name == strings.universalOrders){
//             loc = "/create_universal"
//         } else if (this.props.name == strings.myOrders){
//             loc = "/neworder"
//         }
//         return loc
//     }
    
   
//     render() {
//         return (
//             <div className="menu_relative">
//                 <Link to={this.props.location}>
//                     <div className={window.location.pathname == this.props.location ? "menu_item_new menu_active_item" : "menu_item_new"}>
//                         <img className={window.location.pathname == this.props.location ? "icon_new" : "icon_new icon_new_alpha"} src={this.props.activeIcon}/>
//                         <div style={this.props.activeIcon == undefined ? {paddingLeft : '24px'} : {}}  className={window.location.pathname == this.props.location ? "selected_menu just_content_auto" : "left-menu-text-li just_content_auto" }>
//                             <a style={window.location.pathname == this.props.location ? {color : 'white'} : {}} href={this.props.location} className="margin_b_0">{this.props.name}</a>
//                         </div>
//                         {this.props.badgeCount == 0 && this.props.location == "/universal_orders" && <p className="fire_icon">🔥</p>}
//                         {this.props.location == "/reviews" && <p className="fire_icon">🔥</p>}
//                     </div>
//                 </Link>
//                 <div className="menu_plus_item">
//                         {this.props.badgeCount > 0 && this.props.location == "/universal_orders" && <div style={this.props.badgeCount > 99 ? {width : '32px'} : {}} className="buble_proofs m_right_12">
//                             <p className="top_3">{this.props.badgeCount > 99 ? "99+" : this.props.badgeCount}</p>
//                         </div>}
//                         {(this.props.name == strings.universalOrders || this.props.name == strings.myOrders) && <a href={this.getImgHref()}><img className="menu_plus_bg hover" src={menu_plus}/></a>}
//                 </div>
//             </div>
            

//         );
//     }
// }
// export default MenuItem;