/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import '../../../css_android/bot_orders.css'
import { getLocalizedStrings } from '../../custom_views/utils_android/LocalizedStrings';
import { declension, isShowLeftTooltip } from '../../custom_views/utils_android/Utils';
import ProgressLine from '../../custom_views/ProgressLine';
import OrderMoreTooltip from '../../custom_views/tooltips/OrderMoreTooltip';
import { getPauseBorderStyle } from '../../custom_views/utils_android/StyleHelper';
import info from '../../custom_views/custom_icons/info.svg';
import more from '../../custom_views/custom_icons/more.svg';
import more_pressed from '../../custom_views/custom_icons/more_pressed.svg';
import search_icon from '../../custom_views/custom_icons/search_icon.svg';
import google_order from '../../custom_views/custom_icons/google_order.svg';
import yandex_order from '../../custom_views/custom_icons/yandex_order.svg';
import { BotApi } from '../../../api/BotApi';
import moment from 'moment'
import play from '../../custom_views/custom_icons/play.svg';
import finished from '../../custom_views/custom_icons/finished.svg';
import pause from '../../custom_views/custom_icons/pause.svg';
import progress_icon from '../../custom_views/custom_icons/progress.svg';
import { SiteType } from '../utils/SiteUtils';
import AddMoneyTooltip from '../../custom_views/AddMoneyTooltip';
import add from '../../custom_views/custom_icons/add.svg';
import block from '../../custom_views/custom_icons/block.svg';
import block_help from '../../custom_views/custom_icons/block_help.svg';
import site_repeat from '../../custom_views/custom_icons/site_repeat.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import DeleteOrderDialog from '../../custom_views/dialogs/DeleteOrderDialog';
import { getRequestParams } from '../../custom_views/utils_android/CookieHelper';
import { SiteSeoApi } from '../../../api/SiteSeoApi';

var strings = getLocalizedStrings()
var borderPauseStyle = getPauseBorderStyle()

function SiteOrderItem(props) {
    const [isShowDeleteDialog, setShowDeleteDialog] = useState(false)
    const [isShowMore, setShowMore] = useState(false)
    const [isShowUpBalance, setShowUpBalance] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [toastText, setToastText] = useState("")
    const order = props.order
    
   
    const cancelOrder = () => {
        onDelete()
    }
    const getOrderDetails = () => {
        setLoading(true)
        BotApi.getOrderDetails(order.external_id, (responseData) => {
            const mOrder = responseData.order
            // mOrder.count_complete_installs = 50
            // mOrder.state = BotOrderStates.completed
            props.updateOrders(mOrder)
            setLoading(false)
        }, () => {
            setLoading(false)
        })
    }
    const setStateOrder = () => {
        var params = getRequestParams()
        const mOrder = {...order}
        const newState = (mOrder.state == 1 ? 0 : 1)
        params._id = mOrder._id
        params.state = newState
        console.log("setStateOrder params = ", params)
        SiteSeoApi.setStateOrder(params, (responseData) => {
            console.log("setStateOrder = ", responseData)
            if (responseData.response == 1) {
                mOrder.state = newState
                props.orderChanged(mOrder, props.index)
            }
        }, () => { })

    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function () {
            setToastText("")
        }, 5000)
    }
    const getOrderStatus = () => {
        if (isOrderCompleted) {
            return strings.finished;
        }
        
        if (order.moderated === 0) {
            return strings.moderating;
        }
        
        if (order.moderated === -1) {
            return strings.blocked;
        }
        
        if (props.count_money_r < 0 || order.state === 0) {
            return strings.pause;
        }
        
        return strings.working;
    }
    const showOrderInfo = () => {
        props.showOrderInfo(props.order, props.index)

    }
    const onDelete = () => {
        var params = getRequestParams()
        params._id = props.order._id
       
        SiteSeoApi.removeOrder(params, (responseData) => {
            setShowDeleteDialog(false)
            props.deleteOrder(props.index)
            props.getMyInfo()
        }, () => { })
    }
    const orderUrl = order.site_name
    console.log("order item = ", order)
    const isOrderCompleted = (order.count_money_r < order.one_perform_price_customer)
    const isBlocked = (order.moderated == -1 && !isOrderCompleted)
    return (
        <div style={false ? borderPauseStyle : {}} className="order_container">
                <div className="padding_12">
                    <div className="top_order">
                        <Link className="order_icon hover" onClick={showOrderInfo} to={"/site_seo_orders/order?id=" + props.order._id}><img alt='' className="order_icon hover" src={info} /></Link>

                        <div className="order_icon_container">
                            <img alt='App icon' className="order_app_icon" src={order.icon}/>
                            <img alt='Search platform icon' className="order_type_icon" src={order.search_platform == SiteType.yandex ? yandex_order : google_order}/>
                        </div>
                        <div className="relative">
                            <img alt='More options' onClick={() => setShowMore(!isShowMore)} className="order_icon hover" src={isShowMore ? more_pressed : more}/>
                            {isShowMore && <OrderMoreTooltip fromSite showIdfa={() => {}} order={order} toogleMore={() => setShowMore(!isShowMore)} deleteOrder={cancelOrder}/>}

                        </div>
                        
                    </div>
                    <a className="order_name hover" rel="noopener noreferrer" target='_blank' href={orderUrl}><p className="order_name hover">{order.site_name}</p></a>
                    <p className="date_text_order">{moment(order.date_run).utc().local().format("DD.MM.YYYY")}</p>
                    <div className="center_req hover">
                        <img alt='Search' className="search_icon" src={search_icon}></img>
                        <p className="order_search_text">{order.search_request == undefined ? strings.withoutReq : JSON.parse(order.search_request).join(", ")}</p>
                    </div>

                    <div className="center_icons">
                        
                        {order.limit_day > 0 && <img alt='' style={{display : 'flex'}} className="tasks_icon" src={progress_icon}></img>}
                        {order.allow_double_search > 0 && <img alt='' style={{display : 'flex'}} className="tasks_icon" src={site_repeat}></img>}
                        <div className='site_seo_time'>{order.seconds_on_site}’</div>
                        {/* {
                        order.day_limit ? 
                            <p className="order_search_text top_12">{strings.maxInstallsDay} {order.day_limit} {strings.onDay}</p>
                        :
                            <p className="order_search_text top_12">{strings.noOptions}</p> 
                        } */}

                    </div>
                </div>
                
                <div className="control_container">
                    {isBlocked ?
                        <div onClick={() => showToast(order.reason_block ? order.reason_block : strings.blockReason)} className="controlls hover">
                            <p className="status_text_control">{strings.status}</p>
                            <div style={{ justifyContent: 'center' }} className='flex'>
                                <p className="status_text">{strings.blocked}</p>
                                <img alt='Block help' className='blocked_help' src={block_help} />
                            </div>
                            <img alt='Block' onClick={setStateOrder} className="controll_icon" src={block} />
                        </div>
                        :
                        <div className="controlls">
                            <p className="status_text_control">{strings.status}</p>
                            <p className="status_text">{getOrderStatus()}</p>
                            <img alt='Control action' style={isOrderCompleted ? { pointerEvents: 'none' } : {}} onClick={setStateOrder} className="controll_icon hover" src={isOrderCompleted ? finished : (order.count_money_r < order.one_perform_price_customer ? play : (order.state == 0 ? play : pause))} />

                        </div>
                    }

                    <div className="line_control"></div>
                    <div className="controlls">
                        <p className="status_text_control">{strings.budget}</p>
                        <p className="status_text">{order.count_money_r}₽</p>
                        <img alt='add' onClick={() => setShowUpBalance(!isShowUpBalance)} className="controll_icon hover" src={add} />
                        {isShowUpBalance && <AddMoneyTooltip isSeo isLeft={isShowLeftTooltip(props.index)} style={window.innerWidth >= 556 ? (isShowLeftTooltip(props.index) ? { top: "-190px", left: '-246px' } : { top: "-190px", left: '96px' }) : { top: "-190px", left: '-186px' }} count_money_r={props.count_money_r} index={props.index} order_id={order._id} addCoinsToOrder={props.addCoinsToOrder} closeUpBalance={() => setShowUpBalance(false)} price_one_install={order.one_perform_price_customer} />}
                    </div>
                </div>

                
                <ProgressLine isOrderCompleted={isOrderCompleted} order={order} count_installed={order.count_installs} max_count_installs={order.max_count_searches}/>
                <div className="install_container">
                        <div className="flex">
                             <p className="installs">{order.count_installs ? order.count_installs : 0}</p>
                             <p className="installs_desc">{declension(order.count_installs, strings.siteCount1, strings.siteCount2, strings.siteCount5)}</p>
                        </div>
                        <p className="installs_desc">{strings.from + " " + props.order.max_count_searches}</p>
                        {/* <p className="installs_desc">{strings.orderedCount + " " + order.count_installs + " " + declension(order.count_installs, strings.installs_1, strings.installs_2, strings.installs_5)}</p> */}

                </div>
              {isShowDeleteDialog && <DeleteOrderDialog onCanceledDelete={() => setShowDeleteDialog(false)} onDelete={onDelete}/>} 
              
        </div>
            
    )
}

export default SiteOrderItem
