import React from 'react';
import {DebounceInput} from 'react-debounce-input';
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';

var strings = getLocalizedStrings()

class UniversalPrice extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            priceOneInstall : this.props.minimumPrice,
            countInstalls : 100,
        } 
        this.priceOneInstallChanged = this.priceOneInstallChanged.bind(this)
        this.countInstallsChanged = this.countInstallsChanged.bind(this)
    }
    priceOneInstallChanged(event){
        if (event){
            var value = event.target.value
            if(value != undefined && value != ""){
                value = parseFloat(value)
            }
            this.setState({priceOneInstall : value})
            this.props.priceOneInstallChanged(value)
        }
    }
    countInstallsChanged(event){
        if (event){
            var value = event.target.value
            this.setState({countInstalls : value})
            this.props.countInstallsChanged(value)
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps != this.props && prevProps.minimumPrice != this.props.minimumPrice){
            this.setState({priceOneInstall : this.props.minimumPrice})
        }
    }
   
    render() {
        return (
            <div className="display_block">
                    <div className="order_row">
                        <div style={this.state.priceOneInstall < this.props.minimumPrice ? {display : 'block',border: "1px solid rgb(255, 98, 98)"} : {display : 'block'}} className="universal_input price_one_install_univer display_block input_margin_bot">{strings.priceOneInstallTitle}
                            <DebounceInput autoComplete="off" debounceTimeout={500}
                                    onChange={this.priceOneInstallChanged} className="input_text_search top_8" placeholder={strings.searchRequestExample} value={this.state.priceOneInstall}/>
                            <p className="universal_desc secs_text">{this.state.secCountDeclencion}</p>
                        </div>
                        <div style={this.props.isClickedCreate && this.state.countInstalls == "" ? {border : "1px solid #CB413E", display : 'block'} : {display : 'block'}} className="universal_input price_one_install_univer display_block">{strings.countInstallsUniversal}
                            <DebounceInput  autoComplete="off" debounceTimeout={500}
                                    onChange={this.countInstallsChanged} className="input_text_search top_8" placeholder={strings.searchRequestExample} value={this.state.countInstalls}/>
                            <p className="universal_desc secs_text">{this.state.secCountDeclencion}</p>
                        </div>
                        <div className="input_description_container">
                            <p className="input_description_text">{strings.minPriceUniversal} <b>{this.props.minimumPrice}₽</b><br/>{strings.minPriceUniversalDesc}</p>
                        </div>
                    </div>
                    {(this.state.priceOneInstall * this.state.countInstalls) < 50 && <p className="min_price_univ">Минимальная сумма заказа - 50 рублей</p>}
            </div>
           
        );
    }
}
export default UniversalPrice;