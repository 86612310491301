/* eslint-disable eqeqeq */
import React from 'react';
import x_close from '../custom_icons/mdi_close.svg'
import qs from 'querystring'
import './dialogs.css'
import { getLocalizedStrings } from '../utils_android/LocalizedStrings';
import { getRequestUrl } from '../utils_android/CookieHelper';
import InputLoader from '../utils_android/InputLoader';
var strings = getLocalizedStrings()
class ForgotPassDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: this.props.email ? this.props.email : "",
            isShowError: false,
            isLoading: false
        }
    }

    sendForgot = () => {
        this.setState({ isShowError: false, isLoading: true })
        var params = {
            'email': this.state.email
        }
        fetch(getRequestUrl() + "forgotPassword", {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: qs.stringify(params)
        }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == -1) {
                    this.setState({ isShowError: true, isLoading: false })
                } else if (responseData.response == 1) {
                    this.props.emailSent()
                }

            })
    }
    onMailChanged = (event) => {
        if (event) {
            this.setState({ email: event.target.value })
        }
    }
    cancel = () => {
        this.props.closePassDialog()
    }
    render() {

        return (
            <div className="error_bg">
                <div className="error_banner_container">
                    <p className="title-forgot-pass">{strings.forgotPassAuth}</p>
                    <div>
                        <input style={{ width: '-webkit-fill-available' }} onChange={this.onMailChanged} type="email" name="email" className="forgot_pass_input" placeholder={strings.enterEmail} value={this.state.email} />
                    </div>
                    <p className={this.state.isShowError ? "title-forgot-pass-error" : "not_display"}>{strings.wrongEmail}</p>
                    <div className="flex">
                        <button onClick={this.cancel} id="cancel-forgot-btn" className="form-control btn btn-login " value="Отмена">{strings.cancelEmail}</button>
                        <button onClick={this.sendForgot} id="login-submit-reg-btn-2" className="form-control btn btn-login forgot_pass_send">{strings.sendEmail}</button>

                    </div>
                    {this.state.isLoading && <InputLoader />}
                </div>
            </div>
        );


    }
}
export default ForgotPassDialog;