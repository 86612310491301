/* eslint-disable eqeqeq */
import React from 'react';
import qs from 'querystring'
import { getRequestParams, getRequestUrl } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from './custom_views/utils_android/LocalizedStrings';
import ChooseType from './custom_views/universal_tasks/ChooseType';
import UniversalNameTask from './custom_views/universal_tasks/UniversalNameTask';
import UniversalUrl from './custom_views/universal_tasks/UniversalUrl';
import UniversalDescription from './custom_views/universal_tasks/UniversalDescription';
import ControlType from './custom_views/universal_tasks/ControlType';
import UniversalPrice from './custom_views/universal_tasks/UniversalPrice';
import { getControlTime, getOrderType, getOrderTypeTaskName } from './custom_views/utils_android/Utils';
import UpBalanceUniversal from './custom_views/universal_tasks/UpBalanceUniversal';
import book from '../pages_android/custom_views/custom_icons/book.svg'
import UniversalLimits from './custom_views/UniversalLimits';
import PreviewTaskDialog from './custom_views/dialogs/PreviewTaskDialog';
import CityPicker from './custom_views/analyze_review/customViews/CityPicker';
var strings = getLocalizedStrings()


class CreateUniversalOrder extends React.Component {
    constructor(props){
        super(props)
       
        this.state = {
            name : "",
            type : "",
            controlType : "",
            url : "",
            description : "",
            reviewText : "",
            secCount : 15,
            countInstalls : 100,
            priceOneInstall : 1,
            autoReview : strings.reviewDays1,
            sumOrder : 100,
            isClickedCreate : false,
            isAddRedirect : false,
            isShowUpBalance : false,
            minimumPrice : 1,
            isNeedScreen : false,
            isNeedMinTimeReview : false,

            isLimitDayChecked : false,
            cities : [{name : strings.allCities, is_selected : true}],
            limitDay : 100,

            autoAnswer : {isPicked : false, text : ""},
            csv : undefined,
            csvKeys : [],
            isCsvShuffle : false,
            isShowPreviewDialog : false
            
        }
        this.typePicked = this.typePicked.bind(this)
        this.nameChanged = this.nameChanged.bind(this)
        this.urlChanged = this.urlChanged.bind(this)
        this.descriptionChanged = this.descriptionChanged.bind(this)
        this.controlTypePicked = this.controlTypePicked.bind(this)
        this.reviewTextChanged = this.reviewTextChanged.bind(this)
        this.autoReviewPicked = this.autoReviewPicked.bind(this)
        this.secundsChanged = this.secundsChanged.bind(this)
        this.countInstallsChanged = this.countInstallsChanged.bind(this)
        this.priceOneInstallChanged = this.priceOneInstallChanged.bind(this)
        this.addRedirectChanged = this.addRedirectChanged.bind(this)
        this.isNeedScreenChanged = this.isNeedScreenChanged.bind(this)
        this.limitsChanged = this.limitsChanged.bind(this)
        this.autoAnswerChanged = this.autoAnswerChanged.bind(this)
        this.isShuffleChanged = this.isShuffleChanged.bind(this)
        this.isNeedMinTimeReview = this.isNeedMinTimeReview.bind(this)
        this.setСities = this.setСities.bind(this)
        this.setCsv = this.setCsv.bind(this)
    }
    typePicked(type){
        var minPrice = 1
        if (type == strings.typeAndroid || type == strings.typeIOs){
            minPrice = 5
        }
        if (type == strings.typeTG){
            minPrice = 1
        }
        this.calcSum(minPrice, this.state.countInstalls)
        this.setState({type : type, name : getOrderTypeTaskName(type), isClickedCreate : false, minimumPrice : minPrice, priceOneInstall : minPrice})
    }
    nameChanged(name){
        this.setState({name : name, isClickedCreate : false})
    }
    urlChanged(url){
        this.setState({url : url, isClickedCreate : false})
    }
    addRedirectChanged(isAddRedirect){
        this.setState({isAddRedirect : isAddRedirect})
    }
    descriptionChanged(description){
        this.setState({description : description, isClickedCreate : false})
    }
    controlTypePicked(controlType){
        const minPrice = this.state.type == strings.typeAndroid ? 5 : controlType == strings.controlTypeReview ? 3 : 1
        this.calcSum(minPrice, this.state.countInstalls)
        this.setState({controlType : controlType, isClickedCreate : false, minimumPrice : minPrice, priceOneInstall : minPrice})
        //this.typePicked(this.state.type)
    }
    reviewTextChanged(reviewText){
        this.setState({reviewText : reviewText, isClickedCreate : false})
    }
    autoReviewPicked(autoReview){
        this.setState({autoReview : autoReview, isClickedCreate : false})
    }
    isNeedScreenChanged(isNeedScreen){
        this.setState({isNeedScreen : isNeedScreen})
    }
    isNeedMinTimeReview(isNeedMinTimeReview){
        this.setState({isNeedMinTimeReview : isNeedMinTimeReview})
    }
    limitsChanged(isLimitDayChecked, limitDay){
        this.setState({isLimitDayChecked : isLimitDayChecked, limitDay : limitDay})
    }
    secundsChanged(secCount){
        var minPrice = 0.5
        if (secCount > 60){
            minPrice = parseFloat(secCount / 60 * 0.5).toFixed(1)
        }
        if (this.state.type == strings.typeAndroid || this.state.type == strings.typeIOs){
            minPrice = 5
        }

        if (this.state.type == strings.typeTG){
            minPrice = 1
        }

        this.setState({secCount : secCount, isClickedCreate : false, minimumPrice : minPrice, priceOneInstall : minPrice})
        this.calcSum(minPrice, this.state.countInstalls)
    }
    togglePreviewDialog = () => {
        this.setState({isShowPreviewDialog : !this.state.isShowPreviewDialog})
    }
    countInstallsChanged(countInstalls){
        this.setState({countInstalls : countInstalls, isClickedCreate : false})
        this.calcSum(this.state.priceOneInstall, countInstalls)
    }
    priceOneInstallChanged(priceOneInstall){
        this.setState({priceOneInstall : priceOneInstall, isClickedCreate : false})
        this.calcSum(priceOneInstall, this.state.countInstalls)
    }
    calcSum = (priceOneInstall, countInstalls) => {
        var sum = priceOneInstall * countInstalls
        this.setState({sumOrder : sum})
    }
    autoAnswerChanged(autoAnswer){
        this.setState({autoAnswer : autoAnswer})
    }
    isShuffleChanged(value){
        this.setState({isCsvShuffle : value})
    }
    setCsv(csv, isCsvShuffle, headers){
        this.setState({csv : csv, isCsvShuffle : !isCsvShuffle, csvKeys : headers})
    }
    setСities(mCities){
        this.setState({cities : mCities})
    }
    validateOrder = () => {
        if (this.state.priceOneInstall * this.state.countInstalls < 50) {
            this.props.showToast(strings.minimalOrderSum + " 50₽")
            return false
        }
        if (this.state.priceOneInstall < this.state.minimumPrice) {
            this.props.showToast(strings.minimalPricePerTask + " " + this.state.minimumPrice + "₽")
            return false
        }
        if (this.state.name == "") {
            this.props.showToast(strings.enterTaskName)
            return false
        }
        if (this.state.controlType == "") {
            this.props.showToast(strings.chooseControlType)
            return false
        }
        if (this.state.url == "") {
            this.props.showToast(strings.enterUrl)
            return false
        }
        if (this.state.description == "") {
            this.props.showToast(strings.enterDescription)
            return false
        }
        if (this.state.type == "") {
            this.props.showToast(strings.chooseTaskType)
            return false
        }
        return true
    }
    createOrder = () => {
        this.setState({isShowUpBalance : false})
        if (!this.validateOrder()) {
            this.setState({isClickedCreate : true})
            return
        }
        if (this.state.controlType == strings.controlTypeReview){
            if (this.state.reviewText == ""){
                this.setState({isClickedCreate : true})
                return
            }
        } else {
            if (this.state.secCount == 0 || this.state.secCount == ""){
                this.setState({isClickedCreate : true})
                return
            }
        }
        var params = getRequestParams()
        params.count_money_r = this.state.sumOrder
        params.count_price_customer_r = this.state.priceOneInstall
        if (this.state.isAddRedirect){
            params.url = "https://vk.com/away.php?utf=1&to=" + this.state.url.toString()
        } else {
            params.url = this.state.url.toString()
        }
        
        params.name = this.state.name
        params.description = this.state.csv != undefined ? this.checkCsv() : this.state.description
        
        if (this.state.cities.filter(c => c.is_selected).length > 0 && !this.state.cities[0].is_selected){
            const mCities = this.state.cities.filter(c => c.is_selected)
            params.city = mCities.map(x => x.name).join(",")
        }
        if (this.state.controlType == strings.controlTypeReview){
            params.control_text = this.state.reviewText
            params.hour_autocomplete = getControlTime(this.state.autoReview)
            params.is_need_screenshot = this.state.isNeedScreen
            if (this.state.autoAnswer.isPicked){
                params.correct_answer = this.state.autoAnswer.text
            }
            if (this.state.isNeedMinTimeReview){
                params.seconds_count = this.state.secCount
            }
        } else if (this.state.controlType == strings.controlTypeTime) {
            params.seconds_count = this.state.secCount
        }
        if (this.state.isLimitDayChecked){
            params.limit_count_in_day = this.state.limitDay
        }
        params.type_order = getOrderType(this.state.type)
        console.log("addNewOrderYoutube params = ", params)
        
        fetch(getRequestUrl() + "addNewOrderYoutube", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == "1") {
                window.location.href = "/universal_orders"
                this.props.showToast("Заказ успешно создан")
            } else {
                this.setState({isShowUpBalance : true})
            }
        })
    }
    upBalanceUniversal = () => {
        window.open('/pay', '_blank');
    }
    closeUpBalance = () => {
        this.setState({isShowUpBalance : !this.state.isShowUpBalance})
    }
    showUniversalBlog = () => {
        // window.location.href = "https://blog.upmob.ru/kak-nakrutit-zagotovlennye-otzyvy-v-google-play/"
        window.open('https://blog.upmob.ru/kak-nakrutit-zagotovlennye-otzyvy-v-google-play/', '_blank')
    }
    checkCsv = () => {
        var final = this.state.description.toString()
        this.state.csvKeys.forEach(element => {
            if (final.includes(element)){
                final = final.replaceAll(element, this.getCsvValues(element))
            }
        });
        if (!this.state.isCsvShuffle){
            final = "[shuffle]" + final
        }
        return final
    }
    getCsvValues = (key) => {
        var values = "["
        this.state.csv.forEach(element => {
            if (element[key] != "" && element[key] != undefined){
                if (values == "["){
                    values += element[key].replaceAll("\"", "")
                } else {
                    values += (";" + element[key].replaceAll("\"", ""))
                }
            }
        });
        values += "]"
        return values
    }
    render() {
        
        const isEnableCreated = (this.state.priceOneInstall * this.state.countInstalls >= 50) && this.state.priceOneInstall >= this.state.minimumPrice && this.state.name != "" && this.state.controlType != "" && this.state.url != "" && this.state.description != "" && this.state.type != ""
        //const isEnableCreated = this.state.priceOneInstall < this.state.minimumPrice && this.state.name == "" && this.state.controlType == "" && this.state.url == "" && this.state.description == "" && this.state.type == ""
        return (
            <div className="display_block">
                <div onClick={this.showUniversalBlog} className="link_to_universal hover">
                    <img className="img_univ_link" src={book}/>
                    <p className="link_text">{strings.linkToUniversal}</p>
                </div>
                <div className="main_container">
                    {this.props.count_money_r_all_from_unitpay < 100 && <p className="up_balance_universal">{strings.upBalanceUniversal} <b onClick={this.upBalanceUniversal} className="hover">{strings.addMoney}</b></p>}
                    <p className="main_title universal_title">{strings.newUniversalOrder}</p>
                    <p className="universal_title_2">{strings.mustHaveOrder}</p>
                    <ChooseType isClickedCreate={this.state.isClickedCreate} typePicked={this.typePicked}/>
                    <div className="line-row"/>
                    <UniversalNameTask isClickedCreate={this.state.isClickedCreate} name={this.state.name} nameChanged={this.nameChanged}/>
                    <div className="line-row"/>
                    <UniversalUrl csv={this.state.csv} isClickedCreate={this.state.isClickedCreate} urlChanged={this.urlChanged}/>
                    <div className="line-row"/>
                    {/* <AddRedirect addRedirectChanged={this.addRedirectChanged}/>
                    <div className="line-row"/> */}
                    <UniversalDescription togglePreviewDialog={this.togglePreviewDialog} isShowPreviewDialog={this.state.isShowPreviewDialog} isShuffleChanged={this.isShuffleChanged} isCsvShuffle={this.state.isCsvShuffle} csvKeys={this.state.csvKeys} csv={this.state.csv} setCsv={this.setCsv} isClickedCreate={this.state.isClickedCreate} descriptionChanged={this.descriptionChanged}/>
                    <div className="line-row"/>
                    <CityPicker cities={this.state.cities} setСities={this.setСities}/>
                    <div className="line-row"/>
                    <ControlType isNeedMinTimeReview={this.isNeedMinTimeReview} csv={this.state.csv} autoAnswer={this.state.autoAnswer} autoAnswerChanged={this.autoAnswerChanged} isNeedScreenChanged={this.isNeedScreenChanged} isClickedCreate={this.state.isClickedCreate} secundsChanged={this.secundsChanged} autoReviewPicked={this.autoReviewPicked} reviewTextChanged={this.reviewTextChanged} typePicked={this.controlTypePicked}/>
                    <div className="line-row"/>
                    <UniversalPrice minimumPrice={this.state.minimumPrice} isClickedCreate={this.state.isClickedCreate} countInstallsChanged={this.countInstallsChanged} priceOneInstallChanged={this.priceOneInstallChanged}/>
                    <div className="line-row"/>
                    <UniversalLimits limitsChanged={this.limitsChanged}/>
                    <div className="line-row"/>
                    
                    <div className="create_order_container">
                        <div className="mobile_cost">
                            <p className="text-cost-left">{strings.orderPrice}</p>
                            <p className="text-cost-right">{this.state.sumOrder}₽</p>
                        </div>
                        <div className="relative">
                            <div onClick={this.createOrder} className="yellow_btn create_order_btn">{strings.saveOrder}</div>
                            {this.state.isShowUpBalance && <UpBalanceUniversal closeUpBalance={this.closeUpBalance} count_money_r={this.props.count_money_r} sumOrder={this.state.sumOrder}/>}
                        </div>
                        <div onClick={this.togglePreviewDialog} className="hover preview_task_btn">{strings.previewTaskBtn}</div>

                        <div className="display_block">
                            <div className="land_cost univ_btn_top">
                                <p className="text-cost-left">{strings.orderPrice}</p>
                                <p className="text-cost-right">{this.state.sumOrder}₽</p>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                {this.state.isShowPreviewDialog && <PreviewTaskDialog orderState={this.state} togglePreviewDialog={this.togglePreviewDialog}/>}
            </div>
           
        );
    }
}
export default CreateUniversalOrder;