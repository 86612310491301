/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Menu from './components_android/MenuAndroid'
import Navbar from './components_android/NavbarAndroid'
import './css_android/style_android.css';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import { getUrlParameter } from './pages_android/custom_views/utils_android/UrlHelperAndroid';
import { getRequestParams, getRequestUrl, getToken, setCookieData, setToken, getEmail, getCookie, setCookie} from './pages_android/custom_views/utils_android/CookieHelper'
import Loader from './pages_android/custom_views/utils_android/LoaderAndroid';
import Orders from './pages_android/Orders';
import Auth from './pages_android/Auth';
import CreateOrder from './pages_android/CreateOrder';
import Referals from './pages_android/Referals';
import Statistics from './pages_android/Statistics';
import Aso from './pages_android/Aso';
import Pay from './pages_android/Pay';
import Policy from './pages_android/Policy';
import UniversalOrders from './pages_android/UniversalOrders';
import CreateUniversalOrder from './pages_android/CreateUniversalOrder';
import Reviews from './pages_android/Reviews';
import { getHttpParams, Pages } from './pages_android/custom_views/analyze_review/utils/Utils';
import Social from './pages_android/Social';
import CreateBotOrder from './pages_android/bot_orders/CreateBotOrder';
import BotOrders from './pages_android/bot_orders/BotOrders';
import BotOrderInfo from './pages_android/bot_orders/BotOrderInfo';
import Admin from './pages_android/Admin';
import { isAdmins } from './pages_android/admin_views/utils/AdminUtils';
import Settings from './pages_android/Settings';
import AdminEmployerInfo from './pages_android/admin_views/AdminEmployerInfo';
import CreateSiteOrder from './pages_android/site_orders/CreateSiteOrder';
import SiteOrders from './pages_android/site_orders/SiteOrders';
import Toast from './pages_android/custom_views/utils_android/Toast';

const Application = () => {
    const [state, setState] = useState({
        done: true,
        count_money_r: 0,
        email: "",
        first_name: "",
        _id: "",
        refList: [],
        badgeCount: 0,
        count_money_r_all_from_unitpay: 0,
        exchangeRate: 90.0,
        info: {}
    });
    const [toastText, setToastText] = useState("")
    useEffect(() => {
        const loadVKWidget = () => {
            if (window.VK) {
                try {
                    window.VK.Widgets.CommunityMessages("vk_community_messages", 157374277, {
                        disableExpandChatSound: "1",
                        tooltipButtonText: "Есть вопрос?",
                        disableButtonTooltip: "1"
                    });
                } catch (e) {
                    console.error('VK widget initialization error:', e);
                }
            }
        };

        if (!document.getElementById('vk-openapi')) {
            const script = document.createElement('script');
            script.src = 'https://vk.com/js/api/openapi.js?169';
            script.id = 'vk-openapi';
            script.async = true;
            script.onload = () => {
                window.VK.init({
                    apiId: 157374277
                });
                loadVKWidget();
            };
            document.body.appendChild(script);
        } else {
            loadVKWidget();
        }

        return () => {
            const script = document.getElementById('vk-openapi');
            if (script) {
                script.remove();
            }
        };
    }, []);
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function () {
            setToastText("")
        }, 5000)
    }
    useEffect(() => {
        const token = getUrlParameter('token');
        if (token) {
            setToken(token);
        }

        const picked_lang = getCookie("picked_lang");
        if (!picked_lang) {
            const lang = navigator.language.includes('ru') ? 'ru' : 'en';
            setCookie("picked_lang", lang);
        }

        if (!window.location.href.includes("auth")) {
            getMyInfo();
            getBadgeCount();
            getExchange();
        }

        if (!getToken() && !window.location.href.includes("auth") && 
            !window.location.href.includes("policy") && 
            !window.location.href.includes("agreement")) {
            window.location.href = '/auth';
        }
    }, []);

    const getMyInfo = useCallback(async () => {
        try {
            const response = await fetch(getRequestUrl() + "getMyInfo", getHttpParams(getRequestParams()));
            const responseData = await response.json();
            
            if (responseData.response <= 0 || !responseData.customer) {
                setToken("");
                window.location.href = '/auth';
                return;
            }

            if (responseData.customer) {
                setState(prev => ({
                    ...prev,
                    info: responseData.customer,
                    count_money_r: responseData.customer.count_money_r,
                    email: responseData.customer.email,
                    first_name: responseData.customer.first_name,
                    _id: responseData.customer._id,
                    refList: responseData.customer.referal_earning_list_new.reverse(),
                    count_money_r_all_from_unitpay: responseData.customer.count_money_r_all_from_unitpay
                }));

                if (responseData.customer.email !== getEmail()) {
                    setCookieData(responseData.customer.email, responseData.customer.first_name);
                }
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
    }, []);

    const getExchange = () => {
        var params = getRequestParams()
        fetch(getRequestUrl() + "getExchange", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1){
                setState(prev => ({
                    ...prev,
                    exchangeRate: responseData.rate
                }));
            }
        })
    }
  
    const updateMoney = useCallback((money) => {
        setState(prev => ({
            ...prev,
            count_money_r: prev.count_money_r + parseFloat(money)
        }));
    }, []);

    const setMoney = (money) => {
        setState(prev => ({
            ...prev,
            count_money_r: money
        }));
    }
    const onOrderCreate = () => {
        setState(prev => ({
            ...prev,
            isOrderCreate: true
        }));
    }
    const getBadgeCount = () => {
        var params = getRequestParams()
        fetch(getRequestUrl() + "getCountNeedCheckOrdersYoutube", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1){
                setState(prev => ({
                    ...prev,
                    badgeCount: responseData.count
                }));
            }            
        })
    }
    const setMyInfo = (info) => {
        setState(prev => ({
            ...prev,
            info: info
        }));
    }
    const removeBadge = useCallback(() => {
        setState(prev => ({
            ...prev,
            badgeCount: Math.max(0, prev.badgeCount - 1)
        }));
    }, []);
    
    if (!state.done) {
        return <div><Loader/></div>;
    }

    return (
        <Router>
            <div className="app">
                <aside className="aside">
                    <Menu badgeCount={state.badgeCount} email={state.email}/>
                </aside>
                <div className="main">
                    <Navbar 
                        exchangeRate={state.exchangeRate}
                        first_name={state.first_name}
                        email={state.email}
                        count_money_r={state.count_money_r}
                    />
                    <section>
                        <main>
                            <Switch>
                                <Route path={Pages.orders}>
                                    <Orders 
                                        exchangeRate={state.exchangeRate}
                                        updateMoney={updateMoney}
                                        getMyInfo={getMyInfo}
                                        count_money_r={state.count_money_r}
                                    />
                                </Route>
                                <Route path={Pages.auth}>
                                    <Auth />
                                </Route>
                                <Route path={Pages.newOrder}>
                                    <CreateOrder exchangeRate={state.exchangeRate}/>
                                </Route>
                                <Route path={Pages.createInstallsOrder}>
                                    <CreateBotOrder count_money_r={state.count_money_r} exchangeRate={state.exchangeRate}/>
                                </Route>
                                <Route path={Pages.createOrderSite}>
                                    <CreateSiteOrder updateMoney={updateMoney} count_money_r={state.count_money_r} exchangeRate={state.exchangeRate}/>
                                </Route>
                                <Route path={Pages.siteSeoOrders}>
                                    <SiteOrders updateMoney={updateMoney} getMyInfo={getMyInfo} count_money_r={state.count_money_r} exchangeRate={state.exchangeRate}/>
                                </Route>
                                <Route path={Pages.installsOrders}>
                                    <BotOrders getMyInfo={getMyInfo} count_money_r={state.count_money_r} exchangeRate={state.exchangeRate}/>
                                </Route>
                                <Route path={Pages.installsOrderInfo}>
                                    <BotOrderInfo getMyInfo={getMyInfo} count_money_r={state.count_money_r} exchangeRate={state.exchangeRate}/>
                                </Route>
                                <Route path={Pages.referals}>
                                    <Referals exchangeRate={state.exchangeRate} refList={state.refList} _id={state._id} />
                                </Route>
                                <Route path={Pages.statistics}>
                                    <Statistics />
                                </Route>
                                <Route path={Pages.seo}>
                                    <Aso />
                                </Route>
                                <Route path={Pages.pay}>
                                    <Pay exchangeRate={state.exchangeRate} count_money_r={state.count_money_r}/>
                                </Route>
                                <Route path={Pages.policy}>
                                    <Policy />
                                </Route>
                                <Route path={Pages.universalOrders}>
                                    <UniversalOrders removeBadge={removeBadge} exchangeRate={state.exchangeRate} updateMoney={updateMoney} getMyInfo={getMyInfo} count_money_r={state.count_money_r} />
                                </Route>
                                <Route path={Pages.createUniversal}>
                                    <CreateUniversalOrder showToast={showToast} count_money_r={state.count_money_r} count_money_r_all_from_unitpay={state.count_money_r_all_from_unitpay}/>
                                </Route>
                                <Route path={Pages.social}>
                                    <Social count_money_r={state.count_money_r} count_money_r_all_from_unitpay={state.count_money_r_all_from_unitpay}/>
                                </Route>
                                <Route path={Pages.reviews}>
                                    <Reviews email={state.email}/>
                                </Route>
                                <Route path={Pages.settings}>
                                    <Settings setMyInfo={setMyInfo} info={state.info}/>
                                </Route>
                                {isAdmins() && 
                                    <Route path={Pages.admin}>
                                        <Admin getMyInfo={getMyInfo} count_money_r={state.count_money_r} exchangeRate={state.exchangeRate}/>
                                    </Route>
                                }
                                {isAdmins() && 
                                    <Route path={Pages.employerInfo}>
                                        <AdminEmployerInfo/>
                                    </Route>
                                }
                            </Switch>
                        </main>
                    </section>
                </div>
                {toastText != "" && <Toast text={toastText} />}

            </div>
        </Router>
    );
};

ReactDOM.render(<Application/>, document.getElementById('root'));
ReactDOM.render(
    !navigator.language.includes("en") ? "Продвижение мобильных приложений" : "Mobile apps promotion",
    document.getElementsByTagName("title")[0]
);
window.onResume = () => {
    console.log("onResume index")
    if (window.photoTask != undefined){
        window.photoTask.onResume();
    }
};

  // "sass": "^1.32.12",
    // "react-tag-input": "^6.4.3",
    // "universal-cookie": "^4.0.3"
    // "react-papaparse": "^3.18.2",
    // "react-dnd": "^5.0.0",
    // "react-contenteditable": "^3.3.4",
    // "@material-ui/core": "^4.9.11",
    // "react-dnd-html5-backend": "^3.0.2",